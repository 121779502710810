import React, { createContext, useCallback, useState, useContext } from 'react';
import jwt_decode from 'jwt-decode';
import api from '../services/api';

interface User {
  nome: string;
  email: string;
  colaborador_id: number;
  email_verificado: string;
}

interface Company {
  id: number;
  nome: string;
  role: string;
}

interface AuthState {
  token: string;
  user: User;
  company: Company;
  companies: Company[];
}

interface SignInCredentials {
  email: string;
  password: string;
  confirm?: string;
}

interface TokenSignInCredentials {
  contract_token: string;
  support_token: string;
}

interface IToken {
  token: string;
  empresas: Company[];
}

interface AuthContextData {
  user: User;
  company: Company;
  companies: Company[];
  signIn(credentials: SignInCredentials): Promise<any>;
  tokenSignIn(credentials: TokenSignInCredentials): Promise<any>;
  signOut(): void;
  updateUser(user: User): void;
  updateCompany(company: Company): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Beersales:token');
    const user = localStorage.getItem('@Beersales:user');
    const company = localStorage.getItem('@Beersales:company');

    if (token && user && company) {
      const company_object = JSON.parse(company);

      api.defaults.headers.authorization = `Bearer ${token}`;
      api.defaults.headers.common.company = company_object.id;
      const decoded: IToken = jwt_decode(token);
      const { empresas } = decoded;

      return {
        token,
        user: JSON.parse(user),
        companies: empresas,
        company: company_object,
      };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password, confirm }) => {
    const response = await api.post(
      'auth/login',
      { email, password },
      {
        headers: { confirm },
      },
    );

    const { token, user, company } = response.data;
    localStorage.setItem('@Beersales:token', token);
    localStorage.setItem('@Beersales:user', JSON.stringify(user));
    localStorage.setItem('@Beersales:company', JSON.stringify(company));

    api.defaults.headers.authorization = `Bearer ${token}`;
    api.defaults.headers.common.company = company.id;

    const decoded: IToken = jwt_decode(token);
    const { empresas } = decoded;

    setData({ token, user, company, companies: empresas });
    return response;
  }, []);

  const tokenSignIn = useCallback(async ({ contract_token, support_token }) => {
    const response = await api.post('auth/token-login', { contract_token, support_token });

    const { token, user, company } = response.data;
    localStorage.setItem('@Beersales:token', token);
    localStorage.setItem('@Beersales:user', JSON.stringify(user));
    localStorage.setItem('@Beersales:company', JSON.stringify(company));

    api.defaults.headers.authorization = `Bearer ${token}`;
    api.defaults.headers.common.company = company.id;

    const decoded: IToken = jwt_decode(token);
    const { empresas } = decoded;

    setData({ token, user, company, companies: empresas });
    return response;
  }, []);

  const updateUser = useCallback(
    (user: User) => {
      localStorage.setItem('@Beersales:user', JSON.stringify(user));

      setData({
        token: data.token,
        user,
        company: data.company,
        companies: data.companies,
      });
    },
    [setData, data.token, data.company, data.companies],
  );

  const updateCompany = useCallback(
    (company: Company) => {
      localStorage.setItem('@Beersales:company', JSON.stringify(company));
      api.defaults.headers.common.company = company.id;
      setData({
        token: data.token,
        company,
        user: data.user,
        companies: data.companies,
      });
    },
    [setData, data.token, data.user, data.companies],
  );

  const signOut = useCallback(() => {
    localStorage.removeItem('@Beersales:token');
    localStorage.removeItem('@Beersales:user');
    localStorage.removeItem('@Beersales:company');
    api.post('auth/logout');
    setData({} as AuthState);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        company: data.company,
        companies: data.companies,
        user: data.user,
        signIn,
        tokenSignIn,
        updateUser,
        updateCompany,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within as AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
