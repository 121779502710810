import React, {
  InputHTMLAttributes,
  useState,
  useCallback,
  ReactElement,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { Container, Prefixo } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  prefixo?: string;
  isDisabled?: boolean;
  showPasswordIcon?: boolean;
  icon?: React.ComponentType<IconBaseProps>;
  error?: string;
  limpa_input?: ReactElement;
}

const Input: React.FC<InputProps> = ({
  error,
  limpa_input,
  name,
  label,
  prefixo,
  icon: Icon,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(true);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  return (
    <Container
      style={rest.style}
      isFilled={isFilled}
      isFocused={isFocused}
      isErrored={!!error}
    >
      <label htmlFor={name}>
        {label}
        <div>
          {Icon && <Icon size={20} />}
          {prefixo && <Prefixo>{prefixo}</Prefixo>}
          <input
            {...rest}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
          />
          {limpa_input && limpa_input}
        </div>
        {error && <span>{error}</span>}
      </label>
    </Container>
  );
};

export default Input;
