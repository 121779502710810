import styled, { css } from 'styled-components';
import { shade } from 'polished';
import { ListItem } from '@material-ui/core';
import * as colors from '../../config/Colors/Ligth';

type ConteudoBotaoProps = {
  selected?: boolean;
};

export const TextoBarraSuperior = styled.div`
  display: flex;
  flex-direction: row;
  p {
    @media only screen and (max-width: 900px) {
      display: none;
    }
  }
`;

export const LinkMenuLateral = styled(ListItem) <ConteudoBotaoProps>`
  margin: 0;
  padding: 0;
  height: 4.5rem;
  div {

    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    padding: 0 15px;
    color: ${colors.ligthFontColor};
    ${props =>
    props.selected &&
    css`
        background: ${shade(0.05, colors.menuLateralBackgroundColor)};
        color: ${shade(0.1, colors.backgroudColor)};
      `}

  }

  span{

    margin-left:3%

  }
`;


