import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  ReactElement
} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { confirmAlert } from 'react-confirm-alert';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FaList, FaDolly } from 'react-icons/fa';
import Badge from '../../../../components/Badge';
import IPedidoTipoEquipamento from '../../../../interfaces/pedidoTipoEquipamento';
import api from '../../../../services/api';
import {
  Container,
  Header,
  Footer,
  Body,
  ContainerBotoesCatalogoCarrinho,
} from './styles';
import Loading from '../../../../components/Loading';
import BotaoDefault from '../../../../components/Botoes/BotaoDefault';
import BotaoSalvar from '../../../../components/Botoes/BotaoSalvar';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useToast } from '../../../../hooks/toast';
import Pesquisa from '../../../../components/Pesquisa';
import CardTipoEquipamento from './CardTipoEquipamento';

interface ITipoEquipamento {
  tipo_equipamento_id: number;
  nome: string;
}

interface IModalProps {
  texto_botao: string | ReactElement;
  status_id: number;
  pedido_id: number;
  pedido_tipo_equipamento: IPedidoTipoEquipamento[];
  setPedidoTipoEquipamento: (pedido_tipo_equipamento: IPedidoTipoEquipamento[]) => void;
  carregar_pesquisa?: string;
  setCodRetornoIntegracao?: (cod: number) => void;
}

const ModalPesquisaTipoEquipamento: React.FC<IModalProps> = ({ texto_botao, status_id, pedido_id, pedido_tipo_equipamento, setPedidoTipoEquipamento, carregar_pesquisa, setCodRetornoIntegracao }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [mostra_filtros, setMostraFiltros] = useState(false);
  const [page, setPage] = useState(1);
  const [max_pages, setMaxPages] = useState(1);
  const [increment_page, setIncrementPage] = useState(false);

  const [search, setSearch] = useState('');
  const [modalIsOpen, setIsOpen] = useState(false);
  const [exibe_somente_itens_pedido, setExibeSomenteItensPedido] = useState(
    false,
  );
  const [tipo_equipamentos, setTipoEquipamentos] = useState<ITipoEquipamento[]>([]);
  const { addToast } = useToast();
  const [copia_pedido_tipo_equipamento, setCopiaPedidoTipoEquipamento] = useState<IPedidoTipoEquipamento[]>(
    pedido_tipo_equipamento || []
  );

  function openModal(): void {
    setSearch(carregar_pesquisa || '');
    setExibeSomenteItensPedido(false);
    setIsOpen(true);
  }

  function closeModal(): void {
    const find_tipo_equipamento = copia_pedido_tipo_equipamento?.find(item => !item.salvo);
    if (find_tipo_equipamento) {
      confirmAlert({
        title: 'Atenção!',
        message:
          'Há alterações não salvas no pedido. Gostaria de sair sem salvar?',
        buttons: [
          {
            label: 'Sair sem salvar',
            onClick: () => setIsOpen(false),
          },
          {
            label: 'Cancelar',
            onClick: () => setIsOpen(true),
          },
        ],
      });
    } else {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    setCopiaPedidoTipoEquipamento(pedido_tipo_equipamento || []);
  }, [pedido_tipo_equipamento]);

  useEffect(() => {

    async function get_tipo_equipamentos(): Promise<void> {
      try {

        let response;
        if (exibe_somente_itens_pedido) {
          const lista = copia_pedido_tipo_equipamento.map(tipo_equipamento => tipo_equipamento.quantidade > 0 && tipo_equipamento.tipo_equipamento_id);
          response = await api.get(`tipo-equipamento?page=1`, {
            params: {
              nome: search,
              tipo_equipamentos: lista,
              limit: 100,
            },
          });

        } else {
          response = await api.get(`tipo-equipamento?page=${page}`, {
            params: {
              nome: search,
              limit: 10,
            },
          });

        }
        setMaxPages(response?.data.last_page || 0);

        if (increment_page && !exibe_somente_itens_pedido) {
          setTipoEquipamentos([...tipo_equipamentos, ...response?.data.data]);
        } else {
          setTipoEquipamentos(response?.data.data);
        }

      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
        setIncrementPage(false);
      }
    }

    if (!increment_page) {
      setPage(1);
      setIsLoading(true);
    }
    get_tipo_equipamentos();
  }, [search, page, pedido_id, pedido_tipo_equipamento, exibe_somente_itens_pedido]);

  const tipo_equipamentos_exibidos: IPedidoTipoEquipamento[] = useMemo(() => {
    return tipo_equipamentos.map(tipo_equipamento => {
      const tipo_equipamento_pedido = copia_pedido_tipo_equipamento.find(item => Number(item.tipo_equipamento_id) === Number(tipo_equipamento.id));

      return {
        id: tipo_equipamento_pedido?.id || null,
        pedido_id,
        nome_tipo_equipamento: `${tipo_equipamento.nome}`,
        tipo_equipamento_id: tipo_equipamento.id,
        quantidade: Number(tipo_equipamento_pedido?.quantidade) || 0,
        salvo: true,
      };
    });
  }, [tipo_equipamentos, copia_pedido_tipo_equipamento, pedido_id]);

  const total = useMemo(() => {
    const itens = copia_pedido_tipo_equipamento?.filter(item => Number(item.quantidade) > 0);

    const quantidade = itens?.length;

    return { quantidade };
  }, [copia_pedido_tipo_equipamento]);

  function handleNovoPedidoTipoEquipamento(tipo_equipamento_id: number, numero: number, tipo: "quantidade" | "valor") {

    const find_tipo_equipamento_pedido = tipo_equipamentos_exibidos?.find(
      item => Number(item.tipo_equipamento_id) === Number(tipo_equipamento_id),
    );

    if (!find_tipo_equipamento_pedido) return;
    const novo_item: IPedidoTipoEquipamento = {
      id: null,
      pedido_id,
      tipo_equipamento_id: find_tipo_equipamento_pedido.tipo_equipamento_id,
      nome_tipo_equipamento: find_tipo_equipamento_pedido.nome_tipo_equipamento,
      quantidade: tipo === "quantidade" ? numero : 0,
      salvo: false,
      cod_retorno_integracao: find_tipo_equipamento_pedido.cod_retorno_integracao,
      motivo_integracao: find_tipo_equipamento_pedido.motivo_integracao
    };

    setCopiaPedidoTipoEquipamento([...copia_pedido_tipo_equipamento, novo_item]);

  }

  function handleQuantidade(tipo_equipamento_id: number, quantidade: string) {

    const clean_input = quantidade.replace(',', '.');
    const allowedChars = '01234567890.';
    const quantidade_clean = Array.from(clean_input).filter(f => allowedChars.includes(f)).join('');

    if (status_id && status_id > 2 ||
      Number(quantidade_clean) > 9999999.999 ||
      Number(quantidade_clean) < 0
    )
      return;

    const find_tipo_equipamento_pedido = copia_pedido_tipo_equipamento?.find(
      item => Number(item.tipo_equipamento_id) === Number(tipo_equipamento_id),
    );

    if (find_tipo_equipamento_pedido) {
      const itens_alocados = copia_pedido_tipo_equipamento?.map(item => {
        return Number(item.tipo_equipamento_id) === Number(tipo_equipamento_id)
          ? {
            ...item,
            salvo: false,
            quantidade: Number(quantidade_clean),
            valor_desconto: 0
          }
          : {
            ...item,
          };
      });
      setCopiaPedidoTipoEquipamento(itens_alocados);
    } else {
      handleNovoPedidoTipoEquipamento(tipo_equipamento_id, Number(quantidade_clean), "quantidade")
    }
  }

  const Salvar = useCallback(async () => {
    try {
      setIsLoading(true);

      const filtered_itens = copia_pedido_tipo_equipamento?.filter(item => {
        return (
          !item.salvo &&
          (item.quantidade > 0 || (item.quantidade === 0 && item.id))
        );
      });

      if (status_id > 1) {
        const itens_com_quantidade = copia_pedido_tipo_equipamento?.filter(item => { return (item.quantidade > 0) });
        if (itens_com_quantidade.length === 0) {
          addToast({
            type: 'error',
            title: 'Atenção! Essa operação não é permitida.',
            description: "O pedido deve possuir ao menos um item!",
          });
          return;
        }
      }

      const data_tipo_equipamentos = filtered_itens.map(item => {
        return {
          id: item.id,
          pedido_id: item.pedido_id,
          tipo_equipamento_id: item.tipo_equipamento_id,
          quantidade: item.quantidade,
        };
      });
      const response = await api.post('pedido-tipo-equipamento/save', data_tipo_equipamentos);
      setPedidoTipoEquipamento(
        copia_pedido_tipo_equipamento
          .filter(item => {
            return item.quantidade > 0;
          })
          .map(filtered => {
            const novo_item = response.data.find(
              retorno => filtered.tipo_equipamento_id === retorno.tipo_equipamento_id,
            );
            return {
              ...filtered,
              id: novo_item ? novo_item.id : filtered.id,
              salvo: true,
              cod_retorno_integracao: 202,
            };
          }),
      );

      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: 'TipoEquipamento(s) salvo(s) com sucesso.',
      });
      if (setCodRetornoIntegracao && status_id > 1) {
        setCodRetornoIntegracao(202);
      }
      setIsOpen(false);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Houve um erro ao salvar o(s) tipo_equipamento(s).',
        description: err.response?.data?.message,
      });
    } finally {
      setIsLoading(false);
    }
  }, [addToast, copia_pedido_tipo_equipamento, setCodRetornoIntegracao, status_id, setPedidoTipoEquipamento]);

  const handlePage = (): void => {
    if (page < max_pages) {
      setIncrementPage(true);
      setPage(page + 1);
    }
  };

  function handleCarrinho(exibe: boolean) {
    setPage(1);
    if (!exibe) {
      setExibeSomenteItensPedido(exibe)
    }
    else if (total.quantidade > 0) {
      setExibeSomenteItensPedido(exibe);
    }
  }

  return (
    <>
      <BotaoDefault
        title="Ir para o catálogo de tipo_equipamentos"
        onClick={() => openModal()}
      >
        {texto_botao}
      </BotaoDefault>
      <Container onClose={closeModal} open={modalIsOpen} fullScreen>
        <Loading isLoading={isLoading} />
        <Header>
          <h1>&nbsp;</h1>
          <h1>TIPOS DE EQUIPAMENTOS</h1>
          {closeModal ? (
            <BotaoDefault onClick={closeModal}>
              <CloseIcon />
            </BotaoDefault>
          ) : null}
        </Header>
        <Body id="scrollable">
          <InfiniteScroll
            dataLength={tipo_equipamentos_exibidos.length}
            next={!exibe_somente_itens_pedido && handlePage}
            hasMore
            loader={!exibe_somente_itens_pedido && <h4>Carregando...</h4>}
            scrollableTarget="scrollable"
          >
            <ContainerBotoesCatalogoCarrinho
              mostra_carrinho={exibe_somente_itens_pedido}
            >
              <div>
                <BotaoDefault onClick={() => handleCarrinho(false)}>
                  <FaList size={20} />
                  LISTAGEM
                </BotaoDefault>
              </div>
              <div>
                <BotaoDefault onClick={() => handleCarrinho(true)}>
                  <Badge display={total.quantidade}>
                    <FaDolly size={20} />
                    ALOCADOS
                  </Badge>
                </BotaoDefault>
              </div>
            </ContainerBotoesCatalogoCarrinho>
            <Pesquisa
              setPage={setPage}
              placeholder="Informe o nome ou código do tipo_equipamento"
              pesquisa={search}
              setPesquisa={setSearch}
              filtros={!exibe_somente_itens_pedido && (
                { mostra_filtros, setMostraFiltros }
              )}
            />
            {tipo_equipamentos_exibidos.map(tipo_equipamento => (
              <CardTipoEquipamento
                key={tipo_equipamento.tipo_equipamento_id}
                tipo_equipamento={tipo_equipamento}
                status_id={status_id}
                handleQuantidade={handleQuantidade}
              />
            ))}
          </InfiniteScroll>
        </Body>
        <Footer>
          <div>
            <div>
              <p>{`ITENS: ${total.quantidade} `}</p>
            </div>

            <div>
              <BotaoSalvar onClick={Salvar}>Salvar</BotaoSalvar>
            </div>
          </div>
        </Footer>
      </Container>
    </>
  );
};
export default ModalPesquisaTipoEquipamento;
