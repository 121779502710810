import styled from 'styled-components';
import { Form } from '@unform/web';
import * as colors from '../../../../config/Colors/Ligth';

export const HeaderCadastro = styled.div`
  display: flex;
  flex-direction: column;
  top: 0;
  height: 40px;
  span {
    color: ${colors.darkFontColor};
  }
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    h1 {
      font-size: 1.4rem;
      font-weight: bold;
      color: ${colors.titleFontColor};
    }
  }
  div + div {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-end;
    span {
      text-align: left;
      font-size: 10px;
    }
  }
`;

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BodyCadastro = styled.div`
  padding: 40px 0 40px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FooterCadastro = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end !important;
  justify-content: center;
  border-top: 1px solid #eee;
`;

export const Voltar = styled.div`
  margin-top: -10px;
  a {
    color: ${colors.ligthFontColor};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    p {
      text-transform: uppercase;
    }
    &:hover {
      color: ${colors.titleFontColor};
    }
  }
`;

export const Inline = styled.section`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const ContainerdadosGerais = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContainerBaseDados = styled.div`
  margin-top: 30px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px dashed ${colors.borderColor};
  border-radius: 4px;
`;

export const ContainerTituloBaseDados = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: underline;
  h1 {
    font-size: 20px;
    color: ${colors.titleFontColor};
    mark {
      color: ${colors.titleFontColor};
      font-size: 20px;
      padding: 0 5px;
    }
  }
`;
