import React, { useCallback, useRef, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import UndoIcon from '@material-ui/icons/ArrowBackIos';
import * as Yup from 'yup';
import { useHistory, Link, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Select from '../../../../components/Inputs/Select';
import getValidationErrors from '../../../../utils/getValidationErrors';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';
import BotaoSalvar from '../../../../components/Botoes/BotaoSalvar';
import Input from '../../../../components/Inputs/Input';
import { Container } from '../../../../styles/GlobalStyles';
import Loading from '../../../../components/Loading';
import {
  HeaderCadastro,
  BodyCadastro,
  FooterCadastro,
  Voltar,
  FormContainer,
  ContainerBaseDados,
  ContainerdadosGerais,
  ContainerTituloBaseDados,
} from './styles';
import { status } from '../../../../config/Basicos';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Usuarios from './components/usuarios';

interface IUsuario {
  id: number;
  nome: string;
  email: string;
  status_acesso: string;
  ativo: boolean;
  full_access?: boolean;
  verificado?: boolean;
  roles: {
    id?: number;
    nome: string;
    empresa_id: number;
  }[];
}

interface IEmpresa {
  id: number;
  nome: string;
}

interface IAccount {
  status: boolean;
  nome: string;
  host: string;
  database: string;
  username: string;
  password: string;
  profile: number | null;
}

interface IRouteParam {
  id?: string;
}

const CadastroAccounts: React.FC<IRouteParam> = () => {
  const { id } = useParams<IRouteParam>();
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();
  const [loading_message, setLoadingMessage] = useState('');
  const [usuarios, setUsuarios] = useState<IUsuario[]>([]);
  const [empresas, setEmpresas] = useState<IEmpresa[]>([]);
  const [total_usuarios_contratados, setTotalUsuariosContratados] =
    useState<number>();
  const mounted = useRef(false);
  const [profile, setProfile] = useState<number | null>(null);
  const profiles_types =
    profile !== 0
      ? [
          { value: 1, label: 'BS - Teste' },
          { value: 2, label: 'BS - Produção' },
        ]
      : [{ value: 0, label: 'Gratuito' }];

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    async function getAccount(): Promise<void> {
      if (!id) return;
      setIsLoading(true);
      const { data } = await api.get<IAccount>(`/admin/account/${id}`);
      if (mounted.current) {
        setProfile(data.profile);
        formRef.current?.setData(data);
        setIsLoading(false);
      }
    }

    getAccount();
  }, [id]);

  useEffect(() => {
    async function getUsuarios(): Promise<void> {
      if (!id) return;
      const { data } = await api.get<IUsuario[]>(`/admin/user/${id}`);
      if (mounted.current) setUsuarios(data);
    }
    getUsuarios();
  }, [id]);

  useEffect(() => {
    async function getEmpresas(): Promise<void> {
      if (!id) return;
      const { data } = await api.get<IEmpresa[]>(
        `admin/empresas-from-account/${id}`,
      );
      if (mounted.current) setEmpresas(data);
    }
    getEmpresas();
  }, [id]);

  useEffect(() => {
    async function getUsuariosContratados(): Promise<void> {
      if (!id) return;
      const { data } = await api.get(`/admin/user-total-contratados/${id}`);
      if (mounted.current) setTotalUsuariosContratados(data);
    }
    getUsuariosContratados();
  }, [id]);

  const handleSubmit = useCallback(
    async (data: IAccount) => {
      setIsLoading(true);
      try {
        setLoadingMessage('Salvando...');
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          nome: Yup.string()
            .required('O nome da empresa é obrigatório')
            .max(100, 'O nome da empresa não pode ter mais de 100 caracteres'),
          max_users: Yup.number()
            .required('Informe a quantidade')
            .max(99999999, 'Informe um número válido'),
          host: Yup.string()
            .required('O host/endereço é obrigatório')
            .max(100, 'O host/endereço não pode ter mais de 100 caracteres'),
          database: Yup.string()
            .required('O nome/caminho da base de dados é obrigatório')
            .max(
              100,
              'O nome/caminho da base de dados não pode ter mais de 100 caracteres',
            ),
          contract_token: Yup.string()
            .required('O token é obrigatório')
            .max(100, 'O token não pode ter mais de 255 caracteres'),
          username: Yup.string()
            .required('O username é obrigatório')
            .max(100, 'O username não pode ter mais de 100 caracteres'),
          password: Yup.string()
            .required('O password é obrigatório')
            .max(100, 'O password não pode ter mais de 100 caracteres'),
          profile: Yup.number()
            .min(1, 'O Perfil escolhido está incorreto')
            .max(2, 'O Perfil escolhido está incorreto')
            .required('O perfil é obrigatório'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        if (id) {
          await api.put(`/admin/account/${id}`, data);
        } else {
          await api.post('/admin/account', data);
        }
        if (mounted.current) {
          history.push('/admin/accounts/lista');

          addToast({
            type: 'success',
            title: 'Sucesso!',
            description: 'Cliente salvo com sucesso',
          });
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        if (err.response.data) {
          if (err.response.data.error) {
            const erros = Object.keys(err.response.data.error);

            erros.map(erro =>
              addToast({
                type: 'error',
                title: 'Erro no cadastro',
                description: err.response.data.error[erro],
              }),
            );
          } else {
            addToast({
              type: 'error',
              title: 'Erro no cadastro',
              description: err.response.data,
            });
          }
        }
      } finally {
        setIsLoading(false);
        setLoadingMessage('');
      }
    },
    [addToast, history, id],
  );

  return (
    <Container>
      <Loading isLoading={isLoading} />
      <Voltar>
        <Link
          to="/admin/accounts/lista"
          title="Voltar para a listagem de clientes"
        >
          <UndoIcon fontSize="large" />
        </Link>
      </Voltar>
      <HeaderCadastro>
        <div>
          <h1>{id ? 'EDITAR CLIENTE' : 'NOVO CLIENTE'}</h1>
        </div>
        <div>
          <span>Os campos com * são obrigatórios</span>
        </div>
      </HeaderCadastro>
      <FormContainer ref={formRef} onSubmit={handleSubmit}>
        <BodyCadastro>
          <ContainerdadosGerais>
            <Grid
              container
              spacing={1}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
              }}
            >
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Select
                  label="Status"
                  isSearchable={false}
                  options={status}
                  name="ativo"
                  defaultValue={{ value: true, label: 'Ativo' }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Select
                  label="Sincronização"
                  isSearchable={false}
                  options={status}
                  name="sincronizar"
                  defaultValue={{ value: true, label: 'Ativo' }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Select
                  label="Perfil"
                  isSearchable={false}
                  options={profiles_types}
                  name="profile"
                  defaultValue={{ value: 2, label: 'BS - Produção' }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Select
                  label="Notificar falta de sincronização por email"
                  isSearchable={false}
                  options={status}
                  name="notificar_falha_sincronizacao"
                  defaultValue={{ value: false, label: 'Inativo' }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Input
                label="Nome da empresa*"
                name="nome"
                maxLength={100}
                placeholder="Informe o nome da empresa"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Input
                label="Número máximo de usuários ativos*"
                name="max_users"
                maxLength={10}
                type="number"
                placeholder="Informe o número máximo de usuários ativos"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Input
                label="Contract token*"
                name="contract_token"
                maxLength={255}
                placeholder="Informe o token"
                showPasswordIcon
                type="password"
              />
            </Grid>
          </ContainerdadosGerais>
          <ContainerBaseDados>
            <ContainerTituloBaseDados>
              <h1>Configuração Banco de Dados Sync 4</h1>
            </ContainerTituloBaseDados>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Input
                label="Host*"
                name="host"
                maxLength={100}
                placeholder="Ex.: 192.168.1.111"
                showPasswordIcon
                type="password"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Input
                label="Database*"
                name="database"
                maxLength={75}
                placeholder="Ex.: /var/www/database/exemplo.fdb"
                showPasswordIcon
                type="password"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Input
                label="Username*"
                name="username"
                maxLength={100}
                placeholder="Informe o nome de usuário"
                showPasswordIcon
                type="password"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Input
                label="Password*"
                name="password"
                showPasswordIcon
                type="password"
                maxLength={100}
                placeholder="Informe a senha"
              />
            </Grid>
          </ContainerBaseDados>
        </BodyCadastro>
        <FooterCadastro>
          <BotaoSalvar loading={loading_message} type="submit">
            Salvar
          </BotaoSalvar>
        </FooterCadastro>
      </FormContainer>
      {id && (
        <>
          <HeaderCadastro>
            <div>
              <h1>PERMISSÕES DE USUÁRIO POR EMPRESAS</h1>
            </div>
          </HeaderCadastro>
          <Usuarios
            total_usuarios_contratados={total_usuarios_contratados}
            usuarios={usuarios}
            empresas={empresas}
            setUsuarios={setUsuarios}
            account_id={Number(id)}
          />
        </>
      )}
    </Container>
  );
};

export default CadastroAccounts;
