import styled from 'styled-components';
import { shade } from 'polished';

import * as colors from '../../../../../config/Colors/Ligth';

export const InfoPedido = styled.div`
  max-width: 600px;
  width: 98%;

  margin: 2px auto !important;
  padding-bottom: 0px;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-around;

  a {
    color: ${colors.ligthFontColor};
    text-decoration: underline;
    background: transparent;
    &:hover {
      &:hover {
        color: ${shade(0.3, colors.ligthFontColor)};
      }
    }
  }
`;

export const Container = styled.div`
  a:hover {
    background-color: ${colors.backgroudColor};
    border-radius: 8px;
    padding: 0 2px;
    margin: 2px 0;
  }
  a {
    margin: 2px;
  }
  background-color: white;
  display: flex;
  flex-direction: column;

  color: ${colors.darkFontColor};
  transition: background-color 0.2s;
  border: 1px solid lightgray;
  border-radius: 4px;
  > div:first-child {
    background-color: ${colors.backgroundColorGrayButton};
    border-radius: 0 4px 0 0 !important;
    > div:first-child {
      display: flex;
      flex-direction: row;
      align-items: center;
      h2 {
        text-transform: capitalize;
      }
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    border-bottom: 1px solid ${colors.formBackgroundColor};

    span {
      font-size: 12px;
      strong {
        font-size: 12px;
        padding: 2px;
        border-radius: 4px;
      }

      strong.orcamento {
        background-color: ${colors.orcamento};
      }
      strong.pedido {
        background-color: ${colors.pedido};
      }
      strong.faturado {
        background-color: ${colors.faturado};
        color: white;
      }
      strong.entregue {
        background-color: ${colors.entregue};
      }
      strong.concluido {
        background-color: ${colors.concluido};
      }
      strong.erro {
        background-color: ${colors.erro};
        color: white;
      }
    }
    button {
      background-color: transparent;
      color: ${colors.ligthFontColor};
      border: none;
      cursor: pointer;
    }
  }
  > div + div {
    padding-left: 0;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    svg {
      color: gray;
    }
    section {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-weight: 400 !important;
      padding: 0 7px;
      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      }
    }
    h2 {
      padding-top: 5px;
      display: flex;
      padding-left: 7px;
      flex-direction: row;
      align-items: center;
    }
    p {
      display: flex;
      font-weight: 400 !important;
      padding-bottom: 5px;
      padding-left: 7px;
      flex-direction: row;
      align-items: center;
    }
  }
  margin-bottom: 20px;

  &.orcamento {
    border-left: 5px solid ${colors.orcamento};
  }
  &.pedido {
    border-left: 5px solid ${colors.pedido};
  }
  &.faturado {
    border-left: 5px solid ${colors.faturado};
  }
  &.entregue {
    border-left: 5px solid ${colors.entregue};
  }
  &.concluido {
    border-left: 5px solid ${colors.concluido};
  }
  &.erro {
    border-left: 5px solid ${colors.erro};
  }
`;

export const ContainerProdutosEquipamentos = styled.div`
  display: flex;
  flex-direction: row !important;
  padding-right: 30px;
  @media only screen and (max-width: 1000px) {
    flex-direction: column !important;
    padding-right: 0px;
  }
`;

export const ListaProdutosEquipamentos = styled.div`
  h1 {
    padding: 3px 0px;
    margin-left: 0;
  }
  margin: 0 10px;
  div {
    height: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;

    /* div {
      padding-right: 0.5;
      img {
        height: 40px;
      }
    }*/
    div {
      strong {
        font-size: 12px;
        color: ${colors.ligthFontColor};
        padding: 0;
      }
      span {
        font-size: 12px;
        padding-left: 10px;
        color: ${colors.ligthFontColor};
      }
      @media only screen and (max-width: 992px) {
        strong {
          font-size: 9px;
        }
        span {
          padding-left: 3px;
          font-size: 9px;
        }
      }
    }
  }
`
export const ContainerItemsLinha = styled.div`
  display:flex;
  justify-content: flex-start;
  margin-top:15px;
`;
;

export const ContainerItemsLado = styled.div`
  padding-left:20%;
`;
;
