import React, { useCallback, useMemo } from 'react';
import Popover from '@material-ui/core/Popover';
import { useHistory } from 'react-router-dom';
import { Etapas, MensagemSincronizacao } from './styles';
import BotaoNovo from '../../Botoes/BotaoNovo';
import BotaoDefault from '../../Botoes/BotaoDefault';
import icone from '../../../assets/favicon.png';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';

interface IEtapa {
  id: number;
  cod_retorno_integracao?: number;
  motivo_integracao?: string;
  tipo: 'cliente' | 'pedido';
}

interface IDados {
  status: string;
  mensagem: string | JSX.Element;
}

const StatusSincronizacao: React.FC<IEtapa> = ({
  id,
  cod_retorno_integracao,
  motivo_integracao,
  tipo,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const popover_id = open ? 'simple-popover' : undefined;
  const { addToast } = useToast();
  const history = useHistory();

  const handleClick = (event): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const reenviarRequisicao = useCallback(async () => {
    try {
      const retorno = await api.post(`/${tipo}/reenviar`, { id });
      if (retorno.data.cod_retorno_integracao < 400) {
        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: `O ${tipo} foi reenviado com sucesso.`,
        });
        handleClose();
        history.push(`/${tipo}s/lista`);

      } else {
        addToast({
          type: 'error',
          time: 30000,
          title: 'Erro na sincronização!',
          description: (
            <>
              <p>Ocorreu um erro ao tentar conectar com o sistema Beersales.</p>
              <p>{`O ${tipo} foi salvo mas não foi sincronizado.`}</p>
            </>
          ),
        });
        history.push(`/${tipo}s/cadastro/${retorno.data.id}`);
      }
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro no cadastro',
        description: err.response?.data?.message,
      });
    }
  }, [addToast, history, id, tipo]);

  const dadosSincronizacao = useMemo((): IDados => {
    switch (cod_retorno_integracao) {
      case null:
        return {
          status: 'Orçamento',
          mensagem:
            'O orçamento será integrado ao sistema Beersales quando for transformado em um pedido.',
        };
      case 0:
        return {
          status: 'Orçamento',
          mensagem:
            'O orçamento será integrado ao sistema Beersales quando for transformado em um pedido.',
        };
      case 201:
        return {
          status: 'Integrado',
          mensagem: 'Integrado com sucesso ao sistema Beersales.',
        };
      case 202:
        return {
          status: 'Sincronizando',
          mensagem:
            'Requisição enviada e esperando sincronização com o sistema Beersales. Aguarde um instante.',
        };
      case 412:
        return {
          status: 'Ocorreu um erro',
          mensagem: (
            <>
              <div>
                <h1>Ocorreu um erro</h1>
              </div>
              <div>
                <p>Ocorreu um erro ao sincronizar com o sistema Beersales.</p>
                <p>Verifique os dados informados e tente novamente.</p>
                <p>{`Erro retornado: ${motivo_integracao}`}</p>
              </div>
              <div>
                <BotaoNovo onClick={reenviarRequisicao}>
                  {`Reenviar ${tipo}`}
                </BotaoNovo>
              </div>
            </>
          ),
        };

      case 500:
        return {
          status: 'Erro de conexão',
          mensagem: (
            <>
              <p>Ocorreu um erro ao tentar conectar com o sistema Beersales.</p>
              <p>{`O ${tipo} foi salvo mas não foi sincronizado.`}</p>
              <div>
                <BotaoNovo onClick={reenviarRequisicao}>
                  {`Reenviar ${tipo}`}
                </BotaoNovo>
              </div>
            </>
          ),
        };
      default:
        return {
          status: '',
          mensagem: '',
        };
    }
  }, [cod_retorno_integracao, motivo_integracao, reenviarRequisicao, tipo]);

  return (
    <Etapas>
      <>
        <BotaoDefault
          title="Clique para ver mais..."
          type="button"
          onClick={handleClick}
          key={id}
          style={{
            color:
              cod_retorno_integracao && cod_retorno_integracao > 400
                ? 'red'
                : 'current',
          }}
        >
          <span>
            <img src={icone} width={16} alt="Beersales" />
          </span>
          {dadosSincronizacao?.status}
        </BotaoDefault>
        <Popover
          id={popover_id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MensagemSincronizacao>
            {dadosSincronizacao?.mensagem}
          </MensagemSincronizacao>
        </Popover>
      </>
    </Etapas>
  );
};

export default StatusSincronizacao;
