import styled from 'styled-components';
import * as colors from '../../config/Colors/Ligth';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  color: ${colors.darkFontColor};
  align-items: center;
  justify-content: center;

  button {
    margin: 0;
    padding-top: 25px;
    svg {
      color: ${colors.borderColor};
    }
  }
`;

export const ContainerResumoFiltros = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  p {
    font-size: 10px;
  }
  padding-bottom: 10px; ;
`;
