/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useMemo, useEffect } from 'react';
import ReactSelect from 'react-select';
import {
  ContainerFiltros,
  ContainerBotoes,
  ContainerResumoFiltros,
  ContainerSelect,
  ContainerDataInicialFinal,
} from './styles';
import { etapasPedido } from '../../../../../config/Basicos';
import BotaoSalvar from '../../../../../components/Botoes/BotaoSalvar';
import FiltroSincronizacao from '../../../../../components/Sincronizacao/FiltroSincronizacao';
import DateComponent from '../../../../../components/Inputs/Date';
import * as colors from '../../../../../config/Colors/Ligth';
import formataData from '../../../../../utils/formataData';

interface IFiltroSincronizacao {
  orcamento?: boolean;
  sincronizado: boolean;
  sincronizando: boolean;
  erro: boolean;
}

interface IFiltros {
  filtro_tipo_pedido: number;
  setFiltroTipoPedido: (filtro_tipo_pedido: number) => void;
  filtro_sincronizacao: IFiltroSincronizacao;
  setFiltroSincronizacao: (filtro_sincronizacao: IFiltroSincronizacao) => void;
  mostra_filtros: boolean;
  setMostraFiltros: (mostra: boolean) => void;
  setFiltroTipoData: (tipoData: null | number) => void;
  filtro_tipo_data: null | number;
  setFiltroDataInicial: (date: Date) => void;
  setFiltroDataFinal: (date: Date) => void;
  setPage: (page: number) => void;
}

const Filtros: React.FC<IFiltros> = ({
  filtro_tipo_pedido,
  setFiltroTipoPedido,
  filtro_sincronizacao,
  setFiltroSincronizacao,
  mostra_filtros,
  setMostraFiltros,
  setFiltroTipoData,
  filtro_tipo_data,
  setFiltroDataInicial,
  setFiltroDataFinal,
  setPage,
}) => {
  let etapas_filtro = [{ id: 0, titulo: 'Todos' }, ...etapasPedido];
  etapas_filtro = etapas_filtro.slice(0, -1);
  const etapas_pedido = etapas_filtro.map(etapa => {
    return { label: etapa.titulo, value: etapa.id };
  });
  const [filtro_sincronizacao_temp, setFiltroSincronizacaoTemp] =
    useState<IFiltroSincronizacao>(filtro_sincronizacao);
  const [filtro_tipo_pedido_temp, setFiltroTipoPedidoTemp] = useState<number>(
    filtro_tipo_pedido || 0,
  );
  const [filtro_tipo_data_temp, setFiltroTipoDataTemp] = useState<
    number | null
  >(null);
  const [filtro_data_inicial_temp, setFiltroDataInicialTemp] = useState(
    new Date(),
  );
  const [filtro_data_final_temp, setFiltroDataFinalTemp] = useState(new Date());
  const [errosData, setErrosData] = useState<string>('');
  const lista_tipo_datas = [
    { label: 'Escolha um Tipo de Data...', value: null },
    { label: 'Data de Bloqueio', value: 1 },
    { label: 'Data do Cancelamento', value: 2 },
    { label: 'Data de Conclusão', value: 3 },
    { label: 'Data de Entrega', value: 4 },
    { label: 'Data do Orçamento', value: 5 },
    { label: 'Data do Pedido', value: 6 },
    { label: 'Data do Faturamento', value: 7 },
    { label: 'Data Prevista de Entrega', value: 8 },
  ];

  function handleAplicarFiltros(): void {
    setFiltroDataFinal(filtro_data_final_temp);
    setFiltroDataInicial(filtro_data_inicial_temp);
    setFiltroTipoData(filtro_tipo_data_temp);
    setFiltroTipoPedido(Number(filtro_tipo_pedido_temp));
    setFiltroSincronizacao(filtro_sincronizacao_temp);
    setMostraFiltros(false);
    setPage(1);
  }

  const getTipoDataNome = () => {
    const tipo_data = lista_tipo_datas.find(
      tipo_data_item => tipo_data_item.value === filtro_tipo_data_temp,
    );
    return tipo_data.label;
  };

  const filtros_ativos = useMemo(() => {
    let sincronizacao = '';
    const etapa_ativa = etapasPedido.filter(
      etapa => etapa.id === filtro_tipo_pedido,
    );
    sincronizacao += filtro_sincronizacao.orcamento ? ' Orçamento;' : '';
    sincronizacao += filtro_sincronizacao.sincronizado ? ' Integrado;' : '';
    sincronizacao += filtro_sincronizacao.sincronizando
      ? ' Sincronizando;'
      : '';
    sincronizacao += filtro_sincronizacao.erro ? ' Erro;' : '';
    const pedido = etapa_ativa[0] ? etapa_ativa[0].titulo : 'Todos';
    sincronizacao +=
      filtro_tipo_data_temp !== null &&
      filtro_tipo_data_temp !== undefined &&
      filtro_tipo_data_temp
        ? ` Tipo de Data: ${getTipoDataNome()} - entre ${formataData(
            filtro_data_inicial_temp,
          )} e ${formataData(filtro_data_final_temp)}`
        : '';

    return `Filtros ativos: Status pedido: ${pedido} - Status sincronização: ${sincronizacao}`;
  }, [
    filtro_sincronizacao,
    filtro_tipo_pedido,
    filtro_tipo_data_temp,
    filtro_data_inicial_temp,
    filtro_data_final_temp,
  ]);

  useEffect(() => {
    filtro_data_inicial_temp > filtro_data_final_temp
      ? setErrosData('As datas estão incorretas!')
      : setErrosData('');
  }, [filtro_data_inicial_temp, filtro_data_final_temp]);

  return (
    <>
      <ContainerResumoFiltros>
        <p>{filtros_ativos}</p>
      </ContainerResumoFiltros>
      {mostra_filtros && (
        <ContainerFiltros>
          <h1>Filtrar por</h1>
          <ContainerSelect>
            <>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            </>
            <label htmlFor="pesquisa">
              Status pedido
              <ReactSelect
                id="pesquisa"
                defaultValue={etapas_pedido.find(
                  etapa => etapa.value === filtro_tipo_pedido,
                )}
                options={etapas_pedido}
                onChange={e => setFiltroTipoPedidoTemp(e.value)}
                classNamePrefix="react-select"
                noOptionsMessage={() => 'Não encontrado!'}
                loadingMessage={() => 'Carregando...'}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: colors.backgroudColor,
                    primary: colors.labelFontColor,
                  },
                })}
              />
            </label>
          </ContainerSelect>
          <FiltroSincronizacao
            filtro={filtro_sincronizacao_temp}
            setFiltro={setFiltroSincronizacaoTemp}
          />
          <ContainerSelect style={{ zIndex: 998 }}>
            <>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            </>
            <label htmlFor="pesquisa">
              Filtrar por Data
              <ReactSelect
                id="pesquisa"
                defaultValue={lista_tipo_datas.find(
                  tipo_data => tipo_data.value === filtro_tipo_data,
                )}
                options={lista_tipo_datas}
                onChange={e => setFiltroTipoDataTemp(e.value)}
                classNamePrefix="react-select"
                noOptionsMessage={() => 'Não foram encontrados tipos de datas.'}
                loadingMessage={() => 'Carregando os tipos de datas...'}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: colors.backgroudColor,
                    primary: colors.labelFontColor,
                  },
                })}
              />
            </label>
          </ContainerSelect>
          {filtro_tipo_data_temp !== null && (
            <ContainerDataInicialFinal>
              <>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              </>
              <label htmlFor="data_inicial">
                Data Inicial
                <DateComponent
                  id="data_inicial"
                  error={!!errosData}
                  value={filtro_data_inicial_temp}
                  format="dd/MM/yyyy"
                  disable
                  helperText={errosData}
                  setValue={setFiltroDataInicialTemp}
                />
              </label>
              <>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              </>
              <label htmlFor="data_final">
                Data Final
                <DateComponent
                  id="data_final"
                  error={!!errosData}
                  value={filtro_data_final_temp}
                  format="dd/MM/yyyy"
                  disable
                  helperText={errosData}
                  setValue={setFiltroDataFinalTemp}
                />
              </label>
            </ContainerDataInicialFinal>
          )}
          <ContainerBotoes>
            <BotaoSalvar onClick={handleAplicarFiltros}>
              Aplicar Filtros
            </BotaoSalvar>
          </ContainerBotoes>
        </ContainerFiltros>
      )}
    </>
  );
};

export default Filtros;
