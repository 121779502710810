import styled from 'styled-components';
import * as colors from '../../../../config/Colors/Ligth';

export const Container = styled.div`
  padding: 20px 0 30px 0;
  display: flex;
  flex-direction: column;
  span {
    font-weight: 600;
    color: #9f9898;
    font-size: 0.9rem;
    margin-left: 10px;
  }
`;

export const ContainerItems = styled.div`
  display: flex;
  margin-left: 30px;
  margin-bottom: 3px;
  flex-direction: row;
`;

export const ContainerItemsDescricao = styled.div`
  display: flex;
  width: 100%;
  max-width: 33%;
  flex-direction: row;
`;

export const ContainerItemsValor = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-left: 10px;
  padding-left: 5px;
  border-color: ${colors.pedido};
  border-style: solid;
  border-width: 0px 0px 0px 5px;
`;
