/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useState,
  useEffect,
  Fragment,
} from 'react';
import { addMonths } from 'date-fns';
import Select from 'react-select';
import 'react-confirm-alert/src/react-confirm-alert.css';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import TrendingUp from '@material-ui/icons/TrendingUp';
import api from '../../../../services/api';
import { Container, ContainerOnlyInputs, TituloInput } from './styles';
import Loading from '../../../../components/Loading';
import { optionsReferencia } from '../../../../config/Basicos';
import { Separador } from '../../../../styles/GlobalStyles';
import { useToast } from '../../../../hooks/toast';
import formataQuantidade from '../../../../utils/formataQuantidade';
import * as colors from '../../../../config/Colors/Ligth';
import formataValor from '../../../../utils/formataNumero';
import DateComponent from '../../../../components/Inputs/Date';

interface IParam {
  id?: number;
  nome_produto?: string;
}

interface IRelatorio {
  id_cliente?: number;
  nome_cliente?: string;
  qntd_pedidos?: string;
  qntd_produtos: string;
  valor_total: string;
  unidade_nome?: string;
}

const Historico: React.FC<IParam> = ({ id, nome_produto }) => {
  const [is_loading, setIsLoading] = useState(false);
  const [filtro_data_inicial, setFiltroDataInicial] = useState(addMonths(new Date(), -1));
  const [filtro_data_final, setFiltroDataFinal] = useState(new Date());
  const [errosData, setErrosData] = useState<string>('');
  const [cliente_mais_compram, setClientesMaisCompram] = useState<IRelatorio[]>([]);
  const [referencia, setReferencia] = useState({
    value: 'data_geracao',
    label: 'Data de Cadastro',
  });
  const { addToast } = useToast();

  useEffect(() => {
    filtro_data_inicial > filtro_data_final
      ? setErrosData('As datas estão incorretas!')
      : setErrosData('');
  }, [filtro_data_inicial, filtro_data_final]);

  useEffect(() => {
    async function getClientesMaisCompram(): Promise<void> {
      try {
        setIsLoading(true);
        if (!id) return;

        const response = await api.get<IRelatorio[]>(
          `relatorios/clientes-mais-compram`, {
          params: {
            data_inicial: filtro_data_inicial,
            data_final: filtro_data_final,
            referencia: referencia.value,
            produto_id: id
          }
        }
        );
        if (response.data) {
          setClientesMaisCompram(response.data);
        }

      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }

    getClientesMaisCompram();
  }, [id, addToast, filtro_data_inicial, filtro_data_final, referencia]);


  return (
    <Container>
      <Loading isLoading={is_loading} />
      <Separador>
        <h2><TrendingUp />&nbsp;{`CLIENTES QUE MAIS COMPRARAM ${nome_produto}`}</h2>
      </Separador>
      <ContainerOnlyInputs>
        <div style={{ marginRight: '20px', width: '250px' }}>
          <TituloInput>
            <div>
              <h1>Data Inicial</h1>
            </div>
          </TituloInput>
          <DateComponent
            id="data_inicial"
            error={!!errosData}
            value={filtro_data_inicial}
            format="dd/MM/yyyy"
            disable
            helperText={errosData}
            setValue={setFiltroDataInicial}
          />
        </div>
        <div style={{ marginRight: '20px', width: '250px' }}>
        <TituloInput>
          <div>
            <h1>Data Final</h1>
          </div>
        </TituloInput>
        <DateComponent
          id="data_final"
          error={!!errosData}
          value={filtro_data_final}
          format="dd/MM/yyyy"
          disable
          helperText={errosData}
          setValue={setFiltroDataFinal}
        />
        </div>
        <div style={{ marginRight: '20px', width: '250px' }}>
          <TituloInput>
            <div>
              <h1>Referência</h1>
            </div>
          </TituloInput>
          <Select
            defaultValue={{
              value: 'data_geracao',
              label: 'Data de Cadastro',
            }}
            onChange={setReferencia}
            isSearchable={false}
            options={optionsReferencia}
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: colors.backgroudColor,
                primary: colors.labelFontColor,
              },
            })}
          />
        </div>
      </ContainerOnlyInputs>
      {
        cliente_mais_compram && cliente_mais_compram.length > 0
          ?
          cliente_mais_compram?.map(({ id_cliente, nome_cliente, qntd_pedidos, qntd_produtos, valor_total, unidade_nome }) => (
            <Fragment key={id_cliente}>
              <Separador>
                <h2><PermContactCalendarIcon />&nbsp;{`CLIENTE ${nome_cliente || 'INDEFINIDO(A)'}`} </h2>
              </Separador>
              <span>{`QUANTIDADE DE PEDIDO(S): ${qntd_pedidos || '0'}`}</span>
              <span>{`QUANTIDADE DE PRODUTO(S): ${formataQuantidade(qntd_produtos)} ${unidade_nome}`}</span>
              <span>{`VALOR TOTAL: R$${formataValor(valor_total, 2, true) || '0'}`}</span>
            </Fragment>
          ))
          : <span>Nenhum(a) cliente encontrado(a)</span>
      }
    </Container>
  );
};

export default Historico;
