import styled from 'styled-components';
import * as colors from '../../../../config/Colors/Ligth';

export const Container = styled.div`
   margin: 50px auto;
   padding: 20px 0 90px 0;
   overflow-x: hidden;
   display: flex;
   flex-direction: column;

   span{
    font-weight: 600;
    color: #9f9898;
    font-size: 0.9rem;
    margin-left: 10px;
  }
`;

export const ContainerOnlyInputs = styled.div`
  display: flex;
  margin-bottom: 35px;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  @media screen and (max-width: 1000px) {
    margin-top: 10px;
    flex-wrap: wrap;
  }
  .react-select__control {
    min-height: 38px;

    @media only screen and (max-width: 768px) {
      min-height: 35px;
    }
  }
  max-width: 100%;
  padding-left: 10px;
  padding-bottom: 20px;
`;

export const TituloInput = styled.div`
  white-space: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.formBackgroundColor};
  color: ${colors.titleFontColor};
  margin-top: 20px;
  h1 {
    padding: 10px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
  }
  @media screen and (max-width: 1000px) {
    height: auto;
    h1 {
      font-size: 10px;
      padding: 5px;
    }
  }
`;

// export const ContainerFull = styled.section`
//   background-color: ${colors.backgroudColor};
//   margin: 50px auto;
//   right: 20px;
//   padding: 3%;
//   border-radius: 4px;
//   box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
//   overflow-x: hidden;
// `;


export const ContainerReferencia = styled.div`
  display:flex;
  align-items:center;
  margin-left: 20px;
  background-color: yellow;
   @media only screen and (max-width: 768px) {
      min-height: 35px;
    }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  top: 0;
  border-bottom: 1px solid #eee;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    h1 {
      font-size: 1.4rem;
      font-weight: bold;
      color: ${colors.titleFontColor};
    }
  }
  div + div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const Voltar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: -10px;
  a {
    color: ${colors.ligthFontColor};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    p {
      text-transform: uppercase;
    }
    &:hover {
      color: ${colors.titleFontColor};
    }
  }
`;

export const MostraGraficos = styled.div`
  display: flex;
  background-color: ${colors.formBackgroundColor};
  flex-direction: column;
  margin-top: 10px !important;

  height: 320px;
  @media screen and (max-width: 1000px) {
    height: 250px;
  }
  width: 100%;
  max-height: 100%;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px px -2px #989898;
  -moz-box-shadow: 0 3px 3px -2px #989898;
  -webkit-box-shadow: 0 3px 3px -2px #989898;
  div > &:nth-last-child(2n) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;
