import React, { useMemo } from 'react';
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai';
import { BsTrash } from 'react-icons/bs';
import { BiBlock } from 'react-icons/bi';
import {
  Container,
  ContainerInput,
} from './styles';
import BotaoDefault from '../../../../../components/Botoes/BotaoDefault';
import IPedidoTipoEquipamento from '../../../../../interfaces/pedidoTipoEquipamento';
import validaNumero from '../../../../../utils/validaNumero';

interface IProdutoPedido {
  tipo_equipamento: IPedidoTipoEquipamento;
  status_id: number;
  handleQuantidade: (tipo_equipamento_id: number, valor: string) => void;
}

const CardProduto: React.FC<IProdutoPedido> = ({
  tipo_equipamento,
  handleQuantidade,
  status_id,
}) => {
  const {
    nome_tipo_equipamento,
    tipo_equipamento_id,
    quantidade,

  } = tipo_equipamento;

  const tipo_equipamento_alocado = useMemo(() => {
    return quantidade > 0;
  }, [quantidade]);

  function handleAumentar(): void {
    const qtde = quantidade + 1;
    handleQuantidade(tipo_equipamento_id, `${qtde}`);
  }

  function handleDiminuir() {
    if (quantidade === 0) return;
    const qtde = quantidade - 1;
    handleQuantidade(tipo_equipamento_id, `${qtde}`);
  }

  const icone_remover = useMemo(() => {
    if (status_id > 2) {
      return <BiBlock title="Você não pode realizar essa operação" size={20} />;
    }
    if (quantidade === 0) {
      return <BiBlock title="Você não pode realizar essa operação" size={20} />;
    }

    if (Number(quantidade) === 1) {
      if (status_id < 2) {
        return (
          <BsTrash title="Clique para remover esse item do pedido." size={20} />
        );
      }
      return <BiBlock title="Você não pode realizar essa operação" size={20} />;
    }
    return (
      <AiOutlineMinusCircle
        title="Clique para diminuir a quantidade no pedido"
        size={20}
      />
    );
  }, [quantidade, status_id]);

  const icone_aumentar = useMemo(() => {
    if (status_id > 2) {
      return <BiBlock title="Você não pode realizar essa operação" size={20} />;
    }

    return (
      <AiOutlinePlusCircle
        title="Clique para aumentar a quantidade no pedido"
        size={20}
      />
    );
  }, [status_id]);

  function handleInput(e) {
    let quantidade_clean = e.target.value.replace('/[^0-9.,]/g', '');
    quantidade_clean = validaNumero(quantidade_clean, 4);
    handleQuantidade(tipo_equipamento_id, quantidade_clean);
  }

  function handleClick(e) {
    e.target.select();
  };

  return (
    <Container
      key={tipo_equipamento_id}
      alocado={tipo_equipamento_alocado}
    >
      <div>
        <h1>{nome_tipo_equipamento}</h1>
      </div>
      <div>
        <div>
          <ContainerInput>
            <BotaoDefault onClick={() => handleDiminuir()}>
              {icone_remover}
            </BotaoDefault>
            <input type="number" onClick={handleClick} onChange={handleInput} value={quantidade} />
            <BotaoDefault onClick={() => handleAumentar()}>
              {icone_aumentar}
            </BotaoDefault>
          </ContainerInput>
        </div>
      </div>
    </Container>
  );
};

export default CardProduto;
