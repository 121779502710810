import React, { useRef, useCallback, useEffect, useState } from 'react';
import { FiLock, FiMail } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import { useToast } from '../../../hooks/toast';
import logoImg from '../../../assets/login-logo.png';
import api from '../../../services/api';

import getValidationErrors from '../../../utils/getValidationErrors';
import Input from '../../../components/Inputs/InputGrande';
import BotaoLogin from '../../../components/Botoes/BotaoLogin';

import { Container, Content, Background, AnimationContainer } from './styles';

interface SignInFormData {
  email: string;
  password: string;
}

interface IRouteParams {
  confirm?: string;
}

const RedefinirSenha: React.FC<IRouteParams> = () => {
  const formRef = useRef<FormHandles>(null);
  const { confirm } = useParams<IRouteParams>();
  const { addToast } = useToast();
  const history = useHistory();
  const [loading_message, setLoadingMessage] = useState('');
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => { mounted.current = false; }; // ... and to false on unmount
  }, []);

  useEffect(() => {
    async function getEmail(): Promise<void> {
      try {
        const response = await api.get(`auth/find-email?token=${confirm}`);
        formRef.current?.setFieldValue('email', response.data.email);
      } catch (err) {
        /*   addToast({
          type: 'error',
          title: 'Erro',
          description: 'Esse link não é válido ou expirou.',
        }); */
        history.push('/login');
      }
    }
    getEmail();
  }, [confirm, history]);

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        setLoadingMessage('Enviando...');
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          password: Yup.string()
            .required('A senha é obrigatória')
            .min(8, 'A senha deve ter no mínimo 8 caracteres'),
          password_confirmation: Yup.string().oneOf(
            [Yup.ref('password')],
            'a senha informada não confere',
          ),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await api.post('/auth/new-password', data, {
          headers: { confirm },
        });
        if(mounted.current){
          addToast({
            type: 'success',
            title: 'Senha alterada com sucesso',
            description: response.data.message,
          });
        }

        setTimeout(() => history.push('/login'), 3000);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Não foi possível concluir sua solicitação',
        });
      } finally {
        setLoadingMessage('');
      }
    },
    [addToast, confirm, history],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="BeerSales" />
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input
              name="email"
              maxLength={255}
              type="email"
              disabled
              icon={FiMail}
              placeholder="Informe o e-mail"
            />
            <Input
              name="password"
              maxLength={255}
              icon={FiLock}
              autoComplete="false"
              type="password"
              placeholder="Informe a senha"
            />
            <Input
              name="password_confirmation"
              maxLength={255}
              icon={FiLock}
              autoComplete="false"
              type="password"
              placeholder="Confirme a senha"
            />
            <BotaoLogin loading={loading_message} type="submit">
              Redefinir senha
            </BotaoLogin>
          </Form>
        </AnimationContainer>
      </Content>
      <Background />
    </Container>
  );
};

export default RedefinirSenha;
