import { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { animated } from 'react-spring';
import * as colors from '../../../config/Colors/Ligth';


interface ContainerProps {
  type?: 'success' | 'error' | 'info';
  hasdescription: string | ReactElement;
}

const toastTypeVariations = {
  info: css`
    background: ${colors.backgroundColorInfo};
    color: ${colors.colorInfo};
  `,
  success: css`
    background: ${colors.backgroundColorSuccess};
    color: ${colors.colorSuccess};
  `,
  error: css`
    background: ${colors.backgroundColorError};
    color: ${colors.colorError};
  `,
};

export const Container = styled(animated.div) <ContainerProps>`
  width: 360;
  position: relative;
  padding: 16px 30px 16px 16px;
  border-radius: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);

  display: flex;

  & + div {
    margin-top: 8px;
  }

  ${props => toastTypeVariations[props.type || 'info']}

  > svg {
    margin: 0px 10px 0 0;
  }

  div {
    flex: 1;

    p {
      margin-top: 4px;
      font-size: 14px;
      opacity: 0.8;
      line-height: 20px;
    }
  }

  > button {
    position: absolute;
    right: 16px;
    top: 19px;
    opacity: 0.6;
    border: 0;
    background: transparent;
    color: inherit;
    cursor: pointer;
  }

  ${props =>
    !props.hasdescription &&
    css`
      align-items: center;

      svg {
        margin-top: 0;
      }
    `}
`;
