import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FiLock, FiMail } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import logoImg from '../../../assets/login-logo.png';

import getValidationErrors from '../../../utils/getValidationErrors';
import Input from '../../../components/Inputs/InputGrande';
import BotaoLogin from '../../../components/Botoes/BotaoLogin';

import { Container, Content, Background, AnimationContainer } from './styles';

interface SignInFormData {
  email: string;
  password: string;
  confirm?: string;
}

interface IRouteParam {
  confirm?: string;
}

const SignIn: React.FC<IRouteParam> = () => {
  const formRef = useRef<FormHandles>(null);
  const { confirm } = useParams<IRouteParam>();
  const { signIn } = useAuth();
  const { addToast } = useToast();
  const [loading_message, setLoadingMessage] = useState('');
  const history = useHistory();
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        setLoadingMessage('Carregando...');
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('O e-mail é obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().required('A senha é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await signIn({
          email: data.email,
          password: data.password,
          confirm,
        });
        if (mounted.current) {
          if (
            response.data.user.role === `eget-admin-${response.data.company.id}`
          ) {
            history.push('/admin/accounts/lista');
          } else if (
            response.data.user.is_first_login !== true &&
            response.data.user.role !== `eget-admin-${response.data.company.id}`
          ) {
            history.push('/dashboard');
          } else if (response.data.user.is_first_login === true) {
            history.push('/meus-dados');
          }
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        if (err.response.status === 429) {
          addToast({
            type: 'error',
            title: 'Erro na autenticação',
            description:
              'Ocorreram muitas tentativas de acesso. Aguarde 1 minuto e tente novamente. Caso o problema persista, contate nosso suporte.',
          });
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
        });
      } finally {
        setLoadingMessage('');
      }
    },

    [signIn, addToast, history, confirm],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="BeerSales" />
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input
              name="email"
              maxLength={255}
              type="email"
              icon={FiMail}
              placeholder="Informe o e-mail"
            />
            <Input
              name="password"
              maxLength={255}
              icon={FiLock}
              autoComplete="false"
              type="password"
              placeholder="Informe a senha"
            />
            <BotaoLogin loading={loading_message} type="submit">
              Entrar
            </BotaoLogin>
            <Link to="/esqueci-minha-senha">Esqueci minha senha</Link>
            {/* <Link to="/novo-usuario">
              <FiLogIn />
              &nbsp;Criar conta
            </Link> */}
          </Form>
        </AnimationContainer>
      </Content>
      <Background />
    </Container>
  );
};

export default SignIn;
