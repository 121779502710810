import React, { useMemo } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import { Grid } from '@material-ui/core';
import BotaoDefault from '../../../../../../../components/Botoes/BotaoDefault';
import BotaoSalvar from '../../../../../../../components/Botoes/BotaoSalvar';
import formataNumero from '../../../../../../../utils/formataNumero';
import Input from '../../../../../../../components/Inputs/ControlledInput';
import { Botao, Container, Header, Footer, Body } from './styles';
import validaNumero from '../../../../../../../utils/validaNumero';

interface IPropsDialog {
  titulo: string;
  valor_bruto: number;
  desconto: number;
  setDesconto: (desconto: string) => void;
  handleSalvarDesconto: (codigo: number, valor: number) => void;
  codigo: number;
  texto_botao: React.ReactNode;
}

const DialogDesconto: React.FC<IPropsDialog> = ({
  titulo,
  valor_bruto,
  desconto,
  setDesconto,
  handleSalvarDesconto,
  codigo,
  texto_botao,
}) => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullWidth = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = (): void => {
    setOpen(true);
  };
  const handleClose = (): void => {
    setOpen(false);
  };

  const handleDesconto = (e): void => {
    let desconto_clean = e.target.value.replace('/[^0-9.,]/g', '');
    desconto_clean = validaNumero(desconto_clean, 4);

    if (Number(desconto_clean || 0) <= Number(valor_bruto)) {
      setDesconto(desconto_clean);
    }
  };

  const handleAdicionarDesconto = (): void => {
    if (Number(desconto) <= Number(valor_bruto)) {
      handleSalvarDesconto(codigo, desconto);
      handleClose();
    }
  };


  const title_botao = useMemo(() => {
    if (desconto > 0) {
      return 'Clique para alterar o valor do desconto.';
    }
    return 'Clique para adicionar um valor de desconto.';
  }, [desconto]);

  function handleClick(e) {
    e.target.select();
  };

  return (
    <>
      <Botao title={title_botao} onClick={handleClickOpen}>
        {texto_botao}
      </Botao>
      <Container
        fullWidth={fullWidth}
        onClose={handleAdicionarDesconto}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <Header>
          <p />
          <h1>{titulo}</h1>
          {handleAdicionarDesconto ? (
            <BotaoDefault onClick={handleAdicionarDesconto}>
              <CloseIcon />
            </BotaoDefault>
          ) : null}
        </Header>
        <Body>
          <Grid item xs={8} sm={8} md={6} lg={6}>
            <Input
              onClick={handleClick}
              name="desconto"
              type="number"
              value={desconto}
              onChange={handleDesconto}
              placeholder="Desconto"
              label="Desconto"
              prefixo="R$"
            />
          </Grid>
          <p>{`Preço: R$${formataNumero(valor_bruto, 4, true)}`}</p>
          <p>
            {`Preço com desconto: R$${formataNumero(
              valor_bruto - desconto,
              4,
              true,
            )}`}
          </p>
        </Body>
        <Footer>
          <BotaoSalvar onClick={() => handleAdicionarDesconto()}>
            Salvar desconto
          </BotaoSalvar>
        </Footer>
      </Container>
    </>
  );
};

export default DialogDesconto;
