import React, { useState, useEffect } from 'react';
import {
  ContainerFiltros,
  ContainerBotoes,
  ContainerResumoFiltros,
} from './styles';
import Select from '../../../../../../components/Inputs/Select';
import IGrupoProduto from '../../../../../../interfaces/selectOptions';
import api from '../../../../../../services/api';
import Slider from '../../../../../../components/Slider';
import BotaoSalvar from '../../../../../../components/Botoes/BotaoSalvar';
import upper from '../../../../../../utils/UppercaseOnlyFirstLetter';

interface IFiltros {
  grupos_selecionados: IGrupoProduto[];
  setGruposSelecionados: (grupo_produto: IGrupoProduto[]) => void;
  estoque_minimo: number;
  setEstoqueMinimo: (estoque_minimo: number) => void;
  mostra_filtros: boolean;
  setMostraFiltros: (mostra: boolean) => void;
  grupos_exibidos: number[];
}

const Filtros: React.FC<IFiltros> = ({
  grupos_selecionados,
  setGruposSelecionados,
  estoque_minimo,
  setEstoqueMinimo,
  mostra_filtros,
  setMostraFiltros,
  grupos_exibidos,
}) => {
  const [grupos_produtos, setGruposProdutos] = useState<IGrupoProduto[]>([]);
  const [grupos_selecionados_temp, setGruposSelecionadosTemp] = useState<
    IGrupoProduto[]
  >(grupos_selecionados);

  const [estoque_minimo_temp, setEstoqueMinimoTemp] = useState(
    estoque_minimo || 0,
  );
  const [estoque_maximo, setEstoqueMaximo] = useState(1000);
  const [step, setStep] = useState(1);

  useEffect(() => {
    async function getGruposProdutos(): Promise<void> {
      const { data } = await api.get<IGrupoProduto[]>('grupo-produto', {
        params: {
          grupos: grupos_exibidos,
        },
      });
      setGruposProdutos(data);
    }

    async function getMaiorEstoque(): Promise<void> {
      const response = await api.get('estoque-max');

      setEstoqueMaximo(1000);
      setStep(10);

      if (response.data && Number(response.data) > 1000) {
        setEstoqueMaximo(10000);
        setStep(100);
      }
      if (response.data && Number(response.data) > 10000) {
        setEstoqueMaximo(100000);
        setStep(1000);
      }
      if (response.data && Number(response.data) > 100000) {
        setEstoqueMaximo(1000000);
        setStep(10000);
      }
      if (response.data && Number(response.data) > 1000000) {
        setEstoqueMaximo(10000000);
        setStep(100000);
      }
    }

    getGruposProdutos();
    getMaiorEstoque();
  }, [grupos_exibidos]);

  function handleAplicarFiltros(): void {
    setEstoqueMinimo(estoque_minimo_temp);
    setGruposSelecionados(grupos_selecionados_temp);
    setMostraFiltros(false);
  }

  return (
    <>
      <ContainerResumoFiltros>
        <p>
          {`Filtros ativos: Grupos:
            ${grupos_selecionados.length > 0
              ? grupos_selecionados.map(grupo => ` ${upper(grupo.label)}`)
              : 'Todos'
            }
            - Estoque maior ou igual a
          ${estoque_minimo}`}
        </p>
      </ContainerResumoFiltros>
      { mostra_filtros &&
        <ContainerFiltros>
          <div>
            <h1>Filtrar por</h1>
          </div>
          <div>
            <Select
              label="Grupo de produtos"
              isSearchable={false}
              defaultValue={grupos_selecionados_temp}
              options={grupos_produtos}
              onChange={setGruposSelecionadosTemp}
              closeMenuOnSelect={false}
              isMulti
              name="grupos_produtos"
              placeholder="Nenhum grupo selecionado..."
            />
          </div>
          <div>
            <Slider
              value={estoque_minimo_temp}
              setValue={setEstoqueMinimoTemp}
              max={estoque_maximo}
              step={step}
              titulo={`Estoque maior ou igual a ${estoque_minimo_temp}`}
            />
          </div>
          <ContainerBotoes>
            <BotaoSalvar onClick={handleAplicarFiltros}>
              Aplicar Filtros
        </BotaoSalvar>
          </ContainerBotoes>
        </ContainerFiltros>

      }
    </>
  );
}

export default Filtros;
