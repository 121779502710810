import styled from 'styled-components';

export const Etapas = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    padding: 0 !important;
    font-size: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 768px) {
      font-size: 8px;
    }

    img {
      margin-right: 3px;
      margin-bottom: -3px;
    }
  }
`;

export const MensagemSincronizacao = styled.div`
  font-weight: 600;
  color: #9f9898;
  line-height: 2;
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;

  div:first-child {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    border-bottom: 1px solid #fafafa;
    h1 {
      font-size: 18px;
    }
  }

  div:last-child {
    min-height: 50px;
    margin-top: 10px;
    border-top: 1px solid #fafafa;
    display: flex;
    align-items: center;
  }
  padding: 10px !important;
  font-weight: 400 !important;
`;
