import React, { useState, useEffect, useRef } from 'react';
import Select, { OptionTypeBase } from 'react-select';
import { FormControlLabel, Switch } from '@material-ui/core';
import Async from 'react-select/async';
import {
  ContainerFiltros,
  ContainerBotoes,
  ContainerResumoFiltros,
} from './styles';
import ISelect from '../../../../interfaces/selectOptions';
import api from '../../../../services/api';
import Slider from '../../../../components/Slider';
import BotaoSalvar from '../../../../components/Botoes/BotaoSalvar';
import upper from '../../../../utils/UppercaseOnlyFirstLetter';
import * as colors from '../../../../config/Colors/Ligth';
import ICliente from '../../../../interfaces/cliente';
import GetNomeClientesConfig from '../../../../utils/GetNomeClientesConfig';

interface IConfigVendedor {
  modo_exibicao_cliente: number;
}

interface IFiltros {
  grupos_selecionados: ISelect[];
  setGruposSelecionados: (grupo_produto: ISelect[]) => void;
  estoque_minimo: number;
  setEstoqueMinimo: (estoque_minimo: number) => void;
  mostra_filtros: boolean;
  setMostraFiltros: (mostra: boolean) => void;
  exibe_estoque: boolean;
  setExibeEstoque: (exibe_estoque: boolean) => void;
  exibe_cliente: boolean;
  setExibeCliente: (exibe_cliente: boolean) => void;
  cliente: number;
  setCliente: (cliente: number) => void;
  prazo: number;
  setPrazo: (prazo: number) => void;
  exibe_preco: boolean;
  setExibePreco: (exibe_preco: boolean) => void;
}

interface IParam {
  filtros: IFiltros;
}

const Filtros: React.FC<IParam> = ({ filtros }) => {
  const {
    exibe_estoque,
    exibe_preco,
    estoque_minimo,
    mostra_filtros,
    cliente,
    grupos_selecionados,
    prazo,
    setCliente,
    setEstoqueMinimo,
    setExibePreco,
    setExibeEstoque,
    setGruposSelecionados,
    setMostraFiltros,
    setPrazo,
  } = filtros;

  const time = useRef<NodeJS.Timeout | null>();
  const [grupos_produtos, setGruposProdutos] = useState<ISelect[]>([]);
  const [prazos, setPrazos] = useState<ISelect[]>([]);
  const [prazo_temp, setPrazoTemp] = useState<
    OptionTypeBase | ISelect | number
  >(prazo || 0);
  const [cliente_temp, setClienteTemp] = useState<OptionTypeBase | number>(
    cliente || 0,
  );
  const [grupos_selecionados_temp, setGruposSelecionadosTemp] =
    useState<ISelect[]>(grupos_selecionados);
  const [exibe_estoque_temp, setExibeEstoqueTemp] = useState<boolean>(
    exibe_estoque || false,
  );
  const [exibe_preco_temp, setExibePrecoTemp] = useState<boolean>(
    exibe_preco || false,
  );
  const [estoque_minimo_temp, setEstoqueMinimoTemp] = useState(
    estoque_minimo || 0,
  );
  const [estoque_maximo, setEstoqueMaximo] = useState(1000);
  const [step, setStep] = useState(1);

  useEffect(() => {
    async function getGruposProdutos(): Promise<void> {
      const { data } = await api.get<ISelect[]>('grupo-produto');
      setGruposProdutos(data);
    }

    async function getPrazos(): Promise<void> {
      const { data } = await api.get<ISelect[]>('prazo');
      setPrazos(data);
    }

    async function getMaiorEstoque(): Promise<void> {
      const response = await api.get('estoque-max');

      setEstoqueMaximo(1000);
      setStep(10);

      if (response.data && Number(response.data) > 1000) {
        setEstoqueMaximo(10000);
        setStep(100);
      }
      if (response.data && Number(response.data) > 10000) {
        setEstoqueMaximo(100000);
        setStep(1000);
      }
      if (response.data && Number(response.data) > 100000) {
        setEstoqueMaximo(1000000);
        setStep(10000);
      }
      if (response.data && Number(response.data) > 1000000) {
        setEstoqueMaximo(10000000);
        setStep(100000);
      }
    }

    getPrazos();
    getGruposProdutos();
    getMaiorEstoque();
  }, []);

  function handleAplicarFiltros(): void {
    setEstoqueMinimo(estoque_minimo_temp);
    setGruposSelecionados(grupos_selecionados_temp);
    setCliente(cliente_temp.value);
    setPrazo(prazo_temp.value);
    setExibeEstoque(exibe_estoque_temp);
    setExibePreco(exibe_preco_temp);
    setMostraFiltros(false);
  }

  async function loadClientes(filter: string): Promise<ISelect[]> {
    const config = await api.get<IConfigVendedor>(
      'busca-configuracao-vendedor',
    );

    const response = await api.get('clientes', {
      params: { q: filter, cod: [201, 202] },
    });
    const configuracao = config.data ? config.data.modo_exibicao_cliente : 0;
    const corrigeKeys = response.data.data
      .filter((cli: ICliente) => cli.ativo === true)
      .map(cli => {
        return {
          value: cli.id,
          label: GetNomeClientesConfig(cli, configuracao),
        };
      });
    return corrigeKeys;
  }

  const loadClientesOptions = (inputValue: string, callback): void => {
    clearTimeout(time.current);
    time.current = setTimeout(async () => {
      callback(await loadClientes(inputValue));
    }, 1000);
  };

  return (
    <>
      <ContainerResumoFiltros>
        <p>
          {`Filtros ativos: Grupos:
            ${
              grupos_selecionados.length > 0
                ? grupos_selecionados.map(grupo => ` ${upper(grupo.label)}`)
                : 'Todos'
            }
            - Estoque maior ou igual a
          ${estoque_minimo}`}
        </p>
      </ContainerResumoFiltros>
      {mostra_filtros && (
        <ContainerFiltros>
          <div>
            <h1>Filtrar por</h1>
          </div>
          <div>
            <Select
              label="Grupo de produtos"
              isSearchable={false}
              defaultValue={grupos_selecionados_temp}
              options={grupos_produtos}
              onChange={setGruposSelecionadosTemp}
              closeMenuOnSelect={false}
              isMulti
              name="grupos_produtos"
              placeholder="Nenhum grupo selecionado..."
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: colors.backgroudColor,
                  primary: colors.labelFontColor,
                },
              })}
            />
          </div>
          <div>
            <Slider
              value={estoque_minimo_temp}
              setValue={setEstoqueMinimoTemp}
              max={estoque_maximo}
              step={step}
              titulo={`Estoque maior ou igual a ${estoque_minimo_temp}`}
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={exibe_estoque_temp}
                  onChange={() => setExibeEstoqueTemp(!exibe_estoque_temp)}
                  name="exibe_estoque"
                  color="default"
                />
              }
              label="Exibir estoque"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={exibe_preco_temp}
                  onChange={() => setExibePrecoTemp(!exibe_preco_temp)}
                  name="exibe_preco"
                  color="default"
                />
              }
              label="Exibir preço"
            />
          </div>
          {exibe_preco_temp && (
            <>
              <div>
                {/* eslint-disable-next-line */}
                <label id="aria-label" htmlFor="aria-example-input">
                  Selecione um cliente
                </label>
                <Async
                  aria-labelledby="aria-label"
                  inputId="aria-example-input"
                  name="aria-live-color"
                  classNamePrefix="react-select"
                  defaultValue={cliente_temp}
                  placeholder="Pesquise por Nome, Cpf/Cnpj ou Cidade"
                  cacheOptions
                  loadOptions={loadClientesOptions}
                  defaultOptions
                  onChange={setClienteTemp}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: colors.backgroudColor,
                      primary: colors.labelFontColor,
                    },
                  })}
                />
              </div>
              <div>
                {/* eslint-disable-next-line */}
                <label id="aria-label" htmlFor="aria-example-input">
                  Selecione um prazo
                </label>
                <Select
                  label="Prazos"
                  isSearchable={false}
                  defaultValue={prazo_temp}
                  value={prazo_temp}
                  options={prazos}
                  onChange={setPrazoTemp}
                  placeholder="Nenhum prazo selecionado..."
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: colors.backgroudColor,
                      primary: colors.labelFontColor,
                    },
                  })}
                />
              </div>
            </>
          )}
          <ContainerBotoes>
            <BotaoSalvar onClick={handleAplicarFiltros}>
              Aplicar Filtros
            </BotaoSalvar>
          </ContainerBotoes>
        </ContainerFiltros>
      )}
    </>
  );
};

export default Filtros;
