import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start !important;
  padding: 5px 0;
  h2 {
    padding-right: 5px;
  }
`;

export const ContainerSelectMultiplo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1100px;
  border-radius: 2px;
  @media only screen and (max-width: 992px) {
    .MuiStepper-root {
      padding: 0;
    }
  }
`;
