import styled from 'styled-components';
import { shade } from 'polished';
import {
  backgroundColorDarkButton,
  colorDarkButton,
} from '../../../config/Colors/Ligth';

export const Container = styled.button`
  background: ${backgroundColorDarkButton};
  color: ${colorDarkButton};
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 56px;
  width: 56px;

  border-radius: 50%;
  border: none;
  bottom: 10px;
  right: 10px;
  text-align: center;
  cursor: pointer;
  transition: all 200ms;

  @media all and (max-width: 962px) {
    height: 40px;
    width: 40px;
    bottom: 5px;
    right: 5px;
  }

  &:hover {
    background: ${shade(0.3, backgroundColorDarkButton)};
  }
`;
