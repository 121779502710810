import styled from 'styled-components';
import * as colors from '../../config/Colors/Ligth';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 5px 5px 5px;

  h2 {
    color: ${colors.labelFontColor};
    padding-left: 5px;
    font-weight: 400;
    font-size: 10px;
  }
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;

    svg {
      color: ${colors.labelFontColor};
      margin-right: 5px;
    }
  }
`;
