import React from 'react';
import { Chip } from '@material-ui/core';
import { Container } from './styles';


interface IFiltro {
  orcamento?: boolean;
  sincronizado: boolean;
  sincronizando: boolean;
  erro: boolean;
}

interface IEtapa {
  filtro: IFiltro;
  setFiltro: (filtro: IFiltro) => void;
}

const FiltroSincronizacao: React.FC<IEtapa> = ({
  filtro,
  setFiltro,
 }) => {

  return (
    <>
    <Container>
      <h2>Status:</h2>
      <Chip
        variant="default"
        color="default"
        style={{
          textDecoration: filtro.sincronizado ? 'none' : 'line-through',
        }}
        size="small"
        onClick={() =>
          setFiltro({ ...filtro, sincronizado: !filtro.sincronizado })
        }
        label="Integrado"
      />
      <Chip
        variant="default"
        color="default"
        size="small"
        style={{
          textDecoration: filtro.sincronizando ? 'none' : 'line-through',
        }}
        onClick={() =>
          setFiltro({ ...filtro, sincronizando: !filtro.sincronizando })
        }
        label="Sincronizando"
      />
      <Chip
        variant="default"
        onClick={() => setFiltro({ ...filtro, erro: !filtro.erro })}
        color="default"
        style={{
          textDecoration: filtro.erro ? 'none' : 'line-through',
        }}
        size="small"
        label="Erro"
      />
      {filtro.orcamento !== undefined && (
        <Chip
          variant="default"
          onClick={() => setFiltro({ ...filtro, orcamento: !filtro.orcamento })}
          color="default"
          style={{
            textDecoration: filtro.orcamento ? 'none' : 'line-through',
          }}
          size="small"
          label="Orçamento"
        />
      )}
      </Container>
    </>
  );
};

export default FiltroSincronizacao;
