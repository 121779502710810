import React, { ButtonHTMLAttributes } from 'react';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { useHistory } from 'react-router-dom';
import { Container } from './styles';
import api from '../../../services/api';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

const BotaoNovoPedidoRodape: React.FC<ButtonProps> = ({
  children,
  ...rest
}) => {
  const history = useHistory();

  async function handleNovoPedido(): Promise<void> {
    const { data } = await api.post(`novo-pedido`);
    history.push(`/pedidos/cadastro/${data.id}`);
  }

  return (
    <Container
      type="button"
      onClick={handleNovoPedido}
      title="Novo pedido"
      {...rest}
    >
      {children}
      <AddShoppingCartIcon />
    </Container>
  );
};
export default BotaoNovoPedidoRodape;
