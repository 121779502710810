import styled, { css, keyframes } from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import * as colors from '../../config/Colors/Ligth';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  isDisabled?: boolean;
}

export const Footer = styled.div`
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  justify-content: center;
  border-top: 1px solid #eee;
`;

export const FooterRemove = styled.div`
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: flex-end;
  border-top: 1px solid #eee;
`;


export const ContainerModal = styled(Dialog)`
  display: flex;
  flex-direction: column;
  color: ${colors.darkFontColor};
  height: 70%;
  max-height: 100%;
  width: 40%;
  max-width: 100%;
  margin: 0 auto;
  @media only screen and (min-width: 1200px) {
    top: 10% !important;
  }

  border-radius: 7px;
  .MuiDialog-paper {
    margin: 0;
  }
  .MuiDialog-paperScrollPaper {
    max-height: 100%;
  }
`;

export const ContainerModalRemove = styled(Dialog)`
  display: flex;
  flex-direction: column;
  color: ${colors.darkFontColor};
  width: 40%;
  max-width: 100%;
  margin: 0 auto;
  @media only screen and (min-width: 1200px) {
    top: 10% !important;
  }

  border-radius: 7px;
  .MuiDialog-paper {
    margin: 0;
  }

`;

export const ModalRemoverEditar = styled(Dialog)`
  display: flex;
  flex-direction: column;
  color: ${colors.darkFontColor};
  width: 40%;
  max-width: 100%;
  margin: 0 auto;
  @media only screen and (min-width: 1200px) {
    top: 10% !important;
  }

  border-radius: 7px;
  .MuiDialog-paper {
    margin: 0;
  }

`;

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;



`;

export const ContainerInitial = styled.div`
  border: 5px solid #FFF;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 7px;
  margin-top: 10px;
  padding: 5px;

 `;

export const ContainerRemoverTitle = styled.div`
border: 5px solid #FFF;
justify-content: center;
box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
border-radius: 7px;
margin-top: 10px;
padding: 5px;

`;

export const ContainerInitialRemove = styled.div`
justify-content: center;
border-radius: 7px;
padding: 5px;

`;

export const ContainerTerminaEm = styled.div`
  padding: 5px 5px 15px;
  border: 5px solid #FFF;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 7px;
  margin-bottom: 6px;  margin-top: 20px;
  label {
    display: flex;
    padding-left: 25px;
    flex-direction: center;
  }
  input {
    display: flex;
    padding-left: 15px;
  }
`;
export const ContainerTextField = styled.div`
  display: flex;
  margin-left: 55px;
  /* background-color: yellow; */
  justify-content: center;
  width: 100%;
  align-items: center;

  input[type=number] {
    width: 100% !important;
    padding: 10px;

  }

`;

export const Ocorrencias = styled.div`
  display: flex;
  color: white;
  font-size: 14px;
  background-color: #555555;
  border-radius: 0px 4px 4px 0px;
  padding: 5px;
  margin-top: 2.5px;
  margin-left: -2px;
  align-items: center;
  justify-content: center;
  `;

export const OcorrenciasDisabled = styled.div`
display: flex;
color: white;
font-size: 14px;
background-color: #c7c7ce;
border-radius: 0px 4px 4px 0px;
padding: 5px;
margin-top: 2.5px;
margin-left: -2px;
align-items: center;
justify-content: center;
`;


export const ContainerCheckbox = styled.div`
  display: flex;
  flex-direction: flex-end;

`;

export const ContainerDateTime = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: 30px;
  align-items: center;
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  padding: 5px;
  border: 5px solid #FFF;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 7px;
  width: 100%;
  align-items: center;

`;

export const ContainerTrash = styled.div`
  cursor: pointer;
  margin-left: -10px;
  background-color:  #c4c4c4;
  border-radius: 4px;
  border: 2px solid #c4c4c4;
  display: flex;
  color: #666360;
  flex-direction: row;
  margin-top: 25px;
  padding: 10px;
  justify-content: center;
  align-items: center;

`;

export const TextInfo = styled.span`
  white-space: normal;
`;

export const TextRenderAllDay = styled.span`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  margin-left: 5px;
  font-size: 11px;
  margin-top:5px;
`;

export const TextRender = styled.span`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  margin-left: 5px;
  font-size: 13px;
`;

export const TextRenderBold = styled.span`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  margin-left: 5px;
  font-weight: bold;
  font-size: 13px;
`;

export const ContainerRender = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: normal;
`;

export const TextEvento = styled.span`
  text-transform: uppercase;
  font-size: 16px;
  color:${colors.darkFontColor};
  font-weight: bold;
`;


export const Body = styled.div`
  background-color: ${colors.formBackgroundColor};
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;

  height: 480px;
  max-height: 100%;
  width: 680px;
  max-width: 100%;

  overflow-x: hidden;

  input[type=number] {
   -moz-appearance: auto !important;
   appearance:  auto !important;
  }

  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance:  auto !important;

  }
`;

export const BodyRemove = styled.div`
  background-color: ${colors.formBackgroundColor};
  margin-top: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  width: 680px;
  max-width: 100%;
  overflow-x: hidden;

`;

export const TextTerminaEm = styled.div`
    margin-top: 6px;
    padding-left: 6px;
    font-size: 10px;
    color: ${colors.labelFontColor};
`;

