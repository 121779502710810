// src/pages/Clientes/Detalhes
import React, { useEffect, useState } from 'react';
import { Tabs as Tab } from '@mui/base/Tabs';
import {
  useParams,
  useHistory,
  Link,
} from 'react-router-dom';
import UndoIcon from '@material-ui/icons/ArrowBackIos';
import { FaCartPlus, FaEdit } from 'react-icons/fa';
import {
  Tabs,
  TabPanels,
  TabsLists,
} from '../../../components/Abas';
import { Container } from '../../../styles/GlobalStyles';
import Resumo from './Resumo';
import Historico from './Historico';
import Cobrancas from './Cobrancas';
import BotaoDefault from '../../../components/Botoes/BotaoDefault';
import { Header, Voltar } from './styles';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import StatusSincronizacao from '../../../components/Sincronizacao/MostraStatusSincronizacao';
import ICliente from '../../../interfaces/cliente';
import Loading from '../../../components/Loading';

interface IRouteParam {
  id?: string;
  status?: string;
}

interface ICobranca {
  data_recebimento: Date;
  data_vencimento: Date;
  valor: number;
}

const Configuracoes: React.FC<IRouteParam> = () => {
  const [is_loading, setIsLoading] = useState(false);
  const { id, status } = useParams<IRouteParam>();
  const { addToast } = useToast();
  const history = useHistory();
  const [cobrancas, setCobrancas] = useState<ICobranca[]>();
  const [cliente, setCliente] = useState<ICliente>();

  useEffect(() => {
    async function getCliente(): Promise<void> {
      try {
        if (!id) return;
        setIsLoading(true);

        const { data } = await api.get<ICliente>(`cliente/${id}`);
        setCliente(data);
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro',
          description: err.response.data.message,
        });
      } finally {
        setIsLoading(false);
      }
    }
    getCliente();
  }, [id, addToast]);

  useEffect(() => {
    async function getCobranca(): Promise<void> {
      try {
        setIsLoading(true);
        if (!id) return;

        const { data } = await api.get<ICobranca[]>(
          `cobranca?cliente_id=${id}&data_recebimento=`,
        );

        setCobrancas(data);
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro',
          description: err.response.data.message,
        });
      } finally {
        setIsLoading(false);
      }
    }

    getCobranca();
  }, [id, addToast]);

  async function handleNovoPedido(): Promise<void> {
    try {
      const { data } = await api.post('novo-pedido', {
        cliente_id: id,
      });
      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: 'Pedido criado com sucesso',
      });
      history.push(`/pedidos/cadastro/${data.id}`);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro!',
        description: 'Ocorreu um erro ao criar o pedido!',
      });
    }
  }

  return (
    <Container>
      <Loading isLoading={is_loading} />
      <Voltar>
        <Link to="/clientes/lista" title="Voltar para a listagem de clientes">
          <UndoIcon fontSize="large" />
        </Link>
      </Voltar>
      <Header>
        <div>
          <h1>CLIENTE</h1>
          <StatusSincronizacao
            tipo="cliente"
            id={Number(id)}
            cod_retorno_integracao={cliente?.cod_retorno_integracao}
            motivo_integracao={cliente?.motivo_integracao}
          />
        </div>

        <div>
          <BotaoDefault
            title="Editar cliente"
            onClick={() => history.push(`/clientes/cadastro/${id}`)}
          >
            <FaEdit size={25} />
          </BotaoDefault>
          {status === 'ativo' && (
            <BotaoDefault
              title="Criar novo pedido a partir desse cliente"
              onClick={handleNovoPedido}
            >
              <FaCartPlus size={25} />
            </BotaoDefault>
          )}
        </div>
      </Header>
      <Tab defaultValue={0}>
        <TabsLists>
          <Tabs>Cadastro</Tabs>
          <Tabs>Histórico</Tabs>
          <Tabs>Cobranças</Tabs>
        </TabsLists>
        <TabPanels value={0}>
          <Resumo cliente={cliente} />
        </TabPanels>
        <TabPanels value={1}>
          <Historico id={Number(id)} />
        </TabPanels>
        <TabPanels value={2}>
          <Cobrancas cobrancas={cobrancas} />
        </TabPanels>
      </Tab>
    </Container>
  );
};

export default Configuracoes;
