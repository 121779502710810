export default function validaCpf(s: string): boolean {
  const cpf = s.replace(/[^0-9]/g, '');
  const c = cpf.substr(0, 9);
  const dv = cpf.substr(9, 2);
  const rejeitar = [
    '00000000000',
    '11111111111',
    '22222222222',
    '33333333333',
    '44444444444',
    '55555555555',
    '66666666666',
    '77777777777',
    '88888888888',
    '99999999999',
  ];

  const existe = !!rejeitar.includes(cpf);
  if (existe) return false;

  let d1 = 0;
  // eslint-disable-next-line
  for (let i = 0; i < 9; i++) {
    const x = parseInt(c.charAt(i), 10);
    d1 += x * (10 - i);
  }
  if (d1 === 0) return false;
  d1 = 11 - (d1 % 11);
  if (d1 > 9) d1 = 0;
  const dvchar = parseInt(dv.charAt(0), 10);
  // eslint-disable-next-line
  if (dvchar != d1) {
    return false;
  }
  d1 *= 2;
  // eslint-disable-next-line
  for (let i = 0; i < 9; i++) {
    const x = parseInt(c.charAt(i), 10);
    d1 += x * (11 - i);
  }
  d1 = 11 - (d1 % 11);
  if (d1 > 9) d1 = 0;
  const dvch = parseInt(dv.charAt(1), 10);
  // eslint-disable-next-line
  if (dvch != d1) {
    return false;
  }
  return true;
}
