// src/pages/Dashboard
import React, { useRef, useState, useEffect } from 'react';
import { Tabs as Tab} from '@mui/base/Tabs';
import Select from 'react-select';
import {
  Tabs,
  TabPanels,
  TabsLists,
} from '../../components/Abas';
import Async from '../../components/Inputs/ControlledAsyncSelect';
import ChartPizza from '../../components/Graficos/ChartBarVertical';
import ChartArea from '../../components/Graficos/ChartLine';
import ChartLineProduto from '../../components/Graficos/ChartLineProduto';
import ISelectOption from '../../interfaces/selectOptions';
import {
  MostraGraficos,
  FiltroFirst,
  Titulo,
  TituloFiltro,
  MostraGraficosPizza,
  GraficosPizza,
  Corpo,
  NoFlexWrap,
} from './styled';
import { ContainerFull } from '../../styles/GlobalStyles';
import api from '../../services/api';
import { meses, optionsReferencia } from '../../config/Basicos';
import formataValor from '../../utils/formataValor';
import formataQuantidade from '../../utils/formataQuantidade';
import { useAuth } from '../../hooks/auth';
import * as colors from '../../config/Colors/Ligth';
import upperFirstLetter from '../../utils/UppercaseOnlyFirstLetter';
import Loading from '../../components/Loading';
import BotaoNovoPedidoRodape from '../../components/Botoes/BotaoNovoPedidoRodape';
import Can from '../../components/Can';

const Dashboard: React.FC = () => {
  const time = useRef<NodeJS.Timeout | null>();
  const time2 = useRef<NodeJS.Timeout | null>();
  const now = new Date();
  const [firstPageLoad, setFirstPageLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [acumuladoValor, setAcumuladoValor] = useState('');
  const [mediaValor, setMediaValor] = useState('');
  const [acumuladoQntd, setAcumuladoQntd] = useState('');
  const [operacoes, setOperacoes] = useState([]);
  const [mediaQntd, setMediaQntd] = useState('');
  const [vendedorSelect, setVendedorSelect] = useState({
    value: 0,
    label: 'Todos',
  });
  const [municipioSelect, setMunicipioSelect] = useState({
    value: 0,
    label: 'Todos',
  });
  const [operacaoSelect, setOperacaoSelect] = useState<ISelectOption[]>([]);
  const [mes, setMes] = useState(now.getMonth());
  const [ano, setAno] = useState(now.getFullYear());
  const [referencia, setReferencia] = useState({
    value: 'data_geracao',
    label: 'Data de Cadastro',
  });
  const [somar, setSomar] = useState({ value: 'vendas_valor', label: 'Valor' });
  const [somarProduto] = useState({ value: 'produto_valor', label: 'Valor' });
  const [pedidos, setPedidos] = useState([]);
  const [produtos_mais_vendidos, setProdutosMaisvendidos] = useState([]);
  const [carteira_clientes, setCarteiraClientes] = useState([]);
  const [clientes_curva_abc, setClientesCurvaAbc] = useState([]);
  const { company } = useAuth();

  const optionsSomar = [
    { value: 'vendas_valor', label: 'Valor' },
    { value: 'vendas_qntd', label: 'Quantidade' },
  ];

  const anos = [];
  // eslint-disable-next-line
  for (let x = now.getFullYear(); x > now.getFullYear() - 6; x--) {
    anos.push({ value: x, label: x });
  }

  async function loadMunicipios(filter: string): Promise<ISelectOption[]> {
    const response = await api.get('cidade', {
      params: {
        pesquisa: filter,
        limit: 100,
      },
    });

    return response.data;
  }

  const loadMunicipiosOptions = (inputValue: string, callback): void => {
    clearTimeout(time.current);
    time.current = setTimeout(async () => {
      callback(await loadMunicipios(inputValue));
    }, 1000);
  };

  async function loadVendedores(filter: string): Promise<ISelectOption[]> {
    const response = await api.get(`relatorios/pedidos-vendedor`, {
      params: {
        pesquisa: filter,
        limit: 100,
      },
    });

    return response.data;
  }

  const loadVendedoresOptions = (inputValue: string, callback): void => {
    clearTimeout(time2.current);
    time2.current = setTimeout(async () => {
      callback(await loadVendedores(inputValue));
    }, 1000);
  };

  useEffect(() => {
    let cancel = false;
    async function getVendasReferencia(): Promise<void> {
      setIsLoading(firstPageLoad);
      if (cancel) return;
      const response = await api.get(`relatorios/pedidos-por-periodo-v2`, {
        params: {
          ano,
          mes: mes + 1,
          referencia: referencia.value,
          somar: somar.value,
          vendedor_id: vendedorSelect.value,
          municipio_id: municipioSelect.value,
          operacao_id: operacaoSelect.map(a => a.value),
        },
      });
      setPedidos(response.data.relatorio);
      setMediaValor(response.data.mediaValor);
      setAcumuladoValor(response.data.acumuladoValor);
      setMediaQntd(response.data.mediaQntd);
      setAcumuladoQntd(response.data.acumuladoQntd);
      setIsLoading(false);
    }

    async function getProdutosMaisvendidos(): Promise<void> {
      setIsLoading(firstPageLoad);
      if (cancel) return;
      const response = await api.get(`relatorios/produtos-mais-vendidos?`, {
        params: {
          ano,
          mes: mes + 1,
        },
      });
      const produtos = response.data.map(produto => {
        return {
          nome: upperFirstLetter(produto.nome),
          valor: produto.valor,
          qntd_produto: produto.qntd_produto,
          peso: produto.peso,
        };
      });
      setProdutosMaisvendidos(produtos);
      setIsLoading(false);
    }

    async function getCarteiraClientes(): Promise<void> {
      setIsLoading(firstPageLoad);
      if (cancel) return;
      const response = await api.get(
        `relatorios/situacao-carteira-clientes?mes=${mes + 1}&ano=${ano}`,
      );
      setCarteiraClientes(response.data);
      setIsLoading(false);
    }

    async function getOperacoes(): Promise<void> {
      setIsLoading(firstPageLoad);
      if (cancel) return;
      const response = await api.get(`operacao?filtrar=0`);
      setOperacoes(response.data);
      setIsLoading(false);
    }

    async function getClientesCurvaAbc(): Promise<void> {
      setIsLoading(firstPageLoad);
      if (cancel) return;
      const response = await api.get(
        `relatorios/clientes-curva-abc?mes=${mes + 1}&ano=${ano}`,
      );
      setClientesCurvaAbc(response.data);
      setIsLoading(false);
      setFirstPageLoad(false);
    }

    getVendasReferencia();
    getProdutosMaisvendidos();
    getCarteiraClientes();
    getOperacoes();
    getClientesCurvaAbc();

    const interval = setInterval(() => {
      getVendasReferencia();
      getProdutosMaisvendidos();
      getCarteiraClientes();
      getOperacoes();
      getClientesCurvaAbc();
    }, 100000);

    return () => {
      clearInterval(interval);
      cancel = true;
    };
  }, [
    mes,
    ano,
    company,
    firstPageLoad,
    referencia,
    somar,
    somarProduto,
    vendedorSelect,
    municipioSelect,
    operacaoSelect,
  ]);

  return (
    <ContainerFull>
      <Loading isLoading={isLoading} />
      <Tab defaultValue={0}>
        <TabsLists>
          <Tabs>Vendas</Tabs>
          <Tabs>Produtos</Tabs>
          <Tabs>Clientes</Tabs>
        </TabsLists>
        <TabPanels value={0}>
          <FiltroFirst>
            <NoFlexWrap>
              <div style={{ marginRight: '20px', width: '100%' }}>
                <TituloFiltro>
                  <div>
                    <h1>Mẽs</h1>
                  </div>
                </TituloFiltro>
                <Select
                  defaultValue={meses[mes]}
                  onChange={e => setMes(e.value)}
                  isSearchable={false}
                  options={meses}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: colors.backgroudColor,
                      primary: colors.labelFontColor,
                    },
                  })}
                />
              </div>
              <div style={{ marginRight: '20px', width: '100%' }}>
                <TituloFiltro>
                  <div>
                    <h1>Ano</h1>
                  </div>
                </TituloFiltro>
                <Select
                  defaultValue={{ value: ano, label: ano }}
                  onChange={e => setAno(e.value)}
                  isSearchable={false}
                  style={{ width: 'auto' }}
                  options={anos}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: colors.backgroudColor,
                      primary: colors.labelFontColor,
                    },
                  })}
                />
              </div>
              <div style={{ marginRight: '20px', width: '100%' }}>
                <TituloFiltro>
                  <div>
                    <h1>Referência</h1>
                  </div>
                </TituloFiltro>
                <Select
                  defaultValue={{
                    value: 'data_geracao',
                    label: 'Data de Cadastro',
                  }}
                  onChange={setReferencia}
                  isSearchable={false}
                  style={{ width: 'auto' }}
                  options={optionsReferencia}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: colors.backgroudColor,
                      primary: colors.labelFontColor,
                    },
                  })}
                />
              </div>
              <div style={{ marginRight: '20px', width: '100%' }}>
                <TituloFiltro>
                  <div>
                    <h1>Somar</h1>
                  </div>
                </TituloFiltro>
                <Select
                  defaultValue={{ value: 'vendas_valor', label: 'Valor' }}
                  onChange={setSomar}
                  isSearchable={false}
                  options={optionsSomar}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: colors.backgroudColor,
                      primary: colors.labelFontColor,
                    },
                  })}
                />
              </div>
              <Can roles={['customer-admin']}>
                <div style={{ marginRight: '20px', width: '100%' }}>
                  <TituloFiltro>
                    <div>
                      <h1>Vendedor</h1>
                    </div>
                  </TituloFiltro>
                  <Async
                    name="vendedor_id"
                    cacheOptions
                    loadOptions={loadVendedoresOptions}
                    defaultValue={{
                      value: 0,
                      label: 'Todos ou Digite',
                    }}
                    defaultOptions
                    onChange={setVendedorSelect}
                  />
                </div>
              </Can>
              <div style={{ marginRight: '20px', width: '100%' }}>
                <TituloFiltro>
                  <div>
                    <h1>Município</h1>
                  </div>
                </TituloFiltro>
                <Async
                  name="municipio_id"
                  cacheOptions
                  loadOptions={loadMunicipiosOptions}
                  defaultValue={{
                    value: 0,
                    label: 'Todos ou Digite',
                  }}
                  defaultOptions
                  onChange={setMunicipioSelect}
                />
              </div>
            </NoFlexWrap>
            <div
              style={{ marginRight: '20px', width: '100%', flexWrap: 'wrap' }}
            >
              <TituloFiltro>
                <div>
                  <h1>Operação</h1>
                </div>
              </TituloFiltro>
              <Select
                isMulti
                placeholder="Nenhuma operação selecionada..."
                onChange={setOperacaoSelect}
                isSearchable={false}
                options={operacoes}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: colors.backgroudColor,
                    primary: colors.labelFontColor,
                  },
                })}
              />
            </div>
          </FiltroFirst>
          <MostraGraficos>
            <Titulo>
              <div>
                <h1>Vendas por período</h1>
              </div>
              <div>
                <h1>{`${meses[mes].label} de ${ano}`}</h1>
              </div>
            </Titulo>
            <Corpo>
              <ChartArea dados={pedidos} />
              <div>
                <h2>Média diária: </h2>
                <p>
                  {mediaValor
                    ? formataValor(mediaValor)
                    : formataQuantidade(mediaQntd)}
                </p>
                <h2>Vendas no mês: </h2>
                <p>
                  {acumuladoValor
                    ? formataValor(acumuladoValor)
                    : acumuladoQntd}
                </p>
              </div>
            </Corpo>
          </MostraGraficos>
        </TabPanels>
        <TabPanels value={1}>
          <FiltroFirst>
            <div style={{ marginRight: '20px', width: '25%' }}>
              <TituloFiltro>
                <div>
                  <h1>Mẽs</h1>
                </div>
              </TituloFiltro>
              <Select
                defaultValue={meses[mes]}
                onChange={e => setMes(e.value)}
                isSearchable={false}
                options={meses}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: colors.backgroudColor,
                    primary: colors.labelFontColor,
                  },
                })}
              />
            </div>
            <div style={{ marginRight: '20px', width: '25%' }}>
              <TituloFiltro>
                <div>
                  <h1>Ano</h1>
                </div>
              </TituloFiltro>
              <Select
                defaultValue={{ value: ano, label: ano }}
                onChange={e => setAno(e.value)}
                isSearchable={false}
                style={{ width: 'auto' }}
                options={anos}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: colors.backgroudColor,
                    primary: colors.labelFontColor,
                  },
                })}
              />
            </div>
          </FiltroFirst>
          <MostraGraficos>
            <Titulo>
              <div>
                <h1>Produtos por período (Máx. 10)</h1>
              </div>
              <div>
                <h1>{`${meses[mes].label} de ${ano}`}</h1>
              </div>
            </Titulo>
            <Corpo>
              <ChartLineProduto dados={produtos_mais_vendidos} />
              <div>
                <h2>Média diária: </h2>
                <p>{mediaValor ? formataValor(mediaValor) : ''}</p>
                <h2>Vendas no mês: </h2>
                <p>{acumuladoValor ? formataValor(acumuladoValor) : ''}</p>
              </div>
            </Corpo>
          </MostraGraficos>
        </TabPanels>
        <TabPanels value={2}>
          <FiltroFirst>
            <div style={{ marginRight: '20px', width: '250px' }}>
              <TituloFiltro>
                <div>
                  <h1>Mẽs</h1>
                </div>
              </TituloFiltro>
              <Select
                defaultValue={meses[mes]}
                onChange={e => setMes(e.value)}
                isSearchable={false}
                options={meses}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: colors.backgroudColor,
                    primary: colors.labelFontColor,
                  },
                })}
              />
            </div>
            <div style={{ marginRight: '20px', width: '250px' }}>
              <TituloFiltro>
                <div>
                  <h1>Ano</h1>
                </div>
              </TituloFiltro>
              <Select
                defaultValue={{ value: ano, label: ano }}
                onChange={e => setAno(e.value)}
                isSearchable={false}
                style={{ width: 'auto' }}
                options={anos}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: colors.backgroudColor,
                    primary: colors.labelFontColor,
                  },
                })}
              />
            </div>
          </FiltroFirst>
          <MostraGraficosPizza>
            <Titulo>
              <div>
                <h1>Clientes por período</h1>
              </div>
              <div>
                <h1>{`${meses[mes].label} de ${ano}`}</h1>
              </div>
            </Titulo>
            <Corpo>
              <ChartArea dados={carteira_clientes} />
            </Corpo>
          </MostraGraficosPizza>
          <GraficosPizza>
            <MostraGraficosPizza>
              <Titulo>
                <div>
                  <h1>Carteira de clientes</h1>
                </div>
                <div>
                  <h1>{`${meses[mes].label} de ${ano}`}</h1>
                </div>
              </Titulo>
              <ChartPizza dados={carteira_clientes} />
            </MostraGraficosPizza>
            <MostraGraficosPizza>
              <Titulo>
                <div>
                  <h1>Clientes na curva ABC</h1>
                </div>
                <div>
                  <h1>{`${meses[mes].label} de ${ano}`}</h1>
                </div>
              </Titulo>
              <ChartPizza dados={clientes_curva_abc} />
            </MostraGraficosPizza>
          </GraficosPizza>
        </TabPanels>
        <BotaoNovoPedidoRodape />
      </Tab>
    </ContainerFull>
  );
};

export default Dashboard;
