export default function validaCnpj(s: string): boolean {
  const cnpj = s.replace(/[^\d]+/g, '');

  if (!cnpj) return false;

  if (cnpj.length !== 14) return false;
  const rejeitar = [
    '00000000000000',
    '11111111111111',
    '22222222222222',
    '33333333333333',
    '44444444444444',
    '55555555555555',
    '66666666666666',
    '77777777777777',
    '88888888888888',
    '99999999999999',
  ];

  const existe = rejeitar.includes(cnpj);
  if (existe) return false;

  const a: number[] = [];
  let b = 0;
  const c = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

  // eslint-disable-next-line
  for (let i = 0; i < 12; i++) {
    a[i] = parseInt(cnpj.charAt(i), 10);
    b += a[i] * c[i + 1];
  }
  let x = b % 11;
  if (x < 2) {
    a[12] = 0;
  } else {
    a[12] = 11 - x;
  }
  b = 0;
  // eslint-disable-next-line
  for (let y = 0; y < 13; y++) {
    // eslint-disable-next-line
    b += a[y] * c[y];
  }
  x = b % 11;
  if (x < 2) {
    a[13] = 0;
  } else {
    a[13] = 11 - x;
  }
  // eslint-disable-next-line
  if (parseInt(cnpj.charAt(12)) != a[12] || parseInt(cnpj.charAt(13)) != a[13]) {
    return false;
  }
  return true;
}
