import React from 'react';
import { CustomizedBadge } from './styles';

interface ILoading {
  display: string | number;
}

const Badge: React.FC<ILoading> = ({ display, children, ...props }) => {
  return (
    <CustomizedBadge
      badgeContent={display}
      color="primary"
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      {...props}
    >
      {children}
    </CustomizedBadge>
  );
};

export default Badge;
