import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as colors from '../../../config/Colors/Ligth';

export const HeaderLista = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
  min-height: 140px;
  h1 {
    font-size: 1.4rem;
    color: ${colors.titleFontColor};
  }
  > div:first-child  {
    display: flex;
    flex-direction: row;
    align-items: center!important;
    justify-content: space-between;
  }
`;

export const BodyLista = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LinkContainer = styled(Link)`
  &:hover {
    h2 {
      color:  ${colors.pedido};
    }
    filter: brightness(80%);
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  padding: 3px 0;
  border-bottom: 1px solid #eee;
  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    h2 {
      font-size: 14px;
      @media only screen and (max-width: 992px) {
        font-size: 12px;
      }
    }
    span {
      color: ${colors.ligthFontColor};
      font-size: 10px;
      svg {
        font-size: 12px;
      }
      font-weight: 600;
      display: flex;
      align-items: flex-start;
      @media only screen and (max-width: 992px) {
        font-size: 8px;
        svg {
          font-size: 10px;
        }
      }
    }
    div {
      margin-top: 2px;
    }
  }
`;
