import styled from 'styled-components';
import * as colors from '../../../../../config/Colors/Ligth';

export const ContainerFiltros = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 4px;
  margin: 5px 0;
  padding: 10px;
  border: 1px solid ${colors.borderColor};
  h1 {
      font-size: 20px;
      padding-bottom: 10px;
      align-self: center;
    }
    `;

export const ContainerBotoes = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`;

export const ContainerResumoFiltros = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  p {
    font-size: 10px;
    font-weight: 600;
    color:${colors.darkFontColor};
  }
  padding-bottom: 10px; ;
`;

export const ContainerSelect = styled.div`
  margin: 16px 0;
  width: 100%;
  max-width: 800px;
  z-index: 1000;
  .react-select__control {
    min-height: 38px;

    @media only screen and (max-width: 768px) {
      min-height: 35px;
    }
  }
  label {
    font-size: 10px;
    color: ${colors.labelFontColor};
  }

  span {
    background-color: transparent;
    padding-top: 0;
    padding-left: 6px;
    font-size: 10px;
  }

`;
export const ContainerDataInicialFinal = styled.div`

  width: 100%;
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  max-width: 800px;
  .react-select__control {
    min-height: 38px;

    @media only screen and (max-width: 768px) {
      min-height: 35px;
    }
  }
  label {
    font-size: 10px;
    color: ${colors.labelFontColor};
  }

`;





