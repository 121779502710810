import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  ReactElement,
  useRef,
} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { confirmAlert } from 'react-confirm-alert';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FaBook, FaShoppingCart } from 'react-icons/fa';
import axios from 'axios';
import Badge from '../../../../components/Badge';
import Filtros from './components/Filtros';
import IPedidoItem from '../../../../interfaces/pedidoItem';
import api from '../../../../services/api';
import {
  Container,
  Header,
  Footer,
  Body,
  ContainerBotoesCatalogoCarrinho,
  SaldoCreditoText,
} from './styles';
import Loading from '../../../../components/Loading';
import BotaoDefault from '../../../../components/Botoes/BotaoDefault';
import formataValor from '../../../../utils/formataValor';
import CardProduto from './components/CardProduto';
import BotaoSalvar from '../../../../components/Botoes/BotaoSalvar';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useToast } from '../../../../hooks/toast';
import IGrupoProduto from '../../../../interfaces/selectOptions';
import Pesquisa from '../../../../components/Pesquisa';

interface IProduto {
  produto_id: number;
  nome: string;
  grupo_produto_nome: string;
  unidade: string;
  valor: number;
  estoque: number;
  estoque_original?: number;
  grupo_produto_id: number;
  codigo: number;
  imagem: string;
  pesquisa: string;
  sem_estoque: boolean;
}

interface IModalProps {
  cliente_id?: number;
  prazo_id?: number;
  pedido_id: number;
  status_id: number;
  pedido_item: IPedidoItem[];
  setPedidoItem: (pedido_item: IPedidoItem[]) => void;
  saldo_credito: number;
  texto_botao: string | ReactElement;
  carregar_pesquisa?: string;
  grupos_exibidos: number[];
  setCodRetornoIntegracao: (cod: number) => void;
}

interface IConfiguracaoEmpresa {
  bloquear_alteracao_preco: boolean;
}

const ModalPesquisaProduto: React.FC<IModalProps> = ({
  cliente_id,
  prazo_id,
  pedido_id,
  status_id,
  pedido_item,
  saldo_credito,
  setPedidoItem,
  texto_botao,
  carregar_pesquisa,
  grupos_exibidos,
  setCodRetornoIntegracao,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [mostra_filtros, setMostraFiltros] = useState(false);
  const [pesquisa, setPesquisa] = useState('');
  const [modalIsOpen, setIsOpen] = useState(false);
  const [exibe_somente_itens_pedido, setExibeSomenteItensPedido] =
    useState(false);
  const [page, setPage] = useState(1);
  const [last_page, setLastPage] = useState(0);
  const [produtos_exibidos, setProdutosExibidos] = useState<IPedidoItem[]>([]);
  const [produtos, setProdutos] = useState<IProduto[]>([]);
  const [estoque_minimo, setEstoqueMinimo] = useState(0);
  const [grupos_selecionados, setGruposSelecionados] = useState<
    IGrupoProduto[]
  >([]);
  const { addToast } = useToast();
  const time = useRef<NodeJS.Timeout | null>();
  const [copia_pedido_item, setCopiaPedidoItem] =
    useState<IPedidoItem[]>(pedido_item);
  const [bloquear_alteracao_preco, setBloquearAlteracaoPreco] =
    useState<boolean>(false);

  useEffect(() => {
    setCopiaPedidoItem(pedido_item);
  }, [pedido_item]);

  function openModal(): void {
    setPesquisa(carregar_pesquisa || '');
    setCopiaPedidoItem(pedido_item);
    setProdutos([]);
    setProdutosExibidos([]);
    setExibeSomenteItensPedido(false);
    setIsOpen(true);
  }

  function closeModal(): void {
    const find_produto = copia_pedido_item.find(item => !item.salvo);
    if (find_produto) {
      confirmAlert({
        title: 'Atenção!',
        message:
          'Há alterações não salvas no pedido. Gostaria de sair sem salvar?',
        buttons: [
          {
            label: 'Sair sem salvar',
            onClick: () => setIsOpen(false),
          },
          {
            label: 'Cancelar',
            onClick: () => setIsOpen(true),
          },
        ],
      });
    } else {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    if (!cliente_id || !prazo_id || !modalIsOpen) return;

    async function getProdutos(): Promise<void> {
      if (page !== last_page) {
        setLastPage(page);
      } else {
        setPage(1);
        setLastPage(1);
        setIsLoading(true);
      }
      let response;
      if (exibe_somente_itens_pedido) {
        const prod = copia_pedido_item.map(produto => produto.produto_id);
        response = await api.get<IProduto[]>('produto', {
          params: {
            preco: true,
            cliente: cliente_id,
            prazo: prazo_id,
            nome: pesquisa,
            estoquemin: estoque_minimo,
            produtos: prod,
            page: 1,
            limit: 100,
          },
        });
      } else {
        const grupos =
          grupos_selecionados.length > 0
            ? grupos_selecionados.map(grupo => grupo.value)
            : grupos_exibidos;
        response = await api.get<IProduto[]>('produto', {
          params: {
            preco: true,
            cliente: cliente_id,
            prazo: prazo_id,
            nome: pesquisa,
            estoquemin: estoque_minimo,
            grupos,
            page,
            limit: 10,
          },
        });
      }
      const { data } = response;
      const produtos_do_pedido = data
        .map(produto => {
          const produto_pedido = copia_pedido_item.find(
            item => item.produto_id === produto.produto_id,
          );

          let valor_produto = 0;

          if (typeof produto_pedido?.valor_produto === 'number') {
            valor_produto = produto_pedido?.valor_produto;
          } else if (produto_pedido?.valor_bruto) {
            valor_produto =
              produto_pedido?.valor_bruto / produto_pedido?.quantidade;
          } else {
            valor_produto = produto.valor;
          }

          return {
            id: produto_pedido?.id || null,
            pedido_id,
            codigo: produto.codigo,
            nome: produto.nome,
            grupo_produto_nome: produto.grupo_produto_nome,
            produto_id: produto.produto_id,
            estoque: produto.estoque,
            estoque_original: produto.estoque,
            sem_estoque: produto.sem_estoque || false,
            unidade: produto_pedido?.unidade
              ? produto_pedido?.unidade
              : produto.unidade,
            valor_original_produto: Number(produto.valor),
            valor_produto,
            valor_bruto: produto_pedido?.valor_bruto
              ? Number(produto_pedido?.valor_bruto)
              : Number(valor_produto),
            valor_liquido: produto_pedido?.valor_liquido
              ? Number(produto_pedido?.valor_liquido)
              : Number(valor_produto),
            valor_desconto: Number(produto_pedido?.valor_desconto) || 0,
            quantidade: Number(produto_pedido?.quantidade) || 0,
            salvo: true,
            imagem: produto.imagem,
          };
        })
        .filter(mapped => {
          return (
            !exibe_somente_itens_pedido ||
            (exibe_somente_itens_pedido && mapped.quantidade > 0)
          );
        });

      if (page > 1) {
        const copia_produtos_exibidos = [
          ...produtos_exibidos,
          ...produtos_do_pedido,
        ];
        setProdutosExibidos(copia_produtos_exibidos);
        const copia_produtos = [...produtos, ...data];
        setProdutos(copia_produtos);
      } else {
        setProdutosExibidos(produtos_do_pedido);
        setProdutos(data);
      }

      setIsLoading(false);
    }
    clearTimeout(time.current);
    time.current = setTimeout(async () => {
      getProdutos();
    }, 1000);
  }, [
    cliente_id,
    prazo_id,
    modalIsOpen,
    grupos_selecionados,
    pesquisa,
    estoque_minimo,
    page,
    exibe_somente_itens_pedido,
  ]);

  useEffect(() => {
    const produtos_do_pedido = produtos
      .map(produto => {
        const produto_pedido = copia_pedido_item.find(
          item => item.produto_id === produto.produto_id,
        );

        let valor_produto = 0;

        if (typeof produto_pedido?.valor_produto === 'number') {
          valor_produto = produto_pedido?.valor_produto;
        } else if (produto_pedido?.valor_bruto) {
          valor_produto =
            produto_pedido?.valor_bruto / produto_pedido?.quantidade;
        } else {
          valor_produto = produto.valor;
        }

        return {
          id: produto_pedido?.id || null,
          pedido_id,
          codigo: produto.codigo,
          nome: produto.nome,
          grupo_produto_nome: produto.grupo_produto_nome,
          produto_id: produto.produto_id,
          estoque: produto.estoque,
          estoque_original: produto.estoque,
          sem_estoque: produto.sem_estoque || false,
          unidade: produto_pedido?.unidade
            ? produto_pedido?.unidade
            : produto.unidade,
          valor_original_produto: Number(produto.valor),
          valor_produto,
          valor_bruto: produto_pedido?.valor_bruto
            ? Number(produto_pedido?.valor_bruto)
            : Number(valor_produto),
          valor_liquido: produto_pedido?.valor_liquido
            ? Number(produto_pedido?.valor_liquido)
            : Number(valor_produto),
          valor_desconto: Number(produto_pedido?.valor_desconto) || 0,
          quantidade: Number(produto_pedido?.quantidade) || 0,
          salvo: true,
          imagem: produto.imagem,
        };
      })
      .filter(mapped => {
        return (
          !exibe_somente_itens_pedido ||
          (exibe_somente_itens_pedido && mapped.quantidade > 0)
        );
      });
    setProdutosExibidos(produtos_do_pedido);
  }, [copia_pedido_item]);

  const total = useMemo(() => {
    const itens = copia_pedido_item.filter(item => Number(item.quantidade) > 0);
    const soma = itens.reduce(
      (accumulator: number, currentValue: IPedidoItem) => {
        return accumulator + Number(currentValue.valor_liquido);
      },
      0,
    );

    const saldo = saldo_credito
      ? Number(saldo_credito) - Number(soma)
      : Number(saldo_credito);
    const quantidade = itens.length;

    return { soma, quantidade, saldo };
  }, [saldo_credito, copia_pedido_item]);

  function handleDiminuir(codigo: number): void {
    if (status_id > 2) return;
    const find_produto = produtos_exibidos.find(
      produto => produto.codigo === codigo,
    );

    const find_item_pedido = copia_pedido_item.find(
      item => item.codigo === codigo,
    );
    if (find_item_pedido) {
      if (find_item_pedido.quantidade > 0) {
        const itens_alocados = copia_pedido_item.map(item => {
          return item.codigo === codigo
            ? {
                ...item,
                salvo: false,
                quantidade: Number(find_produto.quantidade) - 1,
                valor_desconto: 0,
                valor_bruto:
                  find_produto.valor_produto *
                  (Number(find_produto.quantidade) - 1),
                valor_liquido:
                  find_produto.valor_produto *
                  (Number(find_produto.quantidade) - 1),
              }
            : {
                ...item,
              };
        });
        setCopiaPedidoItem(itens_alocados);
      }
    }
  }

  function handleAumentar(codigo: number): void {
    if (status_id > 2) return;
    const find_produto = produtos_exibidos.find(
      produto => produto.codigo === codigo,
    );

    const find_item_pedido = copia_pedido_item.find(
      item => item.codigo === codigo,
    );

    if (find_item_pedido) {
      const itens_alocados = copia_pedido_item.map(item => {
        return item.codigo === codigo
          ? {
              ...item,
              salvo: false,
              quantidade: Number(find_produto.quantidade) + 1,
              valor_desconto: 0,
              valor_produto: find_produto.valor_produto,
              valor_bruto:
                find_produto.valor_produto *
                (Number(find_produto.quantidade) + 1),
              valor_liquido:
                find_produto.valor_produto *
                (Number(find_produto.quantidade) + 1),
            }
          : {
              ...item,
            };
      });
      setCopiaPedidoItem(itens_alocados);
    } else {
      setCopiaPedidoItem([
        ...copia_pedido_item,
        {
          id: null,
          pedido_id,
          codigo: find_produto.codigo,
          produto_id: find_produto.produto_id,
          nome: find_produto.nome,
          grupo_produto_nome: find_produto.grupo_produto_nome,
          estoque: find_produto.estoque,
          unidade: find_produto.unidade,
          valor_original_produto: find_produto.valor_original_produto,
          valor_produto: find_produto.valor_produto,
          valor_bruto: find_produto.valor_bruto,
          valor_liquido: find_produto.valor_liquido,
          valor_desconto: find_produto.valor_desconto,
          quantidade: 1,
          salvo: false,
          sem_estoque: find_produto.sem_estoque,
          imagem: find_produto.imagem,
          cod_retorno_integracao: find_produto.cod_retorno_integracao,
          motivo_integracao: find_produto.motivo_integracao,
          deleted_at: null,
        },
      ]);
    }
  }

  function handleDesconto(codigo: number, valor: number): void {
    if (status_id > 2) return;

    const find_item_pedido = copia_pedido_item.find(
      item => item.codigo === codigo,
    );

    if (find_item_pedido) {
      const itens = copia_pedido_item.map(item => {
        return item.codigo === codigo
          ? {
              ...item,
              salvo: false,
              valor_desconto:
                Number(item.valor_bruto * item.quantidade) >= Number(valor)
                  ? valor
                  : item.valor_desconto,
              valor_liquido:
                Number(item.valor_bruto) >= Number(valor)
                  ? Number(item.valor_bruto) - Number(valor)
                  : item.valor_liquido,
            }
          : {
              ...item,
            };
      });
      setCopiaPedidoItem(itens);
    }
  }

  function handleValorUnitario(codigo: number, valor: number): void {
    if (status_id > 2) return;

    const find_item_pedido = copia_pedido_item.find(
      item => item.codigo === codigo,
    );

    if (find_item_pedido) {
      const itens = copia_pedido_item.map(item => {
        return item.codigo === codigo
          ? {
              ...item,
              salvo: false,
              valor_desconto: 0,
              valor_produto: Number(valor),
              valor_bruto: Number(valor) * item.quantidade,
              valor_liquido:
                Number(valor) * item.quantidade - Number(item.valor_desconto),
            }
          : {
              ...item,
            };
      });

      setCopiaPedidoItem(itens);
    } else {
      const find_produto = produtos_exibidos.find(
        produto => produto.codigo === codigo,
      );
      setCopiaPedidoItem([
        ...copia_pedido_item,
        {
          id: null,
          pedido_id,
          codigo: find_produto.codigo,
          produto_id: find_produto.produto_id,
          nome: find_produto.nome,
          grupo_produto_nome: find_produto.grupo_produto_nome,
          estoque: find_produto.estoque,
          unidade: find_produto.unidade,
          valor_original_produto: find_produto.valor_original_produto,
          valor_produto: Number(valor),
          valor_bruto: 0,
          valor_liquido: 0,
          valor_desconto: 0,
          quantidade: 0,
          salvo: false,
          sem_estoque: find_produto.sem_estoque,
          imagem: find_produto.imagem,
          cod_retorno_integracao: find_produto.cod_retorno_integracao,
          motivo_integracao: find_produto.motivo_integracao,
          deleted_at: null,
        },
      ]);
    }
  }

  function handleQuantidade(codigo: number, quantidade: string): void {
    if (
      status_id > 2 ||
      Number(quantidade) > 9999999.999 ||
      Number(quantidade) < 0
    )
      return;
    const find_produto = produtos_exibidos.find(
      produto => produto.codigo === codigo,
    );

    const find_item_pedido = copia_pedido_item.find(
      item => item.codigo === codigo,
    );

    if (find_item_pedido) {
      const itens = copia_pedido_item.map(item => {
        return item.codigo === codigo
          ? {
              ...item,
              salvo: false,
              quantidade: Number(quantidade),
              valor_desconto: 0,
              valor_bruto:
                Number(find_produto.valor_produto) * Number(quantidade),
              valor_liquido:
                Number(find_produto.valor_produto) * Number(quantidade),
            }
          : {
              ...item,
            };
      });
      setCopiaPedidoItem(itens);
    } else {
      setCopiaPedidoItem([
        ...copia_pedido_item,
        {
          id: null,
          pedido_id,
          codigo: find_produto.codigo,
          produto_id: find_produto.produto_id,
          nome: find_produto.nome,
          grupo_produto_nome: find_produto.grupo_produto_nome,
          estoque: find_produto.estoque,
          unidade: find_produto.unidade,
          valor_original_produto: find_produto.valor_original_produto,
          valor_produto: find_produto.valor_original_produto,
          valor_bruto:
            Number(quantidade) * Number(find_produto.valor_original_produto),
          valor_liquido:
            Number(quantidade) * Number(find_produto.valor_original_produto),
          valor_desconto: 0,
          quantidade: Number(quantidade),
          salvo: false,
          sem_estoque: find_produto.sem_estoque,
          imagem: find_produto.imagem,
          cod_retorno_integracao: find_produto.cod_retorno_integracao,
          motivo_integracao: find_produto.motivo_integracao,
          deleted_at: null,
        },
      ]);
    }
  }

  const Salvar = useCallback(async () => {
    try {
      setIsLoading(true);

      const filtered_itens = copia_pedido_item.filter(item => {
        return (
          !item.salvo &&
          (item.quantidade > 0 || (item.quantidade === 0 && item.id))
        );
      });

      if (status_id > 1) {
        const itens_com_quantidade = copia_pedido_item.filter(item => {
          return item.quantidade > 0;
        });
        if (itens_com_quantidade.length === 0) {
          addToast({
            type: 'error',
            title: 'Atenção! Essa operação não é permitida.',
            description: 'O pedido deve possuir ao menos um item!',
          });
          return;
        }
      }

      const data_produtos = filtered_itens.map(item => {
        return {
          id: item.id,
          pedido_id: item.pedido_id,
          produto_id: item.produto_id,
          quantidade: item.quantidade,
          valor_bruto: item.valor_bruto,
          valor_desconto: item.valor_desconto,
          valor_liquido: item.valor_liquido,
        };
      });
      const response = await api.post('pedido-item/save', data_produtos);
      setPedidoItem(
        copia_pedido_item
          .filter(item => {
            return item.quantidade > 0;
          })
          .map(filtered => {
            const novo_item = response.data.find(
              retorno => filtered.produto_id === retorno.produto_id,
            );
            return {
              ...filtered,
              id: novo_item ? novo_item.id : filtered.id,
              salvo: true,
              cod_retorno_integracao: 202,
            };
          }),
      );

      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: 'Produto(s) salvo(s) com sucesso.',
      });
      if (setCodRetornoIntegracao && status_id > 1) {
        setCodRetornoIntegracao(202);
      }
      setIsOpen(false);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Houve um erro ao salvar o(s) produto(s).',
        description: err.response?.data?.message,
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    addToast,
    copia_pedido_item,
    setCopiaPedidoItem,
    setCodRetornoIntegracao,
    status_id,
  ]);

  function handlePage(): void {
    if (exibe_somente_itens_pedido) return;
    const pagina = page + 1;
    setPage(pagina);
  }

  function handleCarrinho(mostra_carrinho: boolean): void {
    if (mostra_carrinho && total.quantidade === 0) return;
    setExibeSomenteItensPedido(mostra_carrinho);
  }

  useEffect(() => {
    axios
      .all([api.get<IConfiguracaoEmpresa>(`busca-configuracoes-empresa`)])
      .then(
        axios.spread(retorno_configuracao_empresa => {
          setBloquearAlteracaoPreco(
            retorno_configuracao_empresa.data.bloquear_alteracao_preco,
          );
        }),
      )
      .catch(error => console.log(error));
  }, []);

  return (
    <>
      <BotaoDefault
        title="Ir para o catálogo de produtos"
        onClick={() => openModal()}
      >
        {texto_botao}
      </BotaoDefault>
      <Container onClose={closeModal} open={modalIsOpen} fullScreen>
        <Loading isLoading={isLoading} />
        <Header>
          <h1>&nbsp;</h1>
          <h1>
            {exibe_somente_itens_pedido ? 'CARRINHO' : 'CATÁLOGO DE PRODUTOS'}
          </h1>
          {closeModal ? (
            <BotaoDefault onClick={closeModal}>
              <CloseIcon />
            </BotaoDefault>
          ) : null}
        </Header>
        <Body id="scrollable">
          <InfiniteScroll
            dataLength={produtos_exibidos.length}
            next={!exibe_somente_itens_pedido && handlePage}
            hasMore
            loader={!exibe_somente_itens_pedido && <h4>Carregando...</h4>}
            scrollableTarget="scrollable"
          >
            <ContainerBotoesCatalogoCarrinho
              mostra_carrinho={exibe_somente_itens_pedido}
            >
              <div>
                <BotaoDefault onClick={() => handleCarrinho(false)}>
                  <FaBook size={20} />
                  CATÁLOGO
                </BotaoDefault>
              </div>
              <div>
                <BotaoDefault onClick={() => handleCarrinho(true)}>
                  <Badge display={total.quantidade}>
                    <FaShoppingCart size={20} />
                    CARRINHO
                  </Badge>
                </BotaoDefault>
              </div>
            </ContainerBotoesCatalogoCarrinho>
            <Pesquisa
              setPage={setPage}
              placeholder="Informe o nome ou código do produto"
              pesquisa={pesquisa}
              setPesquisa={setPesquisa}
              filtros={
                !exibe_somente_itens_pedido && {
                  mostra_filtros,
                  setMostraFiltros,
                }
              }
            />
            {!exibe_somente_itens_pedido && (
              <Filtros
                grupos_selecionados={grupos_selecionados}
                setGruposSelecionados={setGruposSelecionados}
                estoque_minimo={estoque_minimo}
                setEstoqueMinimo={setEstoqueMinimo}
                mostra_filtros={mostra_filtros}
                grupos_exibidos={grupos_exibidos}
                setMostraFiltros={setMostraFiltros}
              />
            )}

            {produtos_exibidos.map(produto => (
              <CardProduto
                key={produto.produto_id}
                produto={produto}
                status_id={status_id}
                handleAumentar={handleAumentar}
                handleDiminuir={handleDiminuir}
                handleDesconto={handleDesconto}
                handleValorUnitario={handleValorUnitario}
                handleQuantidade={handleQuantidade}
                bloquear_alteracao_preco={bloquear_alteracao_preco}
              />
            ))}
          </InfiniteScroll>
        </Body>
        <Footer>
          <div>
            <div>
              <h1>{`TOTAL: ${formataValor(`${total.soma}`)} `}</h1>
              <p>{`ITENS: ${total.quantidade} `}</p>
              {!!saldo_credito && (
                <SaldoCreditoText
                  negativo={!!(Number(total.saldo) < 0)}
                >{`SALDO: ${formataValor(
                  `${total.saldo}`,
                )} `}</SaldoCreditoText>
              )}
            </div>

            <div>
              <BotaoSalvar onClick={Salvar}>Salvar</BotaoSalvar>
            </div>
          </div>
        </Footer>
      </Container>
    </>
  );
};
export default ModalPesquisaProduto;
