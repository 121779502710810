import styled from 'styled-components';
import * as colors from '../../../../config/Colors/Ligth';

export const Container = styled.div`
  padding: 20px 0 30px 0;
  display: flex;
  flex-direction: column;
  span{
    font-weight: 600;
    color: #9f9898;
    font-size: 0.9rem;
    margin-left: 10px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  top: 0;
  border-bottom: 1px solid #eee;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    h1 {
      font-size: 1.4rem;
      font-weight: bold;
      color: ${colors.titleFontColor};
    }
  }
  div + div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
`;


