import React from 'react';
import {
  RouteProps as ReactDOMRouterProps,
  Route as ReactDOMRoute,
  Redirect,
} from 'react-router-dom';
import { useAuth } from '../hooks/auth';

interface RouteProps extends ReactDOMRouterProps {
  isPrivate?: boolean;
  component: React.ComponentType;
  roles?: string[];
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  roles,
  ...rest
}) => {
  const { user, company, companies } = useAuth();

  const permissao = companies && company ? companies[company.id].role : '';
  let usuario_possui_permissao = true;
  if (roles) {
    usuario_possui_permissao = roles && roles.includes(permissao);
  }

  /* const redirect = useMemo(() => {
    return roles && roles[0] === 'eget-admin'
      ? '/admin/accounts/lista'
      : '/dashboard';
  }, [roles]); */

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) =>
        isPrivate === !!user && usuario_possui_permissao ? (
          <Component />
        ) : (
            <Redirect
              to={{
                pathname: isPrivate ? '/login' : '/',
                state: { from: location },
              }}
            />
          )
      }
    />
  );
};

export default Route;
