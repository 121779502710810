import React from 'react';

import { Lista } from './styled';
import formataNumero from '../../../utils/formataNumero';
import StatusSincronizacaoItens from '../../../components/Sincronizacao/MostraStatusSincronizacaoItens';
import IPedidoTipoEquipamento from '../../../interfaces/pedidoTipoEquipamento';
import ModalPesquisaTipoEquipamento from "../Cadastro/ModalPesquisaTipoEquipamento";

interface IListaProdutosProps {
  pedido_id: number;
  status_id: number;
  pedido_tipo_equipamento: IPedidoTipoEquipamento[];
  bloqueia_clique: boolean;
  setPedidoTipoEquipamento: (pedido_tipo_equipamento: IPedidoTipoEquipamento[]) => void;
}

const ListaTipoEquipamentosPedido: React.FC<IListaProdutosProps> = ({
  pedido_id,
  status_id,
  pedido_tipo_equipamento,
  bloqueia_clique,
  setPedidoTipoEquipamento,
  ...props
}) => {

  return (
    <>
      {pedido_tipo_equipamento?.length > 0 && (
        <Lista {...props}>
          <li>
            {/* }   <div>&nbsp;</div>{ */}
            <div>
              <strong>Tipo de equipamento</strong>
            </div>
            <div>
              <strong>Qtde</strong>
            </div>
            <div>
              <strong>#</strong>
            </div>
          </li>
          {pedido_tipo_equipamento.map(
            (item) =>
              !item.deleted_at && (
                <li key={item.tipo_equipamento_id}>
                  <>
                    <div>
                      {bloqueia_clique ? (
                        <p>{item.nome_tipo_equipamento}</p>
                      ) : (
                          <ModalPesquisaTipoEquipamento
                            pedido_id={Number(pedido_id)}
                            status_id={status_id}
                            setPedidoTipoEquipamento={setPedidoTipoEquipamento}
                            pedido_tipo_equipamento={pedido_tipo_equipamento}
                            texto_botao={item.nome_tipo_equipamento}
                          />
                        )}
                    </div>
                    <div>
                      <p>
                        {`${formataNumero(`${item.quantidade}`, 3, true)} `}
                      </p>
                    </div>
                    <div>
                      <StatusSincronizacaoItens
                        tipo="item"
                        id={Number(item.id)}
                        cod_retorno_integracao={item.cod_retorno_integracao}
                        motivo_integracao={item.motivo_integracao}
                      />
                    </div>
                  </>
                </li>
              ),
          )}
          <li>
            <strong>
              Quantidade:
              {pedido_tipo_equipamento.length}
            </strong>
          </li>
        </Lista>
      )}
    </>
  );
};

export default ListaTipoEquipamentosPedido;
