import React, { useMemo } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

import 'react-confirm-alert/src/react-confirm-alert.css';

interface IUsuario {
  id: number;
  nome: string;
  email: string;
  status_acesso: string;
  ativo: boolean;
  roles?: {
    id?: number;
    nome: string;
    empresa_id: number;
  }[];
}

interface IEmpresa {
  id: number;
  nome: string;
}

interface IParams {
  usuario: IUsuario;
  empresas: IEmpresa[];
  handleDelete: (empresa_id: number, user_id: number) => void;
  handleClick: (empresa_id: number, user_id: number, permissao: string) => void;
}

const Usuarios: React.FC<IParams> = ({
  usuario,
  empresas,
  handleDelete,
  handleClick,
}) => {
  const permissoes_empresas = useMemo(() => {
    const permissoes_por_empresas = empresas.map(empresa => {
      let usuario_permissao = 'I';
      let color = 'default';
      let title = `${empresa.nome}\nClique para ativar o usuário na empresa`;

      const usuario_ativo = usuario.roles?.find(
        empresa_usuario =>
          Number(empresa_usuario.empresa_id) === Number(empresa.id),
      );

      if (usuario_ativo) {
        if (usuario.roles.length > 0) {
          const permissao = usuario.roles?.find(
            role => Number(role.empresa_id) === Number(empresa.id),
          );

          if (permissao?.nome === 'customer-admin') {
            color = 'secondary';
            usuario_permissao = 'A';
            title = `${empresa.nome}\nClique para alterar a permissão do usuário para Vendedor`;
          } else {
            color = 'primary';
            usuario_permissao = 'V';
            title = `${empresa.nome}\nClique para alterar a permissão do usuário para Admin`;
          }
        }
      }

      return {
        empresa_id: empresa.id,
        nome: empresa.nome,
        ativo: !!usuario_ativo,
        permissao: usuario_permissao,
        color,
        title,
      };
    });
    return permissoes_por_empresas;
  }, [empresas, usuario]);

  function Delete(empresa_id, usuario_id) {
    if (usuario.roles.length > 0) {
      handleDelete(empresa_id, usuario_id);
    }
  }

  return (
    <>
      {permissoes_empresas.map(pem => (
        <>
          <Chip
            variant="default"
            color={pem.color}
            label={pem.nome}
            onDelete={() => Delete(pem.empresa_id, usuario.id)}
            onClick={() =>
              handleClick(pem.empresa_id, usuario.id, pem.permissao)
            }
            avatar={<Avatar>{pem.permissao}</Avatar>}
            title={pem.title}
          />
        </>
      ))}
    </>
  );
};

export default Usuarios;
