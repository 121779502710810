import styled, { css } from 'styled-components';
import * as colors from '../../../config/Colors/Ligth';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  margin-top: 16px;
  flex: 1;
  label {
    padding-left: 6px;
    font-size: 10px;
    color: ${colors.labelFontColor};
    ${props =>
    props.isFocused &&
    css`
        font-weight: 700;
      `}

    ${props =>
    props.isErrored &&
    css`
        color: ${colors.erro};
      `}

    div {
      background: transparent;
      border-radius: 4px;
      padding: 8px;
      width: 100%;
      display: flex;
      align-items: center;

      border: 2px solid #c4c4c4;
      color: #666360;
      svg {
        margin-right: 8px;
      }

      ${props =>
    props.isFocused &&
    css`
          border-color: ${colors.labelFontColor};
          border-width: 2px;
          color: #666360;
        `}

      input {
        flex: 1;
        background: transparent;
        border: 0;

        input::-ms-reveal,
        input::-ms-clear {
          display: none;
        }

        &::placeholder {
          color: #666360;
        }
      }
      button {
        padding: 0;
      }
    }

    span {
      padding-top: 0;
      padding-left: 6px;
      font-size: 10px;
    }
  }
`;

export const Prefixo = styled.h1`
  margin: 0;
  padding-right: 8px;
`;
