import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Container, Header, Body } from './styles';
import BotaoDefault from '../../../../components/Botoes/BotaoDefault';
import Cobrancas from '../../../Clientes/Detalhes/Cobrancas';

interface ICobranca {
  data_recebimento: Date;
  data_vencimento: Date;
  valor: number;
}

interface IParam {
  cobrancas: ICobranca[];
}

const ModalCobrancas: React.FC<IParam> = ({ cobrancas }) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal(): void {
    setIsOpen(true);
  }

  function closeModal(): void {
    setIsOpen(false);
  }

  return (
    <>
      <BotaoDefault
        style={{ color: 'red' }}
        title="Clique para ver as cobranças em aberto"
        onClick={() => openModal()}
      >
        Ver cobranças em aberto
      </BotaoDefault>
      <Container onClose={closeModal} open={modalIsOpen}>
        <Header>
          {closeModal ? (
            <BotaoDefault onClick={closeModal}>
              <CloseIcon />
            </BotaoDefault>
          ) : null}
        </Header>
        <Body>
          <Cobrancas cobrancas={cobrancas} />
        </Body>
      </Container>
    </>
  );
};
export default ModalCobrancas;
