import styled from 'styled-components';

import * as colors from '../../../../../config/Colors/Ligth';

interface ContainerProps {
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  a:hover {
    background-color: ${colors.backgroudColor};
    border-radius: 8px;
    padding: 0 2px;
    margin: 2px 0;
  }
  padding: 5px;
  a {
    margin: 2px;
  }
  background-color: white;
  display: flex;
  flex-direction: column;

  color: ${colors.darkFontColor};
  transition: background-color 0.2s;
  border: 1px solid
    ${({ isErrored }) =>
      isErrored ? colors.colorError : colors.ligthFontColor};
  border-radius: 4px;
  > div:first-child {
    background-color: ${colors.backgroundColorGrayButton};
    border-radius: 0 4px 0 0 !important;
    > div:first-child {
      display: flex;
      flex-direction: row;
      align-items: center;
      h2 {
        text-transform: capitalize;
      }
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    border-bottom: 1px solid ${colors.formBackgroundColor};

    span {
      font-size: 12px;
      strong {
        font-size: 12px;
        padding: 2px;
        border-radius: 4px;
      }

      strong.orcamento {
        background-color: ${colors.orcamento};
      }
      strong.pedido {
        background-color: ${colors.pedido};
      }
      strong.faturado {
        background-color: ${colors.faturado};
        color: white;
      }
      strong.entregue {
        background-color: ${colors.entregue};
      }
      strong.ativo {
        background-color: ${colors.concluido};
      }
      strong.inativo {
        background-color: ${colors.erro};
        color: white;
      }
    }
    button {
      background-color: transparent;
      color: ${colors.ligthFontColor};
      border: none;
      cursor: pointer;
    }
  }
  > div + div {
    padding-left: 0;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    svg {
      color: gray;
    }
    section {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-weight: 400 !important;
      padding: 0 7px;
      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      }
    }
    h2 {
      padding-top: 5px;
      display: flex;
      padding-left: 7px;
      flex-direction: row;
      align-items: center;
    }
    p {
      display: flex;
      font-weight: 400 !important;
      padding-bottom: 5px;
      padding-left: 7px;
      flex-direction: row;
      align-items: center;
      font-size: small;
    }
  }
  margin-bottom: 20px;

  &.orcamento {
    border-left: 5px solid ${colors.orcamento};
  }
  &.pedido {
    border-left: 5px solid ${colors.pedido};
  }
  &.faturado {
    border-left: 5px solid ${colors.faturado};
  }
  &.entregue {
    border-left: 5px solid ${colors.entregue};
  }
  &.ativo {
    border-left: 5px solid ${colors.concluido};
  }
  &.inativo {
    border-left: 5px solid ${colors.erro};
  }
`;

export const ContainerItemsLinha = styled.div`
  display: flex;
  justify-content: flex-start;
`;
