import React from 'react';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PeopleIcon from '@material-ui/icons/People';
import { Link } from 'react-router-dom';
import { Container } from './styles';
import IAccount from '../../../../../interfaces/account';
import { erro } from '../../../../../config/Colors/Ligth';

interface IParam {
  account: IAccount;
}

const ItemListaPedidos: React.FC<IParam> = ({ account }) => {
  const chooseProfile = () => {
    let profile = '';
    if (!account.profile) profile = 'Sem perfil definido';
    if (account.profile === 0) profile = 'Gratuito';
    if (account.profile === 1) profile = 'BS - Teste';
    if (account.profile === 2) profile = 'BS - Produção';
    return profile;
  };

  const to_link = () => {
    let link = '';
    if (account.id) link = `/admin/accounts/cadastro/${account.id}`;
    else link = `/admin/accounts/lista`;

    return link;
  };

  return (
    <Link title="Detalhes do Cliente" to={to_link()}>
      <Container
        isErrored={
          !!account.erro_clientes ||
          !!account.erro_pedidos ||
          account.erro_sincronizacao
        }
      >
        <div style={{ borderRadius: '8px' }}>
          <div>
            <AccountCircleIcon fontSize="large" />

            <h2 style={{ marginLeft: '10px', marginTop: '-8px' }}>
              {account.nome}
            </h2>
          </div>
          <div>
            <span>
              <div key={account.id}>
                {!account.ativo ? (
                  <span style={{ color: erro }}>INATIVO</span>
                ) : (
                  <>
                    <p
                      style={{
                        color: account.erro_sincronizacao ? erro : undefined,
                      }}
                    >
                      Última sincronização:&nbsp;
                      {account.last_sync_at === null &&
                        !account.sincronizando &&
                        'Aguardando 1ª sincronização'}
                      {account.sincronizando
                        ? 'Sincronizando'
                        : `${account.formatted_date} `}
                    </p>
                  </>
                )}
              </div>
            </span>
          </div>
        </div>
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingRight: 10,
            }}
          >
            <p title="Perfil do Cliente">
              <AccountBoxIcon />
              &nbsp; {chooseProfile()}
            </p>

            {!!account.erro_clientes && account.erro_clientes > 0 && (
              <p style={{ color: erro }}>
                {account.erro_clientes ?? 0} cliente(s) com erro/ sincronizando
              </p>
            )}
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingRight: 10,
            }}
          >
            <p title="Usuários Contratados">
              <PeopleIcon />
              &nbsp;
              {account.max_users}
            </p>
            {!!account.erro_pedidos && account.erro_pedidos > 0 && (
              <p style={{ color: erro }}>
                {account.erro_pedidos ?? 0} pedido(s) com erro/ sincronizando
              </p>
            )}
          </div>
        </div>
      </Container>
    </Link>
  );
};

export default ItemListaPedidos;
