import React, { useState, useCallback, useEffect } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import axios from 'axios';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';

import Select from '../../Inputs/Select';
import api from '../../../services/api';
import {
  Container,
  Header,
  Footer,
  Body,
  Lista,
  ContainerLista,
  ContainerPesquisa,
} from './styles';
import Input from '../../Inputs/Input';
import BotaoNovo from '../../Botoes/BotaoNovo';
import Loading from '../../Loading';
import IOption from '../../../interfaces/selectOptions';
import BotaoDefault from '../../Botoes/BotaoDefault';

interface IBuscaCep {
  cep?: string;
  logradouro?: string;
  complemento?: string;
  bairro?: string;
}

const ModalPesquisaEndereco: React.FC = ({ pesquisaCep }) => {
  const [isLoading, setIsLoading] = useState(false);
  // const [estado_id, setEstadoId] = useState(0);
  const [estado, setEstado] = useState<IOption>({ value: 1, label: 'AC' });
  const [estados, setEstados] = useState([]);
  const [cidade, setCidade] = useState<IOption>();
  const [cidades, setCidades] = useState([]);
  const [resultado_pesquisa, setResultadoPesquisa] = useState<IBuscaCep[]>([]);
  const [logradouro, setLogradouro] = useState('');
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal(): void {
    setIsOpen(true);
  }

  function closeModal(): void {
    setIsOpen(false);
  }

  useEffect(() => {
    async function getEstados(): Promise<void> {
      const response = await api.get('estado');
      setEstados(response.data);
    }
    getEstados();
  }, []);

  useEffect(() => {
    async function getCidades(): Promise<void> {
      const response = await api.get(`cidade?uf=${estado?.value}`);
      setCidades(response.data);
    }
    getCidades();
  }, [estado?.value]);

  const handlePesquisa = useCallback((event) => {
    setLogradouro(event.target.value);
    // console.log(event.target.value === 'enter');
  }, []);

  const buscaCepViacep = useCallback(async (uf, cid, rua) => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `https://viacep.com.br/ws/${uf}/${cid}/${rua}/json/`,
      );
      setResultadoPesquisa(data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const changeEstado = useCallback(option => {
    setEstado(option);
    setCidade({ label: '', value: 0 });
  }, []);

  const changeCidade = useCallback(option => {
    setCidade(option);
  }, []);

  const selecionaCep = useCallback(
    (endereco: IBuscaCep, uf: string, cid: string) => {
      pesquisaCep({
        cep: endereco.cep,
        logradouro: endereco.logradouro,
        complemento: endereco.complemento,
        bairro: endereco.bairro,
        cidade: cid,
        estado: uf,
      });
      closeModal();
    },
    [pesquisaCep],
  );

  return (
    <>
      <Loading isLoading={isLoading} />
      <BotaoDefault
        title="Clique para pesquisar um cep"
        onClick={() => openModal()}
      >
        <SearchIcon />
        Pesquisar Cep
      </BotaoDefault>
      <Container onClose={closeModal} open={modalIsOpen}>
        <Header>
          <p />
          <h1>Pesquisar endereço</h1>
          {closeModal ? (
            <BotaoDefault onClick={closeModal}>
              <CloseIcon />
            </BotaoDefault>
          ) : null}
        </Header>
        <Body>
          <ContainerPesquisa>
            <Grid container spacing={1}>
              <Grid item xs={3} sm={3} md={2} lg={2}>
                <Select
                  name="uf"
                  placeholder="UF"
                  options={estados}
                  value={estado}
                  onChange={option => changeEstado(option)}
                  label="UF"
                />
              </Grid>
              <Grid item xs={9} sm={9} md={10} lg={10}>
                <Select
                  name="cidade"
                  placeholder="Selecione uma cidade"
                  options={cidades}
                  value={cidade}
                  onChange={option => changeCidade(option)}
                  label="Cidade"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Input
                name="pesquisa"
                label="Pesquisar endereço"
                placeholder="Informe o nome da rua"
                value={logradouro}
                onChange={handlePesquisa}
              />
            </Grid>
          </ContainerPesquisa>
          <ContainerLista>
            <Lista>
              {resultado_pesquisa.length ? (
                resultado_pesquisa.map((option) => (
                  <li title="Selecionar esse endereço">
                    <BotaoDefault
                      onClick={() =>
                        selecionaCep(option, estado.label, cidade.label)
                      }
                    >
                      <p>
                        <strong>{`${option.cep} - `}</strong>
                        {option.logradouro ? option.logradouro : 'Cep geral'}
                        {option.complemento ? ` - ${option.complemento}` : ''}
                        {option.bairro ? ` - ${option.bairro}` : ''}
                      </p>
                    </BotaoDefault>
                  </li>
                ))
              ) : (
                  <li>
                    <p>Nenhum resultado para exibir.</p>
                  </li>
                )}
            </Lista>
          </ContainerLista>
        </Body>
        <Footer>
          <BotaoNovo
            onClick={() =>
              buscaCepViacep(estado.label, cidade?.label, logradouro)
            }
          >
            Pesquisar
          </BotaoNovo>
        </Footer>
      </Container>
    </>
  );
};
export default ModalPesquisaEndereco;
