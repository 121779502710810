import React from 'react';
import { OptionTypeBase } from 'react-select';
import AsyncSelect, { Props as AsyncProps } from 'react-select/async';
import * as colors from '../../../config/Colors/Ligth';

interface IProps extends AsyncProps<OptionTypeBase> {
  name: string;
  label?: string;
  tamanho?: number;
}

const Async: React.FC<IProps> = ({ name, label, ...rest }) => {
 
 
  return (
      <label htmlFor={name}>
        {label}
        <AsyncSelect
          cacheOptions
          classNamePrefix="react-select"
          noOptionsMessage={() => 'Não encontrado!'}
          loadingMessage={() => 'Carregando...'}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: colors.backgroudColor,
              primary: colors.labelFontColor,
            },
          })}
          {...rest}
        />
      </label>
  );
};

export default Async;
