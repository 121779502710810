const validaInputNumerico = (numero: string, zeros_direita: number): string => {
  const numero_clean = numero.replace('/^[0-9.,]+$/', '');
  // numero_clean.replace('/^0+/', '');
  numero_clean.replaceAll(',', '.');
  numero_clean.replaceAll('-', '');

  const quebra_numero = numero_clean.split('.');

  if (!quebra_numero[0]) return numero_clean;
  let retorno = '';

  retorno =
    quebra_numero[0].length > 7
      ? quebra_numero[0].slice(0, -1)
      : quebra_numero[0];

  if (!quebra_numero[1]) return retorno;

  retorno += '.';
  retorno +=
    quebra_numero[1].length > zeros_direita
      ? quebra_numero[1].slice(0, -1)
      : quebra_numero[1];

  return retorno;
};

export default validaInputNumerico;
