import styled, { css } from 'styled-components';
import * as colors from '../../../config/Colors/Ligth';

interface ICadastroProps {
  status_id?: number;
  cod_integracao?: boolean;
}

export const HeaderCadastro = styled.div`
  display: flex;
  flex-direction: column;
  top: 0;
  height: 60px;
  border-bottom: 1px solid #eee;
  span {
    color: ${colors.darkFontColor};
  }
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    h1 {
      font-size: 1.4rem;
      font-weight: bold;
      color: ${colors.titleFontColor};
    }
  }
  div + div {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-end;
    span {
      text-align: left;
      font-size: 10px;
    }
  }
`;

export const BodyCadastro = styled.div`
  padding: 20px 0 30px 0;
  border-bottom: 1px solid #eee;
  background-color: 'orange';
  display: flex;
  flex-direction: column;
  .MuiStepIcon-root.MuiStepIcon-completed {
    color: #eb9122;
  }
  .MuiStepIcon-root.MuiStepIcon-active {
    color: #eb9122;
  }
  .MuiStepLabel-label.MuiStepLabel-active {
    text-decoration: underline;
    color: #eb9122;
  }
  .MuiFormControl-root {
    padding-top: 35px !important;
    @media only screen and (max-width: 768px) {
      padding-top: 33px !important;
    }
  }
`;

export const FooterCadastro = styled.div`
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  div {
    margin: 10px;
  }
`;

export const ContainerSincronizacao = styled.div<ICadastroProps>`
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
  ${props =>
    props.status_id &&
    props.status_id < 2 &&
    css`
      display: none;
    `}
`;

export const Voltar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: -10px;
  a {
    color: ${colors.ligthFontColor};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    p {
      text-transform: uppercase;
    }
    &:hover {
      color: ${colors.titleFontColor};
    }
    >
  }
  div{
    display:flex;
    flex-direction:row;
  }
`;

export const ExibeCadastro = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 0;
  padding-left: 10px;
  text-transform: capitalize;
  line-height: 25px;
  color: ${colors.darkFontColor};
  h2 {
    font-size: 1.2rem;
    font-weight: 700;
    padding-bottom: 10px;
    @media screen and(max-width: 1000px) {
      padding-bottom: 0;
      font-size: 1rem;
    }
  }
  p {
    font-weight: 500 !important;
    font-size: 1rem;
  }
  button {
    font-size: 10px;
    height: 12px;
    padding: 10px 0 0 0;
    color: ${colors.ligthFontColor};
  }
`;

export const Inline = styled.section`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const DivBotoes = styled.div<ICadastroProps>`
  display: flex;
  height: 40px;
  margin-top: 20px;
  ${props =>
    props.status_id &&
    props.status_id > 2 &&
    !props.cod_integracao &&
    css`
      display: none;
    `}
`;

export const NenhumItemCadastrado = styled.p`
  flex: 1;
  height: 50px;
  padding: 30px;
  border: 1px dashed ${colors.ligthFontColor};
  border-spacing: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${colors.ligthFontColor};
  font-size: 18px;
  @media only screen and (max-width: 992px) {
    font-size: 16px;
  }
`;

export const Catalogo = styled.strong`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: -10px;
  border: 1px solid ${colors.borderColor};
  padding: 5px;
  border-radius: 4px;
`;

export const Typography = styled.div`
  font-weight: 600;
  color: #9f9898;
  line-height: 2;
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
`;
