import styled from 'styled-components';
import { labelFontColor } from '../../../config/Colors/Ligth';

export const Container = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;

  label {
    font-size: 16px;
    color: ${labelFontColor};
    margin-left:4px;
  }
`;
