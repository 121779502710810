import React, { useRef, useEffect, useState, useCallback } from 'react';
import { OptionTypeBase } from 'react-select';
import AsyncSelect, { Props as AsyncProps } from 'react-select/async';
import { useField } from '@unform/core';
import { Container } from './styles';
import * as colors from '../../../config/Colors/Ligth';

interface IProps extends AsyncProps<OptionTypeBase> {
  name: string;
  label?: string;
  tamanho?: number;
}

const Async: React.FC<IProps> = ({ name, tamanho, label, ...rest }) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!selectRef.current);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.select.state.value) {
            return [];
          }

          return ref.select.state.value.map(
            (option: OptionTypeBase) => option.value,
          );
        }
        if (!ref.select.state.value) {
          return '';
        }

        return ref.select.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <Container
      isErrored={!!error}
      tamanho={tamanho}
      isFilled={isFilled}
      isFocused={isFocused}
    >
      <label htmlFor={name}>
        {label}
        <AsyncSelect
          cacheOptions
          defaultValue={defaultValue}
          ref={selectRef}
          classNamePrefix="react-select"
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          noOptionsMessage={() => 'Não encontrado!'}
          loadingMessage={() => 'Carregando...'}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: colors.backgroudColor,
              primary: colors.labelFontColor,
            },
          })}
          {...rest}
        />

        {error && <span>{error}</span>}
      </label>
    </Container>
  );
};

export default Async;
