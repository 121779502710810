import styled, { css } from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import * as colors from '../../../../config/Colors/Ligth';

interface IContainerBotoesCatalogoCarrinho {
  mostra_carrinho: boolean;
}

interface ISaldoText {
  negativo: boolean;
}

export const Container = styled(Dialog)`
  background-color: ${colors.formBackgroundColor};
  display: flex;
  flex-direction: column;
  color: ${colors.darkFontColor};
`;

export const Header = styled.div`
  border-bottom: 1px solid ${colors.borderColor};
  padding: 5px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  h1 {
    font-size: 18px;
    font-weight: 700;
    color: ${colors.darkFontColor};
  }
`;

export const Body = styled.div`
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  width: 650px;
  max-width: 100%;

  overflow-x: hidden;
  height: calc(100% - 102px);
`;

export const Footer = styled.div`
  border-top: 1px solid ${colors.borderColor};
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  div {
    margin: 0 auto;
    padding: 0 10px 0 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between !important;
    width: 620px;
    max-width: 100%;
    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h1 {
        font-size: 14px;
        color: ${colors.darkFontColor};
      }
      p {
        font-size: 10px;
        font-weight: 600;
        color: ${colors.ligthFontColor};
      }
    }
    div + div {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-top: 5px;
      height: 50px;
    }
  }
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: row;
  color: ${colors.darkFontColor};
  align-items: flex-end;
  justify-content: center;
  button {
    margin: 0;

    svg {
      color: ${colors.borderColor};
    }
  }
`;

export const ContainerBotoesCatalogoCarrinho = styled.div<IContainerBotoesCatalogoCarrinho>`
  display: flex;
  flex-direction: row;
  color: ${colors.darkFontColor};
  align-items: flex-end;
  justify-content: center;

  border-bottom: 1px solid ${colors.borderColor};

  div {
    width: 50%;
  }
  div:first-child {
    ${props =>
    !props.mostra_carrinho &&
    css`
        border-bottom: 2px solid ${colors.darkFontColor};
        background-color: ${colors.colorDarkButton};
        button {
          font-size: 15px;
          width: 100%;
          color: ${colors.darkFontColor};
          svg {
            color: ${colors.darkFontColor};
          }
        }
      `}
  }
  div:last-child {
    ${props =>
    props.mostra_carrinho &&
    css`
        border-bottom: 2px solid ${colors.darkFontColor};
        background-color: ${colors.colorDarkButton};
        button {
          font-size: 15px;
          width: 100%;
          color: ${colors.darkFontColor};
          svg {
            color: ${colors.darkFontColor};
          }
        }
      `}
  }

  button {
    font-size: 15px;
    width: 100%;
    svg {
      margin-right: 3px;
    }
  }
`;

export const SaldoCreditoText = styled.strong<ISaldoText>`
        font-size: 10px;
        font-weight: 600;
        color: ${colors.ligthFontColor};
      ${props =>
    props.negativo &&
    css`
      color: ${colors.erro};
      `}
`;
