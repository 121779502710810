import styled, { css } from 'styled-components';
import * as colors from '../../../../../config/Colors/Ligth';

interface IContainerProps {
  alocado: boolean;
}

export const Container = styled.div<IContainerProps>`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 4px;
  border: 1px solid ${colors.borderColor};
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  padding: 10px;
  margin: 3px 0;
  background-color: white;
  ${props =>
    props.alocado &&
    css`
      border: 1px solid ${colors.concluido};
    `}
  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    h1 {
      font-size: 1.2rem;
      font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
      font-weight: 400;
      line-height: 1.334;
      letter-spacing: 0em;
      color: rgba(0, 0, 0, 0.87);
    }
    div {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: baseline;
      flex: 1;
      height: 100%;
      div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
      }
      & + div {
        flex: 1;
        height: 100%;
        align-items: flex-end;
        justify-content: baseline;
      }
    }
  }

  button {
    padding: 0;
    display: flex;
    justify-content: flex-end;
    height: 100%;
  }

  small {
    font-size: 0.8rem;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0.00938em;
    margin-bottom: 5px;
    color: ${colors.etapa_concluida};
  }
`;


export const ContainerInput = styled.div`
  position: relative;
  display: inline-block;
  border-bottom: 2px solid ${colors.borderColor};

  padding-right: 5px;
  input {
    text-align: center;
    font-size: 1.2rem;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans - serif;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0em;
    padding: 0 5px;
    margin-right: 5px;
    color: rgba(0, 0, 0, 0.87);
    width: 100px;
    border: none;
  }
`;

