import React, {
  TextareaHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { useField } from '@unform/core';
import { Container } from './styles';

interface InputProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  label?: string;
  tamanho?: number;
  isDisabled?: boolean;
  icon?: React.ComponentType<IconBaseProps>;
}

const TextArea: React.FC<InputProps> = ({
  name,
  label,
  tamanho,
  icon: Icon,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container
      style={rest.style}
      isErrored={!!error}
      isDisabled={rest.disabled}
      tamanho={tamanho}
      isFilled={isFilled}
      isFocused={isFocused}
    >
      <label htmlFor={name}>
        {label}
        <div>
          {Icon && <Icon size={20} />}

          <textarea
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            // formNoValidate
            defaultValue={defaultValue}
            ref={inputRef}
            {...rest}
          />
        </div>
        {error && <span>{error}</span>}
      </label>
    </Container>
  );
};

export default TextArea;
