import React, { useState, useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import { format } from 'date-fns';
import {
  Container,
  Header,
  Footer,
  Body,
} from './styles';
import BotaoNovo from '../../Botoes/BotaoNovo';
import BotaoDefault from '../../Botoes/BotaoDefault';
import api from '../../../services/api';
import DateTime from '../../Inputs/DateTime';
import { useToast } from '../../../hooks/toast';
import Select from '../../Inputs/ControlledSelect';
import { useAuth } from '../../../hooks/auth';
import IEntregador from '../../../interfaces/selectOptions';

interface IPedido {

  pedido_id: number;
  onSuccess: (show_loading: boolean) => void;

}

interface IColaborador {

  pessoa: {
    nome: string;
    id: number;
  }

}

const ModalEntregaPedido: React.FC<IPedido> = ({ children, pedido_id, onSuccess, ...rest }) => {

  const { user } = useAuth();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [entregadores, setEntregadores] = useState<IEntregador[]>([]);
  const [entregador, setEntregador] = useState<IEntregador>({ value: user.colaborador_id, label: user.nome });
  const [data_entrega, setDataEntrega] = useState<Date>(format(new Date(), 'yyyy-MM-dd HH:mm:ss'));
  const { addToast } = useToast();


  function openModal(): void {
    setIsOpen(true);
  }

  function closeModal(): void {
    setIsOpen(false);
  }

  useEffect(() => {
    async function getEntregadores(): Promise<void> {
      const response = await api.get<IColaborador[]>('colaborador');
      setEntregadores(response.data.map(item => {

        return { label: item.pessoa?.nome, value: item.pessoa?.id }

      }));

    }
    getEntregadores();
  }, []);

  const SalvaEntregaPedido = async (): Promise<void> => {

    if (!pedido_id || !entregador || !data_entrega) {

      addToast({
        type: 'error',
        title: 'Erro na cadastro',
        description: 'Por favor, preencha todos os campos para continuar.'
      });

    }

    else {

      try {

        await api.post('pedido/entregar-pedido', { pedido_id, entregador_id: entregador.value, data_entrega });

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Pedido entregue com sucesso.',
        });

        onSuccess(true);
        closeModal();
        
      }

      catch (err) {

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: err.response.data.message,
        });

      }

    }

  }

  return (
    <>
      <BotaoDefault
        onClick={() => openModal()}
        title="Clique para entregar o pedido..."

      >
        {children}
      </BotaoDefault>
      <Container {...rest} onClose={closeModal} open={modalIsOpen}>
        <Header>
          <p />
          <h1>Entrega do Pedido</h1>
          {closeModal ? (
            <BotaoDefault onClick={closeModal}>
              <CloseIcon />
            </BotaoDefault>
          ) : null}
        </Header>
        <Body>
          <Grid
            container
            spacing={1}
            style={{ padding: 0, margin: '0 0 20px 0' }}
          >

            <Grid item xs={12} sm={12} md={6} lg={6} style={{ alignItems: 'flex-end' }} >

              <Select
                name="entregador_id"
                isSearchable={false}
                label="Entregador*"
                value={entregador}
                options={entregadores}
                onChange={setEntregador}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} style={{ alignItems: 'flex-end' }} >
              <DateTime
                name="data_entrega"
                label="Data de Entrega*"
                format="dd/MM/yyyy HH:mm"
                setValue={e => setDataEntrega(e)}
                value={data_entrega}
              />
            </Grid>
          </Grid>
        </Body>
        <Footer>
          <BotaoNovo onClick={SalvaEntregaPedido}>
            Concluir
          </BotaoNovo>
        </Footer>
      </Container>
    </>
  );
};
export default ModalEntregaPedido;
