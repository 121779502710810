import React, { ButtonHTMLAttributes } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Container } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  funcao_id: number;
  titulo: string;
  mensagem: string;
  funcao: Function;
}

const BotaoComConfirmacao: React.FC<ButtonProps> = ({
  funcao_id,
  titulo,
  mensagem,
  funcao,
  children,
  ...rest
}) => {
  const submit = () => {
    confirmAlert({
      title: titulo,
      message: mensagem,
      buttons: [
        {
          label: 'Confirmar',
          onClick: () => funcao(funcao_id),
        },
        {
          label: 'Cancelar',
          onClick: () => (0),
        },
      ],
    });
  };

  return (
    <Container type="button" title={titulo} onClick={submit} {...rest}>
      {children}
    </Container>
  );
};

export default BotaoComConfirmacao;
