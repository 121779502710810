import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react';
import axios from 'axios';
import { FormHandles } from '@unform/core';
import UndoIcon from '@material-ui/icons/ArrowBackIos';
import { Form } from '@unform/web';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useHistory, Link, useParams } from 'react-router-dom';
import { FaBook, FaRegFilePdf, FaTruckLoading } from 'react-icons/fa';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepButton from '@material-ui/core/StepButton';
import { format } from 'date-fns';
import Grid from '@material-ui/core/Grid';
import StatusSincronizacao from '../../../components/Sincronizacao/MostraStatusSincronizacao';
import DateTime from '../../../components/Inputs/DateTime';
import formataDataHora from '../../../utils/formataDataHora';
import limpaNull from '../../../utils/limpaNull';
import ModalPesquisaEndereco from '../../../components/Modal/ModalPesquisaEndereco';
import upper from '../../../utils/strToUpper';
import ICliente from '../../../interfaces/cliente';
import Select from '../../../components/Inputs/Select';
import Async from '../../../components/Inputs/AsyncSelect';
import IPedidoItem from '../../../interfaces/pedidoItem';
import IPedidoTipoEquipamento from '../../../interfaces/pedidoTipoEquipamento';
import ISelectOption from '../../../interfaces/selectOptions';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import BotaoNovo from '../../../components/Botoes/BotaoNovo';
import BotaoSalvar from '../../../components/Botoes/BotaoSalvar';
import BotaoDefault from '../../../components/Botoes/BotaoDefault';
import Input from '../../../components/Inputs/Input';
import TextArea from '../../../components/Inputs/TextArea';
import InputMasked from '../../../components/Inputs/InputMask';
import { Container } from '../../../styles/GlobalStyles';
import Loading from '../../../components/Loading';
import ListaProdutosPedido from '../ListaProdutos';
import ListaTiposEquipamentoPedido from '../ListaTipoEquipamentos';
import retornaObjeto from '../../../utils/buscaObjeto';
import {
  HeaderCadastro,
  BodyCadastro,
  ContainerSincronizacao,
  DivBotoes,
  Voltar,
  Inline,
  NenhumItemCadastrado,
  Catalogo,
  Typography,
} from './styles';
import { previsoesEntrega, previsoesDevolucao } from '../../../config/Basicos';
import IPedido from '../../../interfaces/pedido';
import retornaLabel from '../../../utils/labelFromArray';
import ModalPesquisaProduto from './ModalPesquisaProduto';
import ModalPesquisaTipoEquipamento from './ModalPesquisaTipoEquipamento';
import mask from '../../../utils/masks';
import GetNomeClientesConfig from '../../../utils/GetNomeClientesConfig';
import ModalEntregaPedido from '../../../components/Modal/ModalEntregaPedido';
import ModalCobrancas from './ModalCobrancas';
import { useAuth } from '../../../hooks/auth';

interface IRouteParam {
  id?: string;
}

interface IBuscaCep {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
}

interface IConfigVendedor {
  grupos_exibidos: number[];
  modo_exibicao_cliente: number;
}

interface ICobranca {
  data_recebimento: Date;
  data_vencimento: Date;
  valor: number;
}

interface IConfiguracaoAdmin {
  bloquear_alteracao_prazo: boolean;
  bloquear_alteracao_forma_pagamento: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: '0',
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    stepIcon: {
      color: 'red',
    },
  }),
);

const CadastroPedidos: React.FC<IRouteParam> = () => {
  const classes = useStyles();
  const time = useRef<NodeJS.Timeout | null>();
  const [activeStep, setActiveStep] = React.useState(0);
  const [pedido_item, setPedidoItem] = useState<IPedidoItem[] | undefined>([]);
  const [pedido_tipo_equipamento, setPedidoTipoEquipamento] = useState<
    IPedidoTipoEquipamento[] | undefined
  >([]);
  const [operacoes, setOperacoes] = useState([]);
  const [prazos, setPrazos] = useState([]);
  const [formas_pagamento, setFormasPagamento] = useState([]);
  const { id } = useParams<IRouteParam>();
  const [isLoading, setIsLoading] = useState(true);
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();
  const [nome, setNome] = useState<string>('');
  const [apelido, setApelido] = useState<string>('');
  const [cpf_cnpj, setCpfCnpj] = useState<string>('');
  const [cliente_id, setClienteId] = useState<number>();
  const [beersales_id, setBeersalesId] = useState<number>();
  const [saldo_credito, setSaldoCredito] = useState<number | null>(0);
  const [erroDataPrevistaEntrega, setErroDataPrevistaEntrega] =
    useState<string>('');
  const [erroDataPrevistaRetorno, setErroDataPrevistaRetorno] =
    useState<string>('');
  const [cep, setCep] = useState<string>('');
  const [logradouro, setLogradouro] = useState<string>('');
  const [observacao, setObservacao] = useState<string>('');
  const [numero, setNumero] = useState<string>('');
  const [complemento, setComplemento] = useState<string>('');
  const [bairro, setBairro] = useState<string>('');
  const [cidade, setCidade] = useState<string>('');
  const [estado, setEstado] = useState<string>('');
  const [endereco_id, setEnderecoId] = useState<number>();
  const [operacao_id, setOperacaoId] = useState<number>();
  const [prazo_id, setPrazoId] = useState<number>();
  const [forma_pagamento_id, setFormaPagamentoId] = useState<number>();
  const [status_id, setStatusId] = useState<number>(5);
  const [codigo_ibge, setCodigoIbge] = useState<string>();
  const [entregar, setEntregar] = useState<boolean>();
  const [buscar_equipamento, setBuscarEquipamento] = useState<boolean>();
  const [, setValorLiquido] = useState<number>();
  const [config_vendedor, setConfigVendedor] = useState<IConfigVendedor | null>(
    null,
  );
  const [, setMotivoCancelamento] = useState<string>();
  const [data_geracao, setDataGeracao] = useState<Date>();
  const [data_faturado, setDataFaturado] = useState<Date>();
  const [data_entregue, setDataEntregue] = useState<Date>();
  const [data_concluido, setDataConcluido] = useState<Date>();
  const [data_cancelado, setDataCancelado] = useState<Date>();
  const [data_bloqueado, setDataBloqueado] = useState<Date>();
  const [cod_retorno_integracao, setCodRetornoIntegracao] = useState<number>();
  const [motivo_integracao, setMotivoIntegracao] = useState<string>();
  const [motivo_pedido, setMotivoPedido] = useState<string>();
  const [data_prevista_entrega, setDataPrevistaEntrega] = useState<Date | null>(
    null,
  );
  const [data_prevista_retorno, setDataPrevistaRetorno] = useState<Date | null>(
    null,
  );
  const [loading_message, setLoadingMessage] = useState('');
  const [numerosNfe, setNumerosNfe] = useState([]);
  const [cobrancas, setCobrancas] = useState<ICobranca[]>();
  const { company, companies } = useAuth();
  const [bloquear_alteracao_prazo, setBloquearAlteracaoPrazo] =
    useState<boolean>(false);
  const [
    bloquear_alteracao_forma_pagamento,
    setBloquearAlteracaoFormaPagamento,
  ] = useState<boolean>(false);

  const role = companies && company && id ? companies[company.id].role : '';

  useEffect(() => {
    if (!id) return;
    axios
      .all([api.get<IConfiguracaoAdmin>(`busca-configuracoes-empresa`)])
      .then(
        axios.spread(retorno_configuracao_empresa => {
          setBloquearAlteracaoPrazo(
            retorno_configuracao_empresa.data.bloquear_alteracao_prazo,
          );
          setBloquearAlteracaoFormaPagamento(
            retorno_configuracao_empresa.data
              .bloquear_alteracao_forma_pagamento,
          );
        }),
      )
      .catch(error => console.log(error));
  }, [id]);

  const handleNext = (): void => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = (): void => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  async function loadClientes(filter: string): Promise<ISelectOption[]> {
    if (!config_vendedor) return [];
    const response = await api.get('clientes', {
      params: { q: filter, cod: [201, 202] },
    });
    const configuracao = config_vendedor
      ? config_vendedor.modo_exibicao_cliente
      : 0;
    const corrigeKeys = response.data.data
      .filter((cliente: ICliente) => cliente.ativo === true)
      .map(cli => {
        return {
          value: cli.id,
          label: GetNomeClientesConfig(cli, configuracao),
        };
      });
    return corrigeKeys;
  }

  const loadClientesOptions = (inputValue: string, callback): void => {
    clearTimeout(time.current);
    time.current = setTimeout(async () => {
      callback(await loadClientes(inputValue));
    }, 1000);
  };

  const salvaCliente = async (): Promise<void> => {
    formRef.current?.setErrors({});
    if (!cliente_id) {
      formRef.current?.setFieldError(
        'cliente_id',
        'Você deve escolher um cliente',
      );
    } else {
      try {
        setLoadingMessage('Salvando...');
        await api.put(`/pedido/${id}`, { cliente_id });
        handleNext();
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Atenção',
          description: err.response.data.message,
        });
      } finally {
        setLoadingMessage('');
      }
    }
  };

  const salvaEndereco = async (): Promise<void> => {
    const data = {
      id,
      observacao,
      endereco: {
        id: endereco_id,
        cep,
        logradouro,
        numero,
        bairro,
        complemento,
        cidade,
        estado,
        codigo_ibge,
      },
    };
    try {
      setLoadingMessage('Salvando...');
      await api.put(`/pedido/${id}`, data);
      handleNext();
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Atenção',
        description: err.response.data.message,
      });
    } finally {
      setLoadingMessage('');
    }
  };

  const salvaInformacoesComplementares = async (): Promise<void> => {
    const data = {
      operacao_id,
      prazo_id,
      forma_pagamento_id,
      entregar,
      buscar_equipamento,
      data_prevista_entrega: format(
        new Date(data_prevista_entrega) || new Date(),
        'yyyy-MM-dd HH:mm:ss',
      ),
      data_prevista_retorno: data_prevista_retorno
        ? format(
            new Date(data_prevista_retorno) || new Date(),
            'yyyy-MM-dd HH:mm:ss',
          )
        : null,
    };
    let erro = false;
    formRef.current?.setErrors({});
    if (!forma_pagamento_id) {
      erro = true;
      formRef.current?.setFieldError(
        'forma_pagamento_id',
        'A forma de pagamento é obrigatória',
      );
    }
    setErroDataPrevistaEntrega('');
    setErroDataPrevistaRetorno('');

    if (!data_prevista_entrega) {
      erro = true;
      setErroDataPrevistaEntrega('A data é obrigatória');
    }
    if (
      !data_prevista_entrega &&
      pedido_tipo_equipamento &&
      pedido_tipo_equipamento.length > 0
    ) {
      erro = true;
      setErroDataPrevistaRetorno(
        'A data é obrigatória quando há tipos de equipamento',
      );
    }

    if (
      data_prevista_retorno &&
      data_prevista_entrega &&
      data_prevista_retorno < data_prevista_entrega
    ) {
      erro = true;
      setErroDataPrevistaRetorno(
        'A data prevista de retorno não pode ser menor que a data prevista de entrega',
      );
    }
    if (buscar_equipamento !== null && !data_prevista_retorno) {
      erro = true;
      setErroDataPrevistaRetorno('A data de retorno é obrigatória');
    }
    if (!prazo_id) {
      erro = true;
      formRef.current?.setFieldError('prazo_id', 'O prazo obrigatório');
    }
    if (!operacao_id) {
      pedido_tipo_equipamento;
      erro = true;
      formRef.current?.setFieldError('operacao_id', 'A operação é obrigatória');
    }
    if (buscar_equipamento === null && data_prevista_retorno) {
      erro = true;
      formRef.current?.setFieldError(
        'buscar_equipamento',
        'A previsão de devolução é obrigatória',
      );
    }

    if (entregar === null || entregar === undefined) {
      erro = true;
      formRef.current?.setFieldError(
        'entregar',
        'A previsão de entrega é obrigatória',
      );
    }

    if (!erro) {
      try {
        setLoadingMessage('Salvando...');
        await api.put(`/pedido/${id}`, data);
        handleNext();
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Atenção',
          description: err.response.data.message,
        });
      } finally {
        setLoadingMessage('');
      }
    }
  };

  const salvaTiposEquipamentos = (): void => {
    let erro = false;
    if (pedido_tipo_equipamento.length > 0) {
      if (!data_prevista_retorno) {
        erro = true;
        setErroDataPrevistaRetorno(
          'A data é obrigatória quando há tipos de equipamento',
        );
      }

      if (buscar_equipamento === null) {
        erro = true;
        formRef.current?.setFieldError(
          'buscar_equipamento',
          'A previsão de devolução é obrigatória',
        );
      }
    }
    if (erro) {
      setActiveStep(2);
      return;
    }
    handleNext();
  };

  const buscaCepViacep = useCallback(async (busca_cep: string) => {
    try {
      const cepPesquisar = busca_cep.replace(/\D+/g, '');

      if (cepPesquisar.length === 8) {
        const { data } = await axios.get(
          `https://viacep.com.br/ws/${cepPesquisar}/json/`,
        );
        setEstado(upper(data.uf));
        setCidade(upper(data.localidade));
        setLogradouro(upper(data.logradouro));
        setBairro(upper(data.bairro));
        setCodigoIbge(data.ibge);
        setComplemento(upper(data.complemento));
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const pesquisaEnderecoViacep = useCallback((endereco: IBuscaCep) => {
    setCep(endereco.cep);
    setLogradouro(endereco.logradouro);
    setComplemento(endereco.complemento);
    setBairro(endereco.bairro);
    setCidade(endereco.cidade);
    setEstado(endereco.estado);
    setNumero('');
  }, []);

  const handleChangeCliente = useCallback(async (e): Promise<void> => {
    const { data } = await api.get<ICliente>(`cliente/${e.value}`);
    const response = await api.get<ICobranca[]>(
      `cobranca?cliente_id=${e.value}&data_recebimento=`,
    );

    setCobrancas(response.data);
    setClienteId(data.id);
    if (data.forma_pagamento_id) {
      setFormaPagamentoId(data.forma_pagamento_id);
    }
    if (data.prazo_id) {
      setPrazoId(data.prazo_id);
    }
    setNome(data.pessoa.nome);
    setApelido(data.pessoa.apelido);
    setCpfCnpj(data.pessoa.cpf_cnpj);
    setEstado(data.pessoa.endereco?.estado);
    setCidade(data.pessoa.endereco?.cidade);
    setBairro(data.pessoa.endereco?.bairro);
    setLogradouro(data.pessoa.endereco?.logradouro);
    setComplemento(data.pessoa.endereco?.complemento);
    setNumero(data.pessoa.endereco?.numero);
    setCep(data.pessoa.endereco?.cep);
    setSaldoCredito(
      Number(data?.limite_credito) > 0 ? data?.saldo_credito : null,
    );
  }, []);

  useEffect(() => {
    async function getDados(): Promise<void> {
      const { data } = await api.get<IPedido>(`pedido/${id}`);

      setFormaPagamentoId(data.forma_pagamento_id);
      setPrazoId(data.prazo_id);
      setOperacaoId(data.operacao_id);
      setEntregar(data.entregar);
      setBuscarEquipamento(data.buscar_equipamento);
      setClienteId(data.cliente_id);
      setNome(data.nome);
      setApelido(data.apelido);
      setBeersalesId(data.beersales_id);
      setCpfCnpj(data.cpf_cnpj);
      setDataGeracao(data.data_geracao);
      setDataFaturado(data.data_faturado);
      setDataEntregue(data.data_entregue);
      setDataConcluido(data.data_concluido);
      setDataCancelado(data.data_cancelado);
      setDataBloqueado(data.data_bloqueado);
      setStatusId(data.status_id);
      setValorLiquido(data.valor_liquido);
      setMotivoCancelamento(data.motivo_cancelamento);
      setMotivoPedido(data.motivo_pedido);
      setDataPrevistaEntrega(data.data_prevista_entrega);
      setDataPrevistaRetorno(data.data_prevista_retorno);
      setSaldoCredito(
        Number(data.limite_credito) > 0 ? data.saldo_credito : null,
      );
      setCodRetornoIntegracao(data.cod_retorno_integracao);
      setMotivoIntegracao(data.motivo_integracao);
      setObservacao(data.observacao);
      setEnderecoId(data.endereco?.id);
      setEstado(data.endereco?.nome_estado);
      setCidade(data.endereco?.nome_cidade);
      setBairro(data.endereco?.bairro);
      setLogradouro(data.endereco?.logradouro);
      setComplemento(data.endereco?.complemento);
      setNumero(data.endereco?.numero);
      setCep(data.endereco?.cep);
      setPedidoItem(data?.pedido_item);
      setPedidoTipoEquipamento(data?.pedido_tipo_equipamento);
      setNumerosNfe(data.nfe);

      if (data.status_id > 2) {
        setActiveStep(4);
        setIsLoading(false);
        return;
      }
      if (!data.cliente_id) {
        setActiveStep(0);
        setIsLoading(false);
        return;
      }
      if (!data.endereco?.cep) {
        setActiveStep(1);
        setIsLoading(false);
        return;
      }
      if (
        !data.operacao_id ||
        !data.prazo_id ||
        !data.forma_pagamento_id ||
        data.entregar === null
      ) {
        setActiveStep(2);
        setIsLoading(false);
        return;
      }

      setActiveStep(3);
      setIsLoading(false);
    }

    axios
      .all([
        api.get(`configuracao-vendedor`),
        api.get(`forma-pagamento`),
        api.get(`prazo`),
        api.get(`operacao`),
      ])
      .then(
        axios.spread(
          (
            retorno_config_vendedor,
            retorno_formas_pagamento,
            retorno_prazos,
            retorno_operacoes,
          ) => {
            setIsLoading(true);
            setConfigVendedor(retorno_config_vendedor.data[0]);
            setFormasPagamento(retorno_formas_pagamento.data);
            setPrazos(retorno_prazos.data);
            setOperacoes(retorno_operacoes.data);
            getDados();
            setIsLoading(false);
          },
        ),
      )
      .catch(error => console.log(error));
  }, [id]);

  const pode_gerar_pedido = useMemo(() => {
    if (
      status_id === 1 &&
      cod_retorno_integracao !== 202 &&
      cliente_id &&
      cep &&
      operacao_id &&
      prazo_id &&
      forma_pagamento_id &&
      entregar !== null &&
      pedido_item &&
      pedido_item?.length > 0 &&
      pedido_tipo_equipamento
    ) {
      return false;
    }
    return true;
  }, [
    cliente_id,
    pedido_item,
    status_id,
    cod_retorno_integracao,
    operacao_id,
    prazo_id,
    forma_pagamento_id,
    entregar,
    cep,
    pedido_tipo_equipamento,
  ]);

  const gerarPedido = useCallback(async () => {
    try {
      setIsLoading(true);
      setLoadingMessage('Salvando...');
      const agora = new Date();

      await api.put(`pedido/${id}`, {
        sincronizar: true,
      });

      setDataGeracao(agora);
      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Pedido gerado com sucesso.',
      });
      history.push('/pedidos/lista');
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Atenção',
        description: 'Ocorreu um erro ao salvar. Por favor, tente novamente.',
      });
    } finally {
      setLoadingMessage('');
      setIsLoading(false);
    }
  }, [addToast, history, id]);

  const salvarOrcamento = useCallback(async () => {
    history.push('/pedidos/lista');
    setIsLoading(false);
  }, [history]);

  const bloqueia_cliente = useMemo(() => {
    return (
      (pedido_item && pedido_item.length !== 0) ||
      (status_id && status_id > 2) ||
      (cod_retorno_integracao === 202 && status_id === 1) ||
      activeStep !== 0 ||
      motivo_pedido === 'BLOQUEADO' ||
      motivo_pedido === 'CANCELADO'
    );
  }, [
    activeStep,
    pedido_item,
    motivo_pedido,
    cod_retorno_integracao,
    status_id,
  ]);

  const bloqueia_endereco = useMemo(() => {
    return (
      (status_id && status_id > 2) ||
      activeStep !== 1 ||
      (cod_retorno_integracao === 202 && status_id === 1) ||
      motivo_pedido === 'BLOQUEADO' ||
      motivo_pedido === 'CANCELADO'
    );
  }, [status_id, activeStep, motivo_pedido, cod_retorno_integracao]);

  const bloqueia_informacoes = useMemo(() => {
    return (
      (status_id && status_id > 2) ||
      (cod_retorno_integracao === 202 && status_id === 1) ||
      activeStep !== 2 ||
      motivo_pedido === 'BLOQUEADO' ||
      motivo_pedido === 'CANCELADO'
    );
  }, [status_id, activeStep, motivo_pedido, cod_retorno_integracao]);

  const bloqueia_produtos = useMemo(() => {
    return (
      !config_vendedor ||
      (status_id && status_id > 2) ||
      activeStep !== 3 ||
      motivo_pedido === 'BLOQUEADO' ||
      motivo_pedido === 'CANCELADO'
    );
  }, [status_id, activeStep, motivo_pedido, config_vendedor]);

  const bloqueia_tipos_equipamentos = useMemo(() => {
    return (
      !config_vendedor ||
      (status_id && status_id > 2) ||
      activeStep !== 4 ||
      motivo_pedido === 'BLOQUEADO' ||
      motivo_pedido === 'CANCELADO'
    );
  }, [status_id, activeStep, motivo_pedido, config_vendedor]);

  const titulo_pagina = useMemo(() => {
    return status_id < 2 ? `ORÇAMENTO ${id}` : `PEDIDO ${beersales_id || ''}`;
  }, [status_id, beersales_id, id]);

  const dados_cliente = useMemo(() => {
    return (
      <>
        <h2>
          {cpf_cnpj?.length > 11 ? `Razão social:  ${nome}` : `Nome: ${nome}`}
        </h2>
        <p>
          {cpf_cnpj?.length > 11
            ? `Nome fantasia:  ${apelido}`
            : `Apelido: ${apelido}`}
        </p>
        <p>
          {cpf_cnpj?.length > 11
            ? `Cnpj:  ${mask({ input: cpf_cnpj, tipo: 'cnpj' })}`
            : `Cpf: ${mask({ input: cpf_cnpj, tipo: 'cpf' })}`}
        </p>
      </>
    );
  }, [apelido, cpf_cnpj, nome]);

  const dados_endereco = useMemo(() => {
    return (
      <>
        <p>{`Cep: ${mask({ input: cep, tipo: 'cep' })}`}</p>
        <p>{`Logradouro: ${limpaNull(logradouro)}`}</p>
        <p>{`Número: ${limpaNull(numero)}`}</p>
        <p>{`Complemento: ${limpaNull(complemento)}`}</p>
        <p>{`Bairro: ${limpaNull(bairro)}`}</p>
        <p>{`Cidade: ${limpaNull(cidade)}`}</p>
        <p>{`UF: ${limpaNull(estado)}`}</p>
        <p>{`Observação: ${limpaNull(observacao)}`}</p>
      </>
    );
  }, [
    cep,
    logradouro,
    numero,
    complemento,
    bairro,
    cidade,
    estado,
    observacao,
  ]);

  const dados_informacoes_complementares = useMemo(() => {
    return (
      <>
        <p>{`Operação: ${retornaLabel(operacoes, operacao_id)}`}</p>
        <p>{`Prazo: ${retornaLabel(prazos, prazo_id)}`}</p>
        <p>
          {`Forma de pagamento: ${retornaLabel(
            formas_pagamento,
            forma_pagamento_id,
          )}`}
        </p>
        <p>
          {`Previsão de entrega: ${retornaLabel(
            previsoesEntrega,
            entregar,
          )} ${formataDataHora(data_prevista_entrega)}`}
        </p>
        <p>
          {`Previsão de retorno: ${retornaLabel(
            previsoesDevolucao,
            buscar_equipamento,
          )} ${formataDataHora(data_prevista_retorno)}`}
        </p>
        <p>
          {numerosNfe.length > 0 ? (
            `NF: ${numerosNfe.map(numeroNfe => numeroNfe.numero)}`
          ) : (
            <> </>
          )}
        </p>
      </>
    );
  }, [
    operacao_id,
    operacoes,
    formas_pagamento,
    forma_pagamento_id,
    entregar,
    data_prevista_entrega,
    data_prevista_retorno,
    buscar_equipamento,
    prazo_id,
    prazos,
    numerosNfe,
  ]);

  const gerarPdf = useCallback(async () => {
    try {
      setIsLoading(true);
      setLoadingMessage('Gerando Pdf do pedido...');

      const response = await api.get(`pedido/pdf/${id}`, {
        responseType: 'blob',
      });

      const file = new Blob([response.data], { type: 'application/pdf' });

      const fileURL = URL.createObjectURL(file); // Open the URL on new Window

      window.open(fileURL);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Atenção',
        description: 'Ocorreu um erro ao salvar. Por favor, tente novamente.',
      });
    } finally {
      setLoadingMessage('');
      setIsLoading(false);
    }
  }, [addToast, id]);

  return (
    <Container>
      <Loading isLoading={isLoading} />
      <Voltar>
        <Link to="/pedidos/lista" title="Voltar para a listagem de pedidos">
          <UndoIcon fontSize="large" />
        </Link>
        <div>
          {status_id === 3 &&
          motivo_pedido === 'A ENTREGAR' &&
          cod_retorno_integracao === 201 ? (
            <ModalEntregaPedido
              onSuccess={() => history.push('/pedidos/lista')}
              pedido_id={Number(id)}
            >
              <FaTruckLoading size={25} />
            </ModalEntregaPedido>
          ) : null}
          <BotaoDefault title="Gerar Pdf do pedido" onClick={gerarPdf}>
            <FaRegFilePdf size={25} />
          </BotaoDefault>
        </div>
      </Voltar>
      <Form ref={formRef} onSubmit={() => console.log('')}>
        <HeaderCadastro>
          <div>
            <h1>{titulo_pagina}</h1>
          </div>
          <div>
            <span>Os campos com * são obrigatórios</span>
          </div>
        </HeaderCadastro>
        <BodyCadastro>
          <div className={classes.root}>
            <Stepper
              activeStep={activeStep}
              nonLinear={status_id > 1}
              orientation="vertical"
            >
              <Step expanded key="CLIENTE">
                <StepButton
                  onClick={
                    !bloqueia_cliente ? handleStep(0) : handleStep(activeStep)
                  }
                  completed={!!cliente_id}
                  color="red"
                >
                  <h1>CLIENTE</h1>
                </StepButton>
                <StepContent>
                  {bloqueia_cliente ? (
                    <Typography>{dados_cliente}</Typography>
                  ) : (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Async
                        label="Cliente *"
                        name="cliente_id"
                        placeholder="Pesquise por Nome, Cpf/Cnpj ou Cidade"
                        cacheOptions
                        loadOptions={loadClientesOptions}
                        defaultValue={
                          cliente_id && {
                            value: cliente_id,
                            label: GetNomeClientesConfig(
                              { nome, apelido, cpf_cnpj },
                              config_vendedor?.modo_exibicao_cliente,
                            ),
                          }
                        }
                        defaultOptions
                        onChange={handleChangeCliente}
                      />
                      {cobrancas && cobrancas.length > 0 && (
                        <ModalCobrancas cobrancas={cobrancas} />
                      )}
                      <div className={classes.actionsContainer}>
                        <DivBotoes status_id={status_id}>
                          <BotaoNovo
                            loading={loading_message}
                            onClick={salvaCliente}
                          >
                            Próximo
                          </BotaoNovo>
                        </DivBotoes>
                      </div>
                    </Grid>
                  )}
                </StepContent>
              </Step>

              <Step expanded key="ENDERECO">
                <StepButton onClick={handleStep(1)} completed={!!cep}>
                  <h1>ENDEREÇO</h1>
                </StepButton>
                <StepContent>
                  {bloqueia_endereco ? (
                    <Typography>{dados_endereco}</Typography>
                  ) : (
                    <div>
                      <Input name="endereco.id" style={{ display: 'none' }} />
                      <Inline>
                        <Grid item xs={6} sm={6} md={4} lg={2}>
                          <InputMasked
                            mask="99.999-999"
                            label="Cep *"
                            name="endereco.cep"
                            placeholder="Cep"
                            value={cep}
                            onChange={e => setCep(e.target.value)}
                            onBlur={e => buscaCepViacep(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={4} lg={2}>
                          <ModalPesquisaEndereco
                            pesquisaCep={pesquisaEnderecoViacep}
                          />
                        </Grid>
                      </Inline>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Input
                          label="Logradouro"
                          name="endereco.logradouro"
                          maxLength={80}
                          value={logradouro}
                          onChange={e => setLogradouro(e.target.value)}
                          placeholder="Informe o logradouro"
                        />
                      </Grid>
                      <Grid item xs={6} sm={6} md={4} lg={2}>
                        <Input
                          label="Número"
                          name="endereco.numero"
                          maxLength={15}
                          value={numero}
                          onChange={e => setNumero(e.target.value)}
                          placeholder="Informe o número"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Input
                          label="Complemento"
                          name="endereco.complemento"
                          maxLength={50}
                          defaultValue={complemento}
                          onChange={e => setComplemento(e.target.value)}
                          placeholder="Informe o complemento"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Input
                          label="Bairro"
                          name="endereco.bairro"
                          maxLength={50}
                          value={bairro}
                          onChange={e => setBairro(e.target.value)}
                          placeholder="Informe o bairro"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Input
                          disabled
                          label="Cidade *"
                          name="endereco.cidade"
                          value={cidade}
                          onChange={e => setCidade(e.target.value)}
                          placeholder="Informe o cidade"
                        />
                      </Grid>
                      <Grid item xs={6} sm={6} md={4} lg={2}>
                        <Input
                          disabled
                          label="UF *"
                          value={estado}
                          onChange={e => setEstado(e.target.value)}
                          name="endereco.estado"
                          placeholder="UF"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextArea
                          name="observacao"
                          label="Observação"
                          maxLength={500}
                          rows={1}
                          defaultValue={observacao}
                          onChange={e => setObservacao(e.target.value)}
                        />
                      </Grid>
                      <div className={classes.actionsContainer}>
                        <DivBotoes status_id={status_id}>
                          <BotaoDefault onClick={handleBack}>
                            Voltar
                          </BotaoDefault>
                          <BotaoNovo
                            loading={loading_message}
                            onClick={salvaEndereco}
                          >
                            Próximo
                          </BotaoNovo>
                        </DivBotoes>
                      </div>
                    </div>
                  )}
                </StepContent>
              </Step>

              <Step expanded key="INFORMACOES">
                <StepButton
                  onClick={handleStep(2)}
                  completed={
                    !!operacao_id &&
                    !!prazo_id &&
                    !!forma_pagamento_id &&
                    entregar !== null
                  }
                >
                  <h1>INFORMAÇÕES COMPLEMENTARES</h1>
                </StepButton>

                <StepContent>
                  {bloqueia_informacoes ? (
                    <Typography>{dados_informacoes_complementares}</Typography>
                  ) : (
                    <div>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Select
                          label="Operação*"
                          options={operacoes}
                          value={retornaObjeto(operacoes, operacao_id)}
                          onChange={e => setOperacaoId(e.value)}
                          isSearchable={false}
                          name="operacao_id"
                          placeholder="Selecione uma operação"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Select
                          label="Prazo*"
                          options={prazos}
                          isSearchable={false}
                          isDisabled={
                            !!(
                              (pedido_item && pedido_item.length) ||
                              (bloquear_alteracao_prazo &&
                                role === 'customer-user' &&
                                prazo_id)
                            )
                          }
                          value={retornaObjeto(prazos, prazo_id)}
                          onChange={e => setPrazoId(e.value)}
                          name="prazo_id"
                          placeholder="Selecione um prazo"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Select
                          label="Forma de pagamento*"
                          options={formas_pagamento}
                          isSearchable={false}
                          value={retornaObjeto(
                            formas_pagamento,
                            forma_pagamento_id,
                          )}
                          onChange={e => setFormaPagamentoId(e.value)}
                          name="forma_pagamento_id"
                          placeholder="Selecione uma forma de pagamento"
                          isDisabled={
                            !!(
                              id &&
                              bloquear_alteracao_forma_pagamento &&
                              role === 'customer-user' &&
                              forma_pagamento_id
                            )
                          }
                        />
                      </Grid>
                      <Grid
                        container
                        spacing={1}
                        style={{ padding: 0, margin: '0' }}
                      >
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                          <Select
                            label="Previsão de entrega*"
                            options={[
                              {
                                value: null,
                                label: 'Nenhuma opção selecionada',
                              },
                              ...previsoesEntrega,
                            ]}
                            isSearchable={false}
                            value={retornaObjeto(previsoesEntrega, entregar)}
                            onChange={e => setEntregar(e.value)}
                            name="entregar"
                            placeholder="Selecione uma previsão de entrega"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          lg={3}
                          style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                          }}
                        >
                          <DateTime
                            value={data_prevista_entrega}
                            error={!!erroDataPrevistaEntrega}
                            disablePast
                            format="dd/MM/yyyy hh:mm"
                            helperText={erroDataPrevistaEntrega}
                            setValue={setDataPrevistaEntrega}
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        spacing={1}
                        style={{ padding: 0, margin: '0' }}
                      >
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                          <Select
                            label="Previsão de devolução"
                            options={[
                              {
                                value: null,
                                label: 'Nenhuma opção selecionada',
                              },
                              ...previsoesDevolucao,
                            ]}
                            isSearchable={false}
                            value={retornaObjeto(
                              previsoesDevolucao,
                              buscar_equipamento,
                            )}
                            onChange={e => setBuscarEquipamento(e.value)}
                            name="buscar_equipamento"
                            placeholder="Selecione uma previsão de devolução"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          lg={3}
                          style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                          }}
                        >
                          <DateTime
                            value={data_prevista_retorno}
                            error={!!erroDataPrevistaRetorno}
                            disablePast
                            minDate={data_prevista_entrega || new Date()}
                            format="dd/MM/yyyy hh:mm"
                            // disable
                            helperText={erroDataPrevistaRetorno}
                            setValue={setDataPrevistaRetorno}
                          />
                        </Grid>
                      </Grid>

                      <div className={classes.actionsContainer}>
                        <DivBotoes status_id={status_id}>
                          <BotaoDefault onClick={handleBack}>
                            Voltar
                          </BotaoDefault>
                          <BotaoNovo
                            loading={loading_message}
                            onClick={salvaInformacoesComplementares}
                          >
                            Próximo
                          </BotaoNovo>
                        </DivBotoes>
                      </div>
                    </div>
                  )}
                </StepContent>
              </Step>

              <Step expanded key="PRODUTOS">
                <StepButton
                  onClick={handleStep(3)}
                  completed={pedido_item && pedido_item?.length > 0}
                >
                  <h1>PRODUTOS</h1>
                </StepButton>
                <StepContent>
                  <div>
                    <div
                      style={{
                        display: bloqueia_produtos ? 'none' : 'block',
                      }}
                    >
                      {pedido_item?.length !== 0 ? (
                        <ModalPesquisaProduto
                          cliente_id={cliente_id}
                          prazo_id={prazo_id}
                          pedido_id={Number(id)}
                          setPedidoItem={setPedidoItem}
                          status_id={status_id}
                          pedido_item={pedido_item}
                          saldo_credito={saldo_credito}
                          grupos_exibidos={config_vendedor?.grupos_exibidos}
                          setCodRetornoIntegracao={setCodRetornoIntegracao}
                          texto_botao={
                            <Catalogo>
                              <FaBook /> &nbsp;CATÁLOGO DE PRODUTOS
                            </Catalogo>
                          }
                        />
                      ) : (
                        <ModalPesquisaProduto
                          cliente_id={cliente_id}
                          prazo_id={prazo_id}
                          pedido_id={Number(id)}
                          status_id={status_id}
                          setPedidoItem={setPedidoItem}
                          pedido_item={pedido_item}
                          saldo_credito={saldo_credito}
                          grupos_exibidos={config_vendedor?.grupos_exibidos}
                          setCodRetornoIntegracao={setCodRetornoIntegracao}
                          texto_botao={
                            <NenhumItemCadastrado>
                              Clique aqui para adicionar um novo produto.
                            </NenhumItemCadastrado>
                          }
                        />
                      )}
                    </div>
                    {pedido_item?.length !== 0 && (
                      <ListaProdutosPedido
                        cliente_id={cliente_id}
                        pedido_id={Number(id)}
                        prazo_id={prazo_id}
                        saldo_credito={saldo_credito}
                        status_id={status_id}
                        pedido_item={pedido_item}
                        setPedidoItem={setPedidoItem}
                        bloqueia_clique={bloqueia_produtos}
                      />
                    )}
                    <div
                      className={classes.actionsContainer}
                      style={{
                        display: bloqueia_produtos ? 'none' : 'block',
                      }}
                    >
                      <DivBotoes status_id={status_id}>
                        <BotaoDefault onClick={handleBack}>Voltar</BotaoDefault>
                        <BotaoNovo
                          loading={loading_message}
                          onClick={handleNext}
                        >
                          Próximo
                        </BotaoNovo>
                      </DivBotoes>
                    </div>
                  </div>
                </StepContent>
              </Step>

              <Step expanded key="TIPOS DE EQUIPAMENTOS">
                <StepButton
                  onClick={handleStep(4)}
                  completed={
                    pedido_tipo_equipamento &&
                    pedido_tipo_equipamento?.length > 0
                  }
                >
                  <h1>TIPOS DE EQUIPAMENTOS</h1>
                </StepButton>
                <StepContent>
                  <div>
                    <div
                      style={{
                        display: bloqueia_tipos_equipamentos ? 'none' : 'block',
                      }}
                    >
                      {pedido_tipo_equipamento?.length !== 0 ? (
                        <ModalPesquisaTipoEquipamento
                          pedido_id={Number(id)}
                          status_id={status_id}
                          setCodRetornoIntegracao={setCodRetornoIntegracao}
                          setPedidoTipoEquipamento={setPedidoTipoEquipamento}
                          pedido_tipo_equipamento={pedido_tipo_equipamento}
                          texto_botao={
                            <Catalogo>
                              <FaBook /> &nbsp;LISTAGEM DE TIPOS DE EQUIPAMENTOS
                            </Catalogo>
                          }
                        />
                      ) : (
                        <ModalPesquisaTipoEquipamento
                          pedido_id={Number(id)}
                          status_id={status_id}
                          setCodRetornoIntegracao={setCodRetornoIntegracao}
                          setPedidoTipoEquipamento={setPedidoTipoEquipamento}
                          pedido_tipo_equipamento={pedido_tipo_equipamento}
                          texto_botao={
                            <NenhumItemCadastrado>
                              Clique aqui para adicionar um novo tipo de
                              equipamento.
                            </NenhumItemCadastrado>
                          }
                        />
                      )}
                    </div>
                    {pedido_tipo_equipamento?.length !== 0 && (
                      <ListaTiposEquipamentoPedido
                        status_id={status_id}
                        pedido_id={Number(id)}
                        pedido_tipo_equipamento={pedido_tipo_equipamento}
                        setPedidoTipoEquipamento={setPedidoTipoEquipamento}
                        bloqueia_clique={bloqueia_tipos_equipamentos}
                      />
                    )}
                    <div
                      className={classes.actionsContainer}
                      style={{
                        display: bloqueia_tipos_equipamentos ? 'none' : 'block',
                      }}
                    >
                      <DivBotoes status_id={status_id}>
                        <BotaoDefault onClick={handleBack}>Voltar</BotaoDefault>
                        <BotaoNovo
                          loading={loading_message}
                          onClick={salvaTiposEquipamentos}
                        >
                          Próximo
                        </BotaoNovo>
                      </DivBotoes>
                    </div>
                  </div>
                </StepContent>
              </Step>

              <Step expanded key="STATUS">
                <StepButton onClick={handleStep(5)} completed={status_id > 4}>
                  <h1>{status_id && status_id < 2 ? 'FINALIZAR' : 'STATUS'}</h1>
                </StepButton>
                <StepContent>
                  <div
                    className={classes.actionsContainer}
                    style={{
                      display:
                        status_id && status_id < 2 && activeStep === 5
                          ? 'block'
                          : 'none',
                    }}
                  >
                    <DivBotoes status_id={status_id}>
                      <BotaoDefault onClick={handleBack}>Voltar</BotaoDefault>
                      <BotaoSalvar
                        disabled={pode_gerar_pedido}
                        loading={loading_message}
                        onClick={gerarPedido}
                      >
                        Gerar pedido
                      </BotaoSalvar>

                      <BotaoDefault
                        disabled={pode_gerar_pedido}
                        onClick={salvarOrcamento}
                      >
                        Salvar Orçamento
                      </BotaoDefault>
                    </DivBotoes>
                  </div>

                  <Typography
                    style={{
                      display: activeStep > 1 ? 'block' : 'none',
                    }}
                  >
                    {data_geracao && (
                      <p>Data geração: {formataDataHora(data_geracao)}</p>
                    )}
                    {data_faturado && (
                      <p>Data faturado: {formataDataHora(data_faturado)}</p>
                    )}
                    {data_entregue && (
                      <p>Data entregue: {formataDataHora(data_entregue)}</p>
                    )}
                    {data_concluido && (
                      <p>Data concluído: {formataDataHora(data_concluido)}</p>
                    )}
                    {data_cancelado && (
                      <p>
                        Data cancelamento: {formataDataHora(data_cancelado)}
                      </p>
                    )}
                    {data_bloqueado && (
                      <p>Data bloqueado: {formataDataHora(data_bloqueado)}</p>
                    )}
                    {data_cancelado && (
                      <p>
                        Data cancelamento: {formataDataHora(data_cancelado)}
                      </p>
                    )}
                  </Typography>

                  <ContainerSincronizacao
                    status_id={status_id}
                    cod_integracao={!!cod_retorno_integracao}
                  >
                    <StatusSincronizacao
                      tipo="pedido"
                      id={Number(id)}
                      cod_retorno_integracao={cod_retorno_integracao}
                      motivo_integracao={motivo_integracao}
                    />
                  </ContainerSincronizacao>
                </StepContent>
              </Step>
            </Stepper>
          </div>
        </BodyCadastro>
      </Form>
    </Container>
  );
};

export default CadastroPedidos;
