import React, { Fragment } from 'react';
import Ballot from '@material-ui/icons/Ballot';
import PermContactCalendarIcon from '@material-ui/icons/IndeterminateCheckBox';
import PersonIcon from '@material-ui/icons/Person';
import { Separador } from '../../../../styles/GlobalStyles';
import IProduto from '../../../../interfaces/produto';
import IEquipamento from '../../../../interfaces/equipamento';
import formataValor from '../../../../utils/formataQuantidade';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Container } from './styles';

interface IParam {
  cadastro?: IProduto;
  estoque_produto_equip?: IEquipamento[];
}

const Cadastro: React.FC<IParam> = ({ cadastro, estoque_produto_equip }) => {
  return (
    <Container>
      <Separador>
        <h2><PersonIcon style={{marginTop:3}}/>&nbsp;INFORMAÇÕES BÁSICAS</h2>
      </Separador>
      <span>{`CÓDIGO: ${cadastro?.codigo || ''}`}</span>
      <span>{`DESCRIÇÃO: ${cadastro?.nome || ''}`}</span>
      <span>{`SITUAÇÃO: ${cadastro?.inativo ? 'INATIVO' : 'ATIVO'}`}</span>
      <span>{`OBSERVAÇÃO: ${cadastro?.observacao || 'VAZIA'}`}</span>
      <Separador>
        <h2><Ballot style={{marginTop:3}}/>&nbsp;CLASSIFICAÇÃO</h2>
      </Separador>
      <span>{`GRUPO PRODUTO: ${cadastro?.grupo_produto_nome || 'INDEFINIDO(A)'}`}</span>
      <span>{`MARCA: ${cadastro?.marca || 'INDEFINIDO(A)'}`}</span>
      <span>{`ESTILO: ${cadastro?.estilo || 'INDEFINIDO(A)'}`}</span>
      <Separador>
        <h2><PermContactCalendarIcon style={{marginTop:3}} />&nbsp;ESTOQUE</h2>
      </Separador>
      {estoque_produto_equip?.map(({ id, nome, quantidade, unidade, patrimonio }) => (
        <Fragment key={id}>
          <div key={id}>
            <span>{`${patrimonio} - ${nome}: ${formataValor(quantidade)} ${unidade}`}</span>
          </div>
        </Fragment>
      ))}
    </Container>
  );
};

export default Cadastro;
