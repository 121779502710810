import React from 'react';
import FullCalendar, {
  DateSelectArg,
  EventClickArg,
  EventContentArg,
  EventInput,
} from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import './calendar.css';

interface IEvent {
  eventClick: (clickInfo: EventClickArg) => void;
  select: (selectInfo: DateSelectArg) => void;
  eventContent: (eventContent: EventContentArg) => void;
  events: EventInput[];
  datesSet: (payload) => void;
}

const Calendario: React.FC<IEvent> = ({
  eventClick,
  select,
  eventContent,
  events,
  datesSet,
}) => {
  const ptLocale = {
    code: 'pt-br',
    allDayText: 'O dia todo',
    buttonText: {
      month: 'Mês',
      week: 'Semana',
      day: 'Dia',
      agendaDay: 'Dia',
      agendaWeek: 'Semana',
      today: 'Hoje',
      list: 'Agenda',
    },
    moreLinkContent: '+ Ver mais',
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
    },
    noEventsText: 'Não há eventos para mostrar',
  };

  const calendarOptions = {
    initialView: 'dayGridMonth',
    editable: true,
    select,
    selectable: true,
    selectMirror: true,
    locale: 'pt-br',
    datesSet,
    dayMaxEvents: 0,
    eventClick,
    events,
    droppable: true,
    eventStartEditable: false,
    eventContent,
    views: {
      month: {
        dayMaxEvents: 1,
        dayMaxEventRows: 1,
      },
      timeGrid: {
        eventLimit: 6,
      },
    },
  };


  return (
    <FullCalendar
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
      locales={[ptLocale]}
      {...calendarOptions}
    />
  );
};

export default Calendario;
