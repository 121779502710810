/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import api from '../../../../services/api';
import {
  Container,
  ContainerItems,
  ContainerItemsDescricao,
  ContainerItemsValor,
} from './styles';
import { Separador } from '../../../../styles/GlobalStyles';
import { useToast } from '../../../../hooks/toast';
import Loading from '../../../../components/Loading';
import IProduto from '../../../../interfaces/produto';
import formataNumero from '../../../../utils/formataNumero';

interface IParam {
  id?: number;
}

interface IPrecos {
  titulo: string;
  itens: IProduto[];
}

const Preco: React.FC<IParam> = ({ id }) => {
  const [is_loading, setIsLoading] = useState(false);
  const [precos, setPrecos] = useState<IPrecos[]>();

  const { addToast } = useToast();

  useEffect(() => {
    async function getProduto(): Promise<void> {
      try {
        if (!id) return;
        setIsLoading(true);

        const response = await api.get(`preco/categoria-preco/${id}`);

        setPrecos([
          {
            titulo: 'Preços por cliente',
            itens: response.data
              .filter((produto: IProduto) => produto.cliente_id)
              .map((produto: IProduto) => {
                return {
                  id: produto.cliente_id,
                  nome: produto.nome_cliente,
                  valor: produto.valor,
                  prazo: produto.nome_prazo ?? null,
                };
              }),
          },
          {
            titulo: 'Preços por tabela de preços',
            itens: response.data
              .filter((produto: IProduto) => produto.tabela_preco_id)
              .map((produto: IProduto) => {
                return {
                  id: produto.tabela_preco_id,
                  nome: produto.nome_tabela_preco,
                  valor: produto.valor,
                  prazo: produto.nome_prazo ?? null,
                };
              }),
          },
          {
            titulo: 'Preços por grupo de clientes',
            itens: response.data
              .filter((produto: IProduto) => produto.grupo_cliente_id)
              .map((produto: IProduto) => {
                return {
                  id: produto.grupo_cliente_id,
                  nome: produto.nome_grupo_cliente,
                  valor: produto.valor,
                  prazo: produto.nome_prazo ?? null,
                };
              }),
          },
          {
            titulo: 'Preços somente por prazo',
            itens: response.data
              .filter(
                (produto: IProduto) =>
                  !produto.cliente_id &&
                  !produto.grupo_cliente_id &&
                  !produto.grupo_cliente_id &&
                  !produto.tabela_preco_id &&
                  produto.prazo_id,
              )
              .map((produto: IProduto) => {
                return {
                  id: produto.prazo_id,
                  nome: null,
                  valor: produto.valor,
                  prazo: produto.nome_prazo ?? null,
                };
              }),
          },
          {
            titulo: 'Preço padrão',
            itens: response.data
              .filter(
                (produto: IProduto) =>
                  !produto.cliente_id &&
                  !produto.grupo_cliente_id &&
                  !produto.tabela_preco_id &&
                  !produto.prazo_id,
              )
              .map((produto: IProduto) => {
                return {
                  id: produto.id,
                  nome: null,
                  valor: produto.valor,
                  prazo: null,
                };
              }),
          },
        ]);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
    getProduto();
  }, [id, addToast]);

  return (
    <Container>
      <Loading isLoading={is_loading} />

      {precos?.map((preco: IPrecos) => (
        <>
          <Separador>
            <h2>
              <MonetizationOn style={{ marginTop: 3 }} />
              {preco.titulo}
            </h2>
          </Separador>
          {preco.itens.map((item: IProduto) => (
            <ContainerItems key={item?.id}>
              <ContainerItemsDescricao>{`${
                item?.nome || ''
              }`}</ContainerItemsDescricao>
              <ContainerItemsDescricao>
                {` ${item?.prazo || ''}`}
              </ContainerItemsDescricao>
              {item?.valor ? (
                <ContainerItemsValor>{`R$ ${
                  formataNumero(item?.valor, 4, true) || ''
                }`}</ContainerItemsValor>
              ) : null}
            </ContainerItems>
          ))}
        </>
      ))}
    </Container>
  );
};

export default Preco;
