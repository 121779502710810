import React, { useState, useEffect } from 'react';
import { Container } from '../../../styles/GlobalStyles';
import Loading from '../../../components/Loading';
import { BodyCadastro, HeaderCadastro } from './styles';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Usuarios from '../../Admin/Accounts/Cadastro/components/usuarios';
import api from '../../../services/api';

interface IUsuario {
  id: number;
  nome: string;
  email: string;
  status_acesso: string;
  ativo: boolean;
  roles: {
    id?: number;
    nome: string;
    empresa_id: number;
  }[];
}

interface IEmpresa {
  id: number;
  nome: string;
}

const ListaUsuarios: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [usuarios, setUsuarios] = useState<IUsuario[]>([]);
  const [empresas, setEmpresas] = useState<IEmpresa[]>([]);
  const [total_usuarios_contratados, setTotalUsuariosContratados] = useState(0);

  useEffect(() => {
    let cancel = false;
    async function getData(): Promise<void> {
      if(cancel) return;
      setIsLoading(true);
      const response_usuarios = await api.get<IUsuario[]>(`/user`);
      setUsuarios(response_usuarios.data);

      const response_empresas = await api.get<IEmpresa[]>(`/empresas-from-account`);
      setEmpresas(response_empresas.data);

      const response_contratados = await api.get(`/user-total-contratados`);
      setTotalUsuariosContratados(response_contratados.data);
      setIsLoading(false);
    }

    getData();
    return () => {  
          cancel = true;
    };
  }, []);

  return (
    <Container>
      <Loading isLoading={isLoading} />
      <HeaderCadastro>
        <div>
          <h1>PERMISSÕES DE USUÁRIO POR EMPRESAS</h1>
        </div>
      </HeaderCadastro>
      <BodyCadastro>
        <Usuarios
          usuarios={usuarios}
          empresas={empresas}
          setUsuarios={setUsuarios}
          total_usuarios_contratados={total_usuarios_contratados}
        />
      </BodyCadastro>
    </Container>
  );
};

export default ListaUsuarios;
