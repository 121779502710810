import styled from 'styled-components';
import * as colors from '../../config/Colors/Ligth';

export const HeaderCadastro = styled.div`
  display: flex;
  flex-direction: column;
  top: 0;
  height: 60px;
  border-bottom: 1px solid #eee;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    h1 {
      font-size: 1.4rem;
      font-weight: bold;
      color: ${colors.titleFontColor};
    }
  }
  div + div {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-end;
    span {
      text-align: left;
      font-size: 10px;
    }
  }
`;

export const BodyCadastro = styled.div`
  padding: 20px 0 30px 0;
  border-bottom: 1px solid #eee;
  background-color: 'orange';
  display: flex;
  flex-direction: column;
`;

export const FooterCadastro = styled.div`
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`;

export const Voltar = styled.div`
  margin-top: -10px;
  a {
    color: ${colors.ligthFontColor};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    p {
      text-transform: uppercase;
    }
    &:hover {
      color: ${colors.titleFontColor};
    }
  }
`;
