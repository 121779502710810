// src/pages/Configuracoes/Pedidos
import React, { useCallback, useRef, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import axios from 'axios';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Grid } from '@material-ui/core';
import { TabPanels }  from '../../../components/Abas';
import { exibirCliente, previsoesEntrega } from '../../../config/Basicos';
import getValidationErrors from '../../../utils/getValidationErrors';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import Input from '../../../components/Inputs/Input';
import Select from '../../../components/Inputs/Select';
import BotaoSalvar from '../../../components/Botoes/BotaoSalvar';
import { BodyCadastro, FooterCadastro } from './styles';


interface IConfiguracaoVendedor {
  config_vendedor_id?: number;
  operacao_id?: number;
  forma_pagamento_id?: number;
  prazo_id?: number;
  entregar?: boolean;
  buscar_equipamento?: boolean;
  modo_exibicao_cliente: number;
}



const Configuracoes: React.FC = () => {
  const [prazos, setPrazos] = useState([]);
  const [operacoes, setOperacoes] = useState([]);
  const [formas_pagamento, setFormasPagamento] = useState([]);
  const [loading_message, setLoadingMessage] = useState('');
  const [value, setValue] = React.useState(0);
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => { mounted.current = false; };
  }, []);


    useEffect(() => {
    axios
      .all([
        api.get(`forma-pagamento`),
        api.get(`prazo`),
        api.get(`operacao${value !== 0 ? '?filtrar=0' : ''}`),
        api.get<IConfiguracaoVendedor>(`busca-configuracao-vendedor`),
      ])
      .then(
        axios.spread(
          (
            retorno_formas_pagamento,
            retorno_prazos,
            retorno_operacoes,
            retorno_configuracao_vendedor,
          ) => {
            setFormasPagamento(retorno_formas_pagamento.data);
            setPrazos(retorno_prazos.data);
            setOperacoes(retorno_operacoes.data);
            const { data } = retorno_configuracao_vendedor;
            formRef.current?.setData({
              config_vendedor_id: data.id,
              operacao_id: data.operacao_id,
              forma_pagamento_id: data.forma_pagamento_id,
              prazo_id: data.prazo_id,
              entregar: data.entregar,
              buscar_equipamento: data.buscar_equipamento,
              modo_exibicao_cliente: data.modo_exibicao_cliente || 0,

            });
          },
        ),
      )
      .catch(error => console.log(error));
  }, []);

  const handleSubmit = useCallback(
    async (data: IConfiguracaoVendedor) => {
      try {
        setLoadingMessage('Salvando...');
        await api.put(`configuracao-vendedor/${data.config_vendedor_id}`, data);
        if(mounted.current){
          addToast({
            type: 'success',
            title: 'Sucesso!',
            description: 'Seu cadastro foi atualizado com sucesso.',
          });
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: err.response?.data?.error,
        });
      } finally {
        setLoadingMessage('');
      }
    },
    [addToast],
  );


  return (
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
        >
          <TabPanels value={0} >
            <div>
              <span>
                * Novos pedidos serão inicializados com os valores configurados
                </span>
            </div>

            <BodyCadastro>
              <Input style={{ display: 'none' }} name="config_vendedor_id" />
              <Grid item xs={12} sm={12} md={10} lg={10}>
                <Select
                  label="Operação"
                  isSearchable={false}
                  options={[
                    { value: null, label: 'Nenhuma opção selecionada' },
                    ...operacoes,
                  ]}
                  name="operacao_id"
                  placeholder="Selecione uma operação"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={10} lg={10}>
                <Select
                  label="Forma de pagamento"
                  isSearchable={false}
                  options={[
                    { value: null, label: 'Nenhuma opção selecionada' },
                    ...formas_pagamento,
                  ]}
                  name="forma_pagamento_id"
                  placeholder="Selecione uma forma de pagamento"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={10} lg={10}>
                <Select
                  label="Prazo"
                  isSearchable={false}
                  options={[
                    { value: null, label: 'Nenhuma opção selecionada' },
                    ...prazos,
                  ]}
                  name="prazo_id"
                  placeholder="Selecione um prazo"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={10} lg={10}>
                <Select
                  label="Modo de exibição do cliente na pesquisa"
                  isSearchable={false}
                  options={exibirCliente}
                  name="modo_exibicao_cliente"
                  placeholder="Selecione um modo de exibição"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={10} lg={10}>
                <Select
                  label="Previsão de entrega"
                  isSearchable={false}
                  options={[
                    { value: null, label: 'Nenhuma opção selecionada' },
                    ...previsoesEntrega,
                  ]}
                  name="entregar"
                  placeholder="Selecione uma previsão de entrega"
                />
              </Grid>
            </BodyCadastro>
            <FooterCadastro>
              <BotaoSalvar loading={loading_message} type="submit">
                Salvar
              </BotaoSalvar>
            </FooterCadastro>

          </TabPanels>
        </Form>
  );
};

export default Configuracoes;
