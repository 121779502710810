import styled from 'styled-components';
import { shade } from 'polished';
import {
  backgroundColorDarkButton,
  colorDarkButton,
} from '../../../config/Colors/Ligth';

export const Container = styled.button`
  background: ${backgroundColorDarkButton};
  color: ${colorDarkButton};
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  width: auto;
  padding: 10px 10px;
  border-radius: 4px;
  transition: all 200ms;

  &:hover {
    background: ${shade(-0.4, backgroundColorDarkButton)};
  }
`;
