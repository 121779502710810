import React, { Fragment, useMemo } from 'react';
import { TiCancel } from 'react-icons/ti';
import { etapasPedido } from '../../config/Basicos';
import formataDataHora from '../../utils/formataDataHora';
import { Container } from './styled';
import upperFirstLetter from '../../utils/UppercaseOnlyFirstLetter';
import ModalEntregaPedido from '../Modal/ModalEntregaPedido';

interface IPedidoItem {
  id: number;
  quantidade?: string;
  valor_bruto?: string;
  valor_liquido?: string;
  valor_desconto?: string;
  produto?: {
    id: number;
    nome_produto: string;
    imagem: string;
    nome_unidade: string;
  };
}

interface IPedido {
  id: number;
  nome: string;
  nome_vendedor?: string;
  valor_liquido?: string;
  prazo: string;
  forma_pagamento: string;
  motivo_cancelamento: string;
  motivo_pedido: string;
  status_id: number;
  data_geracao: Date;
  data_faturado: Date;
  data_entregue: Date;
  data_concluido: Date;
  data_bloqueado: Date;
  data_cancelado: Date;
  created_at: Date;
  cod_retorno_integracao?: number;
  pedido_item: IPedidoItem[];
  motivo_entrega: string;
}

interface IParam {
  getData: (show_loading: boolean) => void;
  pedido: IPedido;
}

const MostraEtapas: React.FC<IParam> = ({ pedido, getData }) => {
  const etapas = useMemo(() => {
    const novas_etapas = etapasPedido.map(etapa => {
      let data;
      let titulo = '';
      let classe = '';
      let classe_hr = '';
      let icone;

      if (pedido.data_bloqueado) {
        titulo = pedido.status_id === etapa.id ? 'Bloqueado' : etapa.titulo;
        classe = etapa.id <= pedido.status_id ? 'erro' : 'pendente';
        icone = pedido.status_id === etapa.id ? TiCancel : etapa.icone;
        data =
          pedido.data_bloqueado &&
          pedido.status_id === etapa.id &&
          formataDataHora(pedido.data_bloqueado);
        classe_hr = etapa.id < pedido.status_id ? classe : 'pendente';
      } else if (pedido.data_cancelado) {
        titulo = pedido.status_id === etapa.id ? 'Cancelado' : etapa.titulo;
        classe = etapa.id <= pedido.status_id ? 'erro' : 'pendente';
        icone = pedido.status_id === etapa.id ? TiCancel : etapa.icone;
        data =
          pedido.data_cancelado &&
          pedido.status_id === etapa.id &&
          formataDataHora(pedido.data_cancelado);
        classe_hr = etapa.id < pedido.status_id ? classe : 'pendente';
      } else {
        titulo = etapa.titulo;
        classe = etapa.id <= pedido.status_id ? 'concluido' : 'pendente';
        icone = etapa.icone;
        classe_hr = classe;
      }

      if (etapa.id === 1) {
        data = data || formataDataHora(pedido.created_at);
      }
      if (etapa.id === 2) {
        data = data || formataDataHora(pedido.data_geracao);
        titulo =
          pedido.motivo_pedido && pedido.status_id === 2
            ? upperFirstLetter(pedido.motivo_pedido)
            : titulo;
      }
      if (etapa.id === 3) {
        data = data || formataDataHora(pedido.data_faturado);
        titulo =
          pedido.motivo_pedido && pedido.status_id === 3
            ? upperFirstLetter(pedido.motivo_pedido)
            : titulo;
      }
      if (etapa.id === 4) {
        data = data || formataDataHora(pedido.data_entregue);
      }
      if (etapa.id === 5) {
        data = data || formataDataHora(pedido.data_concluido);
      }

      return {
        key: etapa.id,
        id: etapa.id,
        classe,
        classe_hr,
        titulo,
        icone,
        data,
      };
    });
    return novas_etapas.slice(0, 5);
  }, [pedido]);

  return (
    <Container>
      {etapas.map(({ id, classe, titulo, icone: Icone, data, classe_hr }) => (
        <Fragment key={id}>
          <div key={id}>
            <div className={classe}>
              {id === 4 &&
              pedido.status_id === 3 &&
              pedido.motivo_pedido === 'A ENTREGAR' &&
              pedido.cod_retorno_integracao === 201 ? (
                <ModalEntregaPedido onSuccess={getData} pedido_id={pedido.id}>
                  {' '}
                  <Icone size={22} />{' '}
                </ModalEntregaPedido>
              ) : (
                <Icone size={22} />
              )}
            </div>
            <span>{titulo}</span>
            <span>{data}</span>
          </div>
          {id < etapas.length && <hr className={classe_hr} />}
        </Fragment>
      ))}
    </Container>
  );
};

export default MostraEtapas;
