import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import * as colors from '../../../config/Colors/Ligth';

export const Container = styled(Dialog)`
  background-color: ${colors.formBackgroundColor};
  display: flex;
  flex-direction: column;
  color: ${colors.darkFontColor};
  height: 500px;
  max-height: 100%;
  width: 700px;
  max-width: 100%;
  margin: 0 auto;
  @media only screen and (min-width: 1200px) {
    top: 10% !important;
  }
  
  border-radius: 6px;
  .MuiDialog-paper {
    margin: 0;
  }
  .MuiDialog-paperScrollPaper {
    max-height: 100%;
  }
`;

export const Header = styled.div`
  background-color: ${colors.formBackgroundColor};
  border-bottom: 1px solid ${colors.borderColor};
  padding: 5px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  h1 {
    font-size: 18px;
  }
`;

export const Body = styled.div`
  background-color: ${colors.formBackgroundColor};
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  flex-direction: column;

  height: 250px;
  max-height: 100%;
  width: 680px;
  max-width: 100%;

  overflow-x: hidden;
`;

export const Footer = styled.div`
  background-color: ${colors.formBackgroundColor};
  padding: 5px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  bottom: 0;
  border-top: 1px solid ${colors.borderColor};
  height: 50px;
`;

export const ContainerPesquisa = styled.div`
  height: 170px;
`;

export const ContainerLista = styled.div`
  margin: 10px 0;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Lista = styled.ul`
  margin: 5px 0;
  display: flex;
  flex-direction: column;

  li {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #fafafa;
    &:hover {
      background-color: #fafafa;
    }
    h2 {
      color: ${colors.titleFontColor};
      margin-right: 10px;
    }
    p {
      color: ${colors.ligthFontColor};
    }
  }
`;
