import React, {
  useState,
  useEffect,
} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import TrendingUp from '@material-ui/icons/TrendingUp';
import api from '../../../../services/api';
import { Container, MostraGraficos } from './styles';
import Loading from '../../../../components/Loading';
import { Separador } from '../../../../styles/GlobalStyles';
import { useToast } from '../../../../hooks/toast';
import ChartPizza from '../../../../components/Graficos/ChartPizza';
import upperFirstLetter from '../../../../utils/UppercaseOnlyFirstLetter';


interface IParam {
  id?: number;
}

interface IRelatorio {
  nome: string;
  valor: string;
}


const Cobrancas: React.FC<IParam> = ({ id }) => {
  const [is_loading, setIsLoading] = useState(false);
  const [produtos_mais_vendidos, setProdutosMaisvendidos] = useState([]);
  const { addToast } = useToast();

  useEffect(() => {
    async function getCobranca(): Promise<void> {
      try {
        setIsLoading(true);
        if (!id) return;

        const response = await api.get<IRelatorio[]>(
          `relatorios/produtos-mais-vendidos-por-cliente?cliente_id=${id}`,
        );

        const produtos = response.data.map(produto => {
          return {
            nome: upperFirstLetter(produto.nome),
            valor: produto.valor,
          };
        });

        setProdutosMaisvendidos(produtos);

      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro',
          description: err.response.data.message,
        });
      } finally {
        setIsLoading(false);
      }
    }

    getCobranca();
  }, [id, addToast]);


  return (
    <Container>
      <Loading isLoading={is_loading} />
      <Separador>
        <h2><TrendingUp />&nbsp;PRODUTOS MAIS COMPRADOS NOS ÚLTIMOS 365 DIAS</h2>
      </Separador>
      {produtos_mais_vendidos && produtos_mais_vendidos.length > 0
        ? <MostraGraficos>
          <ChartPizza dados={produtos_mais_vendidos} />
        </MostraGraficos>
        : <span>Nenhum item encontrado</span>}
    </Container>
  );
};

export default Cobrancas;
