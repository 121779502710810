import React, { useMemo } from 'react';
import { useAuth } from '../../hooks/auth';

interface IPermission {
  roles: string[];
  retorno_else?: React.ReactElement;
}

const Can: React.FC<IPermission> = ({ roles, retorno_else, children }) => {
  const { company, companies } = useAuth();
  const role = companies && company ? companies[company.id].role : '';

  const possui_permissao = useMemo(() => {
    return roles.includes(role);
  }, [roles, role]);

  return possui_permissao ? <>{children}</> : (retorno_else || <></>);
};

export default Can;
