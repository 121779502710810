// -----------------------------------------------------------------------------
// -------------------------------BOTÕES----------------------------------------
// -----------------------------------------------------------------------------

// BOTÃO LOGIN
export const backgroundColorLoginButton = '#343a40';
export const colorLoginButton = '#fea700';

// BOTÃO ESCURO
export const backgroundColorDarkButton = '#343a40';
export const colorDarkButton = '#fff';

// BOTÃO TRANSPARENTE
export const backgroundColorTransparentButton = 'transparent';
export const colorTransparentButton = '#9f9898';

// BOTÃO CLARO
export const backgroundColorLigthButton = '#f3d078';
export const backgroundColorGrayButton = '#fafafa';
export const colorLigthButton = '#343a40';

// -----------------------------------------------------------------------------
// -------------------------------FONTES----------------------------------------
// -----------------------------------------------------------------------------

// TÍTULOS
export const titleFontColor = '#555';

// FONTE CLARA
export const ligthFontColor = '#9f9898';

// FONTE ESCURA
export const darkFontColor = '#555';

// FONTE ESCURA
export const labelFontColor = '#446275';

// -----------------------------------------------------------------------------
// -------------------------------CORES DE FUNDO--------------------------------
// -----------------------------------------------------------------------------

// MENU LATERAL
export const menuLateralBackgroundColor = '#343a40';

// COR DE FUNDO GERAL
export const backgroudColor = '#f4f6f9';

// COR DE FUNDO CONTAINERS/ FORMS
export const formBackgroundColor = '#fff';

// COR DAS BORDAS DOS FORMS E INPUTS
export const borderColor = '#c4c4c4';

// ----------------------------------------------------------------------------
// ---------------------CORES DAS ETAPAS DO PEDIDO-----------------------------
// ----------------------------------------------------------------------------
export const orcamento = '#d3d3d3';
export const pedido = '#fabe12';
export const faturado = '#ff682c';
export const entregue = '#06b4db';
export const concluido = '#76c459';
export const erro = '#E82D22';

export const etapa_pendente = '#d3d3d3';
export const etapa_concluida = '#76c459';
export const etapa_erro = '#E82D22';

// -----------------------------------------------------------------------------
// ---------------------CORES DAS TOAST MESSAGES--------------------------------
//------------------------------------------------------------------------------
export const backgroundColorInfo = '#ebf8ff';
export const colorInfo = '#3172b7';

export const backgroundColorSuccess = '#e6fffa';
export const colorSuccess = '#2e656a';

export const backgroundColorError = '#fddede';
export const colorError = '#E82D22';
