import styled from 'styled-components';
import * as colors from '../../config/Colors/Ligth';

export const Container = styled.div`
  max-width: 600px;
  width: 98%;

  margin: 2px auto !important;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-around;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50px;
    @media only screen and (max-width: 992px) {
      width: 40px;
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center !important;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: none;
      @media only screen and (max-width: 992px) {
        width: 40px;
        height: 40px;
      }
        button {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: ${colors.etapa_pendente};
        animation: blinking 2s infinite;
        border: 2px solid;
        cursor: pointer;
        @keyframes blinking {
          0% {
            border-color: ${colors.etapa_concluida};
          }
          100% {
            border-color: ${colors.etapa_pendente};
          }
        }
      }
      background-color: #fafafa;
      svg {
        color: whitesmoke !important;
      }
    }
    span {
      font-size: 8px;
      @media only screen and (max-width: 992px) {
        font-size: 7px;
      }
    }
    > span + span {
      font-size: 8px;
      @media only screen and (max-width: 992px) {
        font-size: 7px;
      }
    }
  }

  hr {
    color: #fafafa;
    width: 100%;
    height: 2px;
    margin-bottom: 25px;
    border: 2px;
    @media only screen and (max-width: 992px) {
      margin-bottom: 20px;
    }
    .pendente {
      background-color: ${colors.etapa_pendente};
    }
    .erro {
      background-color: ${colors.etapa_erro};
    }
    .concluido {
      background-color: ${colors.etapa_concluida};
    }
  }

  .pendente {
    background-color: ${colors.etapa_pendente};
  }
  .erro {
    background-color: ${colors.etapa_erro};
  }
  .concluido {
    background-color: ${colors.etapa_concluida};
  }
`;
