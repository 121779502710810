import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import * as colors from '../../../../config/Colors/Ligth';

export const Container = styled(Dialog)`
  background-color: ${colors.formBackgroundColor};
  display: flex;
  flex-direction: column;
  color: ${colors.darkFontColor};
  height: 600px;
  max-height: 100%;
  width: 700px;
  max-width: 100%;
  margin: 0 auto;
  @media only screen and (min-width: 1200px) {
    top: 10% !important;
  }

  border-radius: 6px;
  .MuiDialog-paper {
    margin: 0;
  }
  .MuiDialog-paperScrollPaper {
    max-height: 100%;
  }
`;

export const Header = styled.div`
  background-color: ${colors.formBackgroundColor};
  padding: 5px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

`;

export const Body = styled.div`
>div{

}
  background-color: ${colors.formBackgroundColor};
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  flex-direction: column;

  min-height: 300px;
  height: 100%;
  width: 500px;
  max-width: 100%;

  overflow-x: hidden;
`;

