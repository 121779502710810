import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import InfiniteScroll from 'react-infinite-scroll-component';
import BotaoNovo from '../../../../components/Botoes/BotaoNovo';
import Loading from '../../../../components/Loading';
import api from '../../../../services/api';
import { Container } from '../../../../styles/GlobalStyles';
import Pesquisa from '../../../../components/Pesquisa';
import { HeaderLista, BodyLista } from './styles';
import Filtros from './Filtros';
import ItemContainer from './ItemLista';
import IProfileFilter from '../../../../interfaces/accounts/profileFilter';
import IStatusFilter from '../../../../interfaces/accounts/statusFilter';
import IErrorFilter from '../../../../interfaces/accounts/errorFilter';
import IAccount from '../../../../interfaces/account';

const ListaClientes: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [accounts, setAccounts] = useState([] as IAccount[]);
  const [pesquisa, setPesquisa] = useState('');
  const [page, setPage] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const [mostra_filtros, setMostraFiltros] = useState(false);
  const [profile_filter, setProfileFilter] = useState<IProfileFilter>({
    gratuito: false,
    bs_teste: true,
    bs_producao: true,
  });
  const [status_filter, setStatusFilter] = useState<IStatusFilter>({
    ativos: true,
    inativos: false,
  });

  const [error_filter, setErrorFilter] = useState<IErrorFilter>({
    com_erro: true,
    sem_erro: true,
  });

  const [total_registers, setTotalRegisters] = useState<number | boolean>(
    false,
  );

  const has_errors = useMemo(() => {
    if (!error_filter.com_erro && error_filter.sem_erro) return false;
    if (error_filter.com_erro && !error_filter.sem_erro) return true;
    return null;
  }, [error_filter]);

  async function getData(show_loading: boolean): Promise<void> {
    if (show_loading) {
      setIsLoading(true);
    }
    const response = await api.get('admin/account', {
      params: {
        q: pesquisa,
        per_page: 20,
        page,
        profile_filter: Object.keys(profile_filter).filter(
          key => profile_filter[key] === true,
        ),
        status_filter: Object.keys(status_filter).filter(
          key => status_filter[key] === true,
        ),
        has_errors,
      },
    });
    setTotalRegisters(response.data.total);

    const account_list = response.data.data.map((account: IAccount) => {
      return {
        ...account,
        last_sync_at:
          account.last_sync_at !== null
            ? new Date(account.last_sync_at)
            : account.last_sync_at,
        formatted_date:
          account.last_sync_at !== null
            ? format(new Date(account.last_sync_at), 'dd/MM HH:mm')
            : account.last_sync_at,
      };
    });

    if (page === 1) {
      setAccounts(account_list);
      setMaxPages(response.data.last_page);
    } else {
      const mergedArray = [...accounts, ...account_list];

      const merged = [
        ...mergedArray
          .reduce((map, obj) => map.set(obj.id, obj), new Map())
          .values(),
      ];
      setAccounts(merged);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    getData(true);
    const interval = setInterval(() => {
      getData(true);
    }, 60000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pesquisa, page, profile_filter, status_filter, error_filter]);

  function handlePage(): void {
    if (page >= maxPages) return;
    const paginaAtual = page + 1;
    setPage(paginaAtual);
  }
  return (
    <Container>
      <Loading isLoading={isLoading} />
      <HeaderLista>
        <div>
          <h1>CLIENTES</h1>
          <Link to="/admin/accounts/cadastro">
            <BotaoNovo>
              <PersonAddIcon />
              &nbsp;Cadastrar
            </BotaoNovo>
          </Link>
        </div>

        <Pesquisa
          placeholder="Pesquisar pelo nome..."
          pesquisa={pesquisa}
          setPesquisa={setPesquisa}
          setPage={setPage}
          filtros={{ mostra_filtros, setMostraFiltros }}
        />
        <Filtros
          profile_filter={profile_filter}
          setProfileFilter={setProfileFilter}
          error_filter={error_filter}
          setErrorFilter={setErrorFilter}
          status_filter={status_filter}
          setStatusFilter={setStatusFilter}
          mostra_filtros={mostra_filtros}
          setMostraFiltros={setMostraFiltros}
          setPage={setPage}
          total_registers={total_registers}
        />
      </HeaderLista>
      <BodyLista>
        <InfiniteScroll
          dataLength={accounts.length}
          next={handlePage}
          hasMore
          loader={page < maxPages && <h4>Carregando...</h4>}
        >
          {accounts.map(item => (
            <ItemContainer account={item} />
          ))}
        </InfiniteScroll>
      </BodyLista>
    </Container>
  );
};

export default ListaClientes;
