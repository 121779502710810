import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FiKey } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import logoImg from '../../../assets/login-logo.png';

import getValidationErrors from '../../../utils/getValidationErrors';
import Input from '../../../components/Inputs/InputGrande';
import BotaoLogin from '../../../components/Botoes/BotaoLogin';

import { Container, Content, Background, AnimationContainer } from './styles';

interface ITokenSignInFormData {
  contract_token: string;
  support_token: string;
}


const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { tokenSignIn } = useAuth();
  const { addToast } = useToast();
  const [loading_message, setLoadingMessage] = useState('');
  const history = useHistory();
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => { mounted.current = false; }; // ... and to false on unmount
  }, []);

  const handleSubmit = useCallback(
    async (data: ITokenSignInFormData) => {
      try {
        setLoadingMessage('Carregando...');
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          contract_token: Yup.string()
            .required('O contract_token é obrigatório'),
          support_token: Yup.string()
            .required('O token é obrigatório')
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await tokenSignIn(data);

        if(mounted.current){
          history.push('/dashboard');
        }

      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
        });
      } finally {
        setLoadingMessage('');
      }
    },
    [tokenSignIn, addToast, history],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="BeerSales" />
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input
              name="contract_token"
              maxLength={255}
              type="text"
              icon={FiKey}
              placeholder="Informe o contract token"
            />
            <Input
              name="support_token"
              maxLength={255}
              type="text"
              icon={FiKey}
              placeholder="Informe o token de acesso"
            />
            <BotaoLogin loading={loading_message} type="submit">
              Entrar
            </BotaoLogin>
          </Form>
        </AnimationContainer>
      </Content>
      <Background />
    </Container>
  );
};

export default SignIn;
