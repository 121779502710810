import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import InfiniteScroll from 'react-infinite-scroll-component';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import PersonIcon from '@material-ui/icons/Person';
import { useAuth } from '../../../hooks/auth';
import BotaoNovo from '../../../components/Botoes/BotaoNovo';
import BotaoNovoPedidoRodape from '../../../components/Botoes/BotaoNovoPedidoRodape';
import Loading from '../../../components/Loading';
import api from '../../../services/api';
import { Container } from '../../../styles/GlobalStyles';
import Filtros from './Filtros';
import abreviaNome from '../../../utils/abreviaNome';
import mask from '../../../utils/masks';
import Can from '../../../components/Can';
import FlagStatus from '../../../components/FlagAtivoInativo';
import Pesquisa from '../../../components/Pesquisa';
import { HeaderLista, BodyLista, ItemContainer, LinkContainer } from './styles';
import StatusSincronizacao from '../../../components/Sincronizacao/MostraStatusSincronizacao';
import ISelectOption from '../../../interfaces/selectOptions';

interface IContato {
  tipo: number;
  descricao: string;
}

interface ICliente {
  id: number;
  nome: string;
  ativo: boolean;
  nome_vendedor: string;
  apelido?: string;
  cpf_cnpj?: string;
  cidade?: string;
  estado?: string;
  condicao: number;
  cod_retorno_integracao?: number;
  motivo_integracao?: string;
  contato?: IContato[];
}

interface IFiltro {
  sincronizado: boolean;
  sincronizando: boolean;
  erro: boolean;
}

const ListaClientes: React.FC = () => {
  const [filtro_sincronizacao, setFiltroSincronizacao] = useState<IFiltro>({
    sincronizado: true,
    sincronizando: true,
    erro: true,
  });
  const { company, companies } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [clientes, setClientes] = useState([] as ICliente[]);
  const [mostra_filtros, setMostraFiltros] = useState(false);
  const [pesquisa, setPesquisa] = useState('');
  const [page, setPage] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const [vendedor, setVendedor] = useState<number[]>([]);
  const [condicao, setCondicao] = useState<ISelectOption>({
    label: 'Todos',
    value: null,
  });
  const [grupo_cliente, setGrupoCliente] = useState<number[]>([]);

  const isAdmin = companies[company.id].role === 'customer-admin';
  useEffect(() => {
    async function getData(show_loading: boolean): Promise<void> {
      if (show_loading) {
        setIsLoading(true);
      }
      const cods = [];
      filtro_sincronizacao.sincronizado && cods.push(201);
      filtro_sincronizacao.sincronizando && cods.push(202);
      filtro_sincronizacao.erro && cods.push(412, 500);
      const response = await api.get(`clientes`, {
        params: {
          q: pesquisa,
          per_page: 20,
          page,
          cod: cods,
          condicao: condicao.value,
          grupo_cliente,
          vendedor,
        },
      });
      if (page === 1) {
        setClientes(response.data.data);
        setMaxPages(response.data.last_page);
      } else {
        const ids = new Set(clientes.map(d => d.id));
        const merged = [
          ...clientes,
          ...response.data.data.filter(d => !ids.has(d.id)),
        ];
        setClientes(merged);
      }
      setIsLoading(false);
    }

    getData(true);

    const interval = setInterval(() => {
      getData(false);
    }, 60000);
    return () => clearInterval(interval);
  }, [pesquisa, page, filtro_sincronizacao, condicao, grupo_cliente, vendedor]);

  function handlePage(): void {
    if (page < maxPages) {
      const paginaAtual = page + 1;
      setPage(paginaAtual);
    }
  }

  return (
    <Container>
      <Loading isLoading={isLoading} />
      <HeaderLista>
        <div>
          <h1>CLIENTES</h1>
          <Link to="/clientes/cadastro">
            <BotaoNovo>
              <PersonAddIcon />
              &nbsp;Cadastrar
            </BotaoNovo>
          </Link>
        </div>
        <Pesquisa
          placeholder="Pesquise por nome, Cpf/ Cnpj ou Cidade"
          pesquisa={pesquisa}
          setPesquisa={setPesquisa}
          setPage={setPage}
          filtros={{ mostra_filtros, setMostraFiltros }}
        />
        <Filtros
          setPage={setPage}
          condicao={condicao}
          setCondicao={setCondicao}
          isAdmin={isAdmin}
          setVendedor={setVendedor}
          vendedor={vendedor}
          grupo_cliente={grupo_cliente}
          setGrupoCliente={setGrupoCliente}
          filtro_sincronizacao={filtro_sincronizacao}
          setFiltroSincronizacao={setFiltroSincronizacao}
          mostra_filtros={mostra_filtros}
          setMostraFiltros={setMostraFiltros}
        />
      </HeaderLista>
      <BodyLista>
        <InfiniteScroll
          dataLength={clientes.length}
          next={handlePage}
          hasMore
          loader={page < maxPages && <h4>Carregando...</h4>}
        >
          {clientes.map(item => (
            <ItemContainer key={item.id}>
              <LinkContainer
                to={`/clientes/resumo/${item.id}/${
                  item.ativo ? 'ativo' : 'inativo'
                }`}
              >
                <div>
                  <h2>
                    {item.nome}
                    &nbsp;
                  </h2>
                  <span>{item.apelido ? item.apelido : ''}</span>

                  <span>
                    {item.cpf_cnpj && item.cpf_cnpj.length === 14
                      ? mask({ input: item.cpf_cnpj, tipo: 'cnpj' })
                      : mask({ input: item.cpf_cnpj, tipo: 'cpf' })}
                  </span>

                  {item.cidade && (
                    <span>
                      {item.cidade}
                      {item.estado ? ` - ${item.estado}` : ''}
                    </span>
                  )}

                  {item.contato?.map(contato => (
                    <span>
                      <AlternateEmailIcon />
                      {contato.descricao}
                    </span>
                  ))}
                  <Can roles={['customer-admin']}>
                    {item.nome_vendedor && (
                      <span>
                        <PersonIcon />
                        {abreviaNome(item.nome_vendedor)}
                      </span>
                    )}
                  </Can>
                </div>
              </LinkContainer>
              <div>
                {!item.ativo ? (
                  <FlagStatus ativo={item.ativo} />
                ) : (
                  <StatusSincronizacao
                    key={item.id}
                    id={item.id}
                    tipo="cliente"
                    cod_retorno_integracao={item.cod_retorno_integracao}
                    motivo_integracao={item.motivo_integracao}
                  />
                )}
              </div>
            </ItemContainer>
          ))}
        </InfiniteScroll>
        <BotaoNovoPedidoRodape />
      </BodyLista>
    </Container>
  );
};

export default ListaClientes;
