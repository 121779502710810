import React from 'react';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';


interface IDado {
  nome: string;
  valor: string;
}

interface IDados {
  dados: IDado[];
}

const ChartLine: React.FC<IDados> = ({ dados }) => {
  const data = dados.map(dado => {
    return { nome: dado.nome, Valor: parseFloat(dado.valor) };
  });

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        layout="vertical"
        data={data}
        margin={{ top: 15, right: 30, left: -15, bottom: 20 }}
      >
          <CartesianGrid />
          <XAxis type="number" />
          <YAxis dataKey="nome" type="category" scale="band"/>
          <Tooltip />
          <Legend />
          <Bar dataKey="Valor" barSize={50} fill="#EBA821" />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default ChartLine;
