import styled from 'styled-components';
import * as colors from '../../../../config/Colors/Ligth';

export const ContainerFiltros = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 4px;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid ${colors.borderColor};

  > div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    h1 {
      font-size: 20px;
      padding-bottom: 10px;
    }
  }

  & > div {
    margin: 0;
    padding: 0;
    width: 100%;
    flex-direction: row;
    align-items: center;
    h2 {
      padding-right: 5px;
      color: ${colors.labelFontColor};
      padding-left: 5px;
      font-weight: 400;
      font-size: 10px;
    }
    margin-bottom: 10px;
  }
  label {
  font-size: 10px;
  color: ${colors.labelFontColor}
}
`;

export const ContainerBotoes = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

`;

export const ContainerResumoFiltros = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  p {
    font-size: 10px;
  }
  padding-bottom: 10px;
`;

