import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import Loading from '../../../components/Loading';
import api from '../../../services/api';
import { Container } from '../../../styles/GlobalStyles';
import BotaoNovo from '../../../components/Botoes/BotaoNovo';
import { etapasPedido } from '../../../config/Basicos';
import { useToast } from '../../../hooks/toast';
import { HeaderLista, BodyLista } from './styles';
import ItemContainer from './components/ItemLista';
import GetNomeClienteConfig from '../../../utils/GetNomeClientesConfig';
import Pesquisa from '../../../components/Pesquisa';
import Filtros from './components/Filtros';

interface IPedidoItem {
  id: number;
  quantidade: string;
  valor_bruto: string;
  valor_liquido: string;
  valor_desconto: string;
  produto: {
    id: number;
    nome_produto: string;
    imagem: string;
    nome_unidade: string;
  };
}

interface IPedido {
  id: number;
  nome: string;
  apelido: string;
  cpf_cnpj: string;
  nome_vendedor: string;
  valor_total: string;
  beersales_id: number;
  prazo: string;
  forma_pagamento: string;
  motivo_cancelamento: string;
  motivo_pedido: string;
  motivo_entrega: string;
  status_id: number;
  data_geracao: Date;
  data_faturado: Date;
  data_entregue: Date;
  data_concluido: Date;
  data_bloqueado: Date;
  data_cancelado: Date;
  created_at: Date;
  cod_retorno_integracao: number;
  motivo_integracao: string;
  chave_nfe: string;
  pedido_item: IPedidoItem[];
  classe?: string;
  titulo?: string;
  nfe: [];
}

interface IFiltro {
  orcamento?: boolean;
  sincronizado: boolean;
  sincronizando: boolean;
  erro: boolean;
}

const ListaPedidos: React.FC = () => {
  const [filtro_sincronizacao, setFiltroSincronizacao] = useState<IFiltro>({
    orcamento: true,
    sincronizado: true,
    sincronizando: true,
    erro: true,
  });
  const history = useHistory();
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [pedidos, setPedidos] = useState([] as IPedido[]);
  const [filtro_tipo_pedido, setFiltroTipoPedido] = useState(0);
  const [pesquisa, setPesquisa] = useState('');
  const [page, setPage] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const [mostra_filtros, setMostraFiltros] = useState(false);
  const [filtro_tipo_data, setFiltroTipoData] = useState(null);
  const [filtro_data_inicial, setFiltroDataInicial] = useState(new Date());
  const [filtro_data_final, setFiltroDataFinal] = useState(new Date());

  async function cancelarPedido(id: number): Promise<void> {
    try {
      await api.delete(`pedido/${id}`);
      const filteredItens = pedidos.filter(item => item.id !== id);
      setPedidos([...filteredItens]);
      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: 'Pedido cancelado com sucesso',
      });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro!',
        description: 'Não foi possível cancelar o pedido',
      });
    }
  }

  async function duplicarPedido(id: number): Promise<void> {
    try {
      const { data } = await api.post(`duplicar`, { id });
      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: 'Pedido duplicado com sucesso',
      });
      history.push(`/pedidos/cadastro/${data.id}`);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro!',
        description: 'Não foi possível duplicar o pedido',
      });
    }
  }

  async function getData(show_loading: boolean): Promise<void> {
    if (show_loading) {
      setIsLoading(true);
    }
    const cods = [];
    filtro_sincronizacao.orcamento && cods.push(0);
    filtro_sincronizacao.sincronizado && cods.push(201);
    filtro_sincronizacao.sincronizando && cods.push(202);
    filtro_sincronizacao.erro && cods.push(412, 500);

    const config = await api.get('busca-configuracao-vendedor');

    const response = await api.get('pedidos', {
      params: {
        q: pesquisa,
        per_page: 20,
        status: filtro_tipo_pedido,
        page,
        cod: cods,
        filtro_tipo_data,
        filtro_data_inicial,
        filtro_data_final,
      },
    });

    const lista_pedidos = response.data.data.map((pedido: IPedido) => {
      let classe;
      let titulo;
      let etapa;
      if (pedido.data_bloqueado) {
        etapa = etapasPedido.find(e => e.id === 6);
        classe = etapa?.classe;
        titulo = etapa?.titulo;
      } else if (pedido.data_cancelado) {
        etapa = etapasPedido.find(e => e.id === 7);
        classe = etapa?.classe;
        titulo = etapa?.titulo;
      } else if (pedido.beersales_id === 412 || pedido.beersales_id === 500) {
        etapa = etapasPedido.find(e => e.id === 8);
        classe = etapa?.classe;
        titulo = etapa?.titulo;
      } else {
        etapa = etapasPedido.find(e => e.id === pedido.status_id);
        classe = etapa?.classe;
        titulo = etapa?.titulo;
      }
      return {
        ...pedido,
        nome:
          GetNomeClienteConfig(
            pedido,
            config.data ? config.data.modo_exibicao_cliente : 0,
          ) || '',
        classe,
        titulo,
      };
    });

    if (page === 1) {
      setPedidos(lista_pedidos);
      setMaxPages(response.data.last_page);
    } else {
      const mergedArray = [...pedidos, ...lista_pedidos];
      const merged = [
        ...mergedArray
          .reduce((map, obj) => map.set(obj.id, obj), new Map())
          .values(),
      ];
      setPedidos(merged);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getData(true);

    const interval = setInterval(() => {
      getData(false);
    }, 60000);

    return () => clearInterval(interval);
  }, [
    filtro_tipo_pedido,
    pesquisa,
    page,
    filtro_sincronizacao,
    filtro_data_inicial,
    filtro_data_final,
    filtro_tipo_data,
  ]);

  async function handleNovoPedido(): Promise<void> {
    const { data } = await api.post(`novo-pedido`);
    history.push(`/pedidos/cadastro/${data.id}`);
  }

  function handlePage(): void {
    if (page >= maxPages) return;
    const paginaAtual = page + 1;
    setPage(paginaAtual);
  }

  return (
    <Container>
      <Loading isLoading={isLoading} />
      <HeaderLista>
        <div>
          <h1>PEDIDOS</h1>
          <BotaoNovo onClick={handleNovoPedido}>
            <AddShoppingCartIcon />
            &nbsp;Cadastrar
          </BotaoNovo>
        </div>

        <Pesquisa
          placeholder="Pesquisar por cliente, NF ou N° do pedido"
          pesquisa={pesquisa}
          setPesquisa={setPesquisa}
          setPage={setPage}
          filtros={{ mostra_filtros, setMostraFiltros }}
        />
        <Filtros
          filtro_tipo_pedido={filtro_tipo_pedido}
          setFiltroTipoPedido={setFiltroTipoPedido}
          filtro_sincronizacao={filtro_sincronizacao}
          setFiltroSincronizacao={setFiltroSincronizacao}
          mostra_filtros={mostra_filtros}
          setMostraFiltros={setMostraFiltros}
          setFiltroTipoData={setFiltroTipoData}
          filtro_tipo_data={filtro_tipo_data}
          filtro_data_inicial={filtro_data_inicial}
          setFiltroDataInicial={setFiltroDataInicial}
          setFiltroDataFinal={setFiltroDataFinal}
          setPage={setPage}
        />
      </HeaderLista>
      <BodyLista>
        <InfiniteScroll
          dataLength={pedidos.length}
          next={handlePage}
          hasMore
          loader={page < maxPages && <h4>Carregando...</h4>}
        >
          {pedidos.map(item => (
            <ItemContainer
              getData={getData}
              key={item.id}
              pedido={item}
              duplicarPedido={duplicarPedido}
              cancelarPedido={cancelarPedido}
              nfe={item.nfe}
            />
          ))}
        </InfiniteScroll>
      </BodyLista>
    </Container>
  );
};

export default ListaPedidos;
