import React, { useEffect, ReactNode, useState, useMemo } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  IconButton,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  AppBar,
  List,
  Menu,
  MenuItem,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  FaChartLine,
  FaCalendarAlt,
  FaShoppingCart,
  FaCog,
  FaUserCircle,
  FaBuilding,
  FaUser,
  FaBook,
  FaBookReader,
  FaUserTie,
} from 'react-icons/fa';
import { useAuth } from '../../hooks/auth';
import {
  menuLateralBackgroundColor,
  formBackgroundColor,
} from '../../config/Colors/Ligth';
import { TextoBarraSuperior, LinkMenuLateral } from './styles';
import Logo from '../../assets/logo-invertida-sidebar.png';
import Whatsapp from '../../assets/whatsapp.png';
import GooglePlay from '../../assets/google-play-badge.png';
import Loading from '../Loading';

const drawerWidth = 220;
const iconSize = 18;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    background: `${formBackgroundColor}`,
    height: 64,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    hover: 'disable',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: {
    theme: theme.mixins.toolbar,
    backgroundColor: menuLateralBackgroundColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 64,
    paddingTop: 10,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: menuLateralBackgroundColor,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
  },
}));

interface IProps {
  children?: ReactNode;
  container?;
}

interface IEmpresa {
  id: number;
  nome: string;
  role?: string;
}

const Sidebar: React.FC<IProps> = ({ children, container }) => {
  const { user, signOut, company, companies, updateCompany } = useAuth();
  const empresa = company?.id;
  const location = useLocation();

  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [empresas, setEmpresas] = useState<IEmpresa[]>(companies || []);
  const [isLoading] = useState(false);
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElEmpresa, setAnchorElEmpresa] = useState(null);

  const open = Boolean(anchorEl);
  const openEmpresa = Boolean(anchorElEmpresa);

  const isLoggedIn = !!user;

  const menu1 = [
    {
      titulo: 'Dashboard',
      hide: !['customer-user', 'customer-admin'].includes(
        companies && companies[empresa].role,
      ),
      link: '/dashboard',
      icone: <FaChartLine size={iconSize} />,
    },

    {
      titulo: 'Agenda',
      hide: !['customer-user', 'customer-admin'].includes(
        companies && companies[empresa].role,
      ),
      link: '/agenda',
      icone: <FaCalendarAlt size={iconSize} />,
    },
    {
      titulo: 'Pedidos',
      hide: !['customer-user', 'customer-admin'].includes(
        companies && companies[empresa].role,
      ),
      link: '/pedidos/lista',
      icone: <FaShoppingCart size={iconSize} />,
    },
    {
      titulo: 'Clientes',
      hide: !['customer-user', 'customer-admin'].includes(
        companies && companies[empresa].role,
      ),
      link: '/clientes/lista',
      icone: <FaUserTie size={iconSize} />,
    },
    {
      titulo: 'Produtos',
      hide: !['customer-user', 'customer-admin'].includes(
        companies && companies[empresa].role,
      ),
      link: '/produtos/lista',
      icone: <FaBook size={iconSize} />,
    },
    {
      titulo: 'Configurações',
      hide: !['customer-user', 'customer-admin'].includes(
        companies && companies[empresa].role,
      ),
      link: '/configuracoes',
      icone: <FaCog size={iconSize} />,
    },
    {
      titulo: 'Usuários',
      hide: !['customer-admin'].includes(companies && companies[empresa].role),
      link: '/usuarios',
      icone: <FaUser size={iconSize} />,
    },
    {
      titulo: 'Accounts',
      hide: !['eget-admin'].includes(companies && companies[empresa].role),
      link: '/admin/accounts/lista',
      icone: <FaBuilding size={iconSize} />,
    },
    {
      titulo: 'Gestão de Conhecimento',
      hide: !['customer-user', 'customer-admin'].includes(
        companies && companies[empresa].role,
      ),
      link: {
        pathname:
          'https://e-get.movidesk.com/kb/article/373799/beersales-forca-de-vendas',
      },
      icone: <FaBookReader size={iconSize} />,
      target_blank: true,
    },
  ];

  const theme = useTheme();

  const handleMenu = (event): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuEmpresa = (event): void => {
    setAnchorElEmpresa(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleCloseEmpresa = (): void => {
    setAnchorElEmpresa(null);
  };

  const handleDrawerToggle = (): void => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (!isLoggedIn) {
      handleClose();
      handleCloseEmpresa();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn) return;
    function getEmpresas(): void {
      setEmpresas(
        Object.keys(companies).map(key => {
          return { id: Number(key), nome: companies[Number(key)].nome };
        }),
      );
    }
    getEmpresas();
  }, [isLoggedIn, company, updateCompany, companies]);

  const handleLogout = (e): void => {
    e.preventDefault();
    handleClose();
    signOut();
    history.push('/login');
  };

  const handleChangeEmpresa = (emp: IEmpresa): void => {
    handleCloseEmpresa();
    updateCompany(emp);
    history.push('/dashboard');
  };

  const endereco_atual = useMemo(() => {
    const endereco_ativo = location.pathname.split('/');
    return endereco_ativo[1] ? endereco_ativo[1] : 'não encontrado';
  }, [location.pathname]);

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <Link to="/dashboard">
          <img alt="BeerSales" src={Logo} style={{ width: 215 }} />
        </Link>
      </div>
      <Divider />
      <List>
        <div>
          {menu1.map(menu => (
            <Link
              onClick={() => setMobileOpen(false)}
              hidden={menu.hide}
              to={menu.link}
              key={menu.titulo}
              target={menu.target_blank && '_blank'}
              rel="noopener noreferrer"
            >
              <LinkMenuLateral
                button
                key={menu.titulo}
                selected={
                  'target_blank' in menu
                    ? null
                    : menu.link.includes(endereco_atual)
                }
              >
                <div>
                  {menu.icone}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>{menu.titulo}</span>
                </div>
              </LinkMenuLateral>
            </Link>
          ))}
        </div>
      </List>
      <div style={{ position: 'fixed', bottom: '50px', left: '10px' }}>
        <p
          style={{ fontSize: '10px', color: 'gray', fontWeight: 700 }}
        >{`V ${process.env.REACT_APP_VERSION}`}</p>
      </div>
      <div style={{ position: 'fixed', bottom: '2px' }}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://play.google.com/store/apps/details?id=com.appforcadevendas&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        >
          <img alt="Disponível no Google Play" src={GooglePlay} />
        </a>
      </div>
      <div style={{ position: 'fixed', bottom: '6px', left: '150px' }}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://api.whatsapp.com/send?phone=555137152785"
        >
          <img title="Fale conosco" alt="Fale conosco" src={Whatsapp} />
        </a>
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      {isLoggedIn && (
        <>
          <CssBaseline />
          <AppBar position="fixed">
            <Loading isLoading={isLoading} />
            <Toolbar className={classes.appBar}>
              <div>
                <IconButton
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar-empresa"
                  aria-haspopup="true"
                  onClick={handleMenuEmpresa}
                  style={{
                    backgroundColor: 'transparent',
                  }}
                >
                  <p style={{ textTransform: 'capitalize' }}>
                    {company?.nome.toLowerCase()}
                  </p>
                  <ExpandMoreIcon />
                </IconButton>
                {empresas && empresas.length > 1 && (
                  <Menu
                    id="menu-appbar-empresa"
                    anchorEl={anchorElEmpresa}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={openEmpresa}
                    onClose={handleCloseEmpresa}
                  >
                    {empresas.map(emp => (
                      <MenuItem
                        key={emp.id}
                        onClick={() => handleChangeEmpresa(emp)}
                      >
                        <p style={{ textTransform: 'capitalize' }}>
                          {emp.nome.toLowerCase()}
                        </p>
                      </MenuItem>
                    ))}
                  </Menu>
                )}
              </div>
              <div>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  style={{
                    backgroundColor: 'transparent',
                  }}
                >
                  <TextoBarraSuperior>
                    <FaUserCircle size={24} />
                    &nbsp;
                    <p style={{ textTransform: 'capitalize' }}>
                      {user?.nome.toLowerCase()}
                    </p>
                  </TextoBarraSuperior>
                  <ExpandMoreIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <Link to="/meus-dados">
                    <MenuItem onClick={handleClose}>Meus dados</MenuItem>
                  </Link>

                  <MenuItem onClick={handleLogout}>Sair</MenuItem>
                </Menu>
              </div>
            </Toolbar>
            {/* }  </PrimarySearchAppBar> */}
          </AppBar>
          <nav className={classes.drawer} aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
              <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
        </>
      )}
      <main className={classes.content}>{children}</main>
    </div>
  );
};

export default Sidebar;
