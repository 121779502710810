import React from 'react';
import ReactSelect, {
  OptionTypeBase,
  Props as SelectProps,
} from 'react-select';
import { Container } from './styles';
import * as colors from '../../../config/Colors/Ligth';

interface Props extends SelectProps<OptionTypeBase> {
  name: string;
  label?: string;
  prefixo?: string;
  isDisabled?: boolean;
  showPasswordIcon?: boolean;
  error?: string;
}

const Select: React.FC<Props> = ({
  error,
  name,
  label,
  ...rest
}) => {
  return (
    <Container isErrored={!!error}>
      <label htmlFor={label}>
        {label}
        <ReactSelect
          name={name}
          classNamePrefix="react-select"
          noOptionsMessage={() => name}
          loadingMessage={() => 'Carregando...'}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: colors.backgroudColor,
              primary: colors.labelFontColor,
            },

          })}
          {...rest}

        />

        {error && <span>{error}</span>}
      </label>
    </Container>
  );
};

export default Select;
