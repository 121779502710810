import React from 'react';
import { format } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import locale from 'date-fns/locale/pt-BR';
import 'react-confirm-alert/src/react-confirm-alert.css';
import AccountBalance from '@material-ui/icons/AccountBalance';
import { Container } from './styles';
import { Separador } from '../../../../styles/GlobalStyles';
import formataValor from '../../../../utils/formataValor';

interface IRouteParam {
  cobrancas: ICobranca[];
}

interface ICobranca {
  data_recebimento: Date;
  data_vencimento: Date;
  valor: number;
}

const Cobrancas: React.FC<IRouteParam> = ({ cobrancas }) => {
  return (
    <Container>
      <Separador>
        <h2>
          <AccountBalance />
          &nbsp;COBRANÇAS EM ABERTO
        </h2>
      </Separador>
      {cobrancas && cobrancas.length === 0 ? (
        <span>Nenhuma cobrança em aberto</span>
      ) : (
        cobrancas?.map(cobranca => (
          <div>
            <span>{`VENCIMENTO: ${
              cobranca.data_vencimento
                ? format(
                    new Date(`${cobranca.data_vencimento}T00:00`),
                    'd/M/Y',
                    { locale },
                  )
                : ''
            }`}</span>
            <span>{`VALOR: ${formataValor(`${cobranca.valor}`)}`}</span>
          </div>
        ))
      )}
    </Container>
  );
};

export default Cobrancas;
