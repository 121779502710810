import styled from 'styled-components';
import ChevronDown from '../../../assets/chevron-down.jpeg';

import * as colors from '../../../config/Colors/Ligth';

export const HeaderLista = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 20px;
  min-height: 140px;
  border-bottom: 1px solid #eee;

  h1 {
    font-size: 1.4rem;
    color: ${colors.titleFontColor};
  }
  & > div:first-child  {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const BodyLista = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  select {
    height: 40px;
    width: 140px;
    @media only screen and (max-width: 1000px) {
      font-size: 12px;
      padding-left: 5px;
    }

    border-radius: 5px 0 0 5px;
    font-weight: 500;
    border: 1px solid lightgray;
    border-right: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(${ChevronDown}) ${colors.formBackgroundColor} no-repeat
      98.5% !important;
    background: url(${ChevronDown}) ${colors.formBackgroundColor} no-repeat
      calc(100% - 3px) !important;
    padding-left: 10px;
  }
  input {
    width: 100%;
    height: 40px;
    padding: 0 12px;
    border-radius: 0;
    color: ${colors.darkFontColor};
    border: 1px solid lightgray;

    &:hover {
      border: 1px solid ${colors.darkFontColor};
    }
    &:focus {
      border: 1px solid ${colors.titleFontColor};
    }
    &:disabled {
      background-color: ${colors.formBackgroundColor};
    }
    &::placeholder {
      color: ${colors.ligthFontColor};
    }

    border-radius: 5px 0 0 5px;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 0 5px 5px 0;
    border: 1px solid lightgray;
    border-left: 0;
  }
`;
