import styled from 'styled-components';
import { shade } from 'polished';
import {
  backgroundColorLoginButton,
  colorLoginButton,
} from '../../../config/Colors/Ligth';

export const Container = styled.button`
  background: ${backgroundColorLoginButton};
  color: ${colorLoginButton};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  border: 0;
  padding: 16px;
  width: 100%;
  margin-top: 16px;

  cursor: pointer;
  border: none;
  border-radius: 8px;
  transition: all 200ms;

  &:hover {
    background: ${shade(0.3, backgroundColorLoginButton)};
  }
`;
