import { useEffect, useRef, InputHTMLAttributes } from 'react'
import { useField } from '@unform/core'
import { Container } from './styles';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label: string
  value: string
}
type InputProps = InputHTMLAttributes<HTMLInputElement> & Props;
const CheckboxInput: React.FC<InputProps> = ({ name, label, value, ...rest }) => {
  const inputRef = useRef()
  const { fieldName, defaultValue, registerField } = useField(name);
  const defaultChecked = defaultValue === value;
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return ref.current.checked
      },
      clearValue: ref => {
        /**
         * If you want to change the default checked for false or true,
         * you can do so here. In this example, when resetting the form,
         * the checkbox goes back to its initial state.
         */
        // eslint-disable-next-line
        ref.current.checked = defaultChecked
      },
      setValue: (ref, val) => {
        // eslint-disable-next-line
        ref.current.checked = val
      },
    })
  }, [defaultValue, fieldName, registerField, defaultChecked])
  return (
    <Container>
      <input
        defaultChecked={defaultChecked}
        ref={inputRef}
        {...rest}
        value={value}
        type="checkbox"
        id={fieldName}
      />
      <label htmlFor={fieldName} key={fieldName}>
        {label}
      </label>
    </Container>
  );
};
export default CheckboxInput;
