import styled, { css } from 'styled-components';
import { labelFontColor, erro } from '../../../config/Colors/Ligth';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  tamanho?: number;
}

export const Container = styled.div<ContainerProps>`
  margin-top: 16px;

  ${props =>
    props.tamanho &&
    css`
      @media all and (max-width: 962px) {
        max-width: ${props.tamanho > 33 ? 100 : props.tamanho * 3}%;
        font-size: 10px;
      }
      @media all and (max-width: 1200px) and (min-width: 963px) {
        max-width: ${props.tamanho > 49 ? 100 : props.tamanho * 2}%;
        font-size: 12px;
      }
      @media all and (min-width: 1201px) {
        max-width: ${props.tamanho}%;
      }
    `}

  label {
    padding-left: 6px;
    font-size: 10px;
    color: ${labelFontColor};
    ${props =>
    props.isFocused &&
    css`
        font-weight: 700;
      `}
    ${props =>
    props.isErrored &&
    css`
        color: ${erro};
      `}
  }

  span {
    background-color: transparent;
    padding-top: 0;
    padding-left: 6px;
    font-size: 10px;
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: ${erro};
    `}

  ${props =>
    props.isFocused &&
    css`
      border-color: ${labelFontColor};
      border-width: 2px;
      color: #666360;
    `}
`;
