import React from 'react';
import { Status } from './styles';

interface ILoading {
  ativo: boolean;
}

const Loading: React.FC<ILoading> = ({ ativo }) => {
  return <Status ativo={ativo}>{ativo ? 'Ativo' : 'Inativo'}</Status>;
};

export default Loading;
