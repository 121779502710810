import ISelectOptions from '../interfaces/selectOptions';

export default function retornaLabel(
  options: ISelectOptions[],
  value: number | boolean | undefined,
): string {
  if (options && value !== null) {
    const findOption = options.find(option => option.value === value);
    if (findOption) return findOption.label;
  }
  return '';
}
