import styled from 'styled-components';
import * as colors from '../../../../config/Colors/Ligth';

export const Container = styled.div`
  padding: 20px 0 30px 0;
  display: flex;
  flex-direction: column;
  div{
    padding: 5px 0;
    span{
      font-weight: 600;
      color: #9f9898;
      font-size: 1rem;
      margin: 10px;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  top: 0;
  border-bottom: 1px solid #eee;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    h1 {
      font-size: 1.4rem;
      font-weight: bold;
      color: ${colors.titleFontColor};
    }
  }
  div + div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const Voltar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: -10px;
  a {
    color: ${colors.ligthFontColor};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    p {
      text-transform: uppercase;
    }
    &:hover {
      color: ${colors.titleFontColor};
    }
  }
`;

