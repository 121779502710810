import styled, { css } from 'styled-components';
import * as colors from '../../../config/Colors/Ligth';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  isDisabled?: boolean;
  tamanho?: number;
}

export const Container = styled.div<ContainerProps>`
  margin-top: 16px;
  ${props =>
    props.tamanho &&
    css`
      @media all and (max-width: 962px) {
        max-width: ${props.tamanho > 33 ? 100 : props.tamanho * 3}%;
        font-size: 10px;
      }
      @media all and (max-width: 1200px) and (min-width: 963px) {
        max-width: ${props.tamanho > 49 ? 100 : props.tamanho * 2}%;
        font-size: 12px;
      }
      @media all and (min-width: 1201px) {
        max-width: ${props.tamanho}%;
      }
    `}

  label {
    padding-left: 6px;
    font-size: 10px;
    color: ${colors.labelFontColor};
    ${props =>
    props.isFocused &&
    css`
        font-weight: 700;
      `}
    ${props =>
    props.isErrored &&
    css`
        color: ${colors.erro};
      `}


  div {
      background: transparent;
      border-radius: 4px;
      padding: 8px;
      width: 100%;
      display: flex;
      align-items: center;

      border: 1px solid #c4c4c4;
      color: #666360;

      ${props =>
    props.isErrored &&
    css`
          border-color: ${colors.erro};
        `}

      ${props =>
    props.isFocused &&
    css`
          border-color: ${colors.labelFontColor};
          border-width: 2px;
          color: #666360;
        `}
        ${props =>
    props.isDisabled &&
    css`
          background-color: #f4f4f4;
        `}


    textArea {
        flex: 1;
        background: transparent;
        border: 0;
        &::placeholder {
          color: #666360;
        }

        ${props =>
    props.isDisabled &&
    css`
            &::placeholder {
              color: transparent;
            }
          `}
      }

      svg {
        margin-right: 16px;
      }
    }

    span {
      padding-top: 0;
      padding-left: 6px;
      font-size: 10px;
    }
  }
`;
