import React, { useState, useMemo } from 'react';
import {
  ContainerFiltros,
  ContainerBotoes,
  ContainerResumoFiltros,
} from './styles';
import BotaoSalvar from '../../../../../components/Botoes/BotaoSalvar';
import MultipleHorizontalCheckProfileFilter from './MultipleHorizontalCheckProfileFilter';
import MultipleHorizontalCheckStatusFilter from './MultipleHorizontalCheckStatusFilter';
import MultipleHorizontalCheckErrorFilter from './MultipleHorizontalCheckErrorFilter';
import IProfileFilter from '../../../../../interfaces/accounts/profileFilter';
import IStatusFilter from '../../../../../interfaces/accounts/statusFilter';
import IErrorFilter from '../../../../../interfaces/accounts/errorFilter';

interface IFiltros {
  profile_filter: IProfileFilter;
  setProfileFilter: (profile_filter: IProfileFilter) => void;
  status_filter: IStatusFilter;
  setStatusFilter: (status_filter: IStatusFilter) => void;
  error_filter: IErrorFilter;
  setErrorFilter: (error_filter: IErrorFilter) => void;
  mostra_filtros: boolean;
  setMostraFiltros: (mostra: boolean) => void;
  setPage: (page: number) => void;
  total_registers: number | boolean;
}

const Filtros: React.FC<IFiltros> = ({
  profile_filter,
  setProfileFilter,
  status_filter,
  setStatusFilter,
  error_filter,
  setErrorFilter,
  mostra_filtros,
  setMostraFiltros,
  setPage,
  total_registers,
}) => {
  const [profile_temp, setProfileTemp] =
    useState<IProfileFilter>(profile_filter);
  const [status_temp, setStatusTemp] = useState<IStatusFilter>(status_filter);
  const [error_temp, setErrorTemp] = useState<IErrorFilter>(error_filter);

  function handleAplicarFiltros(): void {
    setProfileFilter(profile_temp);
    setStatusFilter(status_temp);
    setErrorFilter(error_temp);
    setMostraFiltros(false);
    setPage(1);
  }

  const filtros_ativos = useMemo(() => {
    const array_types_profiles = Object.keys(profile_temp).filter(
      key => profile_temp[key] === true,
    );

    const array_status = Object.keys(status_temp).filter(
      key => status_temp[key] === true,
    );

    let profile_types_resume = '';
    let status_resume = '';

    profile_types_resume += array_types_profiles.includes('gratuito')
      ? 'Gratuito;'
      : '';

    profile_types_resume += array_types_profiles.includes('bs_teste')
      ? 'BS - Teste;'
      : '';

    profile_types_resume += array_types_profiles.includes('bs_producao')
      ? 'BS - Produção'
      : '';

    status_resume += array_status.includes('ativos') ? 'Ativos;' : '';

    status_resume += array_status.includes('inativos') ? 'Inativos' : '';

    if (!array_status.includes('ativos') && !array_status.includes('inativos'))
      status_resume = '';

    const resume_string = `Filtros ativos: Perfil: ${profile_types_resume} - Status: ${status_resume} |
    Total de Registros encontrados: ${total_registers}`;

    return resume_string;
  }, [profile_temp, status_temp, total_registers]);

  return (
    <>
      <ContainerResumoFiltros>
        <p>{filtros_ativos}</p>
      </ContainerResumoFiltros>
      {mostra_filtros && (
        <ContainerFiltros>
          <div>
            <h1>Filtrar por</h1>
          </div>
          <MultipleHorizontalCheckProfileFilter
            filtro={profile_temp}
            setFiltro={setProfileTemp}
          />

          <MultipleHorizontalCheckStatusFilter
            filtro={status_temp}
            setFiltro={setStatusTemp}
          />
          <MultipleHorizontalCheckErrorFilter
            filtro={error_temp}
            setFiltro={setErrorTemp}
          />
          <ContainerBotoes>
            <BotaoSalvar onClick={handleAplicarFiltros}>
              Aplicar Filtros
            </BotaoSalvar>
          </ContainerBotoes>
        </ContainerFiltros>
      )}
    </>
  );
};

export default Filtros;
