import styled from 'styled-components';
import { concluido} from '../../../config/Colors/Ligth';

export const Container = styled.button`
  background: ${concluido};
  color: ${concluido};
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  width: auto;
  padding: 10px 10px;
  border-radius: 6px;
  transition: all 300ms;

  &:hover {
    color: ${concluido};
    filter: brightness(100%);
  }
`;
