import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { DeleteForever } from '@material-ui/icons';
import api from '../../../../../../services/api';
import EmpresasPermissoes from '../EmpresasPermissoes';
import FlagStatus from '../../../../../../components/FlagAtivoInativo';
import { useAuth } from '../../../../../../hooks/auth';
import { useToast } from '../../../../../../hooks/toast';
import {
  HeaderLista,
  BodyLista,
  ItemLista,
  Legenda,
  ContainerEsquerdoHeader,
  ContainerDireitoHeader,
  ContainerStatus,
  ContainerButton,
} from './styles';

import 'react-confirm-alert/src/react-confirm-alert.css';
import { erro } from '../../../../../../config/Colors/Ligth';
import BotaoComConfirmacao from '../../../../../../components/Botoes/BotaoComConfirmacao';
import ContainerConvite from '../../../../../../components/Botoes/BotaoComConfirmacaoConvite';
import BotaoEnviar from '../../../../../../components/Botoes/BotaoEnviarConvite';
import Can from '../../../../../../components/Can';

interface IUsuario {
  id: number;
  nome: string;
  email: string;
  status_acesso: string;
  ativo: boolean;
  full_access?: boolean;
  verificado?: boolean;
  roles: {
    id?: number;
    nome: string;
    empresa_id: number;
  }[];
}

interface IEmpresa {
  id: number;
  nome: string;
}


interface IAccount {
  account_id?: number;
  usuarios: IUsuario[];
  empresas: IEmpresa[];
  setUsuarios: (usuarios: IUsuario[]) => void;
  total_usuarios_contratados: number | undefined;
}

const Usuarios: React.FC<IAccount> = ({
  account_id,
  usuarios,
  empresas,
  setUsuarios,
  total_usuarios_contratados,
}) => {
  const { company, companies } = useAuth();
  const user_role = companies && company ? companies[company.id].role : '';
  const is_eget_admin = user_role === 'eget-admin';
  const { addToast } = useToast();
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => { mounted.current = false; }; // ... and to false on unmount
  }, []);


  async function handleDelete(
    empresa_id: number,
    user_id: number,
  ): Promise<void> {
    const response = is_eget_admin
      ? await api.post(`/admin/user-remove-permissao-empresa`, {
        empresa_id,
        user_id,
      })
      : await api.post(`/user-remove-permissao-empresa`, {
        empresa_id,
        user_id,
      });

    const find_index = usuarios.findIndex(usuario => usuario.id === user_id);
    const copia_usuarios = [...usuarios];

    const new_roles = usuarios[find_index].roles.filter(
      role => Number(role.empresa_id) !== Number(empresa_id),
    );

    copia_usuarios[find_index] = {
      ...copia_usuarios[find_index],
      ativo: response.data.ativo,
      roles: new_roles,
    };

    setUsuarios(copia_usuarios);
  }

  async function handleClick(
    empresa_id: number,
    user_id: number,
  ): Promise<void> {
    const find_index = usuarios.findIndex(usuario => usuario.id === user_id);
    const copia_usuarios = [...usuarios];

    const find_usuario_empresa = usuarios[find_index].roles.find(
      role => Number(role.empresa_id) === Number(empresa_id),
    );
    if (!find_usuario_empresa) {
      try {
        is_eget_admin
          ? await api.post(`/admin/user-adiciona-permissao-empresa`, {
            account_id,
            empresa_id,
            user_id,
          })
          : await api.post(`/user-adiciona-permissao-empresa`, {
            empresa_id,
            user_id,
          });
        const copia_roles = [...copia_usuarios[find_index].roles];

        copia_roles.push({ nome: 'customer-user', empresa_id });

        copia_usuarios[find_index] = {
          ...copia_usuarios[find_index],
          ativo:
            copia_roles.length === 1 ? true : copia_usuarios[find_index].ativo,
          roles: copia_roles,
        };
      } catch (err) {
        if (err.response.status === 402) {
          addToast({
            type: 'error',
            title: 'Permissão negada',
            description: 'O limite contratado de usuários foi atingido',
          });
        }
      }
    } else {
      is_eget_admin
        ? await api.post(`/admin/user-altera-permissao-empresa`, {
          account_id,
          empresa_id,
          user_id,
        })
        : await api.post(`/user-altera-permissao-empresa`, {
          empresa_id,
          user_id,
        });
      copia_usuarios[find_index] = {
        ...copia_usuarios[find_index],
        roles: usuarios[find_index].roles.map(role => {
          return Number(role.empresa_id) === Number(empresa_id)
            ? {
              nome:
                role.nome === 'customer-admin'
                  ? 'customer-user'
                  : 'customer-admin',
              empresa_id: role.empresa_id,
            }
            : {
              nome: role.nome,
              empresa_id: role.empresa_id,
            };
        }),
      };
    }

    setUsuarios(copia_usuarios);
  }

  const total_usuarios_ativos = useMemo(() => {
    const soma = usuarios.reduce(
      (accumulator: number, currentValue: IUsuario) => {
        return accumulator + (currentValue.ativo ? 1 : 0);
      },
      0,
    );
    return soma;
  }, [usuarios]);


  async function handleRemoveUsuarioPermenentemente(
    user_id: number,
  ): Promise<void> {

    try {
      await api.delete(`/admin/remove-usuario/${user_id}`);
      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Usuário removido com sucesso',
      });
      setUsuarios(usuarios.filter(item => {
        return item.id !== user_id
      }));

    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro.',
        description: 'Ocorreu um erro ao remover o usuário!',
      });
    }

  }
  const handleEnviarConvite = useCallback(
    async (email: string) => {

      try {

        const password = Math.random().toString(36).slice(-8);

        await api.post(
          `/auth/create`, {
          params: {
            email,
            password
          }
        }
        );
        if (mounted.current) {
          addToast({
            type: 'success',
            title: 'Sucesso',
            description: 'Email enviado com sucesso!',
          });
        }

      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro.',
          description: 'Ocorreu um erro ao enviar email ao usuário!',
        });
      }
    },
    []
  );

  return (
    <>
      <HeaderLista>
        <ContainerEsquerdoHeader>
          <Legenda>
            <div>
              <Chip
                variant="default"
                color="default"
                label="Inativo"
                avatar={<Avatar>I</Avatar>}
              />
            </div>
            <div>
              <Chip
                variant="default"
                color="primary"
                label="Vendedor"
                avatar={<Avatar>V</Avatar>}
              />
            </div>
            <div>
              <Chip
                variant="default"
                color="secondary"
                label="Admin"
                avatar={<Avatar>A</Avatar>}
              />
            </div>
          </Legenda>
          <small>
            Clique sobre o nome da empresa para alterar ou remover as permissões
          </small>
        </ContainerEsquerdoHeader>
        <ContainerDireitoHeader>
          <small>{`Total de usuários contratados: ${total_usuarios_contratados || 0
            }`}</small>
          <small>{`Total de usuários ativos: ${total_usuarios_ativos || 0
            }`}</small>
        </ContainerDireitoHeader>
      </HeaderLista>
      <BodyLista>
        {usuarios.map(usuario => (
          <ItemLista key={usuario.id}>
            <div>
              <div>
                <h1>{usuario.nome}</h1>
                <p>{usuario.email}</p>
                <Can roles={["eget-admin"]}>
                  <BotaoComConfirmacao
                    funcao_id={usuario.id}
                    title="Clique para remover o usuário!"
                    titulo="Remover usuário"
                    mensagem="Deseja realmente remover esse o usuário? Essa operação não poderá ser desfeita."
                    funcao={handleRemoveUsuarioPermenentemente}
                    style={{ padding: 0 }}
                  >
                    <DeleteForever style={{ color: erro }} />
                  </BotaoComConfirmacao>
                </Can>
              </div>
              <ContainerStatus>
                <FlagStatus ativo={usuario.ativo} />
              </ContainerStatus>
            </div>
            <Can roles={["eget-admin"]}>
              <div>
                <small>{usuario.status_acesso}</small>
              </div>
            </Can>
            <div>
              <EmpresasPermissoes
                handleDelete={handleDelete}
                handleClick={handleClick}
                key={usuario.id}
                usuario={usuario}
                empresas={empresas}
              />

              <Can roles={["customer-admin", "eget-admin"]}>
                {
                  !usuario.verificado && usuario.ativo

                    ?

                    <ContainerButton>
                      <ContainerConvite
                        email={usuario.email}
                        title="Clique para enviar email de confirmação para cadastramento!"
                        titulo="Confirmação"
                        mensagem="Deseja cadastrar este usuário? Um email de confirmação será enviado ao email!"
                        funcao={handleEnviarConvite}
                        style={{ padding: 0 }}
                      >
                        <BotaoEnviar>Enviar Convite</BotaoEnviar>
                      </ContainerConvite>
                    </ContainerButton>
                    :
                    null

                }
              </Can>
            </div>
          </ItemLista>
        ))}
      </BodyLista>
    </>
  );
};

export default Usuarios;
