import React from 'react';
import { Chip } from '@material-ui/core';
import { Container } from './styles';

interface IFiltro {
  gratuito: boolean;
  bs_teste: boolean;
  bs_producao: boolean;
}

interface IEtapa {
  filtro: IFiltro;
  setFiltro: (filtro: IFiltro) => void;
}

const FiltroHorizontalCheck: React.FC<IEtapa> = ({ filtro, setFiltro }) => {
  return (
    <>
      <Container>
        <h2>Perfil:</h2>
        <Chip
          variant="default"
          color="default"
          style={{
            textDecoration: filtro.gratuito ? 'none' : 'line-through',
          }}
          size="small"
          onClick={() => setFiltro({ ...filtro, gratuito: !filtro.gratuito })}
          label="Gratuito"
        />
        <Chip
          variant="default"
          color="default"
          size="small"
          style={{
            textDecoration: filtro.bs_teste ? 'none' : 'line-through',
          }}
          onClick={() => setFiltro({ ...filtro, bs_teste: !filtro.bs_teste })}
          label="BS - Teste"
        />
        <Chip
          variant="default"
          onClick={() =>
            setFiltro({ ...filtro, bs_producao: !filtro.bs_producao })
          }
          color="default"
          style={{
            textDecoration: filtro.bs_producao ? 'none' : 'line-through',
          }}
          size="small"
          label="BS - Produção"
        />
      </Container>
    </>
  );
};

export default FiltroHorizontalCheck;
