import styled, { createGlobalStyle } from 'styled-components';
import { shade } from 'polished';
import * as colors from '../config/Colors/Ligth';

interface SeparadorProps {
  ativo?: boolean;
}

export default createGlobalStyle`
*{
  font-size: 14px;
  @media only screen and (max-width: 1200px) {
    font-size: 13px;
}
  @media only screen and (max-width: 992px) {
    font-size: 12px;
}
  @media only screen and (max-width: 768px) {
    font-size: 11px;
}

  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  font-weight: 500;
  }

  body{
    height: 100%;
  }

  h1,h2,h3,h4,h5,h6,strong,a,button, mark{
    font-weight: 600;
  }

  html, border-style, #root{
    height: 100%;
    background: ${colors.backgroudColor};
  }

.gray{
  background-color:gray;
}

a {
  text-decoration: none;
  color: #4F4F4F;
}

ul{
  list-style: none;
}

input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;

}
input[type=number] {
   -moz-appearance: textfield;
   appearance: textfield;

}

.MuiListItemIcon-root{
    color: ${colors.darkFontColor};
}

//CONFIGURAÇÃO DAS FONTES DOS GRÁFICOS
.recharts-legend-item-text, .recharts-cartesian-axis-tick-value tspan{
  font-size:9px;
  color: ${colors.ligthFontColor};
}

//CONFIGURAÇÃO DAS FONTES DO TIPOGRAPHY
.MuiTypography-body1, .MuiListItemIcon-root{
  font-weight: 600;
  color: ${colors.ligthFontColor};
  line-height: 2;
}

.MuiAppBar-colorPrimary {
  color: #fff;
  background-color: #fafafa;
}



/*.css-1pahdxg-control{
  border-color: red !important;
  box-shadow: 0 0 0 1px green !important;
  &:hover{
    color: yellow;
  }
}*/

.bloqueado{
    background: ${shade(0.2, '#f3d078')};
}

.visible {
  display: block !important;
}

.invisible {
  display: none !important;
}

#react-confirm-alert{
  position: absolute;
  z-index: 9999;
}


.react-confirm-alert-body {
  color: ${colors.darkFontColor};
}

.react-confirm-alert-button-group > button:first-child {
	background: #f3d078;
	background: -webkit-linear-gradient(top,#f7dfa5,#f0c14b);
	background: linear-gradient(to bottom,#f7dfa5,#f0c14b);
  color: ${colors.darkFontColor};
}
.react-confirm-alert-button-group > button:last-child {
  background-color: ${colors.menuLateralBackgroundColor};
  color: white;
}

input:invalid {
  box-shadow: none !important;
}
`;

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1100px;
  background-color: ${colors.formBackgroundColor};
  min-height: 70vh;
  margin: 80px auto;
  @media only screen and (max-width: 992px) {
    .MuiStepper-root {
      padding: 0;
    }
  }
  padding: 3%;
  border-radius: 2px;
  overflow-x: hidden;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 4px -2px #989898;
  -moz-box-shadow: 0 4px 4px -2px #989898;
  -webkit-box-shadow: 0 4px 4px -2px #989898;
`;

export const ContainerFull = styled.section`
  background-color: ${colors.backgroudColor};
  margin: 50px auto;
  right: 20px;
  padding: 3%;
  border-radius: 4px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  overflow-x: hidden;
`;

export const Separador = styled.div<SeparadorProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-items: baseline;
  margin: 15px 0;
  @media screen and (max-width: 1000px) {
    margin-bottom: 0;
  }

  border-bottom: 1px solid #fafafa;

  background-image: linear-gradient(
    to bottom,
    #fefefe,
    #fdfdfd,
    #fcfcfc,
    #fbfbfb,
    #fafafa
  );

  h2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-weight: 700;
    margin-left: 5px;
    font-size: 1.2rem;
    color: ${colors.titleFontColor};

    ${props =>
    props.ativo &&
    `
      background-color: ${colors.backgroudColor};
      padding: 2px 4px;
      border-radius: 4px;
      text-decoration: underline;
  `}

    svg {
      font-size: 1.6rem;
      @media screen and (max-width: 1000px) {
        font-size: 1.2rem;
      }
    }
  }
`;
