import React from 'react';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { InputAdornment } from '@material-ui/core';
import { FcCalendar } from 'react-icons/fc';
import locale from 'date-fns/locale/pt-BR';
import BotaoDefault from '../../Botoes/BotaoDefault';

interface DateTimeProps {
  format:string;
  name: string;
  titulo?: string;
  label: string;
  value: MaterialUiPickersDate;
  setValue: (value: MaterialUiPickersDate) => void;
}

const DateTime: React.FC<DateTimeProps> = ({
  format,
  name,
  label,
  value,
  setValue,
  ...rest
}) => {

  return (
    <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
    <label style={{fontSize: '10px', color: '#446275'}} htmlFor={name}>
     {label}
      <DateTimePicker
        {...rest}
        ampm={false}
        clearable
        inputVariant="outlined"
        size="small"
        style={{ width: '100%', marginRight: '5px' }}
        value={value}
        onChange={(date: MaterialUiPickersDate) => setValue(date)}
        format={format}
        clearLabel="Limpar"
        cancelLabel="Cancelar"
        todayLabel="Hoje"
        placeholder="dd/mm/aaaa hh:mm"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <BotaoDefault>
                <FcCalendar size={20} />
              </BotaoDefault>
            </InputAdornment>
          ),
        }}
      />
      </label>
    </MuiPickersUtilsProvider>
  );
};

export default DateTime;
