import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';
import LoginBackground from '../../../assets/login-background.jpg';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 700px;
`;

const appearFromleft = keyframes`
from{
  opacity: 0;
  transform: translateX(-100px);
}
to{
  opacity: 1;
  transform: translateX(0);
}
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 450px;
  width: 100%;
  justify-content: center;
  align-items: center;

  animation: ${appearFromleft} 1s;

  form {
    margin: 40px 0;
    max-width: 500px;
    width: 90%;
    text-align: center;
    h1 {
      margin-bottom: 24px;
      font-size: 18px;
    }

    a {
      color: #4f4f4f;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;
      &:hover {
        color: ${shade(0.2, '#f4f4f4')};
      }
    }
    h1 {
      font-size: 3rem;
      font-weight: 700;
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${LoginBackground}) no-repeat center;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray; /* IE */
  background-size: cover;
`;
