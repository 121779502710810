import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { useField } from '@unform/core';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';
import { Container, Prefixo } from './styles';
import BotaoDefault from '../../Botoes/BotaoDefault';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  prefixo?: string;
  isDisabled?: boolean;
  showPasswordIcon?: boolean;
  icon?: React.ComponentType<IconBaseProps>;
}

const Input: React.FC<InputProps> = ({
  name,
  label,
  prefixo,
  icon: Icon,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleVisible = useCallback(() => {
    if (inputRef.current?.type) {
      setShowPassword(!showPassword);
      inputRef.current.type === 'password'
        ? (inputRef.current.type = 'text')
        : (inputRef.current.type = 'password');
    }
  }, [showPassword]);

  return (
    <Container
      style={rest.style}
      isErrored={!!error}
      isDisabled={rest.disabled}
      isFilled={isFilled}
      isFocused={isFocused}
    >
      <label htmlFor={name}>
        {label}
        <div>
          {Icon && <Icon size={20} />}
          {prefixo && <Prefixo>{prefixo}</Prefixo>}
          <input
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            formNoValidate
            defaultValue={defaultValue}
            ref={inputRef}
            {...rest}
          />
          {rest.showPasswordIcon && (
            <BotaoDefault type="button" onClick={handleVisible}>
              {!showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </BotaoDefault>
          )}
        </div>
        {error && <span>{error}</span>}
      </label>
    </Container>
  );
};

export default Input;
