import styled from 'styled-components';
import * as colors from '../../../config/Colors/Ligth';

export const Lista = styled.ul`
  color: ${colors.darkFontColor};
  display: flex;
  border: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  h2 {
    font-size: 20px;
    padding-bottom: 10px;
  }

  li {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #eee;
    background: white;
    padding-right: 10px;
    min-height: 30px;
    &:nth-child(odd) {
      background: ${colors.formBackgroundColor};
    }

    &:last-child {
      border-bottom: none;
      width: 100%;
    }
    div {
      p {
        font-size: 12px;
        font-weight: 600;
        color: ${colors.ligthFontColor};
      }

      width: 12%;
      button {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0;
        text-align: left;
      }
      &:first-child {
        flex: 1;
        margin-right: 8px;
      }
      &:last-child {
        width: 10px;
      }
      @media screen and (max-width: 1100px) {
        p {
          font-size: 10px;
        }
      }
      @media screen and (max-width: 962px) {
        p {
          font-size: 9px;
        }
        strong {
          font-size: 9px;
        }
      }
      @media screen and (max-width: 768px) {
        p {
          font-size: 7px;
        }
      }
      img {
        width: 25px;
      }
      button {
        color: ${colors.ligthFontColor};
      }
    }
  }
  .danger {
    color: ${colors.ligthFontColor};
    &:hover {
      color: ${colors.erro};
    }
  }
`;
