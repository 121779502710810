import React, { useMemo, useState, useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { BsSearch } from 'react-icons/bs';
import { RiFilterFill, RiFilterOffFill } from 'react-icons/ri';
import BotaoDefault from '../Botoes/BotaoDefault';
import { Container } from './styles';
import Input from '../Inputs/ControlledInput';

interface IFiltro {
  mostra_filtros: boolean;
  setMostraFiltros: (mostra_filtros: boolean) => void;
}

interface ILoading {
  placeholder: string;
  pesquisa: string;
  setPesquisa: (search: string) => void;
  setPage: (page: number) => void;
  filtros?: IFiltro;
}

const Pesquisa: React.FC<ILoading> = ({
  placeholder,
  pesquisa,
  setPesquisa,
  setPage,
  filtros,
}) => {
  const [pesquisa_temp, setPesquisaTemp] = useState(pesquisa);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setPage(1);
      setPesquisa(pesquisa_temp);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [pesquisa_temp, setPesquisa, setPage]);

  const limpa_busca = useMemo(() => {
    return pesquisa_temp ? (
      <BotaoDefault onClick={() => setPesquisaTemp('')}>
        <AiOutlineClose size={20} />
      </BotaoDefault>
    ) : (
      <></>
    );
  }, [pesquisa_temp]);

  const filtro_exibido = useMemo(() => {
    if (!filtros) return <></>;
    const { mostra_filtros, setMostraFiltros } = filtros;
    return mostra_filtros ? (
      <BotaoDefault onClick={() => setMostraFiltros(!mostra_filtros)}>
        <RiFilterOffFill size={25} />
      </BotaoDefault>
    ) : (
      <BotaoDefault onClick={() => setMostraFiltros(!mostra_filtros)}>
        <RiFilterFill size={25} />
      </BotaoDefault>
    );
  }, [filtros]);

  return (
    <Container>
      <Input
        style={{ margin: '0' }}
        name="pesquisa"
        value={pesquisa_temp}
        placeholder={placeholder}
        onChange={e => setPesquisaTemp(e.target.value)}
        icon={BsSearch}
        limpa_input={limpa_busca}
      />
      {filtros && filtro_exibido}
    </Container>
  );
};

export default Pesquisa;
