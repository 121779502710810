import mask from './masks';

interface INomeCliente {
  nome: string;
  apelido: string;
  cpf_cnpj: string;
}

export default function NomeClienteConfig(
  cliente: INomeCliente,
  configuracao: number,
): string {
  const tipo = cliente.cpf_cnpj?.length === 11 ? 'cpf' : 'cnpj';

  const nome_clean = cliente.nome ? `${cliente.nome} - ` : '';
  const apelido_clean = cliente.apelido ? `${cliente.apelido} - ` : '';
  const cpf_cnpj_clean = cliente.cpf_cnpj
    ? mask({
      input: cliente.cpf_cnpj,
      tipo,
    })
    : '';
  switch (configuracao) {
    case 0:
      return cliente.nome || '';
    case 1:
      return nome_clean + cpf_cnpj_clean;
    case 2:
      return cliente.apelido || '';
    case 3:
      return apelido_clean + cpf_cnpj_clean;
    case 4:
      return `${nome_clean} ${(cliente.apelido || '')}`;
    case 5:
      return nome_clean + apelido_clean + cpf_cnpj_clean;
    default:
      return cliente.nome || '';
  }
}
