import React, { useMemo } from 'react';

import { Lista } from './styled';
import formataValor from '../../../utils/formataValor';
import formataNumero from '../../../utils/formataNumero';
import StatusSincronizacaoItens from '../../../components/Sincronizacao/MostraStatusSincronizacaoItens';
// import sem_imagem from '../../../assets/sem_imagem.jpg';
import IPedidoItem from '../../../interfaces/pedidoItem';
import ModalPesquisaProduto from "../Cadastro/ModalPesquisaProduto";

interface IListaProdutosProps {
  cliente_id: number;
  prazo_id: number;
  pedido_id: number;
  status_id: number;
  pedido_item: IPedidoItem[];
  saldo_credito: number;
  bloqueia_clique: boolean;
  setPedidoItem: (pedido_item: IPedidoItem[]) => void;
}

const ListaProdutosPedido: React.FC<IListaProdutosProps> = ({
  cliente_id,
  prazo_id,
  pedido_id,
  status_id,
  saldo_credito,
  pedido_item,
  bloqueia_clique,
  setPedidoItem,
  ...props
}) => {
  const total = useMemo((): number => {
    const soma = pedido_item.reduce(
      (accumulator: number, currentValue: IPedidoItem) => {
        return Number(accumulator) + Number(currentValue.valor_liquido);
      },
      0,
    );
    return soma;
  }, [pedido_item]);

  return (
    <>
      {pedido_item?.length > 0 && (
        <Lista {...props}>
          <li>
            {/* }   <div>&nbsp;</div>{ */}
            <div>
              <strong>Produto</strong>
            </div>
            <div>
              <strong>Qtde</strong>
            </div>
            <div>
              <strong>Preço</strong>
            </div>
            <div>
              <strong>Desc</strong>
            </div>
            <div>
              <strong>Valor</strong>
            </div>
            <div>
              <strong>#</strong>
            </div>
          </li>
          {pedido_item.map(
            (item) =>
              !item.deleted_at && (
                <li key={item.produto_id}>
                  {/* }   <div>
                    <img
                      alt={item.nome}
                      src={item.imagem ? item.imagem : sem_imagem}
                    />
              </div>{ */}

                  <>
                    <div>
                      {bloqueia_clique ? (
                        <p>{`${item.codigo} - ${item.nome}`}</p>
                      ) : (
                          <ModalPesquisaProduto
                            cliente_id={cliente_id}
                            prazo_id={prazo_id}
                            pedido_id={pedido_id}
                            setPedidoItem={setPedidoItem}
                            status_id={status_id}
                            pedido_item={pedido_item}
                            saldo_credito={saldo_credito}
                            carregar_pesquisa={item.nome}
                            texto_botao={<p>{`${item.codigo} - ${item.nome}`}</p>}
                          />
                        )}
                    </div>
                    <div>
                      <p>
                        {`${formataNumero(`${item.quantidade}`, 3, true)} ${item.unidade
                          }`}
                      </p>
                    </div>
                    <div>
                      <p>
                        {formataNumero(
                          `${item.valor_bruto / Number(item.quantidade)}`,
                          4,
                          true,
                        )}
                      </p>
                    </div>
                    <div>
                      <p>{formataNumero(`${item.valor_desconto}`, 2, true)}</p>
                    </div>
                    <div>
                      <p>{formataNumero(`${item.valor_liquido}`, 4, true)}</p>
                    </div>
                    <div>
                      <StatusSincronizacaoItens
                        tipo="item"
                        id={Number(item.id)}
                        cod_retorno_integracao={item.cod_retorno_integracao}
                        motivo_integracao={item.motivo_integracao}
                      />
                    </div>
                  </>
                </li>
              ),
          )}
          <li>
            <strong>
              Total:
              {formataValor(`${total}`)}
            </strong>
          </li>
        </Lista>
      )}
    </>
  );
};

export default ListaProdutosPedido;
