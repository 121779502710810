import React, { useMemo, useState, useEffect } from 'react';
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai';
import { BsTrash } from 'react-icons/bs';
import { BiBlock } from 'react-icons/bi';
import { MdEdit } from 'react-icons/md';
import {
  Container,
  Precos,
  ContainerInput,
  ContainerGrupoProduto,
} from './styles';
import BotaoDefault from '../../../../../../components/Botoes/BotaoDefault';
import formataNumero from '../../../../../../utils/formataNumero';
import DialogDesconto from './DialogDesconto';
import DialogValorUnitario from './DialogValorUnitario';
import upperFirstLetter from '../../../../../../utils/UppercaseOnlyFirstLetter';
import IPedidoItem from '../../../../../../interfaces/pedidoItem';
import validaNumero from '../../../../../../utils/validaNumero';
import Can from "../../../../../../components/Can";

interface IProdutoPedido {
  produto: IPedidoItem;
  status_id: number;
  handleAumentar: (codigo: number) => void;
  handleDiminuir: (codigo: number) => void;
  handleDesconto: (codigo: number, valor: number) => void;
  handleValorUnitario: (codigo: number, valor: number) => void;
  handleQuantidade: (codigo: number, valor: string) => void;
  bloquear_alteracao_preco: boolean;
}

const CardProduto: React.FC<IProdutoPedido> = ({
  produto,
  handleAumentar,
  handleDiminuir,
  handleDesconto,
  handleValorUnitario,
  handleQuantidade,
  status_id,
  bloquear_alteracao_preco,
}) => {
  const {
    codigo,
    estoque,
    nome,
    quantidade,
    unidade,
    valor_bruto,
    valor_liquido,
    valor_desconto,
    grupo_produto_nome,
    valor_original_produto,
    valor_produto,
  } = produto;

  const [desconto, setDesconto] = useState(valor_desconto);
  const [valor_unitario, setValorUnitario] = useState(valor_produto);

  const estoque_negativo = useMemo(() => {
    return estoque <= 0;
  }, [estoque]);

  const produto_alocado = useMemo(() => {
    return quantidade > 0;
  }, [quantidade]);

  useEffect(() => {
    setDesconto(valor_desconto);
  }, [valor_desconto]);

  const preco_final_exibido = useMemo(() => {
    if (Number(quantidade) === 0) return <></>;
    if (desconto > 0) {
      return (
        <>
          <DialogDesconto
            texto_botao={
              <>
                <p style={{ textDecoration: 'line-through' }}>
                  <MdEdit size={16} />
                  {`R$ ${formataNumero(valor_bruto, 4, true)}`}
                </p>

                <h1>{`R$ ${formataNumero(valor_liquido, 4, true)}`}</h1>
              </>
            }
            titulo={`${nome}`}
            valor_bruto={valor_bruto}
            desconto={desconto}
            setDesconto={setDesconto}
            codigo={codigo}
            handleSalvarDesconto={handleDesconto}
          />
        </>
      );
    }
    return (
      <>
        <DialogDesconto
          texto_botao={
            <h1>
              <MdEdit size={16} />
              {`R$ ${formataNumero(valor_bruto, 4, true)}`}
            </h1>
          }
          titulo={`${nome}`}
          valor_bruto={valor_bruto}
          desconto={desconto}
          setDesconto={setDesconto}
          codigo={codigo}
          handleSalvarDesconto={handleDesconto}
        />
      </>
    );
  }, [
    quantidade,
    valor_bruto,
    codigo,
    desconto,
    handleDesconto,
    nome,
    valor_liquido,
  ]);

  const preco_unitario_exibido = useMemo(() => {
    if (Number(valor_unitario) !== Number(valor_original_produto)) {
      return (

        bloquear_alteracao_preco ?
          <Can
            roles={["eget-admin"]}
            retorno_else={<h1>
              {`R$ ${formataNumero(valor_original_produto, 4, true)}`}
            </h1>}
          >
            <DialogValorUnitario
              texto_botao={
                <>
                  <p style={{ textDecoration: 'line-through' }}>
                    {`R$ ${formataNumero(valor_original_produto, 4, true)}`}
                  </p>

                  <h1>&nbsp;{`R$ ${formataNumero(valor_unitario, 4, true)}`}</h1>
                  <MdEdit size={16} />
                </>
              }
              titulo={`${nome}`}
              valor_unitario={valor_unitario}
              valor_original_produto={valor_original_produto}
              setValorUnitario={setValorUnitario}
              codigo={codigo}
              handleSalvarValorUnitario={handleValorUnitario}
            />
          </Can> :
          <DialogValorUnitario
            texto_botao={
              <>
                <p style={{ textDecoration: 'line-through' }}>
                  {`R$ ${formataNumero(valor_original_produto, 4, true)}`}
                </p>

                <h1>&nbsp;{`R$ ${formataNumero(valor_unitario, 4, true)}`}</h1>
                <MdEdit size={16} />
              </>
            }
            titulo={`${nome}`}
            valor_unitario={valor_unitario}
            valor_original_produto={valor_original_produto}
            setValorUnitario={setValorUnitario}
            codigo={codigo}
            handleSalvarValorUnitario={handleValorUnitario}
          />
      );
    }
    return (

      bloquear_alteracao_preco ?
        <Can
          roles={["customer-admin"]}
          retorno_else={<h1>
            {`R$ ${formataNumero(valor_original_produto, 4, true)}`}
          </h1>}
        >
          <DialogValorUnitario
            texto_botao={
              <h1>
                {`R$ ${formataNumero(valor_original_produto, 4, true)}`}
                <MdEdit size={16} />
              </h1>
            }
            titulo={`${nome}`}
            valor_unitario={valor_unitario}
            valor_original_produto={valor_original_produto}
            setValorUnitario={setValorUnitario}
            codigo={codigo}
            handleSalvarValorUnitario={handleValorUnitario}
          />
        </Can> :
        <DialogValorUnitario
          texto_botao={
            <h1>
              {`R$ ${formataNumero(valor_original_produto, 4, true)}`}
              <MdEdit size={16} />
            </h1>
          }
          titulo={`${nome}`}
          valor_unitario={valor_unitario}
          valor_original_produto={valor_original_produto}
          setValorUnitario={setValorUnitario}
          codigo={codigo}
          handleSalvarValorUnitario={handleValorUnitario}
        />

    );
  }, [codigo, valor_unitario, valor_original_produto, handleValorUnitario, nome, bloquear_alteracao_preco]);

  const icone_remover = useMemo(() => {
    if (status_id > 2) {
      return <BiBlock title="Você não pode realizar essa operação" size={20} />;
    }
    if (quantidade === 0) {
      return <BiBlock title="Você não pode realizar essa operação" size={20} />;
    }

    if (Number(quantidade) === 1) {
      if (status_id < 2) {
        return (
          <BsTrash title="Clique para remover esse item do pedido." size={20} />
        );
      }
      return <BiBlock title="Você não pode realizar essa operação" size={20} />;
    }
    return (
      <AiOutlineMinusCircle
        title="Clique para diminuir a quantidade no pedido"
        size={20}
      />
    );
  }, [quantidade, status_id]);

  const icone_aumentar = useMemo(() => {
    if (status_id > 2) {
      return <BiBlock title="Você não pode realizar essa operação" size={20} />;
    }

    return (
      <AiOutlinePlusCircle
        title="Clique para aumentar a quantidade no pedido"
        size={20}
      />
    );
  }, [status_id]);

  const botao_excluir = useMemo(() => {
    if (status_id < 2 && quantidade > 0) {
      return (
        <BotaoDefault onClick={() => handleQuantidade(codigo, '0')}>
          <BsTrash title="Clique para remover esse item do pedido." size={20} />
        </BotaoDefault>
      );
    }
    return '';
  }, [status_id, quantidade, codigo, handleQuantidade]);

  function handleInput(e) {
    let quantidade_clean = e.target.value.replace('/[^0-9.,]/g', '');
    quantidade_clean = validaNumero(quantidade_clean, 4);
    handleQuantidade(codigo, quantidade_clean);
  }

  function handleClick(e) {
    e.target.select();
  };

  return (
    <Container
      key={codigo}
      estoque_negativo={estoque_negativo}
      alocado={produto_alocado}
    >
      <div>
        <h1>{`${codigo} - ${nome}`}</h1>
        {botao_excluir}
      </div>
      <ContainerGrupoProduto>
        <p>{grupo_produto_nome}</p>
      </ContainerGrupoProduto>
      <div>
        <div>
          <Precos>{preco_unitario_exibido}</Precos>
          <small>
            {`Estoque: ${formataNumero(estoque, 3, true)} ${upperFirstLetter(
              unidade,
            )}`}
          </small>

          <ContainerInput>
            <BotaoDefault onClick={() => handleDiminuir(codigo)}>
              {icone_remover}
            </BotaoDefault>
            <input type="number" onClick={handleClick} onChange={handleInput} value={quantidade} />
            <span>{upperFirstLetter(unidade)}</span>
            <BotaoDefault onClick={() => handleAumentar(codigo)}>
              {icone_aumentar}
            </BotaoDefault>
          </ContainerInput>
        </div>
        <div>
          <Precos>{preco_final_exibido}</Precos>
        </div>
      </div>
    </Container>
  );
};

export default CardProduto;
