import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiLock, FiMail } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import getValidationErrors from '../../utils/getValidationErrors';
import Loading from '../../components/Loading';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import Input from '../../components/Inputs/Input';
import BotaoSalvar from '../../components/Botoes/BotaoSalvar';
import { HeaderCadastro, BodyCadastro, FooterCadastro } from './styles';
import { Container } from '../../styles/GlobalStyles';
import { useAuth } from '../../hooks/auth';

interface MeusDadosFormData {
  email: string;
  novo_email: string;
  password: string;
  novo_password: string;
  novo_password_confirmation: string;
}

const MeusDados: React.FC = () => {
  const history = useHistory();
  const [loading_message, setLoadingMessage] = useState('');
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { user, updateUser } = useAuth();
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => { mounted.current = false; }; // ... and to false on unmount
  }, []);
  

  const handleSubmit = useCallback(
    async (data: MeusDadosFormData) => {
      try {
        setLoadingMessage('Salvando...');
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          novo_email: Yup.string()
            .required('O e-mail é obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().required('Informe a senha atual'),

          novo_password: Yup.string().when(
            'trocar',
            (trocar: boolean, schem) => {
              return (
                !trocar &&
                schem
                  .min(8, 'A senha deve ter no mínimo oito dígitos')
                  .notOneOf(
                    [Yup.ref('password'), undefined],
                    'A senha não pode ser igual a senha anterior',
                  )
              );
            },
          ),
          novo_password_confirmation: Yup.string().when(
            'trocar',
            (trocar: boolean, schem) => {
              return (
                !trocar &&
                schem.oneOf(
                  [Yup.ref('novo_password'), undefined],
                  'A nova senha deve ser igual a confirmação de senha',
                )
              );
            },
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await api.post('/auth/meus-dados', data);
        if(mounted.current){

          updateUser(response.data.user);

          history.push('/dashboard');

          addToast({
            type: 'success',
            title: 'Sucesso!',
            description: 'Seu cadastro foi atualizado com sucesso.',
          });
          
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na cadastro',
          description: err.response.data.error,
        });
      } finally {
        setLoadingMessage('');
      }
    },
    [addToast, history, updateUser],
  );
  return (
    <Container>
      <Loading isLoading={!!loading_message} />
      <Form
        ref={formRef}
        initialData={{ email: user.email, novo_email: user.email }}
        onSubmit={handleSubmit}
      >
        <HeaderCadastro>
          <div>
            <h1>{user.nome}</h1>
          </div>
        </HeaderCadastro>
        <BodyCadastro>
          <Input style={{ display: 'none' }} type="hidden" name="email" />
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <Input
              label="E-mail"
              type="email"
              name="novo_email"
              disabled
              icon={FiMail}
              placeholder="E-mail"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Input
              label="Senha atual"
              name="password"
              maxLength={255}
              icon={FiLock}
              type="password"
              placeholder="Informe sua senha atual"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Input
              label="Nova senha"
              name="novo_password"
              maxLength={255}
              icon={FiLock}
              type="password"
              placeholder="Informe a nova senha"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Input
              label="Confirme a nova senha"
              name="novo_password_confirmation"
              maxLength={255}
              icon={FiLock}
              type="password"
              placeholder="Repita a nova senha"
            />
          </Grid>
        </BodyCadastro>
        <FooterCadastro>
          <BotaoSalvar loading={loading_message} type="submit">
            Salvar
          </BotaoSalvar>
        </FooterCadastro>
      </Form>
    </Container>
  );
};

export default MeusDados;
