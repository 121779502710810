import styled from 'styled-components';
import * as colors from '../../../config/Colors/Ligth';

export const HeaderCadastro = styled.div`
  display: flex;
  flex-direction: column;
  top: 0;
  height: 60px;
  border-bottom: 1px solid #eee;

  span {
    color: ${colors.darkFontColor};
  }
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    h1 {
      font-size: 1.4rem;
      font-weight: bold;
      color: ${colors.titleFontColor};
    }
  }
  div + div {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-end;
    span {
      text-align: left;
      font-size: 10px;
    }
  }
`;

export const BodyCadastro = styled.div`
  padding: 20px 0 30px 0;
  border-bottom: 1px solid #eee;
  background-color: 'orange';
  display: flex;
  flex-direction: column;
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  padding: 5px;
  border: 5px solid #FFF;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 7px;
  width: 70%;
  align-items: center;

`;

export const TextInfo = styled.span`

`;

export const TextEvento = styled.span`
  text-transform: uppercase;
  font-size: 16px;
  color:${colors.darkFontColor};
  font-weight: bold;
`;



export const FooterCadastro = styled.div`
  margin-top: 140px;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Voltar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: -10px;
  a {
    color: ${colors.ligthFontColor};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    p {
      text-transform: uppercase;
    }
    &:hover {
      color: ${colors.titleFontColor};
    }
  }
`;

export const Inline = styled.section`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;
