import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import { Grid } from '@material-ui/core';
import BotaoDefault from '../../../../../../../components/Botoes/BotaoDefault';
import BotaoSalvar from '../../../../../../../components/Botoes/BotaoSalvar';
import formataNumero from '../../../../../../../utils/formataNumero';
import Input from '../../../../../../../components/Inputs/ControlledInput';
import { Botao, Container, Header, Footer, Body } from './styles';
import validaNumero from '../../../../../../../utils/validaNumero';

interface IPropsDialog {
  titulo: string;
  valor_original_produto: number;
  valor_unitario: number;
  setValorUnitario: (valor_unitario: number) => void;
  handleSalvarValorUnitario: (codigo: number, valor: number) => void;
  codigo: number;
  texto_botao: React.ReactNode;
}

const DialogValorUnitario: React.FC<IPropsDialog> = ({
  titulo,
  valor_original_produto,
  valor_unitario,
  setValorUnitario,
  handleSalvarValorUnitario,
  codigo,
  texto_botao,
}) => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullWidth = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = (): void => {
    setOpen(true);
  };
  const handleClose = (): void => {
    setOpen(false);
  };

  const handleValorUnitario = (e): void => {
    let valor_clean = e.target.value.replace('/[^0-9.,]/g', '');
    valor_clean = validaNumero(valor_clean, 4);
    setValorUnitario(valor_clean);
  };

  const handleAdicionarValorUnitario = (): void => {
    handleSalvarValorUnitario(codigo, valor_unitario);

    handleClose();
  };

  function handleClick(e) {
    e.target.select();
  };

  return (
    <>
      <Botao
        title="Clique para alterar o valor do item."
        onClick={handleClickOpen}
      >
        {texto_botao}
      </Botao>
      <Container
        fullWidth={fullWidth}
        onClose={handleAdicionarValorUnitario}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <Header>
          <p />
          <h1>{titulo}</h1>
          {handleAdicionarValorUnitario ? (
            <BotaoDefault onClick={handleAdicionarValorUnitario}>
              <CloseIcon />
            </BotaoDefault>
          ) : null}
        </Header>
        <Body>
          <Grid item xs={8} sm={8} md={6} lg={6}>
            <Input
              onClick={handleClick}
              name="valor"
              type="number"
              value={valor_unitario}
              onChange={handleValorUnitario}
              placeholder="Preço unitário"
              label="Preço unitário"
              prefixo="R$"
            />
          </Grid>
          <p>{`Preço original: R$${formataNumero(
            valor_original_produto,
            4,
            true,
          )}`}</p>
          <p>{`Preço editado: R$${formataNumero(valor_unitario, 4, true)}`}</p>
        </Body>
        <Footer>
          <BotaoSalvar onClick={() => handleAdicionarValorUnitario()}>
            Salvar Valor
          </BotaoSalvar>
        </Footer>
      </Container>
    </>
  );
};

export default DialogValorUnitario;
