import ISelectOptions from '../interfaces/selectOptions';

export default function retornaObjeto(
  options: ISelectOptions[],
  value: number | undefined,
): ISelectOptions | undefined {
  if (options && value !== null) {
    const findOption = options.find(option => option.value === value);
    if (findOption) return findOption;
  }
  return undefined;
}
