interface ITipo {
  input: string | undefined;
  tipo: 'cpf' | 'cnpj' | 'fone' | 'cep';
}

function cpfMask(cpf: string): string {
  let maskedCpf = cpf;
  maskedCpf = maskedCpf.replace(/\D/g, '');
  maskedCpf = maskedCpf.replace(/(\d{3})(\d)/, '$1.$2');
  maskedCpf = maskedCpf.replace(/(\d{3})(\d)/, '$1.$2');
  maskedCpf = maskedCpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  return maskedCpf;
}

function cnpjMask(cnpj: string): string {
  let maskedCnpj = cnpj;
  maskedCnpj = maskedCnpj.replace(/\D/g, '');
  maskedCnpj = maskedCnpj.replace(/^(\d{2})(\d)/, '$1.$2');
  maskedCnpj = maskedCnpj.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
  maskedCnpj = maskedCnpj.replace(/\.(\d{3})(\d)/, '.$1/$2');
  maskedCnpj = maskedCnpj.replace(/(\d{4})(\d)/, '$1-$2');
  return maskedCnpj;
}

function cepMask(cep: string): string {
  let maskedCep = cep;
  maskedCep = maskedCep.replace(/\D/g, '');
  maskedCep = maskedCep.replace(/^(\d{2})(\d)/, '$1.$2');
  maskedCep = maskedCep.replace(/\.(\d{3})(\d)/, '.$1-$2');
  return maskedCep;
}

function foneMask(fone: string): string {
  let maskedFone = fone;
  maskedFone = maskedFone.replace(/\D/g, '');
  maskedFone = maskedFone.replace(/^(\d)/, '($1');
  maskedFone = maskedFone.replace(/(.{3})(\d)/, '$1) $2');
  if (maskedFone.length === 9) {
    maskedFone = maskedFone.replace(/(.{1})$/, '-$1');
  } else if (maskedFone.length === 10) {
    maskedFone = maskedFone.replace(/(.{2})$/, '-$1');
  } else if (maskedFone.length === 11) {
    maskedFone = maskedFone.replace(/(.{3})$/, '-$1');
  } else if (maskedFone.length === 12) {
    maskedFone = maskedFone.replace(/(.{5})$/, '-$1');
  } else if (maskedFone.length === 13) {
    maskedFone = maskedFone.replace(/(.{4})$/, '-$1');
  } else if (maskedFone.length > 13) {
    maskedFone = maskedFone.replace(/(.{5})$/, '-$1');
  }
  return maskedFone;
}

export default function mask({ input, tipo }: ITipo): string {
  if (!input) return '';
  switch (tipo) {
    case 'cpf':
      return cpfMask(input);
      break;
    case 'cnpj':
      return cnpjMask(input);
      break;
    case 'cep':
      return cepMask(input);
      break;
    case 'fone':
      return foneMask(input);
      break;
    default:
      return '';
  }
}
