import { styled } from '@mui/system';
import { TabsList } from '@mui/base/TabsList';
import { TabPanel } from '@mui/base/TabPanel';
import { buttonClasses } from '@mui/base/Button';
import { Tab, tabClasses } from '@mui/base/Tab';

const cor = {
  50: '#f4f6f9',
  100: '#EBA821',
  200: '#EBA821',
  300: '#EBA821',
  400: '#EBA821',
  500: '#a9a9a9',
  600: '#EBA821',
  700: '#EBA821',
  800: '#EBA821',
  900: '#EBA821',
};

export const Tabs = styled(Tab)`
  font-family: IBM Plex Sans, sans-serif;
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  margin: 6px 6px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${cor[400]};
  }

  &.${buttonClasses.focusVisible} {
    color: #fff;
    outline: none;
    background-color: ${cor[200]};
  }

  &.${tabClasses.selected} {
    background-color: ${cor[50]};
    color: ${cor[600]};
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
  @media screen and (min-width: 1180px) and (max-width: 1200px) {
    width: 98%;
  }
  @media screen and (min-width: 1150px) and (max-width: 1179px) {
    width: 96%;
  }
  @media screen and (max-width: 1149px) {
    width: 92%;
  }
  @media screen and (max-width: 1109px) {
    width: 90%;
  }
  @media screen and (min-width: 670px) and (max-width: 700px) {
    width: 88%;
  }
  @media screen and (min-width: 635px) and (max-width: 669px) {
    width: 86%;
  }
  @media screen and (min-width: 600px) and (max-width: 634px) {
    width: 84%;
  }
`;

export const TabPanels = styled(TabPanel)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;

  @media screen and (min-width: 1180px) and (max-width: 1200px) {
    width: 98%;
  }
  @media screen and (min-width: 1150px) and (max-width: 1179px) {
    width: 96%;
  }
  @media screen and (max-width: 1149px) {
    width: 92%;
  }
  @media screen and (max-width: 1109px) {
    width: 90%;
  }
  @media screen and (min-width: 670px) and (max-width: 700px) {
    width: 88%;
  }
  @media screen and (min-width: 635px) and (max-width: 669px) {
    width: 86%;
  }
  @media screen and (min-width: 600px) and (max-width: 634px) {
    width: 84%;
  }
`;

export const TabsLists = styled(TabsList)`
  width: 100%;
  background-color: ${cor[500]};
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;

  @media screen and (min-width: 1180px) and (max-width: 1200px) {
    width: 98%;
  }
  @media screen and (min-width: 1150px) and (max-width: 1179px) {
    width: 96%;
  }
  @media screen and (max-width: 1149px) {
    width: 92%;
  }
  @media screen and (max-width: 1109px) {
    width: 90%;
  }
  @media screen and (min-width: 670px) and (max-width: 700px) {
    width: 88%;
  }
  @media screen and (min-width: 635px) and (max-width: 669px) {
    width: 86%;
  }
  @media screen and (min-width: 600px) and (max-width: 634px) {
    width: 84%;
  }
`;
