import { Container } from './styles';

interface IProps {
  name: string
  label: string
  value: string
}

const CheckboxInput: React.FC<IProps> = ({ name, label, value, ...rest }) => {


  return (
    <Container>
      <input
        {...rest}
        name={name}
        value={value}
        type="checkbox"
        id={name}
      />
      <label htmlFor={name} key={name}>
        {label}
      </label>
    </Container>
  );
};
export default CheckboxInput;
