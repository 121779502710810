import styled, { css } from 'styled-components';
import { erro, etapa_concluida } from '../../config/Colors/Ligth';

interface IStatus {
  ativo: boolean;
}

export const Status = styled.span<IStatus>`
  display: flex;
  font-weight: 600;
  padding: 1px 4px;
  border-radius: 8px;
  font-size: 11px;
  background-color: ${etapa_concluida};
  ${props =>
    !props.ativo &&
    css`
      background-color: ${erro};
    `}

  color: white !important;
`;
