import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { version: `web - ${process.env.REACT_APP_VERSION}`}
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (
      error.response.status === 401 /* &&
      error.response.statusText === 'Unauthorized' */
    ) {
      localStorage.clear();

      if (error.response?.data?.message) {
        confirmAlert({
          title: 'Opa!',
          message: error.response?.data?.message,
          buttons: [
            {
              label: 'Ok',
              onClick: () => window.location.reload(),
            },
          ],
        });
        setTimeout(() => window.location.reload(), 5000);
      } else {
        window.location.reload();
      }
    }

    return Promise.reject(error);
  },
);

export default api;
