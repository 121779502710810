import styled from 'styled-components';
import * as colors from '../../../config/Colors/Ligth';


export const Container = styled.div`
  color:  ${colors.labelFontColor};
  margin-top: 20px;
  display: flex;
  align-items: center;
  padding-left: 6px;

  font-size: 10px;

  label {
    color: ${colors.labelFontColor};
    margin-left: 14px;

  }
  input[type=radio] {
    cursor: pointer;
  }

span {

  font-size: 14px;
  display: flex;
  width: 100%;
  height: 20px;
  line-height: -3rem;
  margin-left:10px;

}

input {
  display: flex;
  padding-left: 6px;
  height: 15px;
  line-height: -3rem;
}

input:disabled {
  color: #c7c7ce;
}


`;
