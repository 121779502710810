import React from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  ResponsiveContainer,
} from 'recharts';

interface IDado {
  nome: string;
  valor: string;
  qntd_produto: string;
  peso: string;
}

interface IDados {
  dados: IDado[];
}

const ChartLine: React.FC<IDados> = ({ dados }) => {
  const data = dados.map(dado => {
    return { 
      nome: dado.nome, 
      Valor: parseFloat(dado.valor),
      Quantidade: parseFloat(dado.qntd_produto),
      Peso: parseFloat(dado.peso)
    };
  });


  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={data}
        margin={{ top: 15, right: 30, left: -15, bottom: 20 }}
      >
        <CartesianGrid strokeDasharray="1 6" />
        <XAxis tick={{ fontSize: '5px; important' }} dataKey="nome" />
        <YAxis dataKey="Valor" />
        <Tooltip />
        <Line
          type="monotone"
          dataKey="Valor"
          stroke="#EBA821"
          fillOpacity={1}
          fill="url(#colorUv)"
        />
        <Line type="monotone" dataKey="Quantidade"  stroke="#eb5721" fillOpacity={1} />
        <Line type="monotone" dataKey="Peso"  stroke="#641503" fillOpacity={1} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ChartLine;
