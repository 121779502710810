import styled from 'styled-components';
import { Badge } from '@material-ui/core';
import * as colors from '../../config/Colors/Ligth';

export const CustomizedBadge = styled(Badge)`
  color: ${colors.ligthFontColor};
  .MuiBadge-colorPrimary {
    background: ${colors.concluido};
    font-weight: 600;
  }

  svg {
    color: ${colors.ligthFontColor};
    background-color: transparent;
  }
  margin-right: 5px;
`;
