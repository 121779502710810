import React from 'react';
import Loader from 'react-spinners/PropagateLoader';
import { Container } from './styles';

interface ILoading {
  isLoading: boolean;
}

const Loading: React.FC<ILoading> = ({ isLoading }) => {
  if (!isLoading) return <></>;
  return (
    <Container>
      <div>
        <div>
          <Loader size={15} color="#fff" loading={isLoading} />
        </div>
      </div>
    </Container>
  );
};

export default Loading;
