import { FaCartPlus, FaShoppingCart, FaTruckMoving } from 'react-icons/fa';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { BiBlock } from 'react-icons/bi';
import { GiCancel } from 'react-icons/gi';
import { HiCheck } from 'react-icons/hi';
import { MdSyncProblem } from 'react-icons/md';

interface FreqOption {
  value: number;
  label: string;
}
interface DiaAtendOption {
  value: number;
  label: string;
}

export const tiposOperacoes = [
  { id: 'V', nome: 'Venda' },
  { id: 'T', nome: 'Troca' },
  { id: 'N', nome: 'Devolução' },
  { id: 'B', nome: 'Bonificação' },
  { id: 'P', nome: 'Compra' },
  { id: 'O', nome: 'Outros' },
];

export const estados = [
  { id: '1', nome: 'Acre' },
  { id: '2', nome: 'Alagoas' },
  { id: '3', nome: 'Amapá' },
  { id: '4', nome: 'Amazonas' },
  { id: '5', nome: 'Bahia' },
  { id: '6', nome: 'Ceará' },
  { id: '7', nome: 'Distrito Federal' },
  { id: '8', nome: 'Espírito Santo' },
  { id: '9', nome: 'Goiás' },
  { id: '10', nome: 'Maranhão' },
  { id: '11', nome: 'mato Grosso' },
  { id: '12', nome: 'Mato Grosso do Sul' },
  { id: '13', nome: 'Minas Gerais' },
  { id: '14', nome: 'Pará' },
  { id: '15', nome: 'Paraíba' },
  { id: '16', nome: 'Paraná' },
  { id: '17', nome: 'Pernambuco' },
  { id: '18', nome: 'Bonificação' },
  { id: '19', nome: 'Compra' },
  { id: '20', nome: 'Outros' },
  { id: '21', nome: 'Venda' },
  { id: '22', nome: 'Troca' },
  { id: '23', nome: 'Devolução' },
  { id: '24', nome: 'Bonificação' },
  { id: '25', nome: 'Compra' },
  { id: '26', nome: 'Outros' },
  { id: '27', nome: 'Devolução' },
];

export const tipoItemProduto = [
  { id: '00', nome: 'Mercadoria para revenda' },
  { id: '01', nome: 'Matéria prima' },
  { id: '02', nome: 'Embalagem' },
  { id: '03', nome: 'Produto em processo' },
  { id: '04', nome: 'Produto acabado' },
  { id: 'O5', nome: 'Subproduto' },
  { id: '06', nome: 'Produto intermediário' },
];

export const tiposPagamento = [
  { value: 1, label: 'Boleto' },
  { value: 2, label: 'Cartão de crédito' },
  { value: 3, label: 'cartão de débito' },
  { value: 4, label: 'Cheque' },
  { value: 5, label: 'Dinheiro' },
  { value: 6, label: 'Transferência bancária' },
  { value: 7, label: 'Outros' },
];

export const optionsReferencia = [
  { value: 'data_geracao', label: 'Data de Cadastro' },
  { value: 'data_faturado', label: 'Data de Faturamento' },
  { value: 'data_entregue', label: 'Data de Entrega' },
  { value: 'data_concluido', label: 'Data de Conclusão' },
];

export const classificacaoPrecos = [
  { id: 5, nome: 'Preço padrão' },
  { id: 4, nome: 'Somente para prazo' },
  { id: 3, nome: 'Para tabela de preços' },
  { id: 2, nome: 'Para grupo de clientes' },
  { id: 1, nome: 'Para cliente específico' },
];

export const previsoesEntrega = [
  {
    value: false,
    label: 'CLIENTE IRÁ RETIRAR',
  },
  {
    value: true,
    label: 'ENTREGAR AO CLIENTE',
  },
];

export const previsoesDevolucao = [
  {
    value: false,
    label: 'CLIENTE RETORNARÁ OS EQUIPAMENTOS',
  },
  {
    value: true,
    label: 'BUSCAR EQUIPAMENTOS NO CLIENTE',
  },
];

export const etapasPedido = [
  {
    id: 1,
    classe: 'orcamento',
    titulo: 'Orçamento',
    icone: IoMdAddCircleOutline,
  },
  {
    id: 2,
    classe: 'pedido',
    titulo: 'Pedido',
    icone: FaCartPlus,
  },
  {
    id: 3,
    classe: 'faturado',
    titulo: 'Faturado',
    icone: FaShoppingCart,
  },
  { id: 4, classe: 'entregue', titulo: 'Entregue', icone: FaTruckMoving },
  { id: 5, classe: 'concluido', titulo: 'Concluído', icone: HiCheck },
  { id: 6, classe: 'erro', titulo: 'Bloqueado', icone: BiBlock },
  { id: 7, classe: 'erro', titulo: 'Cancelado', icone: GiCancel },
  {
    id: 8,
    classe: 'erro',
    titulo: 'Não sincronizado',
    icone: MdSyncProblem,
  },
];

export const status = [
  { value: true, label: 'Ativo' },
  { value: false, label: 'Inativo' },
];

export const meses = [
  { value: 0, label: 'Janeiro' },
  { value: 1, label: 'Fevereiro' },
  { value: 2, label: 'Março' },
  { value: 3, label: 'Abril' },
  { value: 4, label: 'Maio' },
  { value: 5, label: 'Junho' },
  { value: 6, label: 'Julho' },
  { value: 7, label: 'Agosto' },
  { value: 8, label: 'Setembro' },
  { value: 9, label: 'Outubro' },
  { value: 10, label: 'Novembro' },
  { value: 11, label: 'Dezembro' },
];

export const tipoPessoa = [
  { value: false, label: 'PESSOA FÍSICA' },
  { value: true, label: 'PESSOA JURÍDICA' },
];

export const exibirCliente = [
  {
    value: 0,
    label: 'EXIBIR NOME/ RAZÃO SOCIAL',
  },
  {
    value: 1,
    label: 'EXIBIR NOME/ RAZÃO SOCIAL + CPF/ CNPJ',
  },
  {
    value: 2,
    label: 'EXIBIR NOME FANTASIA/ APELIDO',
  },
  {
    value: 3,
    label: 'EXIBIR NOME FANTASIA/ APELIDO + CPF/ CNPJ',
  },
  {
    value: 4,
    label: 'EXIBIR NOME/ RAZÃO SOCIAL + NOME FANTASIA/ APELIDO',
  },
  {
    value: 5,
    label: 'EXIBIR NOME/ RAZÃO SOCIAL + NOME FANTASIA/ APELIDO + CPF/CNPJ',
  },
];


export const freqOptions: FreqOption[] = [
  { value: 1, label: 'DIÁRIA - (1 dia)' },
  { value: 7, label: 'SEMANAL - (7 dias)' },
  { value: 14, label: 'QUINZENAL - (14 dias)' },
  { value: 30, label: 'MENSAL - (30 dias)' },
  { value: 60, label: 'BIMESTRAL - (60 dias)' },
  { value: 90, label: 'TRIMESTRAL - (90 dias)' },
  { value: 180, label: 'SEMESTRAL - (180 dias)' },
  { value: 365, label: 'ANUAL - (365 dias)' },
];

export const diaAtendOptions: DiaAtendOption[] = [
  { value: 0, label: 'SEGUNDA-FEIRA' },
  { value: 1, label: 'TERÇA-FEIRA' },
  { value: 2, label: 'QUARTA-FEIRA' },
  { value: 3, label: 'QUINTA-FEIRA' },
  { value: 4, label: 'SEXTA-FEIRA' },
  { value: 5, label: 'SÁBADO' },
  { value: 6, label: 'DOMINGO' },
];


