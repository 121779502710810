import styled from 'styled-components';
import * as colors from '../../../../config/Colors/Ligth';

export const ContainerFiltros = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 4px;
  margin: 5px 0;
  padding: 10px;
  border: 1px solid ${colors.borderColor};
  h1 {
    font-size: 20px;
    padding-bottom: 10px;
    align-self: center;
  }
`;

export const ContainerBotoes = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`;

export const ContainerResumoFiltros = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  p {
    font-size: 10px;
    font-weight: 600;
    color: ${colors.darkFontColor};
  }
  padding-bottom: 10px; ;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start !important;
  padding: 5px 0;
  h2 {
    padding-right: 5px;
  }
`;

export const ContainerSelectMultiplo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1100px;
  border-radius: 2px;
  @media only screen and (max-width: 992px) {
    .MuiStepper-root {
      padding: 0;
    }
  }
`;
