import { Container } from "./styles";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const RadioButton = ({label1, name, value, label2, type, onChange, label}) => {

  return (
    <Container>
      <input
        value={value}
        name={name}
        type={type}
        checked={label === label1}
        onChange={onChange}
      />
      <span> {label1 === '1' ? '' : label1} {label2}</span>
    </Container>
  );

};
export default RadioButton;
