import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  DateSelectArg,
  EventClickArg,
  EventContentArg,
  EventInput,
} from '@fullcalendar/react';
import 'react-confirm-alert/src/react-confirm-alert.css';
// eslint-disable-next-line import/no-duplicates
import {
  addMonths,
  endOfMonth,
  format,
  parseISO,
  startOfMonth,
  // eslint-disable-next-line import/no-duplicates
} from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import ptBrLocale from 'date-fns/locale/pt-BR';
import { BsTrash } from 'react-icons/bs';
import CalendarIcon from '@material-ui/icons/CalendarTodayOutlined';
import CircleIcon from '@material-ui/icons/Lens';
import CloseIcon from '@material-ui/icons/Close';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Calendario from '../../components/Calendar';
import { Container, Separador } from '../../styles/GlobalStyles';
import {
  Body,
  ContainerModal,
  ContainerInitial,
  TextTerminaEm,
  ContainerCheckbox,
  ContainerTerminaEm,
  ContainerTextField,
  ContainerDateTime,
  Footer,
  ContainerInfo,
  TextInfo,
  TextEvento,
  Ocorrencias,
  OcorrenciasDisabled,
  ContainerModalRemove,
  BodyRemove,
  ContainerInitialRemove,
  FooterRemove,
  TextRender,
  ContainerRender,
  TextRenderBold,
  TextRenderAllDay,
  ModalRemoverEditar,
  ContainerTrash,
} from './styles';
import { freqOptions } from '../../config/Basicos';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import BotaoDefault from '../../components/Botoes/BotaoDefault';
import BotaoSalvar from '../../components/Botoes/BotaoSalvarEvento';
import { Header } from '../../components/Modal/ModalPesquisaEndereco/styles';
import DateTime from '../../components/Inputs/DateTime';
import Select from '../../components/Inputs/Select';
import Input from '../../components/Inputs/Input';
import retornaObjeto from '../../utils/buscaObjeto';
import RadioButton from '../../components/Inputs/RadioButton';
import getValidationErrors from '../../utils/getValidationErrors';

interface IAgenda {
  id?: number;
  descricao: string;
  periodicidade: number;
  all_day: boolean;
  data_inicio: Date;
  data_fim: Date;
  dia_atendimento: number;
  n_ocorrencias: number;
  data_termina: Date;
}

interface IAgendaRecorrencia {
  id?: string;
  recorrencia?: boolean;
}

const Agenda: React.FC = () => {
  const { addToast } = useToast();
  const [eventos, setEventos] = useState<EventInput[]>([]);
  const [dateInicial, setDateInicial] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalRemoveIsOpen, setModalRemoveIsOpen] = useState(false);
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false);
  const [modalRemoverEditarIsOpen, setModalRemoverEditarIsOpen] =
    useState(false);
  const [message, setMessage] = useState('');
  const [dateFim, setDateFim] = useState('');
  const [removerEventTitle, setRemoverEventTitle] = useState('');
  const [editEventTitle, setEditEventTitle] = useState('');
  const [removerEvent, setRemoverEvent] = useState<EventClickArg | null>();
  const [editEvent, setEditEvent] = useState<EventClickArg | null>();
  const [addEvent, setAddEvent] = useState<DateSelectArg | null>();
  const [label, setLabel] = useState('NUNCA');
  const [recorrencia, setRecorrencia] = useState(false);
  const [labelRemove, setLabelRemove] = useState('1');
  const [n_ocorrencias, setNumeroOcorrencias] = useState<string>('');
  const [eventsPeriodicidade, setEventsPeriodicidade] = useState<
    IAgendaRecorrencia[]
  >([]);
  const [data_inicio, setDataInicio] = useState(0);
  const formRef = useRef<FormHandles>(null);
  const formRefRemove = useRef<FormHandles>(null);
  const formRefEdit = useRef<FormHandles>(null);
  const formRefRemoverEditar = useRef<FormHandles>(null);
  const [dataTexto, setDataTexto] = useState<Date | null>(null);
  const [data_termina, setDataTermina] = useState<Date | null>(null);
  const mounted = useRef(false);
  const data_texto = format(new Date(dataTexto), 'EEEE', {
    locale: ptBrLocale,
  });
  const [evento_alterado, setEventoAlterado] = useState(new Date());

  function periodo() {
    switch (data_inicio) {
      case 1:
        return 'DIÁRIA';
      case 7:
        return 'SEMANAL';
      case 14:
        return 'QUINZENAL';
      case 30:
        return 'MENSAL';
      case 60:
        return 'BIMESTRAL';
      case 90:
        return 'TRIMESTRAL';
      case 180:
        return 'SEMESTRAL';
      case 365:
        return 'ANUAL';
      default:
        return 'ÚNICA';
    }
  }

  function dia_atend() {
    switch (data_texto) {
      case 'segunda-feira':
        return 'terça-feira';
      case 'terça-feira':
        return 'quarta-feira';
      case 'quarta-feira':
        return 'quinta-feira';
      case 'quinta-feira':
        return 'sexta-feira';
      case 'sexta-feira':
        return 'sábado';
      case 'sábado':
        return 'domingo';
      case 'domingo':
        return 'segunda-feira';
      default:
        return '';
    }
  }

  function handleChangeOcorrencias(e) {
    setNumeroOcorrencias(e.currentTarget.value);
  }

  const removeOptions = () => {
    if (recorrencia) {
      return [
        {
          id: 1,
          label1: '1',
          label2: 'Remover apenas este evento',
          name: 'remove 1',
        },
        {
          id: 2,
          label1: '',
          label2: 'Remover todas ocorrências deste evento',
          name: 'remove 2',
        },
      ];
    }
    return [
      {
        id: 3,
        label1: '',
        label2: 'Remover este evento',
        name: 'remove 3',
      },
    ];
  };
  const checkboxOptions = [
    {
      id: 1,
      label1: 'NUNCA',
      label2: '',
      name: 'null',
      enabled: true,
    },
    {
      id: 2,
      label1: 'EM',
      label2: (
        <ContainerDateTime>
          <DateTime
            value={data_termina}
            disablePast
            name="data_termina"
            minDate={data_termina || new Date()}
            format="dd/MM/yyyy hh:mm"
            // helperText={erroDataPrevistaRetorno}
            setValue={setDataTermina}
            disabled={!!(label === 'NUNCA' || label === 'APÓS')}
          />
        </ContainerDateTime>
      ),
      name: 'data_termina',
      enabled: false,
    },
    {
      id: 3,
      label1: 'APÓS',
      label2: (
        <ContainerTextField>
          <TextField
            type="number"
            name="n_ocorrencias"
            variant="outlined"
            InputProps={{
              inputProps: {
                max: 500,
                min: 1,
              },
            }}
            disabled={!!(label === 'NUNCA' || label === 'EM')}
            onChange={handleChangeOcorrencias}
          />
          {label === 'APÓS' ? (
            <Ocorrencias>ocorrências</Ocorrencias>
          ) : (
            <OcorrenciasDisabled>ocorrências</OcorrenciasDisabled>
          )}
        </ContainerTextField>
      ),
      name: 'n_ocorrencias',
      enabled: false,
    },
  ];

  useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  });

  function handleMonthChange(payload) {
    const calendarApi = payload.view.calendar;
    const date_calendar = calendarApi.getDate();
    const start_month = new Date(startOfMonth(date_calendar));
    const end_month = new Date(endOfMonth(date_calendar));
    const data_final = addMonths(end_month, 1);
    const data_initial = addMonths(start_month, -1);
    setDateFim(format(data_final, 'yyyy-MM-dd HH:ii:ss'));
    setDateInicial(format(data_initial, 'yyyy-MM-dd HH:ii:ss'));
  }

  const handleChange = event => {
    if (event.target.value !== '') setMessage(event.target.value);
  };

  useEffect(() => {
    async function getEvento(): Promise<void> {
      try {
        if (!dateInicial) return;

        const response = await api.get(`agenda`, {
          params: {
            data_inicio: dateInicial,
            data_fim: dateFim,
          },
        });

        if (mounted.current) {
          const events: EventInput[] = [];
          const events_recorrencia: IAgendaRecorrencia[] = [];
          response.data.forEach(item => {
            if (Array.isArray(item)) {
              item.forEach((element: EventInput) => {
                events.push({
                  id: element.id,
                  title: element.descricao,
                  allDay: element.all_day,
                  start: element.all_day
                    ? format(parseISO(element.data_inicio), 'yyyy-MM-dd')
                    : element.data_inicio,
                  end: element.all_day
                    ? format(parseISO(element.data_inicio), 'yyyy-MM-dd')
                    : element.data_inicio,
                });
              });
              item.forEach((element: IAgendaRecorrencia) => {
                events_recorrencia.push({
                  id: element.id,
                  recorrencia: element.recorrencia,
                });
              });
            } else {
              events.push({
                id: item.id,
                title: item.descricao,
                start: item.all_day
                  ? format(parseISO(item.data_inicio), 'yyyy-MM-dd')
                  : item.data_inicio,
                end: item.all_day
                  ? format(parseISO(item.data_inicio), 'yyyy-MM-dd')
                  : item.data_fim,
                allDay: item.all_day || false,
              });
              events_recorrencia.push({
                id: item.id,
                recorrencia: item.recorrencia,
              });
            }
          });
          setEventsPeriodicidade(events_recorrencia);
          setEventos(events);
        }
      } catch (err) {
        console.log(err);
      }
    }
    getEvento();
  }, [dateInicial, dateFim, evento_alterado]);

  const handleSubmit = useCallback(
    async (data: IAgenda) => {
      try {
        if (!message) return;

        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          descricao: Yup.string()
            .required('O nome do evento é obrigatório')
            .max(500, 'O nome do evento não pode ter mais de 500 caracteres'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (addEvent?.allDay) {
          const response = await api.post('agenda', {
            descricao: `${data.descricao}`,
            data_inicio:
              addEvent.startStr &&
              format(parseISO(addEvent.startStr), 'yyyy-MM-dd'),
            data_fim:
              addEvent.endStr &&
              format(parseISO(addEvent.endStr), 'yyyy-MM-dd'),
            periodicidade: data.periodicidade,
            dia_atendimento: data.dia_atendimento,
            data_termina:
              data_termina && label === 'EM'
                ? format(data_termina, 'yyyy-MM-dd HH:ii:ss')
                : null,
            n_ocorrencias:
              n_ocorrencias && label === 'APÓS' ? n_ocorrencias : null,
            recorrencia: data.periodicidade >= 1 ? 'true' : 'false',
          });

          if (mounted.current) {
            addToast({
              type: 'success',
              title: 'Evento adicionado com sucesso!',
              description: response.data.message,
            });
          }
        } else {
          const response = await api.post('agenda', {
            descricao: `${data.descricao}`,
            data_inicio: addEvent?.startStr,
            data_fim: addEvent?.endStr,
            all_day: 'false',
            periodicidade: data.periodicidade,
            dia_atendimento: data.dia_atendimento,
            data_termina:
              data_termina && label === 'EM'
                ? format(data_termina, 'yyyy-MM-dd HH:ii:ss')
                : null,
            n_ocorrencias:
              n_ocorrencias && label === 'APÓS' ? n_ocorrencias : null,
            recorrencia: data.periodicidade >= 1 ? 'true' : 'false',
          });

          if (mounted.current) {
            addToast({
              type: 'success',
              title: 'Evento adicionado com sucesso!',
              description: response.data.message,
            });
          }
        }
        setModalIsOpen(false);
        setMessage('');
        setLabel('NUNCA');
        setDataInicio(0);
        setNumeroOcorrencias('');
        setDataTermina(new Date());
        setEventoAlterado(new Date());
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao adicionar evento!',
          description: err.response.data.message,
        });
      }
    },
    [
      message,
      addEvent?.allDay,
      addEvent?.startStr,
      addEvent?.endStr,
      data_termina,
      label,
      n_ocorrencias,
      addToast,
    ],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function RemoveEvent(clickInfo: EventClickArg) {
    try {
      if (labelRemove === '1') {
        if (clickInfo.event.allDay === true) {
          const response = await api.post('agenda/agenda-excluida', {
            descricao: `${clickInfo.event.title}`,
            data_inicio:
              clickInfo.event.startStr &&
              format(parseISO(clickInfo.event.startStr), 'yyyy-MM-dd'),
            data_fim:
              clickInfo.event.endStr &&
              format(parseISO(clickInfo.event.endStr), 'yyyy-MM-dd'),
            agendas_id: clickInfo.event.id,
          });

          if (mounted.current) {
            addToast({
              type: 'success',
              title: 'Evento excluído com sucesso!',
              description: response.data.message,
            });
          }
        } else {
          const response = await api.post('agenda/agenda-excluida', {
            descricao: `${clickInfo.event.title}`,
            data_inicio: clickInfo.event.startStr,
            data_fim: clickInfo.event.endStr,
            agendas_id: clickInfo.event.id,
          });

          if (mounted.current) {
            addToast({
              type: 'success',
              title: 'Evento excluído com sucesso!',
              description: response.data.message,
            });
          }
        }
      } else {
        const response = await api.delete(`agenda/${clickInfo.event.id}`);

        if (mounted.current) {
          addToast({
            type: 'success',
            title: 'Evento(s) excluído(s) com sucesso!',
            description: response.data.message,
          });
        }
      }
      setModalRemoveIsOpen(false);
      setModalRemoverEditarIsOpen(false);
      setEventoAlterado(new Date());
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao excluir evento!',
      });
    }
  }

  const handleSubmitRemove = useCallback(async () => {
    try {
      RemoveEvent(removerEvent);
    } catch (err) {
      console.log(err);
    }
  }, [RemoveEvent, removerEvent]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function EditEvent(clickInfo: EventClickArg) {
    try {
      const response = await api.put(`agenda/${clickInfo.event.id}`, {
        descricao: `${editEventTitle}`,
      });

      if (mounted.current) {
        addToast({
          type: 'success',
          title: 'Evento alterado com sucesso!',
          description: response.data.message,
        });
      }

      setModalRemoverEditarIsOpen(false);
      setEventoAlterado(new Date());
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao alterar evento!',
      });
    }
  }

  const handleSubmitEdit = useCallback(async () => {
    try {
      EditEvent(editEvent);
    } catch (err) {
      console.log(err);
    }
  }, [EditEvent, editEvent]);

  function renderEventContent(eventContent: EventContentArg) {
    return (
      <>
        {/* {eventContent.event?.isMoreLink
          ? `${(
              <div className="custom-more-link">${eventContent.event.title}</div>
            )}`
          : null} */}
        {eventContent.event.allDay ? (
          <ContainerRender>
            <TextRenderAllDay>{eventContent.event.title}</TextRenderAllDay>
          </ContainerRender>
        ) : (
          <ContainerRender>
            <CircleIcon
              style={{ fontSize: 10, marginBottom: 2, color: '#3788d8' }}
            />
            <TextRender>{eventContent.timeText}</TextRender>
            <TextRenderBold>{eventContent.event.title}</TextRenderBold>
          </ContainerRender>
        )}
      </>
    );
  }

  const onChangeRemove = event => {
    setLabelRemove(event.currentTarget.value);
  };

  function handleRemoverEditarEvent(clickInfo: EventClickArg) {
    const recorrencia_events = eventsPeriodicidade.some(
      item =>
        String(item.id) === clickInfo.event.id && item.recorrencia === true,
    );

    if (recorrencia_events) {
      setRecorrencia(true);
      setLabelRemove('1');
    } else {
      setRecorrencia(false);
      setLabelRemove('2');
    }

    setModalRemoverEditarIsOpen(true);
    setRemoverEvent(clickInfo);
    setRemoverEventTitle(clickInfo.event.title);
    setEditEvent(clickInfo);
    formRefEdit.current?.setFieldValue('descricao_edit', clickInfo.event.title);
    setEditEventTitle(clickInfo.event.title);
  }

  function closeModal(): void {
    setModalIsOpen(false);
  }

  function closeModalRemove(): void {
    setModalRemoveIsOpen(false);
  }

  function closeModalRemoverEditar(): void {
    setModalRemoverEditarIsOpen(false);
  }

  function showModalRemover(): void {
    setModalRemoveIsOpen(true);
  }

  const onChange = event => {
    setLabel(event.currentTarget.value);
  };

  function handleAddEvent(selectInfo: DateSelectArg) {
    setModalIsOpen(true);
    setAddEvent(selectInfo);
  }

  function textData() {
    if (periodo() === 'ÚNICA') return '';
    return (
      <>
        a cada<TextEvento> {dia_atend()}</TextEvento>
      </>
    );
  }

  return (
    <Container>
      <Calendario
        eventClick={handleRemoverEditarEvent}
        eventContent={renderEventContent}
        select={handleAddEvent}
        events={eventos}
        datesSet={handleMonthChange}
      />
      <ContainerModal onClose={closeModal} open={modalIsOpen}>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Header>
            <Separador>
              <h2>
                <CalendarIcon />
                &nbsp;ADICIONAR EVENTO
              </h2>
            </Separador>
            {closeModal ? (
              <BotaoDefault onClick={closeModal}>
                <CloseIcon />
              </BotaoDefault>
            ) : null}
          </Header>
          <Body>
            <ContainerInitial>
              <Input
                label="Nome"
                name="descricao"
                maxLength={500}
                placeholder="Informe o nome do evento"
                onChange={handleChange}
                style={{ marginTop: 6 }}
              />
              <Select
                label="Frequência"
                options={[
                  {
                    value: null,
                    label: 'Nenhuma opção selecionada',
                  },
                  ...freqOptions,
                ]}
                isSearchable={false}
                value={retornaObjeto(freqOptions, data_inicio)}
                onChange={e => setDataInicio(e.value)}
                name="periodicidade"
                placeholder="Nenhuma opção selecionada"
                style={{ color: 'red' }}
              />
            </ContainerInitial>
            {data_inicio ? (
              <ContainerTerminaEm>
                <TextTerminaEm> Termina em</TextTerminaEm>
                {checkboxOptions.map(option => (
                  <ContainerCheckbox key={option.id}>
                    <RadioButton
                      key={option.id}
                      label1={option.label1}
                      label2={option.label2}
                      value={option.label1}
                      type="radio"
                      name={option.name}
                      label={label}
                      onChange={onChange}
                    />
                  </ContainerCheckbox>
                ))}
              </ContainerTerminaEm>
            ) : null}
            <ContainerInfo>
              <TextInfo>
                {' '}
                Serão criados eventos na Agenda de forma{' '}
                <TextEvento> {periodo()} </TextEvento> {textData()}
              </TextInfo>
            </ContainerInfo>
          </Body>
          <Footer>
            <BotaoSalvar type="submit">Salvar</BotaoSalvar>
          </Footer>
        </Form>
      </ContainerModal>
      <ContainerModalRemove onClose={closeModalRemove} open={modalRemoveIsOpen}>
        <Form ref={formRefRemove} onSubmit={handleSubmitRemove}>
          <Header>
            <Separador>
              <h2>
                <CalendarIcon />
                &nbsp;REMOVER EVENTO
              </h2>
            </Separador>
            {closeModalRemove ? (
              <BotaoDefault onClick={closeModalRemove}>
                <CloseIcon />
              </BotaoDefault>
            ) : null}
          </Header>
          <BodyRemove>
            <ContainerInfo>
              <TextInfo>
                {' '}
                Evento <TextEvento>{removerEventTitle}</TextEvento>
              </TextInfo>
            </ContainerInfo>
            <ContainerInitialRemove>
              {removeOptions().map(option => (
                <ContainerCheckbox key={option.id}>
                  <RadioButton
                    key={option.id}
                    label1={option.label1}
                    label2={option.label2}
                    value={option.label1}
                    type="radio"
                    name={option.name}
                    label={labelRemove}
                    onChange={onChangeRemove}
                  />
                </ContainerCheckbox>
              ))}
            </ContainerInitialRemove>
          </BodyRemove>
          <FooterRemove style={{ marginTop: 20 }}>
            <BotaoSalvar
              style={{ backgroundColor: 'white', marginRight: 20, width: 150 }}
              onClick={closeModalRemove}
            >
              Cancelar
            </BotaoSalvar>
            <BotaoSalvar style={{ width: 150 }} type="submit">
              Remover
            </BotaoSalvar>
          </FooterRemove>
        </Form>
      </ContainerModalRemove>
      <ModalRemoverEditar
        onClose={closeModalRemoverEditar}
        open={modalRemoverEditarIsOpen}
      >
        <Form ref={formRefEdit} onSubmit={handleSubmitEdit}>
          <Header>
            <Separador>
              <h2>
                <CalendarIcon />
                &nbsp;EVENTO
              </h2>
            </Separador>
            {closeModalRemoverEditar ? (
              <BotaoDefault onClick={closeModalRemoverEditar}>
                <CloseIcon />
              </BotaoDefault>
            ) : null}
          </Header>
          <BodyRemove>
            <ContainerInfo>
              <Input
                label="Nome"
                name="descricao_edit"
                value={editEventTitle}
                onChange={e => setEditEventTitle(e.target.value)}
                maxLength={500}
                style={{ marginTop: 6, width: '100%' }}
              />
              <ContainerTrash>
                <BsTrash
                  size={20}
                  title="Clique para remover evento"
                  color="#E82D22"
                  onClick={showModalRemover}
                />
              </ContainerTrash>
            </ContainerInfo>
            <FooterRemove style={{ marginTop: 20 }}>
              <BotaoSalvar
                style={{
                  backgroundColor: 'white',
                  marginRight: 20,
                  width: 150,
                }}
                onClick={closeModalRemoverEditar}
              >
                Cancelar
              </BotaoSalvar>
              <BotaoSalvar style={{ width: 150 }} type="submit">
                Editar
              </BotaoSalvar>
            </FooterRemove>
          </BodyRemove>
        </Form>
      </ModalRemoverEditar>
    </Container>
  );
};

export default Agenda;
