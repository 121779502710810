import React, { useState, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EyeIcon from '@material-ui/icons/RemoveRedEye';
import DeleteIcon from '@material-ui/icons/Delete';
import EventIcon from '@material-ui/icons/Event';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PersonIcon from '@material-ui/icons/Person';
import Note from '@material-ui/icons/Note';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import api from '../../../../../services/api';
import { useToast } from '../../../../../hooks/toast';
// import sem_imagem from '../../../../../assets/sem_imagem.jpg';
import MostraEtapa from '../../../../../components/MostraEtapasPedido';
import formataNumero from '../../../../../utils/formataNumero';
import formataValor from '../../../../../utils/formataValor';
import formataData from '../../../../../utils/formataData';
import abreviaNome from '../../../../../utils/abreviaNome';
import StatusSincronizacao from '../../../../../components/Sincronizacao/MostraStatusSincronizacao';
import Can from '../../../../../components/Can';
import {
  Container,
  InfoPedido,
  ContainerProdutosEquipamentos,
  ListaProdutosEquipamentos,
  ContainerItemsLinha,
  ContainerItemsLado,
} from './styles';
import BotaoComConfirmacao from '../../../../../components/Botoes/BotaoComConfirmacao';
import BotaoDefault from '../../../../../components/Botoes/BotaoDefault';

interface IPedidoItem {
  id: number;
  quantidade: string;
  valor_bruto: string;
  valor_liquido: string;
  valor_desconto: string;
  produto: {
    id: number;
    nome_produto: string;
    imagem: string;
    nome_unidade: string;
  };
}

interface IPedido {
  id: number;
  nome: string;
  apelido: string;
  cpf_cnpj: string;
  nome_vendedor: string;
  valor_total: string;
  beersales_id: number;
  prazo: string;
  forma_pagamento: string;
  motivo_cancelamento: string;
  motivo_pedido: string;
  motivo_entrega: string;
  status_id: number;
  data_geracao: Date;
  data_faturado: Date;
  data_entregue: Date;
  data_concluido: Date;
  data_bloqueado: Date;
  data_cancelado: Date;
  created_at: Date;
  cod_retorno_integracao: number;
  motivo_integracao: string;
  chave_nfe: string;
  pedido_item: IPedidoItem[];
  classe?: string;
  titulo?: string;
  nfe: [];
}

interface IParam {
  pedido: IPedido;
  cancelarPedido: (id: number) => void;
  duplicarPedido: (id: number) => void;
  getData: (show_loading: boolean) => void;
  nfe: {
    numero: number;
    chave_acesso: string;
  }[];
}

const ItemListaPedidos: React.FC<IParam> = ({
  pedido,
  cancelarPedido,
  duplicarPedido,
  getData,
  nfe,
}) => {
  const [expanded, setExpanded] = useState(0);
  const { addToast } = useToast();

  const gerarPdf = useCallback(async () => {
    try {
      const response = await api.get(`pedido/pdf/${pedido.id}`, {
        responseType: 'blob',
      });

      const file = new Blob([response.data], { type: 'application/pdf' });

      const fileURL = URL.createObjectURL(file); // Open the URL on new Window

      window.open(fileURL);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Atenção',
        description: 'Ocorreu um erro ao salvar. Por favor, tente novamente.',
      });
    }
  }, [addToast, pedido.id]);

  return (
    <Container className={pedido.classe}>
      <div style={{ borderRadius: '8px' }}>
        <div>
          <button
            type="button"
            onClick={() => setExpanded(expanded === pedido.id ? 0 : pedido.id)}
          >
            {expanded ? (
              <KeyboardArrowUpIcon fontSize="large" />
            ) : (
              <KeyboardArrowDownIcon fontSize="large" />
            )}
          </button>
          <h2>{pedido.nome}</h2>
        </div>
        <div>
          <span>
            <strong className={pedido.classe}>{pedido.titulo}</strong>
            &nbsp;
            {pedido.created_at ? formataData(pedido.created_at) : ''}
          </span>
        </div>
      </div>

      <div>
        <div>
          <section>
            <div>
              <strong>
                {`${pedido.status_id === 1 ? 'Orçamento' : 'Pedido'}
                      ${pedido.beersales_id || ''}  `}
                &nbsp;
              </strong>
              <Link
                style={{ marginTop: 5, marginLeft: 10, marginRight: 10 }}
                title="Visualizar pedido!"
                to={`/pedidos/cadastro/${pedido.id}`}
              >
                <EyeIcon />
              </Link>
              <BotaoComConfirmacao
                funcao_id={pedido.id}
                titulo="Duplicar pedido!"
                mensagem="Tem certeza que deseja duplicar o pedido?"
                funcao={duplicarPedido}
              >
                <FileCopyIcon />
              </BotaoComConfirmacao>
              {pedido.status_id === 1 &&
                [0, 412, 500].includes(pedido.cod_retorno_integracao) && (
                  <BotaoComConfirmacao
                    funcao_id={pedido.id}
                    titulo="Excluir pedido!"
                    mensagem="Tem certeza que deseja excluir o pedido?"
                    funcao={cancelarPedido}
                  >
                    <DeleteIcon />
                  </BotaoComConfirmacao>
                )}
              {![412, 500].includes(pedido.cod_retorno_integracao) && (
                <BotaoComConfirmacao
                  funcao_id={pedido.id}
                  titulo="Gerar PDF!"
                  mensagem="Gostaria de gerar o PDF do Pedido?"
                  funcao={gerarPdf}
                >
                  <PictureAsPdf />
                </BotaoComConfirmacao>
              )}
            </div>
            <StatusSincronizacao
              key={pedido.id}
              tipo="pedido"
              id={pedido.id}
              cod_retorno_integracao={pedido.cod_retorno_integracao}
              motivo_integracao={pedido.motivo_integracao}
            />
          </section>
        </div>
        <ContainerItemsLinha>
          <div>
            <p>
              <MonetizationOnIcon />
              &nbsp;
              {pedido.forma_pagamento ? pedido.forma_pagamento : ''}
            </p>
          </div>
          {nfe?.length > 0 ? (
            <ContainerItemsLado>
              <div>
                <p>
                  <Note />
                  &nbsp;
                  {`NF: ${nfe.map(itemNfe => itemNfe.numero).join(', ')}`}
                </p>
              </div>
            </ContainerItemsLado>
          ) : (
            <></>
          )}
        </ContainerItemsLinha>
        <div>
          <p>
            <EventIcon />
            &nbsp;
            {pedido.prazo ? pedido.prazo : ''}
          </p>
        </div>
        <div>
          <p>
            <ShoppingCartIcon />
            &nbsp;
            {formataValor(`${pedido.valor_total || 0}`)}
          </p>
        </div>
        <div>
          <Can roles={['customer-admin']}>
            <p>
              <PersonIcon />
              &nbsp;
              {`${abreviaNome(pedido.nome_vendedor)}`}
            </p>
          </Can>
        </div>
      </div>

      {expanded === pedido.id && (
        <>
          <InfoPedido>
            <Link to={`/pedidos/cadastro/${pedido.id}`}>
              Detalhes do pedido
            </Link>
            {pedido.status_id > 2 && pedido.chave_nfe && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`http://www.nfe.fazenda.gov.br/portal/consultaRecaptcha.aspx?tipoConteudo=XbSeqxE8pl8=&tipoConsulta=completa&nfe=${pedido.chave_nfe}&`}
              >
                Ver nota
              </a>
            )}
          </InfoPedido>
          <MostraEtapa getData={getData} key={pedido.id} pedido={pedido} />
        </>
      )}
    </Container>
  );
};

export default ItemListaPedidos;
