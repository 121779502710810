import { format } from 'date-fns';

const formataData = (date: Date | undefined) => {
  if (!date) {
    return '-';
  }

  return format(new Date(date), 'dd/MM HH:mm');
};

export default formataData;
