import React from 'react';
import { Chip } from '@material-ui/core';
import { Container } from './styles';

interface IFiltro {
  ativos: boolean;
  inativos: boolean;
}

interface IEtapa {
  filtro: IFiltro;
  setFiltro: (filtro: IFiltro) => void;
}

const FiltroHorizontalCheckStatus: React.FC<IEtapa> = ({
  filtro,
  setFiltro,
}) => {
  return (
    <>
      <Container>
        <h2>Status:</h2>
        <Chip
          variant="default"
          color="default"
          style={{
            textDecoration: filtro.ativos ? 'none' : 'line-through',
          }}
          size="small"
          onClick={() => setFiltro({ ...filtro, ativos: !filtro.ativos })}
          label="Ativos"
        />
        <Chip
          variant="default"
          color="default"
          size="small"
          style={{
            textDecoration: filtro.inativos ? 'none' : 'line-through',
          }}
          onClick={() => setFiltro({ ...filtro, inativos: !filtro.inativos })}
          label="Inativos"
        />
      </Container>
    </>
  );
};

export default FiltroHorizontalCheckStatus;
