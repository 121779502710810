import styled from 'styled-components';
import * as colors from '../../config/Colors/Ligth';

export const GraficosPizza = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  background-color: ${colors.backgroudColor};
  border: none;
  width: 100%;
`;

export const Filtro = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: 1000px) {
    margin-top: 10px;
  }
  background-color: ${colors.formBackgroundColor};
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  padding-left: 20px;
  white-space: normal;
`;

export const FiltroFirst = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px px -2px #989898;
  -moz-box-shadow: 0 3px 3px -2px #989898;
  -webkit-box-shadow: 0 3px 3px -2px #989898;
  background-color: ${colors.formBackgroundColor};
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  padding-left: 20px;
  padding-bottom: 20px;
  padding-top: 20px;

`;

export const NoFlexWrap = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  @media screen and (min-width: 1000px) {
    margin-top: 10px;
    flex-wrap: nowrap;
  }
`;

export const MostraGraficos = styled.div`
  display: flex;
  background-color: ${colors.formBackgroundColor};
  flex-direction: column;
  margin-top: 10px !important;

  height: 320px;
  @media screen and (max-width: 1000px) {
    height: 320px;
  }
  width: 100%;
  /* max-height: 100%; */
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px px -2px #989898;
  -moz-box-shadow: 0 3px 3px -2px #989898;
  -webkit-box-shadow: 0 3px 3px -2px #989898;
  div > &:nth-last-child(2n) {
    margin-left: 10px;
    margin-right: 10px;
  }
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;

`;

export const MostraGraficosPizza = styled.div`
  display: flex;
  background-color: ${colors.formBackgroundColor};
  flex-direction: column;
  margin-top: 10px !important;
  height: 320px;
  @media screen and (max-width: 1000px) {
    height: 320px;
  }
  /* min-height: 320px; */
  width: 100%;
  max-height: 100%;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px px -2px #989898;
  -moz-box-shadow: 0 3px 3px -2px #989898;
  -webkit-box-shadow: 0 3px 3px -2px #989898;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
`;

export const Titulo = styled.div`
  display: flex;
  white-space: normal;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.formBackgroundColor};
  color: ${colors.titleFontColor};
  border-bottom: 1px solid #f5f5f5;
  height: 50px;
  border-radius: 3px 3px 0 0;
  h1 {
    padding: 10px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
  }
  @media screen and (max-width: 1000px) {
    height: auto;
    h1 {
      font-size: 10px;
      padding: 5px;
    }
  }
`;

export const TituloFiltro = styled.div`
  white-space: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.formBackgroundColor};
  color: ${colors.titleFontColor};
  margin-top: 20px;
  h1 {
    padding: 10px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
  }
  @media screen and (max-width: 1000px) {
    height: auto;
    h1 {
      font-size: 10px;
      padding: 5px;
    }
  }
`;

export const TituloFiltroMunicipio = styled.div`
  white-space: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.formBackgroundColor};
  color: ${colors.titleFontColor};
  margin-top: 25px;
  h1 {
    padding-top: 15px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
  }
  @media screen and (max-width: 1000px) {
    height: auto;
    h1 {
      font-size: 10px;
      padding: 5px;
    }
  }
`;

export const Corpo = styled.div`
  display: flex;
  background-color: ${colors.formBackgroundColor};
  height: 100%;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px px -2px #989898;
  -moz-box-shadow: 0 3px 3px -2px #989898;
  -webkit-box-shadow: 0 3px 3px -2px #989898;
  width: 100%;
  div:first-child {
    flex: 1;
  }
  div + div {
    border-left: 1px solid #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 140px;
    padding-top: 90px;
    color: ${colors.ligthFontColor};

    h2 {
      padding-top: 30px;
      color: ${colors.titleFontColor};
      font-size: 11px;
      text-transform: uppercase;
    }

    p {
      color: ${colors.ligthFontColor};
      font-size: 11px;
      text-transform: uppercase;
    }

    @media screen and (max-width: 1000px) {
      justify-content: center;
      padding: 0;
      width: 100%;
      flex-direction: row;
      height: auto;
      p,
      h2 {
        font-size: 9px;
        padding: 0;
        padding-left: 10px;
      }
    }
  }
`;
