import React, { useRef, useEffect, useState, useCallback } from 'react';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';
import { useField } from '@unform/core';
import { IconBaseProps } from 'react-icons';
import { Container } from './styles';

interface Props extends InputProps {
  name: string;
  label: string;
  icon?: React.ComponentType<IconBaseProps>;
  tamanho?: number;
}

const InputMasked: React.FC<Props> = ({
  name,
  icon: Icon,
  tamanho,
  label,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value: string) {
        ref.setInputValue(value || '');
      },
      clearValue(ref) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container
      isErrored={!!error}
      tamanho={tamanho}
      isFilled={isFilled}
      isFocused={isFocused}
    >
      <label htmlFor={name}>
        {label}
        <div>
          {Icon && <Icon size={14} />}
          <ReactInputMask
            ref={inputRef}
            defaultValue={defaultValue}
            onFocus={handleInputFocus}
            onBlurCapture={handleInputBlur}
            maskChar={null}
            {...rest}
          />
        </div>
        {error && <span>{error}</span>}
      </label>
    </Container>
  );
};

export default InputMasked;
