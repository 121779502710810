import React, { useMemo } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';

interface IDado {
  nome: string;
  valor: string;
}

interface IDados {
  dados: IDado[];
}

const ChartPizza: React.FC<IDados> = ({ dados }) => {
  const data = dados.map(dado => {
    return { nome: dado.nome, valor: parseFloat(dado.valor) };
  });
  const colors = ['#8884d8', '#82ca9d', 'lightgray', 'orange', 'lightblue'];

  const tamanho_tela = window.screen.availWidth;

  const tamanho_pizza = useMemo(() => {
    if (tamanho_tela > 1000) {
      return 80;
    }
    return 60;
  }, [tamanho_tela]);

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor="middle"
        dominantBaseline="central"
        style={{ fontSize: '10px', fontWeight: 'bolder' }}
      >
        {value}
      </text>
    );
  };

  return (
    <ResponsiveContainer>
      <PieChart>
        <Pie
          data={data}
          isAnimationActive={false}
          dataKey="valor"
          nameKey="nome"
          labelLine={false}
          cx="50%"
          cy="50%"
          outerRadius={tamanho_pizza}
          // innerRadius={tamanho_pizza - 30}
          label={renderCustomizedLabel}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${entry.nome}`} fill={colors[index]} />
          ))}
        </Pie>
        <Legend
          align="left"
          verticalAlign="bottom"
          iconType="square"
          layout="horizontal"
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default ChartPizza;
