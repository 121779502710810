import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import * as colors from '../../../../../../../config/Colors/Ligth';
import BotaoDefault from '../../../../../../../components/Botoes/BotaoDefault';

export const Botao = styled(BotaoDefault)`
  padding: 0px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &:hover {
    filter: brightness(50%);
    text-decoration: underline;
  }
  svg {
    margin-bottom: 3px;
  }
`;

export const Container = styled(Dialog)`
  background-color: ${colors.formBackgroundColor};
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  .MuiPaper-root {
    max-width: 400px;
    width: 100%;
  }
`;

export const Header = styled.div`
  border-bottom: 1px solid ${colors.borderColor};
  padding: 5px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.backgroudColor};
`;

export const Body = styled.div`
  background-color: ${colors.backgroudColor};
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;
  p {
    padding: 10px 0;
  }
  button {
    padding: 10px 0;
  }
`;

export const Footer = styled.div`
  border-top: 1px solid ${colors.borderColor};
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  overflow-x: hidden;
  background-color: ${colors.backgroudColor};
`;
