import React, { useRef, useEffect, useState, useCallback } from 'react';
import ReactSelect, {
  OptionTypeBase,
  Props as SelectProps,
} from 'react-select';
import { useField } from '@unform/core';
import { Container } from './styles';
import * as colors from '../../../config/Colors/Ligth';

interface Props extends SelectProps<OptionTypeBase> {
  name: string;
  label?: string;
}

const Select: React.FC<Props> = ({ name, label, ...rest }) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!selectRef.current);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      setValue: (ref, value) => {
        let obj = null;
        if (ref.props.options) {
          const { options } = ref.props;
          obj = options.find(option => option.value === value);
        }
        ref.select?.setValue(obj || value);
      },

      clearValue: ref => {
        ref.select.clearValue();
      },
      getValue: rest.isMulti
        ? ref =>
            ref.state.value?.map((option: OptionTypeBase) => option.value) || []
        : ref => (ref.state.value ? ref.state.value.value : ''),
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <Container isErrored={!!error} isFilled={isFilled} isFocused={isFocused}>
      <label htmlFor={name}>
        {label}
        <ReactSelect
          defaultValue={defaultValue}
          ref={selectRef}
          classNamePrefix="react-select"
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          noOptionsMessage={() => 'Não encontrado!'}
          loadingMessage={() => 'Carregando...'}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: colors.backgroudColor,
              primary: colors.labelFontColor,
            },
          })}
          {...rest}
        />

        {error && <span>{error}</span>}
      </label>
    </Container>
  );
};

export default Select;
