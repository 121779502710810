import React, { useEffect, useState, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FaRegFilePdf } from 'react-icons/fa';
import { Container } from '../../../styles/GlobalStyles';
import Loading from '../../../components/Loading';
import {
  HeaderLista,
  BodyLista,
  ItemContainer,
  ContainerGrupoProduto,
  LinkContainer,
} from './styles';
import Pesquisa from '../../../components/Pesquisa';
import Filtros from './Filtros';
import IGrupoProduto from '../../../interfaces/selectOptions';
import api from '../../../services/api';
import BotaoNovoPedidoRodape from '../../../components/Botoes/BotaoNovoPedidoRodape';
// import sem_imagem from '../../../assets/sem_imagem.jpg';
import BotaoDefault from '../../../components/Botoes/BotaoDefault';
import { useToast } from '../../../hooks/toast';
import formataNumero from '../../../utils/formataNumero';

const ListaClientes: React.FC = () => {
  const { addToast } = useToast();
  const [last_page, setLastPage] = useState(0);
  const [is_loading, setIsLoading] = useState(false);
  const [exibe_estoque, setExibeEstoque] = useState<boolean>(false);
  const [exibe_preco, setExibePreco] = useState<boolean>(false);
  const [cliente, setCliente] = useState(0);
  const [prazo, setPrazo] = useState(0);
  const [pesquisa, setPesquisa] = useState('');
  const [page, setPage] = useState(1);
  const [mostra_filtros, setMostraFiltros] = useState(false);
  const [produtos, setProdutos] = useState([]);
  const [estoque_minimo, setEstoqueMinimo] = useState(0);
  const [grupos_selecionados, setGruposSelecionados] = useState<
    IGrupoProduto[]
  >([]);
  const filtros = {
    grupos_selecionados,
    setGruposSelecionados,
    estoque_minimo,
    setEstoqueMinimo,
    mostra_filtros,
    setMostraFiltros,
    exibe_estoque,
    setExibeEstoque,
    exibe_preco,
    setExibePreco,
    cliente,
    setCliente,
    prazo,
    setPrazo,
  };

  useEffect(() => {
    async function getProdutos(): Promise<void> {
      if (page === 1) {
        setIsLoading(true);
      }
      if (page !== last_page) {
        setLastPage(page);
      } else {
        setPage(1);
        setLastPage(1);
      }
      const response = await api.get('produto', {
        params: {
          preco: true,
          nome: pesquisa,
          estoquemin: estoque_minimo,
          cliente: cliente || 0,
          prazo: prazo || 0,
          grupos:
            grupos_selecionados &&
            grupos_selecionados.map(grupo => grupo.value),
          page,
          limit: 50,
          exibe_preco,
          exibe_estoque,
        },
      });
      if (page === 1) {
        setProdutos(response.data);
      } else {
        const novos_produtos = response.data;
        const copia_produtos = [...produtos, ...novos_produtos];
        setProdutos(copia_produtos);
      }
      setIsLoading(false);
    }
    getProdutos();
  }, [
    estoque_minimo,
    grupos_selecionados,
    pesquisa,
    page,
    cliente,
    prazo,
    exibe_preco,
    exibe_estoque,
  ]);

  const gerarPdf = useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await api.get(`produto-pdf`, {
        params: {
          preco: true,
          nome: pesquisa,
          estoquemin: estoque_minimo,
          cliente: cliente || 0,
          prazo: prazo || 0,
          grupos:
            grupos_selecionados &&
            grupos_selecionados.map(grupo => grupo.value),
          exibe_estoque,
          exibe_preco,
        },
        responseType: 'blob',
      });

      const file = new Blob([response.data], { type: 'application/pdf' });

      const fileURL = URL.createObjectURL(file); // Open the URL on new Window

      window.open(fileURL);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro no cadastro',
        description: 'Ocorreu um erro ao salvar. Por favor, tente novamente.',
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    addToast,
    cliente,
    estoque_minimo,
    grupos_selecionados,
    pesquisa,
    prazo,
    exibe_preco,
    exibe_estoque,
  ]);

  function handlePage(): void {
    const paginaAtual = page + 1;
    setPage(paginaAtual);
  }

  return (
    <Container>
      <Loading isLoading={is_loading} />
      <HeaderLista>
        <div>
          <h1>PRODUTOS</h1>
          <BotaoDefault title="Gerar Pdf do pedido" onClick={gerarPdf}>
            <FaRegFilePdf size={25} />
          </BotaoDefault>
        </div>

        <Pesquisa
          placeholder="Informe o nome do produto"
          pesquisa={pesquisa}
          setPesquisa={setPesquisa}
          setPage={setPage}
          filtros={{ mostra_filtros, setMostraFiltros }}
        />
        <Filtros filtros={filtros} />
      </HeaderLista>
      <BodyLista>
        <InfiniteScroll
          dataLength={produtos.length}
          next={handlePage}
          hasMore
          loader={page > 1 && <h4>Carregando...</h4>}
        >
          {produtos.map(item => (
            <ItemContainer key={item.produto_id}>
              <LinkContainer
                to={`/produtos/cadastro/${item.produto_id}`}
              >
                <div>
                  <h2>{`${item.codigo} - ${item.nome}`}</h2>
                  <ContainerGrupoProduto>
                    <p>{`${item.grupo_produto_nome}`}</p>
                  </ContainerGrupoProduto>
                  {exibe_estoque && (
                    <span>{`ESTOQUE: ${item.estoque} ${item.unidade}`}</span>
                  )}
                  {exibe_preco && (
                    <span>{`PREÇO: R$ ${formataNumero(
                      item.valor,
                      4,
                      true,
                    )}`}</span>
                  )}
                </div>
              </LinkContainer>
              {/* } <img height={70} src={item.imagem ?? sem_imagem} alt={`${item.codigo} - ${item.nome}`} /> { */}
            </ItemContainer>
          ))}
        </InfiniteScroll>
        <BotaoNovoPedidoRodape />
      </BodyLista>
    </Container>
  );
};

export default ListaClientes;
