import React from 'react';
import Slider from '@material-ui/core/Slider';
import { IoMdBeer } from 'react-icons/io';
import { BiBeer } from 'react-icons/bi';
import { Container } from './styles';
import * as colors from '../../config/Colors/Ligth';
import Input from '../Inputs/ControlledInput';

interface IFiltros {
  value: number;
  setValue: (value: number) => void;
  max: number;
  step: number;
  titulo: string;
}

const SliderInput: React.FC<IFiltros> = ({
  value,
  setValue,
  max,
  step,
  titulo,
}) => {
  const handleSliderChange = (event, newValue: number) => {
    setValue(Number(newValue));
  };

  const handleInputChange = val => {
    setValue(Number(val));
  };

  return (
    <Container>
      <h2>{titulo}</h2>
      <div>
        <BiBeer size={25} />
        <Slider
          min={0}
          max={max}
          step={step}
          value={typeof value === 'number' ? value : 0}
          onChange={handleSliderChange}
          aria-labelledby="input-slider"
          valueLabelDisplay="auto"
          style={{ marginRight: '5px', color: colors.labelFontColor }}
        />
        <IoMdBeer size={25} style={{ marginRight: '20px' }} />
        <Input
          name="value"
          value={value}
          onChange={e => handleInputChange(e.target.value)}
          type="number"
          style={{ maxWidth: 70, marginRight: '20px', borderWidth: '1px' }}
        />
      </div>
    </Container>
  );
};

export default SliderInput;
