import styled, { css } from 'styled-components';
import { labelFontColor, erro } from '../../../config/Colors/Ligth';

interface ContainerProps{
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  // margin-top: 16px;
  .react-select__control {
    min-height: 38px;

    @media only screen and (max-width: 768px) {
      min-height: 35px;
    }
  }
  label {
    // padding-left: 6px;
    font-size: 10px;
    color: ${labelFontColor};
    ${props =>
    props.isErrored &&
    css`
        color: ${erro};
      `}
  }

  span {
    background-color: transparent;
    padding-top: 0;
    padding-left: 6px;
    font-size: 10px;
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: ${erro};
    `}
`;
