export default function abreviaNome(nome: string): string {
  if (!nome) {
    return '';
  }

  const array = nome.split(' ');
  let nome_abreviado = '';
  for (let x = 0; x < array.length; x += 1) {
    if (x === 0 || x === array.length - 1) {
      nome_abreviado = `${nome_abreviado}${x === 0 ? '' : ' '}${array[x]}`;
    } else {
      nome_abreviado = `${nome_abreviado} ${array[x].charAt(0)}.`;
    }
  }

  return nome_abreviado;
}
