const formataNumero = (
  numero: string | Number,
  zeros_direita: number,
  usar_virgula?: boolean,
): string => {
  const novoNumero = !numero ? 0 : parseFloat(`${numero}`);
  if (usar_virgula) {
    return novoNumero.toFixed(zeros_direita).replace('.', ',');
  }
  return novoNumero.toFixed(zeros_direita);
};

export default formataNumero;
