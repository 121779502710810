import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FiArrowLeft, FiMail } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { useToast } from '../../../hooks/toast';
import logoImg from '../../../assets/login-logo.png';
import api from '../../../services/api';

import getValidationErrors from '../../../utils/getValidationErrors';
import Input from '../../../components/Inputs/InputGrande';
import BotaoLogin from '../../../components/Botoes/BotaoLogin';

import { Container, Content, Background, AnimationContainer } from './styles';

interface SignInFormData {
  email: string;
  password: string;
}

const EsqueciMinhaSenha: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading_message, setLoadingMessage] = useState('');
  const { addToast } = useToast();
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => { mounted.current = false; }; // ... and to false on unmount
  }, []);

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        setLoadingMessage('Enviando...');
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('O e-mail é obrigatório')
            .email('Digite um e-mail válido'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await api.post('/auth/forgot-password', data);
        if(mounted.current){
          addToast({
            type: 'success',
            title: 'Verifique seu e-mail',
            description: response.data.message,
          });
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'E-mail não encontrado',
          description: err.response.data.message,
        });
      } finally {
        setLoadingMessage('');
      }
    },
    [addToast],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="BeerSales" />
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input
              name="email"
              maxLength={255}
              type="email"
              icon={FiMail}
              placeholder="Informe o e-mail"
            />
            <BotaoLogin loading={loading_message} type="submit">
              Redefinir senha
            </BotaoLogin>
            <Link to="/login">
              <FiArrowLeft />
              &nbsp;Voltar para o login
            </Link>
          </Form>
        </AnimationContainer>
      </Content>
      <Background />
    </Container>
  );
};

export default EsqueciMinhaSenha;
