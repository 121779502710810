import styled from 'styled-components';
import * as colors from '../../../../../../config/Colors/Ligth';

export const HeaderLista = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  top: 0;
  height: 40px;
`;

export const ContainerEsquerdoHeader = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  small {
    color: ${colors.darkFontColor};
    font-size: 11px;
  }
`;

export const ContainerDireitoHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  small {
    color: ${colors.darkFontColor};
    font-size: 11px;
  }
`;

export const BodyLista = styled.div`
  padding-top: 30px;
  border-bottom: 1px solid #eee;
  display: flex;
  flex-direction: column;
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 30px;
`;

export const ItemLista = styled.div`
  padding: 5px 0 15px 0;
  margin: 0;
  border-top: 1px solid #eee;
  display: flex;
  flex-direction: column;
  justify-content: center;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 20px;
  }
  > div {
    justify-content: space-between;
    align-items: baseline;
    justify-content: baseline;

    h1 {
      font-size: 18px;
      margin-right: 10px;

    }
    p {
      color: ${colors.ligthFontColor};
    }
    small {
      color: ${colors.ligthFontColor};
      font-size: 10px;
    }
    & + div {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding-right: 5px;
    }
  }
`;

export const Legenda = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContainerStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;


