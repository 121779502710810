// src/pages/Produtos/Detalhes
import React, { useEffect, useState } from 'react';
import { Tabs as Tab} from '@mui/base/Tabs';
import {
  useParams,
  useHistory,
  Link,
} from 'react-router-dom';
import UndoIcon from '@material-ui/icons/ArrowBackIos';
import {
  Tabs,
  TabPanels,
  TabsLists,
} from '../../../components/Abas';
import { Container } from '../../../styles/GlobalStyles';
import Cadastro from './Cadastro';
import Historico from './Historico';
import Preco from './Preco';
import { Header, Voltar } from './styles';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import IProduto from '../../../interfaces/produto';
import IEquipamento from '../../../interfaces/equipamento';
import Loading from '../../../components/Loading';

interface IRouteParam {
  id?: string;
  status?: string;
}

const Configuracoes: React.FC<IRouteParam> = () => {
  const [is_loading, setIsLoading] = useState(false);
  const { id } = useParams<IRouteParam>();
  const { addToast } = useToast();
  const [produto, setProduto] = useState<IProduto>();
  const [estoque_produto_equip, setEstoqueProdutoEquip] = useState<
    IEquipamento[]
  >([]);

  useEffect(() => {
    async function getProduto(): Promise<void> {
      try {
        if (!id) return;
        setIsLoading(true);

        const { data } = await api.get<IProduto>(`produto/${id}`);
        setProduto(data);
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro',
          description: err.response.data.message,
        });
      } finally {
        setIsLoading(false);
      }
    }
    getProduto();
  }, [id, addToast]);

  useEffect(() => {
    async function getProdutoEstoqueEquipamento(): Promise<void> {
      try {
        if (!id) return;
        setIsLoading(true);

        const { data } = await api.get<IEquipamento[]>(
          `produto/estoque-produto-equipamento/${id}`,
        );
        setEstoqueProdutoEquip(data);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
    getProdutoEstoqueEquipamento();
  }, [id, addToast]);

  return (
    <Container>
      <Loading isLoading={is_loading} />
      <Voltar>
        <Link to="/produtos/lista" title="Voltar para a listagem de produtos">
          <UndoIcon fontSize="large" />
        </Link>
      </Voltar>
      <Header>
        <div>
          <h1>PRODUTO</h1>
        </div>
      </Header>
      <Tab defaultValue={0}>
        <TabsLists>
          <Tabs>Cadastro</Tabs>
          <Tabs>Histórico</Tabs>
          <Tabs>Preço</Tabs>
        </TabsLists>
        <TabPanels value={0}>
          <Cadastro
            cadastro={produto}
            estoque_produto_equip={estoque_produto_equip}
          />
        </TabPanels>
        <TabPanels value={1}>
          <Historico id={Number(id)} nome_produto={produto?.nome} />
        </TabPanels>
        <TabPanels value={2}>
          <Preco id={Number(id)} />
        </TabPanels>
      </Tab>
    </Container>
  );
};

export default Configuracoes;
