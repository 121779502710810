import React from 'react';
import { Switch } from 'react-router-dom';
import { useAuth } from '../hooks/auth';

import Route from './Route';

import TokenSignIn from '../pages/Auth/TokenSignIn';
import SignIn from '../pages/Auth/SignIn';
import RedefinirSenha from '../pages/Auth/RedefinirSenha';
import EsqueciMinhaSenha from '../pages/Auth/EsqueciMinhaSenha';
import MeusDados from '../pages/MeusDados';
import Dashboard from '../pages/Dashboard';
import Agenda from '../pages/Agenda';
import ListaClientes from '../pages/Clientes/Lista';
import CadastroClientes from '../pages/Clientes/Cadastro';
import ResumoClientes from '../pages/Clientes/Detalhes';
import ListaPedidos from '../pages/Pedidos/Lista';
import CadastroPedidos from '../pages/Pedidos/Cadastro';
import Configuracoes from '../pages/Configuracoes';
import ListaAccounts from '../pages/Admin/Accounts/Lista';
import CadastroAccounts from '../pages/Admin/Accounts/Cadastro';
import ListaProdutos from '../pages/Produtos/Lista';
import CadastroProdutos from '../pages/Produtos/Detalhes';
import ListaUsuarios from '../pages/Usuarios/Lista';

const Routes: React.FC = () => {
  const { user, company, companies } = useAuth();
  const permissao = companies && company ? companies[company.id].role : '';
  const redirecionar = permissao === 'eget-admin' ? ListaAccounts : Dashboard;

  const eget = ['eget-admin'];
  const customers = ['customer-user', 'customer-admin'];

  return (
    <Switch>
      {/* ------------------------- ROTAS ABERTAS --------------------------- */}
      <Route
        exact
        path="/"
        component={user ? redirecionar : SignIn}
        isPrivate
      />
      <Route exact path="/login-suporte" component={TokenSignIn} />
      <Route exact path="/login" component={SignIn} />
      <Route exact path="/login/:confirm" component={SignIn} />
      {/* <Route exact path="/novo-usuario" component={SignUp} /> */}
      <Route exact path="/esqueci-minha-senha" component={EsqueciMinhaSenha} />
      <Route
        exact
        path="/redefinir-senha/:confirm"
        component={RedefinirSenha}
      />
      <Route
        roles={customers}
        exact
        path="/dashboard"
        component={Dashboard}
        isPrivate
      />
      <Route
        roles={customers}
        exact
        path="/agenda"
        component={Agenda}
        isPrivate
      />
      <Route
        roles={customers}
        exact
        path="/configuracoes"
        component={Configuracoes}
        isPrivate
      />
      <Route
        roles={customers}
        exact
        path="/produtos/lista"
        component={ListaProdutos}
        isPrivate
      />
      <Route
        roles={customers}
        exact
        path="/produtos/cadastro/:id/"
        component={CadastroProdutos}
        isPrivate
      />
      <Route
        roles={['customer-admin']}
        exact
        path="/usuarios"
        component={ListaUsuarios}
        isPrivate
      />
      <Route
        roles={['customer-user', 'customer-admin', 'eget-admin']}
        exact
        path="/meus-dados"
        component={MeusDados}
        isPrivate
      />
      <Route
        roles={customers}
        exact
        path="/clientes/lista"
        component={ListaClientes}
        isPrivate
      />
      <Route
        roles={customers}
        exact
        path="/clientes/cadastro"
        component={CadastroClientes}
        isPrivate
      />
      <Route
        roles={customers}
        exact
        path="/clientes/cadastro/:id"
        component={CadastroClientes}
        isPrivate
      />
      <Route
        roles={customers}
        exact
        path="/clientes/resumo/:id/:status"
        component={ResumoClientes}
        isPrivate
      />
      <Route
        roles={customers}
        exact
        path="/pedidos/lista"
        component={ListaPedidos}
        isPrivate
      />
      <Route
        roles={customers}
        exact
        path="/pedidos/cadastro"
        component={CadastroPedidos}
        isPrivate
      />
      <Route
        roles={customers}
        exact
        path="/pedidos/cadastro/:id"
        component={CadastroPedidos}
        isPrivate
      />
      <Route
        roles={eget}
        exact
        path="/admin/accounts/lista"
        component={ListaAccounts}
        isPrivate
      />
      <Route
        roles={eget}
        exact
        path="/admin/accounts/cadastro"
        component={CadastroAccounts}
        isPrivate
      />
      <Route
        roles={eget}
        exact
        path="/admin/accounts/cadastro/:id"
        component={CadastroAccounts}
        isPrivate
      />
    </Switch>
  );
};

export default Routes;
