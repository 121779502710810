import React, { ButtonHTMLAttributes } from 'react';
import { Container } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: string;
};

const BotaoNovo: React.FC<ButtonProps> = ({ children, loading, ...rest }) => (
  <Container disabled={!!loading} type="button" {...rest}>
    {loading || children}
  </Container>
);

export default BotaoNovo;
