import React, { useState, useMemo, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import {
  ContainerFiltros,
  ContainerBotoes,
  ContainerResumoFiltros,
  ContainerSelectMultiplo
} from './styles';
import BotaoSalvar from '../../../../components/Botoes/BotaoSalvar';
import FiltroSincronizacao from '../../../../components/Sincronizacao/FiltroSincronizacao';
import ISelectOption from '../../../../interfaces/selectOptions';
import Select from '../../../../components/Inputs/ControlledSelect';
import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/auth';

interface IFiltroSincronizacao {
  sincronizado: boolean;
  sincronizando: boolean;
  erro: boolean;
}

interface IFiltros {
  filtro_sincronizacao: IFiltroSincronizacao;
  setFiltroSincronizacao: (filtro_sincronizacao: IFiltroSincronizacao) => void;
  setMostraFiltros: (mostra: boolean) => void;
  setGrupoCliente: (grupo_cliente: number []) => void;
  setVendedor: (vendedor: number []) => void;
  setCondicao:(condicao:ISelectOption) => void;
  isAdmin: boolean;
  setPage:(page: number) => void;
  mostra_filtros: boolean;
  condicao:ISelectOption;
  grupo_cliente: number [];
  vendedor:number [];
}

const Filtros: React.FC<IFiltros> = ({
  setFiltroSincronizacao,
  setMostraFiltros,
  setGrupoCliente,
  setVendedor,
  setCondicao,
  setPage,
  isAdmin,
  mostra_filtros,
  filtro_sincronizacao,
  condicao,
  grupo_cliente,
  vendedor
}) => {

  const [filtro_sincronizacao_temp, setFiltroSincronizacaoTemp] = useState<IFiltroSincronizacao>(filtro_sincronizacao);
  const [condicao_temp, setCondicaoTemp] = useState<ISelectOption>(condicao);
  const [lista_grupo_clientes, setListaGrupoClientes] = useState<ISelectOption []>([]);
  const [grupo_cliente_temp, setGrupoClienteTemp] = useState<number []>(grupo_cliente);
  const [lista_vendedor, setListaVendedor] = useState<ISelectOption []>([]);
  const [vendedor_temp, setVendedorTemp] = useState<number []>(vendedor);
  const {user} = useAuth();
  const condicoes = [
    {label:'Todos', value: null},
    {label: 'Ativos', value:true},
    {label: 'Inativos', value:false}
  ];

  const getEntityName = entity_temp => entity_temp.map(itemEntity => itemEntity.label)
  const createArrayOfEntityWithComma = entity =>  getEntityName(entity).join(', ');

  const findCondicao = item => item.value === condicao.value
  const getCondicao = condicao_ => ` Condição: ${condicao_[0].label}`

  function handleAplicarFiltros(): void {
    setPage(1);
    setVendedor(vendedor_temp.map(item => item.value));
    setGrupoCliente(grupo_cliente_temp.map(item => item.value));
    setCondicao(condicao_temp);
    setFiltroSincronizacao(filtro_sincronizacao_temp);
    setMostraFiltros(false);
  }

  useEffect(() => {
    async function getGrupoCliente(): Promise<void>{
      const response = await api.get('grupo-cliente');
      setListaGrupoClientes(response.data);
    }
    getGrupoCliente()
  }, [setListaGrupoClientes])

  useEffect(() => {
    async function getVendedor(): Promise<void>{
      if(isAdmin){
        const response = await api.get('colaborador');
        setListaVendedor(response.data.map(item => {
          return { label: item.id === user.colaborador_id ? 'MEUS CLIENTES' : item.pessoa?.nome, value: item.pessoa?.id }
        }));
      }else{
        setListaVendedor([{label:'MEUS CLIENTES', value:user.colaborador_id}])
      }
    }
    getVendedor();
  },[setListaVendedor, user.colaborador_id, isAdmin]);

  const filtros_ativos = useMemo(() => {
    let sincronizacao = '';
    sincronizacao += filtro_sincronizacao.sincronizado ? ' Integrado;' : '';
    sincronizacao += filtro_sincronizacao.sincronizando ? ' Sincronizando;' : '';
    sincronizacao += filtro_sincronizacao.erro ? ' Erro;' : '';
    sincronizacao += `${getCondicao(condicoes.filter(findCondicao))};`;
    sincronizacao += grupo_cliente_temp.length > 0 ? ` Grupo de Clientes: ${createArrayOfEntityWithComma(grupo_cliente_temp)};` : '';
    sincronizacao += vendedor_temp.length > 0 ? ` Vendedores(a): ${createArrayOfEntityWithComma(vendedor_temp)}`: '';
    return `Filtros ativos: Status sincronização: ${sincronizacao}`
  }, [filtro_sincronizacao, findCondicao, createArrayOfEntityWithComma]);

  return (
    <>
      <ContainerResumoFiltros>
        <p>{filtros_ativos}</p>
      </ContainerResumoFiltros>
      { mostra_filtros &&
        <ContainerFiltros>
          <h1>Filtrar por</h1>
          <FiltroSincronizacao
            filtro={filtro_sincronizacao_temp}
            setFiltro={setFiltroSincronizacaoTemp}
          />
           <ContainerSelectMultiplo style={{zIndex:999}}>
        <Grid item xs={12} sm={12} md={10} lg={10}>
          <Select
            defaultValue={condicao_temp}
            label="Condição"
            isSearchable={false}
            options={condicoes}
            closeMenuOnSelect={false}
            placeholder="Nenhuma condição selecionada..."
            onChange={setCondicaoTemp}
          />
        </Grid>
      </ContainerSelectMultiplo>
       <ContainerSelectMultiplo style={{zIndex:998}}>
        <Grid item xs={12} sm={12} md={10} lg={10}>
          <Select
            defaultValue={grupo_cliente_temp}
            label="Grupos de Clientes"
            isSearchable={false}
            options={lista_grupo_clientes}
            closeMenuOnSelect={false}
            isMulti
            placeholder="Nenhum grupo selecionado..."
            onChange={setGrupoClienteTemp}
          />
        </Grid>
      </ContainerSelectMultiplo>
          <ContainerSelectMultiplo style={{zIndex:997}}>
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <Select
                defaultValue={vendedor_temp}
                label="Vendedores"
                isSearchable={false}
                options={[...lista_vendedor, {label:'SEM VENDEDOR DEFINIDO', value:'null'}]}
                closeMenuOnSelect={false}
                isMulti
                placeholder="Nenhum vendedor selecionado..."
                onChange={setVendedorTemp}
              />
            </Grid>
          </ContainerSelectMultiplo>
          <ContainerBotoes>
            <BotaoSalvar onClick={handleAplicarFiltros}>
              Aplicar Filtros
            </BotaoSalvar>
          </ContainerBotoes>
        </ContainerFiltros>
      }
    </>
  );
}

export default Filtros;
